import { createPromiseAction } from '@adobe/redux-saga-promise'

export const CURRENCIES_CALL = 'CURRENCIES_CALL'
export const ALL_CURRENCIES_CALL = 'ALL_CURRENCIES_CALL'

export const CURRENCY_RATES_CALL = 'CURRENCY_RATES_CALL'
export const CURRENCY_RATE_CALL = 'CURRENCY_RATE_CALL'
export const CURRENCY_RATE_ADD_CALL_PROMISE = 'CURRENCY_RATE_ADD_CALL_PROMISE'
export const CURRENCY_RATE_EDIT_CALL_PROMISE = 'CURRENCY_RATE_EDIT_CALL_PROMISE'
export const CURRENCY_RATE_DELETE_CALL_PROMISE = 'CURRENCY_RATE_DELETE_CALL_PROMISE'
export const CURRENCY_RATE_LOADING_SWITCH = 'CURRENCY_RATE_LOADING_SWITCH'

export const currencyRateAddPromise = createPromiseAction(CURRENCY_RATE_ADD_CALL_PROMISE)
export const currencyRateEditPromise = createPromiseAction(CURRENCY_RATE_EDIT_CALL_PROMISE)
export const currencyRateDeletePromise = createPromiseAction(CURRENCY_RATE_DELETE_CALL_PROMISE)

export function currenciesCall() {
  return { type: CURRENCIES_CALL }
}

export function allCurrenciesCall() {
  return { type: ALL_CURRENCIES_CALL }
}

export function currencyRatesCall() {
  return { type: CURRENCY_RATES_CALL }
}

export function currencyRateLoadingSwitch(status) {
  return { type: CURRENCY_RATE_LOADING_SWITCH, payload: { status } }
}
