import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useDidUpdate, useSearch } from 'hooks'
import {
  Button,
  Divider,
  EmptyContent,
  Box,
  useSnackbar,
  GridList,
  Loader
} from 'components/common'
import { commonContactsSelector, commonContactSelector } from 'store/common/contacts/selectors'
import { usePermission } from 'components/common/permission'
import {
  commonContactAddPromise,
  commonContactActionInStepPromise,
  commonContactEditPromise,
  commonContactDeletePromise
} from 'store/common/contacts/actions'
import { useTranslation } from 'react-i18next'
import AddEditContact from './contact-add-edit'
import ContactListCard from './contact-list-card'

const ContactsSection = ({ title, moduleName, accountId, isStep }) => {
  const dispatch = useDispatch()
  const addSnackbar = useSnackbar()
  const search = useSearch()

  const { t } = useTranslation()

  const { data, inStepData } = useSelector(
    (store) => commonContactsSelector(store, moduleName),
    isEqual
  )
  const { isLoading } = useSelector((store) => commonContactSelector(store, moduleName), isEqual)
  const [contacts, setContacts] = useState((isStep ? inStepData : data) || [])

  const [action, setAction] = useState(null)

  const { filteredList: filteredContacts, SearchBar } = search(contacts, [
    {
      type: 'text',
      keyName: 'full_name',
      condition: 'contains',
      placeholder: 'Search...'
    }
  ])

  const findContactById = useCallback(
    (contactId) => {
      if (contactId && contactId !== 'new') {
        return contacts.find((c) => c.id === contactId)
      }
      return null
    },
    [contacts]
  )

  // basic create (no step)
  const createContact = useCallback(
    async (finalData) => {
      try {
        await dispatch(
          commonContactAddPromise({
            moduleName,
            finalData
          })
        )

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: t('Contact created.')
        })
      } catch (error) {
        // console.error(error);
      }
    },
    [moduleName]
  )

  // basic edit (no step)
  const editContact = useCallback(
    async (finalData, contactId) => {
      try {
        await dispatch(
          commonContactEditPromise({
            moduleName,
            finalData,
            contactId
          })
        )

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: t('Contact edited.')
        })
      } catch (error) {
        // console.error(error);
      }
    },
    [moduleName]
  )

  // basic delete (no step)
  const deleteContact = useCallback(
    async (contactId) => {
      try {
        await dispatch(commonContactDeletePromise({ moduleName, contactId }))

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: t('Contact deleted.')
        })
      } catch (error) {
        // console.error(error);
      }
    },
    [moduleName]
  )

  const findContactByIndex = useCallback(
    (IndexAndMethod) => {
      if (IndexAndMethod && IndexAndMethod !== 'new') {
        return { ...contacts[IndexAndMethod.index], ...IndexAndMethod }
      }
      return null
    },
    [contacts]
  )

  // create/edit/delete when step
  const contactActionOnStep = useCallback(
    async (contact) => {
      try {
        await dispatch(
          commonContactActionInStepPromise({
            moduleName,
            finalData: contact
          })
        )

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: t('Changes saved.')
        })
      } catch (error) {
        // console.error(error);
      }
    },
    [moduleName]
  )

  // Update contact list from store
  useDidUpdate(() => {
    setContacts(isStep ? inStepData : data)
  }, [isStep ? inStepData : data])

  const canCompanyAccountContactManage = usePermission('company_account_contact-manage')

  return (
    <>
      {!action && isLoading && <Loader fixed />}
      {SearchBar}
      <Divider className="mb-sm" />
      <Box
        title={title}
        rightSection={
          canCompanyAccountContactManage && (
            <Button variant="link" onClick={() => setAction('new')}>
              + {t('Add Contact')}
            </Button>
          )
        }
      >
        <div className="card-list__container">
          {contacts.length === 0 ? (
            <EmptyContent
              title={t('This list of contacts is empty')}
              description={t(
                'Click "+ Add Contact" at the top right to add contact(s) to this list'
              )}
            />
          ) : filteredContacts.length === 0 ? (
            <EmptyContent title={t('No contacts found')} description={t('Try something else.')} />
          ) : (
            <GridList columns={{ md: 2, lg: 3, xl: 4 }} padding="px-0 py-xs" gap="sm">
              {filteredContacts.map((contact, index) => (
                <ContactListCard
                  key={contact.id || index}
                  data={contact.id ? contact : { ...contact, index }}
                  onEdit={setAction}
                  onDelete={isStep ? contactActionOnStep : deleteContact}
                />
              ))}
            </GridList>
          )}
        </div>
      </Box>
      {Boolean(action) && (
        <AddEditContact
          accountId={accountId}
          isOpen={Boolean(action)}
          close={() => setAction(null)}
          contactData={isStep ? findContactByIndex(action) : findContactById(action)}
          createAction={isStep ? contactActionOnStep : createContact}
          editAction={isStep ? contactActionOnStep : editContact}
          isLoading={isLoading}
          isStep={isStep}
        />
      )}
    </>
  )
}

ContactsSection.propTypes = {
  title: PropTypes.string,
  moduleName: PropTypes.string.isRequired,
  isStep: PropTypes.bool
}

ContactsSection.defaultProps = {
  title: 'Contacts',
  isStep: undefined
}

export default ContactsSection
ContactsSection.displayName = 'ContactsSection'
