/* eslint-disable func-names */
import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
// eslint-disable-next-line import/no-cycle
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import {
  COMMON_CONTACTS_GET,
  COMMON_CONTACT_GET,
  commonContactAddPromise,
  commonContactEditPromise,
  commonContactDeletePromise,
  commonContactActionInStepPromise,
  commonContactLoadingSwitch
} from './actions'

const prefix = '/account/contact'

// Loading wrapper
function* commonContactGeneratorWrapper(moduleName, gen) {
  yield put(commonContactLoadingSwitch(moduleName, true))
  yield gen()
  yield put(commonContactLoadingSwitch(moduleName, false))
}

function* commonContactsCallGenerator(action) {
  const {
    type,
    payload: { moduleName, accountId }
  } = action

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', prefix, {
      params: { account_id: accountId }
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d.data } })
}

function* commonContactGetGenerator(action) {
  const {
    type,
    payload: { moduleName, userId }
  } = action

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/${userId}`)
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d } })
}

function* commonContactAddPromiseGenerator(action) {
  const {
    type,
    payload: { moduleName, finalData }
  } = action

  yield commonContactGeneratorWrapper(moduleName, function* () {
    try {
      const { data } = yield call(axiosSagaRequest, 'post', `${prefix}`, finalData)
      yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
      yield call(resolvePromiseAction, action, data)
    } catch (error) {
      yield put(errorAdd(error, action))
      yield call(rejectPromiseAction, action, error)
    }
  })
}

function* commonContactEditPromiseGenerator(action) {
  const {
    type,
    payload: { moduleName, finalData, contactId }
  } = action

  yield commonContactGeneratorWrapper(moduleName, function* () {
    try {
      const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/${contactId}`, finalData)
      yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
      yield call(resolvePromiseAction, action, data)
    } catch (error) {
      yield put(errorAdd(error, action))
      yield call(rejectPromiseAction, action, error)
    }
  })
}

function* commonContactDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, contactId }
    } = action

    yield commonContactGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/${contactId}`)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, contactId } })
        yield call(resolvePromiseAction, action, contactId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* commonContactActionInStepPromiseGenerator(action) {
  const {
    type,
    payload: { moduleName, finalData }
  } = action

  yield commonContactGeneratorWrapper(moduleName, function* () {
    try {
      const { data } = yield call(axiosSagaRequest, 'post', `/account/${moduleName}`, finalData)
      yield put({
        type: `${type}_RESPONSE`,
        payload: {
          moduleName,
          data: { ...data, index: finalData.index, method: finalData.method }
        }
      })
      yield call(resolvePromiseAction, action, data)
    } catch (error) {
      yield put(errorAdd(error, action))
      yield call(rejectPromiseAction, action, error)
    }
  })
}

export default function* commonContactsWatcherSaga() {
  yield takeLatest(COMMON_CONTACTS_GET, commonContactsCallGenerator)
  yield takeEvery(COMMON_CONTACT_GET, commonContactGetGenerator)
  yield takeEvery(commonContactAddPromise, commonContactAddPromiseGenerator)
  yield takeEvery(commonContactActionInStepPromise, commonContactActionInStepPromiseGenerator)
  yield takeEvery(commonContactEditPromise, commonContactEditPromiseGenerator)
  yield takeEvery(commonContactDeletePromise, commonContactDeletePromiseGenerator)
}
