import { useContext } from 'react'
import { SnackbarContext } from './snackbar-context'

/**
 * Snackbar hook to create snackbars
 */

const useSnackbar = () => {
  const { addSnackbar } = useContext(SnackbarContext)
  return addSnackbar
}

// Modal.propTypes = {
//     open: PropTypes.bool,
//     close: PropTypes.func,
//     type: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
//     size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
//     title: PropTypes.string,
//     description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
//     className: PropTypes.string,
//     children: PropTypes.oneOfType([
//         PropTypes.arrayOf(PropTypes.element),
//         PropTypes.element
//     ]),
//     disableBackdropClick: PropTypes.bool,
//     actions: PropTypes.arrayOf(PropTypes.shape({
//         text: PropTypes.string.isRequired,
//         type: PropTypes.string,
//         onClick: PropTypes.func
//     }))
// };
//
// Modal.defaultProps = {
//     open: false,
//     close: undefined,
//     type: 'default',
//     size: 'md',
//     title: '',
//     description: '',
//     className: '',
//     children: undefined,
//     disableBackdropClick: false,
//     actions: []
// };

export default useSnackbar
