/* eslint-disable no-useless-escape */
import Joi from 'joi'
import joiDefaultErrorMessages from './messages'

export const emailSchema = Joi.string()
  .max(100)
  .email({ tlds: { allow: false } })
  .messages(joiDefaultErrorMessages)

export const passwordSchema = Joi.string()
  .min(8)
  .regex(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]).{8,}$/
  )
  .required()
  .messages({
    ...joiDefaultErrorMessages,
    'string.pattern.base': 'Must include a-z, A-Z, 0-9, and symbol'
  })

export const editPasswordSchema = Joi.string()
  .min(8)
  .regex(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]).{8,}$/
  )
  .allow('')
  .messages({
    ...joiDefaultErrorMessages,
    'string.pattern.base': 'Must include a-z, A-Z, 0-9, and symbol'
  })
// "password": "required, min-characters:8, Requires: Mixed case characters and numbers",

export const ftpSchema = Joi.object({
  hostname: Joi.string().allow('').optional(),
  username: Joi.string().allow('').optional(),
  password: Joi.string().allow('').optional(),
  protocol: Joi.string().allow('').optional(),
  port: Joi.string().allow('').optional()
})

export const apiSchema = Joi.object({
  api_id: Joi.number().allow('').optional()
  // username: Joi.string().allow('').optional(),
  // password: Joi.string().allow('').optional()
})
