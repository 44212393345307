/* eslint-disable func-names */
import { call, cancel, fork, put, take } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'

export function takeLatestPerKey(patternOrChannel, worker, keySelector, ...args) {
  return fork(function* () {
    const tasks = {}

    while (true) {
      const action = yield take(patternOrChannel)
      const key = yield call(keySelector, action)

      const task = tasks[key]
      if (task && task.isRunning()) {
        yield cancel(task)
      }

      tasks[key] = yield fork(worker, ...args, action)
    }
  })
}

export function axiosGetGenerator(url, params) {
  return function* gen(action) {
    let d
    try {
      const { data } = yield call(axiosSagaRequest, 'get', url, params)
      d = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }
    const { type } = action
    yield put({ type: `${type}_RESPONSE`, payload: d })
  }
}
