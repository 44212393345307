/* eslint-disable func-names */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction
} from '@adobe/redux-saga-promise'
// eslint-disable-next-line import/no-cycle
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import { appendObjectToFormData } from 'utils/helpers'
import { initSettingsCompanyIdSelector } from 'store/general/selectors'
import {
  ACCOUNT_COLLECTION_GET,
  ACCOUNT_GET,
  accountAddPromise,
  accountEditPromise,
  accountStatusPromise,
  accountDeletePromise,
  accountLoadingSwitch
} from './actions'

const accountPrefix = '/account'

// Loading wrapper
function* accountLoadingGeneratorWrapper(moduleName, gen) {
  yield put(accountLoadingSwitch(moduleName, true))
  yield gen()
  yield put(accountLoadingSwitch(moduleName, false))
}

function* accountCollectionGetGenerator(action) {
  const {
    type,
    payload: { moduleName, page, status_id }
  } = action

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${accountPrefix}/${moduleName}`, {
      params: {
        page,
        status: status_id
      }
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }

  yield put({
    type: `${type}_RESPONSE`,
    payload: { moduleName, data: d?.data, pagination: d?.pagination }
  })
}

function* accountGetGenerator(action) {
  const {
    type,
    payload: { moduleName, id, body }
  } = action

  let d
  try {
    let accountId
    if (id !== undefined) {
      accountId = id
    } else {
      accountId = yield select(initSettingsCompanyIdSelector)
    }

    if (moduleName === 'company') {
      const { data } = yield call(axiosSagaRequest, 'get', `${accountPrefix}/${moduleName}`)
      d = data
    } else {
      const { data } = yield call(
        axiosSagaRequest,
        'post',
        `${accountPrefix}/${moduleName}/${accountId}`,
        body
      )
      d = data
    }
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d.data } })
}

function* accountAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, accountData }
    } = action
    yield accountLoadingGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(
          axiosSagaRequest,
          'post',
          `${accountPrefix}/${moduleName}`,
          accountData
        )

        if (accountData.step) {
          if (accountData.step === 'final') {
            yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
            if(moduleName !== 'supplier') {
              yield put({ type: 'COMMON_USER_CLEAR_STEP_DATA', payload: { moduleName } })
            }else {
              yield put({ type: 'COMMON_CONTACT_CLEAR_STEP_DATA', payload: { moduleName } })              
            }
          } else if (accountData.step === 'financial_detail') {
            yield put({
              type: 'FINANCIAL_DETAILS_EDIT_PROMISE.TRIGGER_RESPONSE',
              payload: { moduleName, finalData: accountData }
            })
          } else {
            yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: accountData } })
          }
        }
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* accountDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, id }
    } = action

    yield accountLoadingGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${accountPrefix}/account/${id}`)
        // yield put({ type: `${type}_RESPONSE`, payload: { moduleName, id } })
        yield call(resolvePromiseAction, action, id)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* accountEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, id, data }
    } = action

    yield accountLoadingGeneratorWrapper(moduleName, function* () {
      try {
        if (moduleName === 'company') {
          const { finalData, logo } = data
          const formData = appendObjectToFormData(finalData)
          formData.append('image', logo.src)

          yield call(axiosSagaRequest, 'post', `${accountPrefix}/${moduleName}`, formData)
          yield put({
            type: 'SETTINGS_EDIT.TRIGGER_RESPONSE',
            payload: { moduleName, id, data: finalData.settings }
          })
        } else {
          yield call(axiosSagaRequest, 'patch', `${accountPrefix}/${moduleName}/${id}`, data)
        }
        yield put({
          type: `${type}_RESPONSE`,
          payload: { moduleName, id, data }
        })
        yield call(resolvePromiseAction, action, id)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* accountStatusPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, id, status }
    } = action
    yield accountLoadingGeneratorWrapper(moduleName, function* () {
      try {
        yield call(
          axiosSagaRequest,
          'get',
          `${accountPrefix}/account/${id}/${status.toLowerCase()}`
        )
        // yield put({
        //   type: `${type}_RESPONSE`,
        //   payload: { moduleName, id, statusData }
        // })
        yield call(resolvePromiseAction, action, id)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* accountWatcherSaga() {
  yield takeLatest(ACCOUNT_COLLECTION_GET, accountCollectionGetGenerator)
  yield takeLatest(ACCOUNT_GET, accountGetGenerator)
  yield takeLatest(accountAddPromise, accountAddPromiseGenerator)
  yield takeLatest(accountEditPromise, accountEditPromiseGenerator)
  yield takeLatest(accountStatusPromise, accountStatusPromiseGenerator)
  yield takeLatest(accountDeletePromise, accountDeletePromiseGenerator)
}
