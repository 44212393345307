/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  PRICING_LEVELS_CALL,
  PRICING_LEVEL_ADD_PROMISE,
  PRICING_LEVEL_EDIT_PROMISE,
  PRICING_LEVEL_DELETE_PROMISE,
  PRICING_LEVEL_SORT_PROMISE,
  PRICING_LEVEL_LOADING_SWITCH
} from './actions'

const initialState = {
  pricingLevels: {
    isFetched: false,
    isLoading: false,
    data: []
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case PRICING_LEVELS_CALL: {
      state.pricingLevels.isFetched = false
      state.pricingLevels.data = []
      return state
    }

    case `${PRICING_LEVELS_CALL}_RESPONSE`: {
      state.pricingLevels.isFetched = true
      state.pricingLevels.data = payload.data || []
      return state
    }

    case `${PRICING_LEVEL_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { pLevel }
      } = payload

      state.pricingLevels.data.push({
        ...pLevel
      })
      return state
    }

    case `${PRICING_LEVEL_SORT_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { pLevel }
      } = payload
      return state
    }

    case `${PRICING_LEVEL_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { price_level }
      } = payload
      state.pricingLevels.data = state.pricingLevels.data.map((mF) =>
        mF.id === price_level.id
          ? {
              thumbnails: mF.thumbnails,
              ...price_level
            }
          : mF
      )
      return state
    }

    case `${PRICING_LEVEL_DELETE_PROMISE}.TRIGGER_RESPONSE`: {
      const { folderId } = payload
      state.pricingLevels.data = state.pricingLevels.data.filter((mF) => mF.id !== folderId)
      return state
    }

    case PRICING_LEVEL_LOADING_SWITCH: {
      const { status } = payload
      state.pricingLevels.isLoading = status !== undefined ? status : !state.pricingLevels.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
