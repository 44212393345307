/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Page Sub Header component
 * @param {Boolean} className - class for container div
 * @param {String} title - Sub Header Title
 * @param {Node} leftSection - Left Section for header Any node
 * @param {Node} rightSection - Right Section for header Any node
 * @param {Node} children - Any node
 */

const PageSubHeader = memo(({ className, title, leftSection, rightSection, children }) => (
  <>
    <div className={`page__sub__header ${className}`}>
      <div>
        {typeof title === 'string' ? <h1 className="page__sub__header__title">{title}</h1> : title}
        {leftSection && leftSection}
      </div>
      <div>{rightSection && rightSection}</div>
    </div>
    {children && children}
  </>
))

PageSubHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  leftSection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ]),
  rightSection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

PageSubHeader.defaultProps = {
  className: '',
  leftSection: undefined,
  rightSection: undefined,
  children: undefined
}

export default PageSubHeader
