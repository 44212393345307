import { useCallback } from 'react'
import * as XLSX from 'xlsx'

const useParseFile = () => {
  const handleUploadFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheet = workbook.SheetNames[0]
        const elements = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet])
        resolve(elements) // Resolve with parsed elements
      }

      reader.onerror = (error) => {
        reject(error) // Reject with error if any
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])

  return {
    handleUploadFile
  }
}

export default useParseFile
