import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TabsContext from './tabs-context'
import Tab from './tab'
import TabTitle from './tab-title'

/**
 * Tabs component
 * @param {Number} defaultTabIndex - Default visible tab index
 * @param {Number} type - Tabs type 1 / 2
 * @param {String} className - Custom className
 * @param {Function} beforeTabChange - Array of Tab components
 * @param {Tab} children - Array of Tab components
 */

const Tabs = ({
  defaultTabIndex,
  type,
  className,
  beforeTabChange,
  children,
  onTabClick,
  tabColor
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const handleTabClick = (id) => {
    if (onTabClick) {
      onTabClick(id)
    }
  }

  return (
    <TabsContext.Provider value={{ activeTabIndex, setActiveTabIndex }}>
      <div className={`tabs v-${type} ${className}`}>
        <ul className="tabs__list">
          {children.map((item, index) => {
            if (item) {
              return (
                <TabTitle
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  onTabClick={() => handleTabClick(item.props.id)}
                  beforeTabChange={beforeTabChange}
                  tabColor={tabColor}
                  {...item.props}
                />
              )
            }
            return <></>
          })}
        </ul>
        {children[activeTabIndex].props.children && (
          <div className="tabs__panels">{children[activeTabIndex]}</div>
        )}
      </div>
    </TabsContext.Provider>
  )
}

Tabs.propTypes = {
  defaultTabIndex: PropTypes.number,
  type: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  beforeTabChange: PropTypes.func,
  onTabClick: PropTypes.func
}

Tabs.defaultProps = {
  defaultTabIndex: 0,
  type: 1,
  className: '',
  beforeTabChange: undefined,
  onTabClick: () => {}
}

Tabs.Tab = Tab

export default Tabs
