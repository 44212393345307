import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { withDataFetch } from 'components/HOC'
import { initSettingsAdditionalSelector } from 'store/general/selectors'
import { Dialog, Divider, Badge } from 'components/common'
import { formatDate } from 'utils/helpers'
import { CardDetails } from 'components/complex'
import { useTranslation } from 'react-i18next'

const badgeColorsByStatus = {
  Active: 'success',
  Inactive: 'neutral',
  Deleted: 'danger'
}

const PreviewCard = ({ isOpen, close, card, id, onAction, className, fetchedData, hasManagePermission }) => {
  const { t } = useTranslation()

  const { data } = fetchedData.accountPreviewData

  const { status: statusList } = useSelector(initSettingsAdditionalSelector, isEqual)

  const status = statusList.find((st) => st.id === data.status_id)

  const dataStructure = useMemo(() => {
    if (card === 'Supplier') {
      const {
        account_detail: {
          address_1,
          address_2,
          town,
          county,
          postal_code,
          country,

          reg_number,
          vat_number,
          min_order,
          discount,
          discount_desc,
          credit_limit,
          delivery_time,
          payment_term_day,
          payment_term_date
        }
      } = data
      return [
        {
          id: 1,
          title: t('Address Info'),
          data: {
            address_1: address_1 || '-',
            address_2: address_2 || '-',
            town: town || '-',
            county: county || '-',
            postal_code: postal_code || '-',
            country: country.name || ''
          }
        },
        {
          id: 2,
          title: t('Account Details'),
          data: {
            account_number_with_supplier: reg_number || '-',
            supplier_vat_number: vat_number || '-',
            minimum_order_value: min_order || '-',
            settlement_discount: discount || '-',
            settlement_discount_description: discount_desc || '-',
            credit_limit: credit_limit || '-',
            payment_terms: payment_term_day || '-',
            payment_date: payment_term_date || '-',
            delivery_time: delivery_time || '-'
          }
        }
      ]
    }

    // branch preveiw field names
    if (card === 'Branch') {
      const {
        account_detail: {
          reg_number,
          vat_number,
          order_recipinet,
          address_1,
          address_2,
          town,
          county,
          postal_code,
          country
        }
      } = data

      return [
        {
          id: 1,
          title: t('General Info'),
          data: {
            account_number: reg_number || '-',
            price_level: '-',
            users: data.users.length,
            vat_number: vat_number || '-',
            reg_number: reg_number || '-',
            order_recipient_email: order_recipinet || '-'
          }
        },
        {
          id: 2,
          title: t('Address Info'),
          data: {
            address_1: address_1 || '-',
            address_2: address_2 || '-',
            town: town || '-',
            county: county || '-',
            postal_code: postal_code || '-',
            country: country.name || ''
          }
        }
      ]
    }

    // Todo
    if (card === 'Customer') {
      const {
        account_detail: {
          address_1,
          address_2,
          town,
          county,
          postal_code,
          country,
          vat_number,
          min_order,
          discount,
          discount_desc,
          credit_limit,
          delivery_time
        }
      } = data

      return [
        {
          id: 1,
          title: t('Address Info'),
          data: {
            address_1: address_1 || '-',
            address_2: address_2 || '-',
            town: town || '-',
            county: county || '-',
            postal_code: postal_code || '-',
            country: country.name || ''
          }
        },
        {
          id: 2,
          title: t('Account Details'),
          // todo
          data: {
            account_number_with_supplier: '-',
            supplier_vat_number: vat_number || '-',
            minimum_order_value: min_order || '-',
            settlement_discount: discount || '-',
            settlement_discount_description: discount_desc || '-',
            credit_limit: credit_limit || '-',
            payment_terms: '-',
            payment_data: '-',
            delivery_time: delivery_time || '-'
          }
        }
      ]
    }

    return null
  }, [])

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="right"
      title={`${card} ${t('Preview')}`}
      actions={hasManagePermission && [
        {
          variant: 'tertiary',
          text: `${t('Edit')} ${card}` ,
          onClick: () => onAction('edit')(id)
        }
      ]}
      className={`preview-dialog ${className}`}
    >
      <div className="preview-dialog__content container p-lg">
        <div>
          <div className="list-card__body">
            <div className="list-card__title">
              <span className="list-card__title__primary">{data.name}</span>
            </div>
            <div className="preview-heading-info">
              <table>
                <tbody>
                  <tr>
                    <td className="table-details__title">
                      {t('Date Created')}: {formatDate(data.created_at)}
                    </td>
                  </tr>
                  <tr>
                    <td className="table-details__value--website">
                      {data.account_detail.website_url}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Badge title={status.name} type={badgeColorsByStatus[status.name]} />
          </div>
          {dataStructure.map((section) => (
            <div className="list-card__body mt-lg" key={section.id}>
              <div className="list-card__title">
                <span className="list-card__title__primary">{section.title}</span>
              </div>
              <Divider className="mt-xxs" />
              <CardDetails data={section.data} />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  )
}

PreviewCard.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  card: PropTypes.string,
  className: PropTypes.string,
  hasManagePermission: PropTypes.bool
}

PreviewCard.defaultProps = {
  isOpen: true,
  card: '',
  className: '',
  hasManagePermission: true
}

export default (props) => {
  return withDataFetch([
    {
      method: 'get',
      key: 'accountPreviewData',
      url: `/account/account/${props.id}`
    }
  ])(PreviewCard)(props)
}
