import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { bytesToSize, generateImageUrl } from 'utils/helpers'
import { useDidUpdate } from 'hooks'
import { useTranslation } from 'react-i18next'
import Button from '../button'

// import { render } from 'node-sass'

/**
 * Image Select component
 * @param {String} label - Label text
 * @param {String} initialImageUrl - Initial url
 * @param {Function} onChange - Image change handler
 * @param {String} config - Validation configs for image select {extensions: ['jpg', 'png'], maxSizeMB: 2, sizes, sizes: {maxWidth: 256, maxHeight: 256}}
 * @param {String} className - Custom classname for section
 * @param {Boolean} isRound - Circular style for image
 * @param {Boolean} disabled - Disable state
 */

function ImageSelect({ label, initialImageUrl, onChange, config, className, isRound, disabled, isCompanyLogo }) {
  const [error, setError] = useState('')
  const { t } = useTranslation()

  const [newImage, setNewImage] = useState({
    src: {},
    url: initialImageUrl?.includes(';base64,') ? initialImageUrl : generateImageUrl(initialImageUrl)
  })

  const infoText = useMemo(() => {
    const extensions = `Accepted file formats: ${config.extensions.join(', ')} `
    const sizes = config.sizes && `(max size ${config.sizes.maxWidth}x${config.sizes.maxHeight}, `
    const fileSize = `${config.maxSizeMB}MB)`

    return `${extensions}${sizes || '('}${fileSize}`
  }, [config])

  const id = `_${Math.random().toString(36).substr(2, 9)}`

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }

  async function validate(file) {
    let valid = true
    let errorMessage = ''

    if (file) {
      const fileSize = file.size
      const fileType = file.type
      const maxSize = config.maxSizeMB * 1024 * 1024
      const allowedExtensions = config.extensions.map((ex) => `image/${ex}`)

      if (!allowedExtensions.includes(fileType)) {
        valid = false
        errorMessage += `\n* ${t('Only f files are allowed', { files: config.extensions.join(', ')})}`
      } else if (config.sizes) {
        const { maxWidth, maxHeight } = config.sizes
        const img = await addImageProcess(URL.createObjectURL(file))
        if (img.width > maxWidth || img.height > maxHeight) {
          valid = false
          errorMessage += `\n* ${t('Max dimensions are WxH pixels', { maxWidth, maxHeight })}`
        }
      }
      if (fileSize > maxSize) {
        valid = false
        errorMessage += `\n* ${t('Max file size is', { size: bytesToSize(maxSize) })}`
      }
    } else {
      valid = false
    }

    if (valid) {
      setError('')
      return true
    }

    setError(errorMessage)
    return false
  }

  async function onNewImageSelect(e) {
    const file = e.target.files[0]
    e.target.value = ''
    if (await validate(file)) {
      const imageUrl = await readFile(file)

      setNewImage({
        src: file,
        url: imageUrl
      })

      onChange({
        src: file,
        url: imageUrl
      })
    }
  }

  useDidUpdate(() => {
    setNewImage({
      src: {},
      url: initialImageUrl?.includes(';base64,') ? initialImageUrl : generateImageUrl(initialImageUrl)
    })
  }, [initialImageUrl])

  const imageRender = useMemo(() => {
    return isCompanyLogo
    ? (
        <img 
          src={newImage.url || "" }
          alt="Company Logo"
          style={{ maxWidth: 180 }}
        />
      )
    : (
        <div
          className={`image__select__display ${isRound ? 'round' : ''}`}
          {...(newImage.url && { style: { 
            backgroundImage: `url(${newImage.url})`
          } })}
        />
  
    )
  }, [newImage.url])

  return (
    <div className={`image__select ${className}`}>
      <input
        id={id}
        type="file"
        onChange={onNewImageSelect}
        multiple={false}
        accept={config.extensions.length > 0 ? config.extensions.map(type => `image/${type}`).join(",") : ""}
        hidden
      />
      {imageRender}
      <div className="image__select__block">
        {label && <span className="image__select__block__label">{label}</span>}

        {!disabled && (
          <>
            {error ? (
              <span className="image__select__block__info error">{error}</span>
            ) : (
              <span className="image__select__block__info">{infoText}</span>
            )}

            <Button tag="label" variant="link" htmlFor={id}>
              Upload Picture
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

ImageSelect.propTypes = {
  label: PropTypes.string,
  initialImageUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  config: PropTypes.shape({
    extensions: PropTypes.arrayOf(PropTypes.string),
    maxSizeMB: PropTypes.number,
    sizes: PropTypes.shape({
      maxWidth: PropTypes.number,
      maxHeight: PropTypes.number
    })
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isRound: PropTypes.bool
}

ImageSelect.defaultProps = {
  label: undefined,
  initialImageUrl: '',
  config: {
    extensions: ['jpg', 'jpeg', 'png'],
    maxSizeMB: 1,
    sizes: null
    // sizes: {
    //     maxWidth: 256,
    //     maxHeight: 256
    // }
  },
  className: '',
  disabled: false,
  isRound: false
}

export default ImageSelect
