/* eslint-disable no-plusplus */
export const errorFirstSelector = (store) => {
  const errArray = Object.values(store.errorData.errorList)
  for (let i = errArray.length - 1; i >= 0; i--) {
    if (errArray[i].isVisible) {
      return errArray[i]
    }
  }
  return null
}

export const errorSelectorById = (store, errorId) => {
  if (errorId) {
    return store.errorData.errorList[errorId]
  }
  return null
}

export const errorSelectorByIds = (store, idList) => {
  if (idList) {
    const { errorList } = store.errorData
    const errorId = Object.keys(errorList).find((err) => idList.includes(err))
    return errorId && errorList[errorId]
  }
  return null
}
