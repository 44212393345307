export const REDUX_STORE_RESET = 'REDUX_STORE_RESET'
export const SETTINGS_CALL = 'SETTINGS_CALL'
export const INIT_SETTINGS_CALL = 'INIT_SETTINGS_CALL'
export const ROLES_CALL = 'ROLES_CALL'
export const SETTINGS_EDIT = 'SETTINGS_EDIT'

export function reduxStoreReset() {
  return { type: REDUX_STORE_RESET }
}

export function settingsCall() {
  return { type: SETTINGS_CALL }
}

export function initSettingsCall() {
  return { type: INIT_SETTINGS_CALL }
}

export function rolesCall(filter, type) {
  return { type: ROLES_CALL, payload: { filter, type } }
}
