/* eslint-disable react/forbid-prop-types */
// import React, { forwardRef, useMemo, useState } from 'react'
import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { Icon } from 'components/common'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import DateField from './date'

// eslint-disable-next-line react/display-name
const FieldBody = forwardRef(
  (
    {
      control,
      type,
      withLabel,
      name,
      label,
      placeholder,
      options,
      disabled,
      isLine,
      onKeyDown,
      onInput,
      maxLength,
      isMulti,
      ...otherProps
    },
    ref
  ) => {
    // const [selectValue, setSelectValue] = useState()
    const { t } = useTranslation()

    switch (type) {
      case 'select': {
        const { isSearchable, closeMenuOnSelect, styles, components } = otherProps
        const searchable = isSearchable ?? options.length > 10

        const commonProps = {
          id: `field-${name}`,
          className: classNames('field__inner', { 'field__is-searchable': searchable }),
          classNamePrefix: 'field__inner',
          menuPlacement: 'auto',
          placeholder: placeholder ?? (isSearchable ? t('Select or Type to search') : t('Select')),
          isDisabled: disabled,
          isSearchable: searchable,
          maxMenuHeight: 8 + 6 * 33,
          options,
          menuPortalTarget: document.body,
          menuPosition: 'fixed',
          menuShouldBlockScroll: true,
          menuShouldScrollIntoView: true,
          isMulti,
          closeMenuOnSelect,
          styles,
          components
        }

        const customStyles = {
          valueContainer: (provided, state) => ({
            ...provided,
            padding: 0
          })
        }

        return control ? (
          <Controller
            control={control}
            name={name}
            render={({ field }) => {
              const { onChange, value, ref: inputRef } = field

              return (
                <Select
                  inputRef={inputRef}
                  value={isMulti ? value : options.find((option) => option.value === value) || null}
                  onChange={(val) => {
                    if (isMulti) {
                      onChange(val)
                    } else {
                      onChange(val.value)
                    }
                    if (otherProps.onChangeHandler) {
                      otherProps?.onChangeHandler(val)
                    }
                  }}
                  styles={isMulti ? styles : customStyles}
                  {...commonProps}
                />
              )
            }}
          />
        ) : (
          <>
            <Select
              ref={ref}
              name={name}
              getOptionLabel={(option) => option.label || t(option.name)}
              getOptionValue={(option) => option.id || option.value}
              {...commonProps}
              {...otherProps}
            />
          </>
        )
      }

      case 'switch': {
        return (
          <Controller
            control={control}
            name={name}
            render={({ field }) => {
              const { onChange, value } = field
              return (
                <div>
                  <input
                    ref={ref}
                    type="checkbox"
                    id={`field-${name}`}
                    name={name}
                    className="field__inner"
                    disabled={disabled}
                    checked={Boolean(value)}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      if (otherProps.onChange) {
                        otherProps.onChange(e) // Pass the event to the parent component
                      }
                    }}
                    {...otherProps}
                  />
                  <button
                    type="button"
                    className={classNames('switch__toggle', {
                      'switch__toggle--disabled': disabled
                    })}
                    {...(otherProps?.onChange
                      ? {
                          onClick: () => {
                            if (!disabled) {
                              onChange(!value)
                            }
                          }
                        }
                      : {
                          onClick: () => {
                            if (!disabled) {
                              onChange(!value)
                            }
                          }
                        })}
                  >
                    <span className="switch__toggle__yes">{t('Yes')}</span>
                    <span className="switch__toggle__no">{t('No')}</span>
                  </button>
                </div>
              )
            }}
          />
        )
      }

      case 'radio':
      case 'checkbox': {
        const inp = (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className={`control control__${type}`}>
            <input
              ref={ref}
              type={type}
              // id={`field-${name}`}
              name={name}
              className="field__inner"
              placeholder={placeholder}
              disabled={disabled}
              {...otherProps}
            />
            <div className="indicator" />
            <span>{label}</span>
          </label>
        )

        return control ? <Controller control={control} name={name} render={() => inp} /> : inp
      }
      case 'date': {
        // TODO => add non control version for date
        return (
          <DateField
            ref={ref}
            name={name}
            control={control}
            placeholder={placeholder}
            disabled={disabled}
            {...otherProps}
          />
        )
      }
      // text, password, number
      case 'textarea': {
        return (
          <textarea
            ref={ref}
            id={`field-${name}`}
            name={name}
            className="field__inner"
            placeholder={placeholder}
            disabled={disabled}
            {...otherProps}
          />
        )
      }
      default: {
        const handleKeyDown = (e) => {
          const { key } = e
          const allowedKeys = [
            'Backspace',
            'Tab',
            'Enter',
            'Escape',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown'
          ]
          const numberAllowedKeys = [...allowedKeys, '.']

          if (Number.isNaN(+key)) {
            if (type === 'wholeNumber' && !allowedKeys.includes(key)) {
              e.preventDefault()
            }
            if (type === 'number' && !numberAllowedKeys.includes(key)) {
              e.preventDefault()
            }
          }
          if (typeof onKeyDown === 'function') {
            onKeyDown(e)
          }
        }

        const handleOnInput = (e) => {
          if (type === 'number' && maxLength) {
            const { value } = e.target
            const newValue = value.replace(/[^0-9]/g, '')

            if (newValue.length > maxLength) {
              e.target.value = newValue.slice(0, maxLength)
            }
          }
          if (typeof onInput === 'function') {
            onInput(e)
          }
        }
        return (
          <input
            ref={ref}
            type={type}
            id={`field-${name}`}
            name={name}
            className={`field__inner${isLine ? ' field__inner--line' : ''}${disabled ? ' field--disabled' : ''}`}
            placeholder={placeholder}
            maxLength={maxLength}
            onKeyDown={handleKeyDown}
            onInput={handleOnInput}
            disabled={disabled}
            {...otherProps}
          />
        )
      }
    }
  }
)

// eslint-disable-next-line react/display-name
const Field = forwardRef(
  (
    {
      control,
      type,
      name,
      label,
      helperText,
      placeholder,
      options,
      className,
      dirty,
      error,
      disabled,
      required,
      fullWidth,
      startAdornment,
      endAdornment,
      withLabel,
      ...otherProps
    },
    ref
  ) => {
    const isInvalid = useMemo(() => error !== undefined, [error])
    const isValid = useMemo(() => dirty && error === undefined, [dirty, error])

    return (
      <div
        className={classNames(
          'field',
          `field__${type}`,
          { 'field--required': required },
          { 'field--is-valid': required !== undefined && isValid },
          { 'field--is-invalid': isInvalid },
          { 'field--full-width': fullWidth },
          { 'field--with-start-adornment': !!startAdornment },
          { 'field--with-end-adornment': !!endAdornment },
          { [className]: className }
        )}
      >
        {withLabel && type !== 'radio' && type !== 'checkbox' && label !== '' && (
          <label htmlFor={`input-${name}`} style={otherProps.isMulti && { textTransform: 'none' }}>
            {label}
          </label>
        )}
        {helperText !== '' && <span className="field__helper">{helperText}</span>}

        <div className="field__container">
          {startAdornment && <div className="field__start-adornment">{startAdornment}</div>}
          <FieldBody
            ref={ref}
            control={control}
            type={type}
            name={name}
            label={label}
            placeholder={placeholder}
            options={options}
            disabled={disabled}
            autoComplete="off"
            {...otherProps}
          />
          {(endAdornment || required !== undefined) && (
            <div className="field__end-adornment flex flex-align-center">
              {endAdornment || type !== 'select' ? (
                <>
                  {required && isInvalid && (
                    <Icon
                      size="14px"
                      iconClass="Exclamation-Triangle"
                      className={`error-icon${endAdornment ? ' mr-xs' : ''}`}
                    />
                  )}
                  {required && isValid && (
                    <Icon
                      size="14px"
                      iconClass="checkmark"
                      className={`success-icon${endAdornment ? ' mr-xs' : ''}`}
                    />
                  )}
                  {endAdornment}
                </>
              ) : null}
            </div>
          )}
        </div>

        <span
          className={classNames('field__error__text', {
            'field__error__text--disable': error === ''
          })}
        >
          {isInvalid ? (error === 'is required' ? `${label} ${error}`.capitalize() : error) : null}
        </span>
      </div>
    )
  }
)

Field.propTypes = {
  control: PropTypes.shape({}),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any
}

Field.defaultProps = {
  control: undefined,
  type: 'text',
  label: '',
  helperText: '',
  placeholder: '',
  options: [],
  className: '',
  dirty: false,
  error: undefined,
  disabled: false,
  required: undefined,
  fullWidth: true,
  startAdornment: undefined,
  endAdornment: undefined
}

export default Field
