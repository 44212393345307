/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import {
  MEDIA_FOLDERS_CALL,
  mediaFolderAddPromise,
  mediaFolderEditPromise,
  mediaFoldersDeletePromise,
  mediaFolderLoadingSwitch
} from './actions'
import { errorAdd } from '../error/actions'

const prefix = '/media_library'

// Loading wrapper
function* mediaFolderGeneratorWrapper(gen) {
  yield put(mediaFolderLoadingSwitch(true))
  yield gen()
  yield put(mediaFolderLoadingSwitch(false))
}

function* mediaFolderAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { folderData }
    } = action

    yield mediaFolderGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, folderData)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* mediaFolderEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { folderId, folderData }
    } = action

    yield mediaFolderGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/${folderId}`, folderData)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* mediaFolderDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { folderId, hasFilesInside }
    } = action

    yield mediaFolderGeneratorWrapper(function* () {
      try {
        yield call(
          axiosSagaRequest,
          'delete',
          `${prefix}/${folderId}${hasFilesInside ? '?delete_anyway=true' : ''}`
        )
        yield put({ type: `${type}_RESPONSE`, payload: { folderId } })
        yield call(resolvePromiseAction, action, folderId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* mediaLibraryWatcherSaga() {
  yield takeLatest(MEDIA_FOLDERS_CALL, axiosGetGenerator(prefix))
  yield takeEvery(mediaFolderAddPromise, mediaFolderAddPromiseGenerator)
  yield takeEvery(mediaFolderEditPromise, mediaFolderEditPromiseGenerator)
  yield takeEvery(mediaFoldersDeletePromise, mediaFolderDeletePromiseGenerator)
}
