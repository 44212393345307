/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Dialog, Button } from 'components/common'
import { useNavigate } from 'react-router-dom'
import './QuantityChanged.css'
import { useTranslation } from 'react-i18next'

const SuccessModal = ({ isOpen, close }) => {
  const history = useNavigate()

  const { t } = useTranslation()

  const handleBack = () => {
    history('/catalogue')
  }

  return (
    <Dialog open={isOpen} close={close} size="sm" alignment="center" title="Order Successful">
      <div className="success-modal-content">
        <h1 style={{ color: '#4CAF50', marginBottom: '10px' }}>{t('Your order was successful!')}</h1>
        <p style={{ fontSize: '16px', marginBottom: '20px' }}>{t('Thank you for shopping with us')}</p>
        <Button
          style={{
            backgroundColor: '#263355',
            borderRadius: '4px',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '16px'
          }}
          onClick={handleBack}
        >
          {t('Back To Catalogue')}
        </Button>
      </div>
    </Dialog>
  )
}

export default SuccessModal
