import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSwitchSuccess } from 'store/user/actions'
import axios from 'axios'
import { BASE_URL } from 'constant'

import {
  fetchAccountMirrorSuccess,
  redirect,
  selectBranch,
  selectCustomer,
  toggleSidebar,
  createAccountMirrorSuccess,
  fetchAccountMirrorsSuccess
} from 'store/account-mirror/actions'
import { accountMirrorDataSelector } from 'store/account-mirror/selectors'
import { fetchInitCatalogRequest } from 'store/catalog/actions'
import localStorageService from 'utils/storage/localStorageService'

import { Button, Dialog } from 'components/common'
import Select from 'react-select'

const getButtonStyles = (backgroundColor, opacityValue = 1) => ({
  backgroundColor,
  borderRadius: 4,
  opacity: opacityValue
})

export default function MirrorSidebar() {
  const dispatch = useDispatch()

  const { showSidebar, customers, branches, mirroredTokenData, selectedBranch, selectedCustomer } =
    useSelector(accountMirrorDataSelector)

  const originalToken = localStorageService.getAccessToken()

  const getBranches = () => {
    axios
      .get(`${BASE_URL}/api/web-catalogue/account-mirror`, {
        headers: { Authorization: `Bearer ${originalToken}` },
        withCredentials: true
      })
      .then((response) => {
        dispatch(fetchAccountMirrorsSuccess({ branches: response.data.branches }))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const getCustomers = (id) => {
    axios
      .get(`${BASE_URL}/api/web-catalogue/account-mirror/${id}`, {
        headers: { Authorization: `Bearer ${originalToken}` },
        withCredentials: true
      })
      .then((response) => {
        dispatch(fetchAccountMirrorSuccess({ customers: response.data.customers }))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const createRequest = (id) => {
    axios
      .post(
        `${BASE_URL}/api/web-catalogue/account-mirror/mirror/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${originalToken}` },
          withCredentials: true
        }
      )
      .then((response) => {
        const { user, expires_in } = response.data

        dispatch(
          createAccountMirrorSuccess({
            token: response.data.token,
            user,
            expires_in
          })
        )
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleSelectBranch = (branch) => {
    getCustomers(branch.id)
    dispatch(selectBranch(branch))
  }

  const transformedCustomers = useMemo(() => {
    return customers.map((customer) => ({
      id: customer.id,
      label: customer.name,
      options: customer.users
    }))
  }, [customers])

  useEffect(() => {
    if (mirroredTokenData.token !== null) {
      dispatch(redirect())
      dispatch(toggleSidebar())
      dispatch(
        userSwitchSuccess({
          token: mirroredTokenData.token,
          user: mirroredTokenData.user
        })
      )
      dispatch(fetchInitCatalogRequest())
    }
  }, [mirroredTokenData.token])

  useEffect(() => {
    if (showSidebar) getBranches()
  }, [showSidebar])

  return (
    <Dialog
      open={showSidebar}
      close={() => dispatch(toggleSidebar())}
      size="sm"
      alignment="right"
      title="View catalogue as:"
    >
      <div className="account-mirror-sidebar-content-wrapper">
        <span>
          To view the catalogue from other user perspective, first select a branch, then select a
          customer of that branch
        </span>

        <div className="account-mirror-sidebar-content-fields">
          <div>
            <span className="fw-bold">Branch</span>
            <Select
              options={branches}
              value={selectedBranch}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleSelectBranch}
              placeholder="Select branch"
              className="mt-xs"
            />
          </div>
          <div>
            <span className="fw-bold" style={{ opacity: !customers.length ? 0.5 : 1 }}>
              Customer
            </span>

            <Select
              options={transformedCustomers}
              value={selectedCustomer}
              getOptionLabel={(option) => option.user_detail?.full_name}
              getOptionValue={(option) => option.id}
              placeholder="Select customer"
              isDisabled={!customers.length && !selectedCustomer}
              onChange={(customer) => dispatch(selectCustomer(customer))}
              className="mt-xs"
            />
          </div>
          <div>
            <Button
              style={getButtonStyles('#263355', selectedCustomer ? 1 : 0.7)}
              onClick={() => createRequest(selectedCustomer.id)}
              disabled={!selectedCustomer}
            >
              Redirect
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
