import { createPromiseAction } from '@adobe/redux-saga-promise'

export const PRICING_LEVELS_CALL = 'PRICING_LEVELS_CALL'
export const PRICING_LEVEL_GET = 'PRICING_LEVEL_GET'
export const PRICING_LEVEL_ADD_PROMISE = 'PRICING_LEVEL_ADD_PROMISE'
export const PRICING_LEVEL_EDIT_PROMISE = 'PRICING_LEVEL_EDIT_PROMISE'
export const PRICING_LEVEL_DELETE_PROMISE = 'PRICING_LEVEL_DELETE_PROMISE'
export const PRICING_LEVEL_SORT_PROMISE = 'PRICING_LEVEL_SORT_PROMISE'

export const PRICING_LEVEL_LOADING_SWITCH = 'PRICING_LEVEL_LOADING_SWITCH'

export const PricingLevelAddPromise = createPromiseAction(PRICING_LEVEL_ADD_PROMISE)
export const PricingLevelEditPromise = createPromiseAction(PRICING_LEVEL_EDIT_PROMISE)
export const PricingLevelDeletePromise = createPromiseAction(PRICING_LEVEL_DELETE_PROMISE)
export const PricingLevelSortPromise = createPromiseAction(PRICING_LEVEL_SORT_PROMISE)

export function pricingLevelsCall() {
  return { type: PRICING_LEVELS_CALL }
}

export function pricingLevelLoadingSwitch(status) {
  return { type: PRICING_LEVEL_LOADING_SWITCH, payload: { status } }
}
