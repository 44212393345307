/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  PRICE_LEVELS_CALL,
  PRICE_LEVELS_ACCOUNT_DATA_CALL,
  PRICE_LEVELS_ACCOUNT_EDIT_PROMISE,
  PRICE_LEVELS_LOADING_SWITCH
} from './actions'

const initialState = {
  branch: {
    isLoading: false,
    isFetched: false,
    groups: [],
    price_levels: [],
    inStepData: {
      price_level_id: undefined,
      price_level_exceptions: []
    },
    data: {
      price_level_id: undefined,
      price_level_exceptions: []
    }
  },
  customer: {
    isLoading: false,
    isFetched: false,
    groups: [],
    price_levels: [],
    inStepData: {
      price_level_id: undefined,
      price_level_exceptions: []
    },
    data: {
      price_level_id: undefined,
      price_level_exceptions: []
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case PRICE_LEVELS_CALL: {
      const { moduleName } = payload

      if (moduleName) {
        state[moduleName].isFetched = false
        state[moduleName].groups = []
        state[moduleName].price_levels = []
      }
      return state
    }
    case `${PRICE_LEVELS_CALL}_RESPONSE`: {
      const { moduleName, data } = payload

      if (moduleName) {
        state[moduleName].isFetched = true
        state[moduleName].groups = data.groups
        state[moduleName].price_levels = data.price_levels
      }
      return state
    }

    case PRICE_LEVELS_ACCOUNT_DATA_CALL: {
      const { moduleName } = payload
      if (moduleName) {
        state[moduleName].isFetched = false
        state[moduleName].data.price_level_id = undefined
        state[moduleName].data.price_level_exceptions = []
      }
      return state
    }
    case `${PRICE_LEVELS_ACCOUNT_DATA_CALL}_RESPONSE`: {
      const {
        moduleName,
        data: { price_level_id, price_level_exceptions }
      } = payload
      if (moduleName) {
        state[moduleName].isFetched = true
        state[moduleName].data.price_level_id = price_level_id
        state[moduleName].data.price_level_exceptions = price_level_exceptions
      }
      return state
    }

    case `${PRICE_LEVELS_ACCOUNT_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const { moduleName, data } = payload

      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: true,
            data: {
              price_level_id: data.price_level_id || undefined,
              price_level_exceptions: data.price_level_exceptions || []
            }
          }
        }
      }
      return state
    }

    case PRICE_LEVELS_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        state[mKey].isLoading = status !== undefined ? status : !state[mKey].isLoading
      }
      return state
    }

    default:
      return state
  }
})
