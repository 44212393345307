import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import { fetchOrdersRequest } from 'store/orders/actions'
import { useTranslation } from 'react-i18next'

const genericDatePickerProps = {
  dateFormat: 'dd.MM.yyyy',
  todayButton: 'Today',
  className: 'field__inner__orders',
  type: 'date'
}

export default function OrdersHeader({
  search,
  customerOrders,
  onUpdateStartDate,
  onUpdateEndDate
}) {
  const dispatch = useDispatch()
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const { t } = useTranslation()

  const handleFetchOrders = (payload) => {
    dispatch(fetchOrdersRequest(payload))
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date)
    onUpdateStartDate(date)
    handleFetchOrders({ start_date: date, end_date, search, customer_orders: customerOrders })
  }

  const handleChangeEndDate = (date) => {
    setEndDate(date)
    onUpdateEndDate(date)
    handleFetchOrders({ start_date, end_date: date, search, customer_orders: customerOrders })
  }

  return (
    <div className="orders-header-wrapper">
      <DatePicker
        {...genericDatePickerProps}
        name="StartDate"
        placeholderText={t('Start Date')}
        selected={start_date}
        onChange={handleChangeStartDate}
      />
      <DatePicker
        {...genericDatePickerProps}
        name="EndDate"
        placeholderText={t('End Date')}
        selected={end_date}
        onChange={handleChangeEndDate}
      />
    </div>
  )
}
