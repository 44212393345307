import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Divider component
 * @param {Number, String} height - Divider height
 * @param {Number, String} width - Divider width
 */

const Divider = memo(({ height, width, className, ...props }) => (
  <hr
    className={`divider ${className}`}
    style={{
      height,
      width
    }}
    width={width}
    {...props}
  />
))

Divider.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
}

Divider.defaultProps = {
  height: 1,
  width: '100%',
  className: ''
}

export default Divider
Divider.displayName = 'Divider'
