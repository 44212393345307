/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Page Header component
 * @param {Boolean} className - class for container div
 * @param {Node} children - Any node
 */

const PageHeader = memo(({ className, children }) => {
  return <div className={`page__header ${className}`}>{children}</div>
})

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

PageHeader.defaultProps = {
  className: '',
  children: undefined
}

export default PageHeader
