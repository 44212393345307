/* eslint-disable no-undef */
/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import {
  CUSTOMER_TYPES_CALL,
  CUSTOMER_TYPE_GET,
  SECTIONS_BY_GROUP_GET,
  BRANDS_BY_SECTION_GET,
  customerTypeAddPromise,
  customerTypeEditPromise,
  customerTypeRenamePromise,
  customerTypeDeletePromise,
  customerTypeSortPromise,
  customerTypeLoadingSwitch
} from './actions'
import { errorAdd } from '../../error/actions'

const prefix = '/customer_types'

// Loading wrapper
function* customerTypeGeneratorWrapper(gen) {
  yield put(customerTypeLoadingSwitch(true))
  yield gen()
  yield put(customerTypeLoadingSwitch(false))
}

function* customerTypeGetGenerator(action) {
  const {
    type,
    payload: { customerTypeId }
  } = action
  yield customerTypeGeneratorWrapper(function* () {
    try {
      const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/${customerTypeId}`)
      yield put({ type: `${type}_RESPONSE`, payload: { data, customerTypeId } })
    } catch (error) {
      yield put(errorAdd(error, action))
    }
  })
}

function* sectionsByGroupGetGenerator(action) {
  const {
    type,
    payload: { groupId, customerTypeId }
  } = action
  yield customerTypeGeneratorWrapper(function* () {
    try {
      const { data } = yield call(
        axiosSagaRequest,
        'get',
        `web-catalogue/preferences/${customerTypeId}/sections?group_id=${groupId}`
      )
      yield put({ type: `${type}_RESPONSE`, payload: { data, groupId, customerTypeId } })
    } catch (error) {
      yield put(errorAdd(error, action))
    }
  })
}

function* brandsBySectionGetGenerator(action) {
  const {
    type,
    payload: { sectionId, customerTypeId, groupId }
  } = action
  yield customerTypeGeneratorWrapper(function* () {
    try {
      const { data } = yield call(
        axiosSagaRequest,
        'get',
        `web-catalogue/preferences/${customerTypeId}/brands?section_id=${sectionId}`
      )
      yield put({ type: `${type}_RESPONSE`, payload: { data, sectionId, customerTypeId, groupId } })
    } catch (error) {
      yield put(errorAdd(error, action))
    }
  })
}

function* customerTypeAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { customerTypeData }
    } = action
    yield customerTypeGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, customerTypeData)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* customerTypeEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { customerTypeId, customerTypeData }
    } = action
    yield customerTypeGeneratorWrapper(function* () {
      try {
        const { data } = yield call(
          axiosSagaRequest,
          'post',
          `${prefix}/${customerTypeId}/details`,
          customerTypeData
        )
        yield put({ type: `${type}_RESPONSE`, payload: { customerTypeId } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* customerTypeRenamePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { id, name }
    } = action

    yield customerTypeGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'PATCH', `${prefix}/${id}`, { name })
        yield put({ type: `${type}_RESPONSE`, payload: { id, name } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* customerTypeDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { customerTypeId, alternativeCustomerTypeId }
    } = action
    yield customerTypeGeneratorWrapper(function* () {
      try {
        const params = alternativeCustomerTypeId ? { customer_type_id: customerTypeId } : {}
        yield call(axiosSagaRequest, 'post', `${prefix}/${customerTypeId}`, {
          _method: 'delete',
          ...params
        })
        yield put({ type: `${type}_RESPONSE`, payload: { customerTypeId } })
        yield call(resolvePromiseAction, action, customerTypeId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* customerTypeSortPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { currentCustomerTypeIds, newCustomerTypeIds }
    } = action

    yield customerTypeGeneratorWrapper(function* () {
      try {
        yield put({ type: `${type}_RESPONSE`, payload: { customerTypeIds: newCustomerTypeIds } })
        const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/sort`, {
          customer_types: newCustomerTypeIds
        })
        // yield put({ type: `${type}_RESPONSE`, payload: { customerTypeIds } });
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put({
          type: `${type}_RESPONSE`,
          payload: { customerTypeIds: currentCustomerTypeIds }
        })
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* customerTypesWatcherSaga() {
  yield takeLatest(CUSTOMER_TYPES_CALL, axiosGetGenerator(prefix))
  yield takeEvery(CUSTOMER_TYPE_GET, customerTypeGetGenerator)
  yield takeEvery(SECTIONS_BY_GROUP_GET, sectionsByGroupGetGenerator)
  yield takeEvery(BRANDS_BY_SECTION_GET, brandsBySectionGetGenerator)
  yield takeEvery(customerTypeAddPromise, customerTypeAddPromiseGenerator)
  yield takeEvery(customerTypeEditPromise, customerTypeEditPromiseGenerator)
  yield takeEvery(customerTypeRenamePromise, customerTypeRenamePromiseGenerator)
  yield takeEvery(customerTypeDeletePromise, customerTypeDeletePromiseGenerator)
  yield takeEvery(customerTypeSortPromise, customerTypeSortPromiseGenerator)
}
