import React from 'react'
import PropTypes from 'prop-types'
import { Draggable as DraggablePackage } from 'react-beautiful-dnd'

// id -> must be string only
const Draggable = ({ draggableId, index, children }) => (
  <DraggablePackage draggableId={`${draggableId}`} index={index}>
    {children}
  </DraggablePackage>
)

Draggable.propTypes = {
  draggableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ])
}

Draggable.defaultProps = {
  children: undefined
}

export default Draggable
