import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function Title({ orderNumber, orderDate }) {
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-align-center flex-justify-space-between mb-xl">
      <p className="fw-bold">{t('Order Number')}: {orderNumber}</p>
      <p className="fw-bold">{t('Order Date')}: {moment(orderDate).format('DD-MM-YYYY HH:mm')}</p>
    </div>
  )
}