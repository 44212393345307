import React, { useLayoutEffect, useState, memo } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { errorFirstSelector } from 'store/error/selector'
import { errorRemove, errorToggle } from 'store/error/actions'
import { Dialog } from 'components/common'
import { NetworkError } from 'utils/models'
import { useTranslation } from 'react-i18next'

const ErrorWindow = memo(() => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const errorData = useSelector(errorFirstSelector, isEqual)
  const [popupData, setPopupData] = useState({})

  function handleButtonAction() {
    dispatch(errorRemove(errorData.action.type))

    if (errorData.action) {
      if (typeof errorData.action === 'function') {
        dispatch(errorData.action())
      } else {
        dispatch(errorData.action)
      }
    }
  }

  function handleErrorCancel() {
    if (popupData.isFatal) {
      dispatch(errorToggle(errorData.action))
    } else {
      dispatch(errorRemove(errorData.action))
    }
  }

  useLayoutEffect(() => {
    if (errorData) {
      const processedError = new NetworkError(errorData)
      setPopupData(processedError)
    }
  }, [errorData])

  return (
    <Dialog
      open={Boolean(errorData && errorData.isVisible && !popupData.isFatal)}
      type="danger"
      size="sm"
      title={popupData.name}
      description={popupData.message}
      disableBackdropClick
      actions={[
        ...(popupData.buttonName
          ? [
              {
                text: popupData.buttonName,
                variant: 'tertiary',
                onClick:
                  popupData.buttonName === t('Retry') ? handleButtonAction : handleErrorCancel
              }
            ]
          : []),
        {
          text: t('Close'),
          isDestructive: true,
          onClick: handleErrorCancel
        }
      ]}
    />
  )
})

export default ErrorWindow
ErrorWindow.displayName = 'ErrorWindow'
