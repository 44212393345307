import React from 'react'

export default function CartHeader({ data }) {
  return (
    <div className="cart-description-details">
      <p className="fw-bold">Nordic</p>
      <p>Unit 5 Castle Business Village</p>
      <p>Station Road</p>
      <p>Hampton</p>
      <p>TW12 2BX</p>
    </div>
  )
}
