import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Icon, Dropdown } from 'components/common'
import { usePermission } from 'components/common/permission'
import { CardDetails } from 'components/complex'
import { useTranslation } from 'react-i18next'

const ContactListCard = memo(({ data, className, onEdit, onDelete }) => {
  const { id, full_name, email, department, job_title, mobile, telephone, index } = data

  const { t } = useTranslation()
  const canCompanyAccountContactManage = usePermission('company_account_contact-manage')

  return (
    <div className={`list-card list-card--contact ${className}`}>
      <div className="list-card__header">
        <div className="list-card__avatar list-card__avatar--text">{full_name.charAt(0)}</div>
        <div className="list-card__title">
          <span className="list-card__title__primary">{full_name}</span>
          <span className="list-card__title__secondary">{job_title || '...'}</span>
        </div>
        {canCompanyAccountContactManage && (
          <Dropdown
            label={
              <button type="button">
                <Icon iconClass="dots" type="lighter" size="18px" />
              </button>
            }
            menuAlignment="right"
            className="list-card__dropdown"
          >
            {/* index is used in account creation process (id is undefined while in step) */}
            <Dropdown.Item
              className="list-card__dropdown-item"
              onClick={() => onEdit(id || { index, method: 'update' })}
            >
              <Icon iconClass="edit-outlined" type="dark" size="16px" />
              {t('Edit')}
            </Dropdown.Item>
            <Dropdown.Item
              className="list-card__dropdown-item red"
              onClick={() => onDelete(id || { index, method: 'delete' })}
            >
              <Icon iconClass="trash-delete" size="16px" />
              {t('Delete')}
            </Dropdown.Item>
          </Dropdown>
        )}
      </div>
      <div className="list-card__body">
        <CardDetails
          data={{
            email: email || '...',
            department: department || '...',
            tel: telephone || '...',
            mobile: mobile || '...'
          }}
        />
      </div>
    </div>
  )
})

ContactListCard.propTypes = {
  data: PropTypes.shape({
    // id,
    // full_name,
    // email,
    // department,
    // job_title,
    // mobile_number,
    // telephone_number
  }).isRequired,
  className: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

ContactListCard.defaultProps = {
  className: ''
}

export default ContactListCard
ContactListCard.displayName = 'ContactListCard'
