/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useParseFile } from 'hooks'
import { Dialog, Dropzone, useSnackbar } from 'components/common'
import { useTranslation } from 'react-i18next'

const FileUpload = ({ isOpen, close, data, onUpload }) => {
  const { handleUploadFile } = useParseFile()
  const { t } = useTranslation()
  const addSnackbar = useSnackbar()
  const dropzoneRef = useRef()

  const validateData = (valData) =>
    valData.every((item) => 'part_number' in item && 'price' in item)

  const handleSubmit = async () => {
    const files = dropzoneRef.current.getFiles()

    if (!files || !files[0]) {
      addSnackbar({
        variant: 'danger',
        message: 'Please select a file first'
      })
      return
    }

    const elements = await handleUploadFile(files[0])
    close()
    onUpload({ fileData: elements, data })

    if (validateData(elements)) {
      addSnackbar({
        variant: 'success',
        message: t('Selling prices have been uploaded successfully')
      })
    } else {
      addSnackbar({
        variant: 'danger',
        message: t('The data is not valid. Some objects are missing part_number or price')
      })
    }
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="center"
      title="Upload Advanced Pricing"
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          variant: 'tertiary'
        },
        {
          text: t('Add Pricing'),
          onClick: handleSubmit
        }
      ]}
    >
      <div className="landed-prices-modal container pt-lg pb-xxs px-md">
        <Dropzone
          className="pricing-add-dropzone"
          ref={dropzoneRef}
          acceptedFilesFormat="Accepted file formats: .xlsx, .xls, .csv"
          showMaxFileSize={false}
          config={{
            acceptedFiles: [
              'text/csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ],
            maxFiles: 1,
            singleFileMaxSizeMB: 1
          }}
        />
      </div>
    </Dialog>
  )
}

FileUpload.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  data: PropTypes.object,
  onUpload: PropTypes.func.isRequired
}

FileUpload.defaultProps = {
  isOpen: true,
  data: {}
}

export default FileUpload
