/* eslint-disable max-len */
import React, { useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { Icon, Typography, useSnackbar } from 'components/common'
import { useTranslation } from 'react-i18next'

/**
 * Dropzone component
 * @param {Object} config - configuration for files
 * @param {String} className - classname for container div
 */

// eslint-disable-next-line react/display-name
const Dropzone = forwardRef(
  (
    {
      config,
      className,
      onDropAccepted,
      acceptedFilesFormat,
      title,
      subTitle = 'to choose a files',
      showMaxFileSize
    },
    ref
  ) => {
    const addSnackbar = useSnackbar()
    const [filesState, setFilesState] = useState([])
    const { t } = useTranslation()
    const validateFiles = (fls) => {
      if (fls.length > config.maxFiles) {
        addSnackbar({
          variant: 'danger',
          message: `Total ${config.maxFiles} files allowed.`
          // preventDuplicate: true
        })
      }

      const files = [...fls].slice(0, config.maxFiles)

      const validFiles = []

      const maxAllowedSize = config.singleFileMaxSizeMB * 1024 * 1024
      // const maxAllowedTotalSize = config.maxSizeMB * 1024 * 1024;

      let totalSize = 0

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        let valid = true
        let errorMessage = ''

        if (file) {
          // const fileName = file.name;
          const fileType = file.type
          const fileSize = file.size

          totalSize += fileSize

          // Check type
          if (!config.acceptedFiles.includes(fileType)) {
            valid = false
            errorMessage += `\n* ${t('Only n file(s) are allowed', {
              number: config.acceptedFiles.join(', ')
            })}.`
          }
          // Check file size
          if (fileSize > maxAllowedSize) {
            valid = false
            errorMessage += `\n* ${t('Max allowed file size n MB', {
              number: config.singleFileMaxSizeMB
            })}.`
          }
        } else {
          valid = false
        }

        if (valid) {
          validFiles.push(file)
        } else {
          addSnackbar({
            variant: 'danger',
            message: errorMessage
            // preventDuplicate: true
          })
        }
      }

      return validFiles
    }

    const onDrop = useCallback(
      (acceptedFiles) => {
        // do nothing if no files
        if (acceptedFiles.length === 0) {
          return
        }

        // acceptedFiles.forEach(async file => {
        //     const reader = new FileReader();
        //
        //     reader.onabort = () => console.log('file reading was aborted');
        //     reader.onerror = () => console.log('file reading has failed');
        //     reader.onload = () => {
        //         // Do whatever you want with the file contents
        //         // const binaryStr = reader.result;
        //     };
        //     reader.readAsArrayBuffer(file);
        // });

        const validFiles = validateFiles([...filesState.map((f) => f.file), ...acceptedFiles])
        setFilesState(
          validFiles.map((f) => ({
            id: `_${Math.random().toString(36).substr(2, 9)}`,
            url: URL.createObjectURL(f),
            file: f
          }))
        )
      },
      [filesState]
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDropAccepted: typeof onDropAccepted === 'function' ? (files) => onDropAccepted(files) : null
      // maxFiles: 1,
      // maxSize: config.maxSizeMB * 1024 * 1024
      // validator: nameLengthValidator
    })

    useImperativeHandle(ref, () => ({
      getFiles() {
        return filesState.map((f) => f.file)
      }
    }))

    return (
      <div className={classNames('dropzone-container p-lg', className)}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input id="files-input" {...getInputProps()} />

          <div className="dropzone__text">
            <Icon iconClass="upload-outlined" type="lighter" size="28px" className="mb-xxs" />
            <Typography className="dropzone__text__title" variant="h1">
              {t(title)}
            </Typography>
            <Typography className="dropzone__text__description" variant="body2">
              {t('Or')} <span style={{ color: '#2569A1', cursor: 'pointer' }}>{t('browse')}</span>{' '}
              {t(subTitle)}
            </Typography>
            <Typography className="dropzone__text__validation" variant="body2" component="p">
              {acceptedFilesFormat}
              <br />
              {showMaxFileSize && t('(At least 1250 x 703 px maximum size 1MB)')}
            </Typography>
          </div>
        </div>
        {filesState.length > 0 && (
          <div className="dropzone__files">
            {filesState.map((file) => (
              <div
                key={file.id}
                className={
                  ['image/jpg', 'image/jpeg', 'image/png'].includes(file.file.type) ? 'file' : ''
                }
              >
                {['image/jpg', 'image/jpeg', 'image/png'].includes(file.file.type) ? (
                  <div
                    key={file.id}
                    className="file"
                    // file={f}
                    // isSelected={selectedFileId === f.id}
                    // deleteFunction={deleteLocalFile}
                  >
                    <img src={file.url} />
                  </div>
                ) : (
                  <div className="flex flex-align-center" key={file.id}>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#217346"
                        d="M31.404 4.136h-10.72v1.984h3.16v3.139h-3.16v1h3.16v3.143h-3.16v1.028h3.16v2.972h-3.16v1.191h3.16v2.979h-3.16v1.191h3.16v2.996h-3.16v2.185h10.72c0.169-0.051 0.311-0.251 0.424-0.597 0.113-0.349 0.172-0.633 0.172-0.848v-21.999c0-0.171-0.059-0.273-0.172-0.309-0.113-0.035-0.255-0.053-0.424-0.053zM30.013 25.755h-5.143v-2.993h5.143v2.996zM30.013 21.571h-5.143v-2.98h5.143zM30.013 17.4h-5.143v-2.959h5.143v2.961zM30.013 13.4h-5.143v-3.139h5.143v3.14zM30.013 9.241h-5.143v-3.12h5.143v3.14zM0 3.641v24.801l18.88 3.265v-31.416l-18.88 3.36zM11.191 22.403c-0.072-0.195-0.411-1.021-1.011-2.484-0.599-1.461-0.96-2.312-1.065-2.555h-0.033l-2.025 4.82-2.707-0.183 3.211-6-2.94-6 2.76-0.145 1.824 4.695h0.036l2.060-4.908 2.852-0.18-3.396 6.493 3.5 6.624-3.065-0.18z"
                      />
                    </svg>

                    <span className="ml-sm">{file.file.name}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
)

Dropzone.propTypes = {
  config: PropTypes.shape({
    acceptedFiles: PropTypes.arrayOf(PropTypes.string),
    maxFiles: PropTypes.number,
    // maxSizeMB: PropTypes.number,
    singleFileMaxSizeMB: PropTypes.number
  }),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  acceptedFilesFormat: PropTypes.string,
  showMaxFileSize: PropTypes.bool,
  className: PropTypes.string
}

Dropzone.defaultProps = {
  config: {
    acceptedFiles: ['image/jpg', 'image/jpeg', 'image/png'],
    maxFiles: 3,
    singleFileMaxSizeMB: 25
  },
  title: 'Drag and drop your files here',
  subTitle: 'to choose a files',
  acceptedFilesFormat: `Accepted file formats: .jpg and .png`,
  showMaxFileSize: true,
  className: ''
}

export default Dropzone
