// Prices
export const selectLandedPrices = (state) => state.landedPricesData.landedPrices
// Single Price
export const selectSingleLandedPrice = (state) => state.landedPricesData.singleLandedPrice
export const selectSections = (state) => state.landedPricesData.sections
export const selectCatalogueCurrency = (state) => state.landedPricesData.catalogueCurrency
export const selectProductPrices = (state) => state.landedPricesData.productPrices
export const selectCosts = (state) => state.landedPricesData.costs
export const selectCurrentCosts = (state) => state.landedPricesData.currentCosts
export const selectSectionId = (state) => state.landedPricesData.sectionId
export const selectExportFile = (state) => state.landedPricesData.exportFile
// Form Data
export const selectFormData = (state) => state.landedPricesData.formData
// Loading & Error
export const selectLoadingStatus = (state) => state.landedPricesData.loading
export const selectError = (state) => state.landedPricesData.error
