import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withDataFetch } from 'components/HOC'
import { Dialog, Badge } from 'components/common'
import { CardDetails } from 'components/complex/index'
import { generateImageUrl } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

const badgeColorsByStatus = {
  true: 'success',
  false: 'neutral'
}

const PreviewCard = ({ isOpen, close, card, id, onAction, className, fetchedData }) => {
  const { landedPrice } = fetchedData.priceHistoryData
  const { landed_price_history } = fetchedData.priceHistoryData
  const { t, i18n } = useTranslation()

  const tableHeaders = [t('Sections Modified'), t('Date Modified')]

  const getSectionName = (data) => {
    return JSON.parse(data).find((translation) => translation[i18n.language])[i18n.language]
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="right"
      title={t('Price File History')}
      className={`preview-dialog ${className}`}
    >
      <div className="preview-dialog__content prices-history-dialog-content">
        <div className="p-lg">
          <div className="list-card__body">
            {landedPrice.brand.image ? (
              <img
                className="list-card__avatar list-card__avatar--img"
                src={generateImageUrl(landedPrice.brand.image)}
                alt="img"
              />
            ) : (
              <div className="list-card__avatar list-card__avatar--text">
                {landedPrice.brand.name.charAt(0)}
              </div>
            )}
            <div className="list-card__title mt-md">
              <span className="list-card__title__primary landed-price-title">
                {landedPrice.brand.name}
              </span>
            </div>
            <div className="preview-heading-info">
              <CardDetails
                data={{
                  dateCreated: moment(landedPrice.created_at).format('DD.MM.YYYY') || '-',
                  fileName: landedPrice.price_file.name || '-',
                  supplier: landedPrice.account.name || '-'
                }}
              />
              <Badge
                title={landedPrice.status ? 'Active' : 'Inactive'}
                type={badgeColorsByStatus[landedPrice.status]}
              />
            </div>
          </div>
        </div>
        <div className="history-records-data">
          <span className="history-records-title">{t('History Record')}</span>
        </div>
        <div className="history-records-list">
          <table style={{ width: '100%', border: '1px solid #dedede', borderCollapse: 'collapse' }}>
            <thead>
              <tr className="table__head">
                {tableHeaders.map((header, index) => (
                  <th
                    className="table__cell"
                    key={header || index}
                    style={{ borderRight: 0, textAlign: 'start' }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table__body">
              {landed_price_history.map((row) => (
                <tr className="table__row" key={row.id}>
                  <td className="table__cell">
                    {row.section ? getSectionName(row.section.name) : 'All Sections'}
                  </td>
                  <td className="table__cell">{row.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  )
}

PreviewCard.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  card: PropTypes.string,
  className: PropTypes.string
}

PreviewCard.defaultProps = {
  isOpen: true,
  card: '',
  className: ''
}

export default (props) => {
  return withDataFetch([
    {
      method: 'get',
      key: 'priceHistoryData',
      url: `/landed-price/history/${props.id}`
    }
  ])(PreviewCard)(props)
}
