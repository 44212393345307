import React, { memo } from 'react'
import Navigation from 'components/admin/navigation'

const Sidebar = () => (
  <div className="sidebar">
    <Navigation />
  </div>
)

export default memo(Sidebar)
