import { createPromiseAction } from '@adobe/redux-saga-promise'

export const MEDIA_FOLDERS_CALL = 'MEDIA_FOLDERS_CALL'
export const MEDIA_FOLDER_ADD_CALL_PROMISE = 'MEDIA_FOLDER_ADD_CALL_PROMISE'
export const MEDIA_FOLDER_EDIT_CALL_PROMISE = 'MEDIA_FOLDER_EDIT_CALL_PROMISE'
export const MEDIA_FOLDERS_DELETE_CALL_PROMISE = 'MEDIA_FOLDERS_DELETE_CALL_PROMISE'

export const MEDIA_FOLDER_LOADING_SWITCH = 'MEDIA_FOLDER_LOADING_SWITCH'

export const mediaFolderAddPromise = createPromiseAction(MEDIA_FOLDER_ADD_CALL_PROMISE)
export const mediaFolderEditPromise = createPromiseAction(MEDIA_FOLDER_EDIT_CALL_PROMISE)
export const mediaFoldersDeletePromise = createPromiseAction(MEDIA_FOLDERS_DELETE_CALL_PROMISE)

export function mediaFoldersCall() {
  return { type: MEDIA_FOLDERS_CALL }
}

export function mediaFolderLoadingSwitch(status) {
  return { type: MEDIA_FOLDER_LOADING_SWITCH, payload: { status } }
}
