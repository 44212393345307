import { Route, Routes } from 'react-router-dom'
import { LoginController, SignUpController, ForgottenPasswordController } from './controllers'
import AuthWrapper from './wrapper'

const AuthModule = () => {
  return (
    <Routes>
      <Route element={<AuthWrapper />}>
        <Route path={LoginController.path} element={LoginController.Component} />
        <Route path={SignUpController.path} element={SignUpController.Component} />
        <Route
          path={ForgottenPasswordController.path}
          element={ForgottenPasswordController.Component}
        />
      </Route>
    </Routes>
  )
}

export default AuthModule
