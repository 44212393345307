import React from 'react'
import PropTypes from 'prop-types'
import { Droppable as DroppablePackage } from 'react-beautiful-dnd'

function Droppable({ droppableId, direction, children }) {
  return (
    <DroppablePackage droppableId={droppableId} direction={direction}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </DroppablePackage>
  )
}

Droppable.propTypes = {
  droppableId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

Droppable.defaultProps = {
  children: undefined
}

export default Droppable
