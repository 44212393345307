import { useEffect } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken } from 'store/user/actions'
import { userTokenSelector } from 'store/user/selectors'

/**
 * This hook refreshes user token with given interval from backend
 */

const useRefreshToken = () => {
  const dispatch = useDispatch()
  const { token, tokenExpiresIn } = useSelector(userTokenSelector, isEqual)

  // tokenExpiresIn is in seconds

  useEffect(() => {
    let tokenRefreshTimeout

    if (token) {
      if (!tokenExpiresIn) {
        // no expiration date for token (login/ register /page first load)
        dispatch(refreshToken())
      } else {
        // secondary call with interval from backend
        tokenRefreshTimeout = setInterval(() => {
          dispatch(refreshToken())
        }, (tokenExpiresIn - 300) * 1000)
      }
    }

    return () => {
      clearInterval(tokenRefreshTimeout)
    }
  }, [token])
}

export default useRefreshToken
