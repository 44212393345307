import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext as DragDropContextPackage } from 'react-beautiful-dnd'

const DragDropContext = ({ children, ...rest }) => {
  return (
    <DragDropContextPackage
      // onDragStart={onDragStart}
      // onDragUpdate={onDragUpdate}
      // onDragEnd={onDragEnd}
      {...rest}>
      {children}
    </DragDropContextPackage>
  )
}

DragDropContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

DragDropContext.defaultProps = {
  children: undefined
}

export default DragDropContext
