import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Empty Content component
 * @param {String} title - Title text for empty section
 * @param {String} description - Description text for empty section
 * @param {Node} children - Any node
 */

const EmptyContent = memo(({ title, description, children }) => (
  <div className="empty-content">
    {title && <h1 className="empty-content__title">{title}</h1>}
    {description && <span className="empty-content__description">{description}</span>}
    {children && children}
  </div>
))

EmptyContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
EmptyContent.defaultProps = {
  title: '',
  description: '',
  children: undefined
}

export default EmptyContent
EmptyContent.displayName = 'EmptyContent'
