/* eslint-disable no-param-reassign */
import produce, { current } from 'immer'
import {
  COMMON_USERS_GET,
  COMMON_USER_GET,
  COMMON_USER_ADD_PROMISE,
  COMMON_USER_EDIT_PROMISE,
  COMMON_USER_ACTION_INSTEP_PROMISE,
  COMMON_USER_STATUS_PROMISE,
  COMMON_USER_DELETE_PROMISE,
  COMMON_USER_LOADING_SWITCH,
  COMMON_SHORT_USERS_GET,
  COMMON_USER_CLEAR_STEP_DATA
} from './actions'

const generateUserImageUrl = (imagePath, imageName) =>
  imagePath && imageName ? `${imagePath}/${imageName}` : null

const initialState = {
  users: {
    company: {
      isFetched: false,
      data: [],
      base_url: {}
    },
    customer: {
      isFetched: false,
      data: [],
      base_url: {},
      inStepData: []
    },
    branch: {
      isFetched: false,
      data: [],
      inStepData: []
    }
  },
  user: {
    company: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
    customer: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
    branch: {
      isFetched: false,
      isLoading: false,
      data: undefined
    }
  },
  shortUsers: {
    company: {
      isFetched: false,
      data: [],
      paths: {}
    },
    branch: {
      isFetched: false,
      data: [],
      paths: {}
    },
    customer: {
      isFetched: false,
      data: [],
      paths: {}
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case COMMON_USERS_GET: {
      if (payload?.moduleName) {
        const mKey = payload.moduleName
        state.users[mKey].isFetched = false
        state.users[mKey].data = []
      }
      return state
    }

    case `${COMMON_USERS_GET}_RESPONSE`: {
      if (payload?.moduleName) {
        const {
          moduleName: mKey,
          data: { users, base_url }
        } = payload

        state.users[mKey].isFetched = true
        state.users[mKey].data = users
          ? users.map((u) => ({
              ...u,
              image: generateUserImageUrl(base_url, u?.image)
            }))
          : []
        state.users[mKey].base_url = base_url || {}
      }
      return state
    }

    case COMMON_USER_GET: {
      if (payload?.moduleName) {
        const mKey = payload.moduleName
        state.user[mKey].isFetched = false
        state.user[mKey].data = []
      }
      return state
    }

    case `${COMMON_USER_GET}_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        state.user[mKey].isFetched = true
        state.user[mKey].data = data
      }
      return state
    }

    case `${COMMON_USER_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        const { base_url } = current(state.users?.[mKey])
        const {
          user: { id, account_id, image, email, role, status_id, user_detail }
        } = data

        state.users[mKey].data.push({
          id,
          account_id,
          image: generateUserImageUrl(base_url, image),
          email,
          role,
          status_id: +status_id,
          user_detail
        })
      }

      return state
    }

    case `${COMMON_USER_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        const { base_url } = current(state.users?.[mKey])
        const {
          user: { id, account_id, image, email, role, status_id, user_detail }
        } = data
        state.users[mKey].data = state.users[mKey].data.map((user) =>
          user.id === id
            ? {
                id,
                account_id,
                image: generateUserImageUrl(base_url, image),
                email,
                role,
                status_id: +status_id,
                user_detail
              }
            : user
        )
      }
      return state
    }

    case `${COMMON_USER_STATUS_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName, userId, statusData } = payload
        state.users[moduleName].data = state.users[moduleName].data.map((user) => {
          if (user.id === userId) {
            if (statusData.name === 'restore') {
              return { ...user, status_id: 1, status_name: 'Active' }
            }
            return { ...user, status_id: statusData.id, status_name: statusData.name }
          }

          return user
        })
      }
      return state
    }

    case `${COMMON_USER_DELETE_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName, userId } = payload
        state.users[moduleName].data = state.users[moduleName].data.map((user) => {
          if (user.id === userId) {
            return { ...user, status_id: 5, status_name: 'Deleted' }
          }

          return user
        })
      }
      return state
    }

    case `${COMMON_USER_ACTION_INSTEP_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload

        if (data.method) {
          const { index, method } = data
          if (method === 'update') {
            state.users[mKey].inStepData[index] = data.user
          }
          if (method === 'delete') {
            state.users[mKey].inStepData.splice(index, 1)
          }
        } else {
          state.users[mKey].inStepData.push({ ...data.user })
        }
      }
      return state
    }

    case COMMON_USER_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        state.user[mKey].isLoading = status !== undefined ? status : !state.user[mKey].isLoading
      }
      return state
    }

    // Short
    case COMMON_SHORT_USERS_GET: {
      if (payload?.moduleName) {
        const mKey = payload.moduleName
        state.shortUsers[mKey].isFetched = false
        state.shortUsers[mKey].data = []
      }
      return state
    }

    case `${COMMON_SHORT_USERS_GET}_RESPONSE`: {
      if (payload?.moduleName) {
        const {
          moduleName: mKey,
          data: { users, paths }
        } = payload
        state.shortUsers[mKey].isFetched = true
        state.shortUsers[mKey].data = users
          ? users.map((u) => ({
              ...u,
              image: generateUserImageUrl(paths?.image_path, u?.image)
            }))
          : []
        state.shortUsers[mKey].paths = paths || {}
      }
      return state
    }

    case COMMON_USER_CLEAR_STEP_DATA: {
      if (payload?.moduleName) {
        const moduleName = payload?.moduleName;
        state.users[moduleName].inStepData = [];
      }
      return state
    }

    default: {
      return state
    }
  }
})
