export default {
  'string.empty': 'is required',
  'string.domain': 'Please enter valid URL',
  'string.uri': 'Please enter valid URL',
  'any.required': 'is required',
  'string.base': "Should be a 'text'",
  'string.min': 'Must have at least {#limit} characters',
  'string.max': 'Must not exceed {#limit} characters',
  'string.email': 'This is not a valid email address',
  'string.pattern.base': 'Invalid value',
  'string.pattern.name': 'you need to use only 0-9, a-z or A-Z and space',
  'string.length': 'Must be {#limit} characters',
  'any.only': 'Password not matched',
  'number.base': 'Must be a number',
  'number.unsafe': 'Number out of range',
  'number.infinity': 'Number out of range'
}
