import * as actionTypes from './actions'

const initialState = {
  locales: [],
  loading: false,
  error: null
}

const localesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCALES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case actionTypes.FETCH_LOCALES_SUCCESS:
      const { locales } = action.payload

      return {
        ...state,
        locales,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_LOCALES_FAILURE:
      return { ...state, error: action.payload, loading: false }

    default:
      return state
  }
}

export default localesReducer
