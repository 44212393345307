/* eslint-disable no-param-reassign */
import produce from 'immer'
import { CHARGES_CALL, CHARGES_EDIT_PROMISE, CHARGES_LOADING_SWITCH } from './actions'

const initialState = {
  charges: {
    isFetched: false,
    isLoading: false,
    data: []
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case CHARGES_CALL: {
      state.charges.isFetched = false
      state.charges.data = []
      return state
    }

    case `${CHARGES_CALL}_RESPONSE`: {
      state.charges.isFetched = true
      state.charges.data = payload.resource
      return state
    }

    case `${CHARGES_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const { finalData } = payload

      state.charges.data = state.charges.data.map((item) => ({
        ...item,
        value: `${finalData?.[item.key]}`
      }))
      return state
    }

    case CHARGES_LOADING_SWITCH: {
      const { finalData } = payload
      state.charges.isLoading = finalData !== undefined ? finalData : !state.charges.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
