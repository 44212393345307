import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dropdown } from 'components/common'
import UserArrow from 'assets/images/user_arrow.png'
import UserAvatar from 'assets/images/user_avatar.png'

const UserHeader = ({ image, name, userRole, className, children }) => (
  <Dropdown
    className={classNames('user', `user--${className}`)}
    menuAlignment="right"
    label={
      <div className={`user__card user--${className}__card`}>
        <img className="user__avatar" src={image} alt="img" />
        <div className="user__nameAndRole">
          <span className="user__name">{name}</span>
          <span className="user__role">{userRole}</span>
        </div>
        <img className="user__arrow" src={UserArrow} alt="User Arrow" />
      </div>
    }
  >
    {children}
  </Dropdown>
)

UserHeader.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  userRole: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

UserHeader.defaultProps = {
  image: UserAvatar,
  name: 'Firstname Lastname',
  userRole: '...',
  className: '',
  children: undefined
}

export default UserHeader
