import React from 'react'
import { Button, Field } from 'components/common'
import { useTranslation } from 'react-i18next'

export default function CartActions({ onBack, onCheckout, onTermsUpdate, termsChecked = false }) {
  const getButtonStyles = (backgroundColor, opacityValue = 0.7) => ({
    marginLeft: '24px',
    backgroundColor,
    borderRadius: 4,
    opacity: opacityValue
  })
  const { t } = useTranslation()

  return (
    <div className="cart-actions">
      <div className="flex flex-align-center mb-sm">
        <span className="mr-sm">{t('I have read and agree to the Terms and Conditions.')}</span>
        <Field
          name="terms-checkbox"
          type="checkbox"
          defaultChecked={termsChecked}
          onChange={(e) => onTermsUpdate(e.target.checked)}
        />
      </div>
      <div className="flex flex-align-center">
        <Button style={getButtonStyles('#263355', 1)} onClick={onBack}>
          {t('Back To Catalogue')}
        </Button>
        <Button
          style={getButtonStyles('#29a126', termsChecked ? 1 : 0.7)}
          onClick={onCheckout}
          disabled={!termsChecked}
        >
          {t('Place Order')}
        </Button>
      </div>
    </div>
  )
}
