/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { usePagination } from 'hooks'
import { Table, Button, Field, Input } from 'components/common'
import { OrderDetails } from 'components/complex'
import { useTranslation } from 'react-i18next'

const inputStyles = { width: '200px' }
const buttonStyles = { backgroundColor: '#263355', borderRadius: 3, marginLeft: '8px' }


export default function OrdersListing({
  orders,
  onUpdate,
  onShowDetails,
  orderDetails,
  onPaginate,
  onSearch,
  user
}) {
  const [showDetails, setShowDetails] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const { t } = useTranslation()

  const handleCheck = (e, value) => {
    onUpdate(e, value)
  }

  const handleShowDetails = (e) => {
    setShowDetails(true)
    onShowDetails(e)
  }

  const columns = [
    {
      Header: t('Order Id'),
      accessor: 'id',
      width: 5
    },
    {
      Header: t('Date/Time'),
      width: 20,
      Cell: ({ row }) => <span>{moment(row.original.created_at).format('DD-MM-YYYY HH:mm')}</span>
    },
    {
      Header: t('Company'),
      accessor: 'account.name',
      width: 10
    },
    {
      Header: t('Items'),
      accessor: 'order_details_count',
      width: 5
    },
    {
      Header: t('Total Price'),
      accessor: 'total_price',
      width: 10
    },
    {
      Header: t('Details'),
      Cell: ({ row }) => (
        <Button
          style={{ backgroundColor: '#2569a1', borderRadius: 0 }}
          onClick={() => handleShowDetails(row.original)}
        >
          Details
        </Button>
      ),
      width: 15
    },
    {
      Header: t('Completed'),
      Cell: ({ row }) => (
        <div className="flex flex-row flex-align-center">
          <Field
            name={`campaigns-checkbox${row.original.id}`}
            type="checkbox"
            disabled={user?.account.type === 'customer'}
            defaultChecked={row.original.is_completed}
            label={row.original.is_completed ? t('Completed') : t('Not completed')}
            onChange={(e) => handleCheck(e.target.checked, row.original)}
          />
        </div>
      ),
      width: 15
    }
  ]

  const { activePage, pagesCount, setActivePage } = usePagination({
    totalCount: orders?.total,
    perPage: orders?.per_page
  })

  const handleSearch = () => {
    onSearch(searchValue)
  }

  useEffect(() => {
    if (activePage >= 0) {
      onPaginate(activePage + 1)
    }
  }, [activePage])

  return (
    <div className="orders-table-wrapper">
      <div className="flex mb-lg">
        <Input
          name="search"
          placeholder={t('Search')}
          style={inputStyles}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button style={buttonStyles} onClick={handleSearch}>
          {t('Search')}
        </Button>
      </div>
      <Table
        columns={columns}
        data={orders?.data || []}
        hasPagination
        pagesCount={pagesCount}
        setActivePage={setActivePage}
      />
      {showDetails && (
        <OrderDetails data={orderDetails} isOpen close={() => setShowDetails(false)} />
      )}
    </div>
  )
}
