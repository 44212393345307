import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useRefreshToken } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorWindow } from 'components/common'
import { withReduxDataFetch } from 'components/HOC'
import { Header, MirrorSidebar, MirrorView } from 'components/client'

import { refreshToken, userSwitchSuccess } from 'store/user/actions'
import { userTokenSelector, userDataSelector } from 'store/user/selectors'

import { fetchInitCatalogRequest } from 'store/catalog/actions'

import { exitMirroring, setOriginalUserToken, toggleSidebar } from 'store/account-mirror/actions'
import localStorageService from 'utils/storage/localStorageService'

const ClientLayout = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const { token } = useSelector(userTokenSelector)
  const { user } = useSelector(userDataSelector)

  const originalUserData = localStorageService.getOriginalToken()

  useRefreshToken()

  useEffect(() => {
    if (user.account?.type !== 'company') {
      dispatch(fetchInitCatalogRequest())
    }
    dispatch(setOriginalUserToken({ token, user }))
  }, [])

  const handleExitView = () => {
    dispatch(exitMirroring())
    window.location.reload()
    history('/catalogue')

    dispatch(
      userSwitchSuccess({
        token: originalUserData.originalToken,
        user: originalUserData.originalUser
      })
    )
  }

  const previusToken = localStorageService.getPreviousToken()?.previousToken

  return (
    <>
      <Header />
      <MirrorSidebar />
      <main className="main main--client" id="main-client">
        {previusToken && (
          <MirrorView
            branchName={user?.account?.branch?.name}
            customerName={user?.user_detail?.full_name}
            onChangeView={() => dispatch(toggleSidebar())}
            onExitView={handleExitView}
          />
        )}
        <Outlet />
      </main>
      <ErrorWindow />
    </>
  )
}

const ClientLayoutLayer = withReduxDataFetch([
  {
    call: refreshToken,
    selector: userTokenSelector
  }
])(ClientLayout)

export default ClientLayoutLayer
