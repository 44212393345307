import _ from 'lodash'
import * as actionTypes from './actions'

const defaultFtpMessage =
  'Secure FTP setup for this supplier can take a few hours, The credentials will be displayed here once this process is complete.'
const initialState = {
  company: {
    data: {
      apis: [],
      stockFeed: {},
      credentials: {},
      ftp: {
        message: '',
        communication_ftp: null
      }
    },
    isFetched: false,
    isLoading: false,
    error: null
  },
  supplier: {
    data: {
      branches: [],
      apis: [],
      stockFeed: {},
      credentials: {},
      ftp: {
        message:
          'Secure FTP setup for this supplier can take a few hours, The credentials will be displayed here once this process is complete.',
        communication_ftp: null
      }
    },
    isFetched: false,
    isLoading: false,
    error: null
  },
  branch: {
    data: {
      branches: [],
      is_combined: false
    },
    isFetched: false,
    isLoading: false,
    error: null
  }
}

const stockFeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST:
    case actionTypes.FETCH_STOCK_FEED_DATA_REQUEST: {
      const { moduleName } = action.payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: false,
            isLoading: true,
            data: {}
          }
        }
      }
      return state
    }
    case actionTypes.STOCK_FEED_EDIT_ACCOUNT_REQUEST: {
      const { moduleName, finalData } = action.payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: false,
            isLoading: true,
            error: null,
            data: {
              ...state[moduleName]?.data,
              stockFeed: {
                ...(state[moduleName]?.data?.stockFeed || {}),
                api_id: finalData.api_id,
                type: finalData.type
              },
              credentials: finalData.api_credentials || {},
              branches: finalData.branches_orders
                ? _.map(state[moduleName]?.data?.branches, (item) => ({
                    ...item,
                    selected: finalData.branches_orders.includes(item?.id?.toString())
                  }))
                : {},
              is_combined: finalData.is_combined ?? false,
            }
          }
        }
      }
      return state
    }
    case actionTypes.FETCH_STOCK_FEED_DATA_SUCCESS: {
      const { moduleName, data } = action.payload
      const ftpData = {
        message: defaultFtpMessage,
        communication_ftp: null
      }
      if (data.stockFeed && data.stockFeed.type === 'ftp') {
        ftpData.message = data.stockFeed.communication_ftp
          ? ''
          : 'Secure FTP setup for this supplier is currently in progress and can take a few hours, The credentials will be displayed here once this process is complete'
        ftpData.communication_ftp = data.stockFeed?.communication_ftp ?? null
      }

      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: true,
            isLoading: false,
            error: null,
            data: {
              branches: data.branches ?? [],
              apis: data.apis ?? [],
              ftp: ftpData
            }
          }
        }
      }
      return state
    }
    case actionTypes.FETCH_STOCK_FEED_FOR_ACCOUNT_SUCCESS: {
      const { moduleName, data } = action.payload
      const ftpData = {
        message: defaultFtpMessage,
        communication_ftp: null
      }
      if (data.stockFeed) {
        if (data.stockFeed.type === 'ftp') {
          ftpData.message = data.stockFeed.communication_ftp
            ? ''
            : 'Secure FTP setup for this supplier is currently in progress and can take a few hours, The credentials will be displayed here once this process is complete'
          ftpData.communication_ftp = data.stockFeed?.communication_ftp ?? null
        }

        if (data.stockFeed?.communication_ftp) {
          ftpData.message = ''
          ftpData.communication_ftp = data.stockFeed?.communication_ftp ?? null
        }
      }
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: true,
            isLoading: false,
            error: null,
            data: {
              branches: data.branches ?? [],
              apis: data.apis ?? [],
              stockFeed: data.stockFeed ?? {},
              credentials: data.credentials ?? {},
              is_combined: data.is_combined ?? false,
              ftp: ftpData
            }
          }
        }
      }
      return state
    }
    case actionTypes.STOCK_FEED_EDIT_ACCOUNT_SUCCESS: {
      const { error, moduleName } = action.payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: true,
            isLoading: false,
            error
          }
        }
      }
      return state
    }
    case actionTypes.FETCH_STOCK_FEED_FOR_ACCOUNT_FAILURE:
    case actionTypes.STOCK_FEED_EDIT_ACCOUNT_FAILURE:
    case actionTypes.FETCH_STOCK_FEED_DATA_FAILURE: {
      const { error, moduleName } = action.payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: false,
            isLoading: true,
            error,
            data: {}
          }
        }
      }
      return state
    }
    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }
    default:
      return state
  }
}

export default stockFeedReducer
