/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useCallback } from 'react'
import classNames from 'classnames'

const useStepByStep = () => (startStep, steps) => {
  const [currentStep, setCurrentStep] = useState(startStep || 0)
  const previousStep = useCallback(() => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep))
  }, [])

  const nextStep = useCallback(() => {
    setCurrentStep((prevStep) => (prevStep < steps.length - 1 ? prevStep + 1 : prevStep))
  }, [])

  const goToStep = useCallback((stepIndex) => {
    setCurrentStep(stepIndex)
  }, [])

  const StepByStep = ({ isTabs }) => (
    <ul className="step-by-step__tabs">
      {steps.map(({ label, ...step }, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          key={label}
          className={classNames(
            'step-by-step__item',
            { 'step-by-step__step': !isTabs },
            { 'step-by-step__tab': isTabs },
            { 'step__tab--passed': index < currentStep },
            { 'step__tab--previous': index === currentStep - 1 },
            { 'step__tab--current': index === currentStep },
            { 'step__tab--next': index === currentStep + 1 },
            { 'cursor-pointer': isTabs }
          )}
          {...step}
          onClick={() => isTabs && goToStep(index)}
        >
          <span className="step-by-step__item__index">{index + 1}</span>
          <span className="step-by-step__item__label">{label}</span>
        </div>
      ))}
    </ul>
  )

  return {
    currentStep,
    previousStep,
    nextStep,
    goToStep,
    StepByStep
  }
}

export default useStepByStep
