/* eslint-disable react/display-name */
import React, { useEffect, useCallback, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { usePrevious } from 'hooks'
import axios from 'axios'
import axiosInstance from 'clients/api'
import { Loader, EmptyContent, Button } from 'components/common'

const withDataFetch =
  (
    requests,
    // showError,
    customErrorScreen,
    errorScreenContainerClass = ''
  ) =>
  (Component) =>
  ({ withoutLoader, ...props } = {}) => {
    const prevRequests = usePrevious(requests)
    const axiosSourceRef = useRef(null)

    const [state, setState] = useState({
      data: [],
      isFetched: true,
      isError: null
    })
    const fetchAll = useCallback((reqs) => {
      setState({
        data: [],
        isFetched: true,
        isError: null
      })

      if (axiosSourceRef.current) {
        axiosSourceRef.current.cancel()
      }

      axiosSourceRef.current = axios.CancelToken.source()

      const allRequests = reqs.map(({ method, url, options, params }) => {
        return axiosInstance[method](url, {
          cancelToken: axiosSourceRef.current.token,
          ...(params && { params }),
          ...(options && { options })
        })
      })

      axios
        .all(allRequests)
        .then(
          axios.spread((...responses) => {
            setState({
              data: responses.reduce(
                (previousValue, currentValue, currentIndex) => ({
                  ...previousValue,
                  [reqs[currentIndex].key]: currentValue.data
                }),
                {}
              ),
              isFetched: false
            })
          })
        )
        .catch((errors) => {
          setState({
            isFetched: false,
            isError: true
          })
        })
    }, [])

    useEffect(() => {
      if (!isEqual(requests, prevRequests)) {
        fetchAll(requests)
      }
    }, [requests])

    return state.isFetched ? (
      <Loader />
    ) : state.isError ? (
      customErrorScreen || (
        <EmptyContent
          // icon={<Warning fontSize="large"/>}
          // title="Failed."
          description="Failed to load data."
          className={`failed-screen ${errorScreenContainerClass}`}
        >
          <div className="flex flex-justify-space-between mb-md">
            <Button variant="primary" size="small" onClick={() => fetchAll(requests)}>
              Refresh
            </Button>
          </div>
        </EmptyContent>
      )
    ) : (
      <Component {...props} fetchedData={state.data} />
    )
  }

export default withDataFetch
