import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

const CardDetails = memo(({ data, refined }) => {
  const { t } = useTranslation()

  const keyNames = {
    title: t('Title'),
    email: t('Email'),
    tel: t('Tel'),
    mobile: t('Mobile'),
    department: t('Department'),
    created_at: t('Date Created'),
    account_number: t('Account Number'),
    price_level: t('Price Level'),
    users: t('Users'),
    vat_number: t('VAT Number'),
    reg_number: t('Company Reg Number'),
    order_recipient_email: t('Order Recipient Email'),
    address_1: t('Address Line 1'),
    address_2: t('Address Line 2'),
    town: t('Town'),
    state: t('County / State'),
    postal_code: t('Post Code / ZIP'),
    country: t('Country'),
    bank_name: t('Bank Name'),
    sort_code: t('Sort Code'),
    iban: t('IBAN'),
    bic: t('BIC / Swift Code'),
    branch: t('Branch'),
    customer_type: t('Customer Type'),
    enabled_brands: t('Enabled Brands'),
    account_number_with_supplier: t('Account Number With Supplier'),
    supplier_vat_number: t('Supplier VAT Number'),
    minimum_order_value: t('Minimum Order Value'),
    settlement_discount: t('Settlement Discount %'),
    settlement_discount_description: t('Settlement Discount Description'),
    credit_limit: t('Credit Limit'),
    payment_terms: t('Payment Terms (days)'),
    payment_date: t('Payment Date'),
    delivery_time: t('Delivery Time'),
    update_frequency: 'Update Frequency',
    pricing_level: t('Pricing Level'),
    dataModified: t('Data Modified'),
    fileName: t('Filename'),
    supplier: t('Supplier'),
    dateCreated: t('Date Created')
  }

  const dateTypes = ['created_at']

  return (
    <div className="table-details">
      <table>
        <tbody>
          {data &&
            Object.keys(data).map(
              (keyName) =>
                keyNames[keyName] && (
                  <tr key={keyName}>
                    <td className="table-details__title">
                      <span>{keyNames[keyName]}:</span>
                    </td>
                    <td className="table-details__value">
                      {keyName !== 'enabled_brands' ? (
                        <span className="flex">
                          {dateTypes.includes(keyName) ? formatDate(data[keyName]) : data[keyName]}
                          {keyName === 'dataModified' && refined && (
                            <p style={{ marginLeft: '4px', color: '#2ca125' }}>({t('Refined')})</p>
                          )}
                        </span>
                      ) : (
                        <span style={{ color: data[keyName] !== '0' ? '#2BA125' : '#EB3B3B' }}>
                          {data[keyName]}
                        </span>
                      )}
                    </td>
                  </tr>
                )
            )}
        </tbody>
      </table>
    </div>
  )
})

CardDetails.propTypes = {
  data: PropTypes.shape({})
}

CardDetails.defaultProps = {
  data: null
}

export default CardDetails
CardDetails.displayName = 'CardDetails'
