import { createPromiseAction } from '@adobe/redux-saga-promise'

export const VAT_RATES_CALL = 'VAT_RATES_CALL'
export const VAT_AVAILABLE_CODES_CALL = 'VAT_AVAILABLE_CODES_CALL'
export const VAT_RATE_ADD_PROMISE = 'VAT_RATE_ADD_PROMISE'
export const VAT_RATE_EDIT_PROMISE = 'VAT_RATE_EDIT_PROMISE'
export const VAT_RATE_DELETE_PROMISE = 'VAT_RATE_DELETE_PROMISE'
export const VAT_RATE_LOADING_SWITCH = 'VAT_RATE_LOADING_SWITCH'

export const vatRateAddPromise = createPromiseAction(VAT_RATE_ADD_PROMISE)
export const vatRateEditPromise = createPromiseAction(VAT_RATE_EDIT_PROMISE)
export const vatRateDeletePromise = createPromiseAction(VAT_RATE_DELETE_PROMISE)

export function vatRatesCall() {
  return { type: VAT_RATES_CALL }
}

export function vatAvailableCodesCall() {
  return { type: VAT_AVAILABLE_CODES_CALL }
}

export function vatRateLoadingSwitch(status) {
  return { type: VAT_RATE_LOADING_SWITCH, payload: { status } }
}