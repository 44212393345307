export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'

export const CHANGE_TOKEN = 'CHANGE_TOKEN'

export const USER_SWITCH = 'USER_SWITCH'
export const USER_SWITCH_SUCCESS = 'USER_SWITCH_SUCCESS'
export const USER_SWITCH_FAILED = 'USER_SWITCH_FAILED'
export const RESET_SWITCH_USER = 'RESET_SWITCH_USER'

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'

export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN'

export const USER_REGISTRATION = 'USER_REGISTRATION'
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS'
export const USER_REGISTRATION_FAILED = 'USER_REGISTRATION_FAILED'

// export const RESET_PASSWORD = 'RESET_PASSWORD';
// export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_LOADING_SWITCH = 'USER_LOADING_SWITCH'

export function userLogin(email, password) {
  return { type: USER_LOGIN, payload: { email, password } }
}

export function userLoginSuccess({ token, expires_in, user }) {
  return { type: USER_LOGIN_SUCCESS, payload: { token, expires_in, user } }
}

export function userLoginFailed(error) {
  return { type: USER_LOGIN_FAILED, payload: { error } }
}

export function userRegister(userData) {
  return { type: USER_REGISTRATION, payload: { userData } }
}

export function userRegisterSuccess(token) {
  return { type: USER_REGISTRATION_SUCCESS, payload: { token } }
}

export function userRegisterFailed(errors) {
  return { type: USER_REGISTRATION_FAILED, payload: { errors } }
}

export function userLogout() {
  return { type: USER_LOGOUT }
}

export function userLogoutSuccess() {
  return { type: USER_LOGOUT_SUCCESS }
}

export function userLogoutFailed() {
  return { type: USER_LOGOUT_FAILED }
}

export function refreshToken() {
  return { type: USER_REFRESH_TOKEN }
}

export function userSwitch() {
  return { type: USER_SWITCH }
}

export function userSwitchSuccess(data) {
  return { type: USER_SWITCH_SUCCESS, payload: data }
}

export function userSwitchFailed(data) {
  return { type: USER_SWITCH_FAILED, payload: data }
}

export function resetSwitchUser() {
  return { type: RESET_SWITCH_USER }
}

export function changeToken(token) {
  return { type: CHANGE_TOKEN, payload: token }
}

export function userLoadingSwitch(loadingStatus) {
  return { type: USER_LOADING_SWITCH, payload: loadingStatus }
}

// function logoutAndDispatch(dispatch, getState){
//         return axiosInstance.post("/logout", {
//             "token": getState().auth.token,
//             "email": getState().auth.user.email
//         }).then(response => {
//             store.remove("token");
//             store.remove("user");
//             store.remove("previousToken");
//             store.remove("previousUser");
//             return dispatch( userLoggedOut() );
//         })
// }

// const cartSetted = cart => ({ type: 'SET_CART_AFTER_LOGIN', payload: cart});

// export const setCart = (cart) => (
//     (dispatch) => {
//         return axiosInstance.get("/catalogue/getCart")
//                 .then(response => {
//                     if(response) {
//                         dispatch( cartSetted(response.data) );
//                         return response.data;
//                     }
//                 });
//     }
// );

// const compareSetted = compare => ({ type: 'SET_COMPARE_AFTER_LOGIN', payload: compare});

// export const setCompare = (compare) => (
//     (dispatch) => {
//         return axiosInstance.get("catalogue/getCompare")
//                 .then(response => {
//                     if(response) {
//                         dispatch( compareSetted(response.data) );
//                         return response.data;
//                     }
//                 });
//     }
// );

// const userDataCleared = () => ({ type: 'CLEAR_USER_DATA'});

// export const LogoutUser = () => {
//     return (dispatch, getState) => {
//         // Todo. Cleaning cart and compare should not be happening here but in api/logout route
//         // let cleanCart = axios.delete("/catalogue/clean-cart?token=" + store.get('token'));
//         // let clearCompare = axios.delete("/catalogue/clear-compare-list?token=" + store.get('token'));
//         // return Promise.all([cleanCart, clearCompare]).then(() => {
//         //     dispatch(userDataCleared());
//         //     return logoutAndDispatch(dispatch, getState);
//         // })

//         return Promise.all([]).then(() => {
//             dispatch(userDataCleared());
//             return logoutAndDispatch(dispatch, getState);
//         })
//     }
// }
