import { useCallback } from 'react'
import { axiosRequest } from 'clients/api'

const usePaginateOnScroll = (moduleName, setIsLoading, setList, setPagination) =>
  useCallback((page, status) => {
    setIsLoading(true)
    axiosRequest('get', `/account/${moduleName}`, {
      params: {
        page,
        status
      }
    })
      .then(({ data }) => {
        setList((prevState) => [...prevState, ...data.data])
        setPagination(data.pagination)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

export default usePaginateOnScroll
