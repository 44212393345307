import {
  COMMON_USERS_GET,
  COMMON_USER_ADD_PROMISE,
  COMMON_USER_EDIT_PROMISE,
  COMMON_USER_DELETE_PROMISE
} from 'store/common/users/actions'
import {
  COMMON_CONTACTS_GET,
  COMMON_CONTACT_ADD_PROMISE,
  COMMON_CONTACT_EDIT_PROMISE,
  COMMON_CONTACT_DELETE_PROMISE
} from 'store/common/contacts/actions'
import {
  ACCOUNT_COLLECTION_GET,
  ACCOUNT_GET,
  ACCOUNT_ADD_PROMISE,
  ACCOUNT_EDIT_PROMISE
} from 'store/account/actions'
import {
  CURRENCIES_CALL,
  CURRENCY_RATES_CALL,
  CURRENCY_RATE_CALL,
  CURRENCY_RATE_ADD_CALL_PROMISE,
  CURRENCY_RATE_EDIT_CALL_PROMISE,
  CURRENCY_RATE_DELETE_CALL_PROMISE
} from 'store/currency/actions'
import {
  MEDIA_FOLDERS_CALL,
  MEDIA_FOLDER_ADD_CALL_PROMISE,
  MEDIA_FOLDER_EDIT_CALL_PROMISE,
  MEDIA_FOLDERS_DELETE_CALL_PROMISE
} from 'store/media-library/actions'
import {
  ROLE_ADD_CALL_PROMISE,
  ROLE_EDIT_CALL_PROMISE,
  ROLES_DELETE_CALL_PROMISE,
  ROLES_PERMISSIONS_CALL
} from 'store/settings/roles-permissions/actions'
import {
  CUSTOMER_TYPES_CALL,
  CUSTOMER_TYPE_ADD_PROMISE,
  CUSTOMER_TYPE_EDIT_PROMISE,
  CUSTOMER_TYPE_DELETE_PROMISE,
  CUSTOMER_TYPE_SORT_PROMISE
} from '../store/settings/customer-types/actions'
import {
  GROUPS_BY_PARTNUMBER_GET,
  GROUPS_BY_REGNUMBER_GET,
  GROUPS_BY_VINNUMBER_GET,
} from '../store/catalogue/actions'

const errorsList = {
  SERVER_DOWN: {
    message: 'System maintenance in progress. Please try again shortly.',
    btnText: 'Refresh',
    isFatal: true
  },

  // COMMON USERS
  [COMMON_USERS_GET]: {
    message: 'Failed to load users.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${COMMON_USER_ADD_PROMISE}.TRIGGER`]: {
    message: 'Failed to add user.',
    btnText: 'Retry'
  },
  [`${COMMON_USER_EDIT_PROMISE}.TRIGGER`]: {
    message: 'Failed to edit user.',
    btnText: 'Retry'
  },
  [`${COMMON_USER_DELETE_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete user.',
    btnText: 'Retry'
  },

  // COMMON CONTACTS
  [COMMON_CONTACTS_GET]: {
    message: 'Failed to load contacts.',
    btnText: 'Refresh',
    isFatal: true
  },
  [COMMON_CONTACT_ADD_PROMISE]: {
    message: 'Failed to add contact.',
    btnText: 'Retry'
  },
  [COMMON_CONTACT_EDIT_PROMISE]: {
    message: 'Failed to edit contact.',
    btnText: 'Retry'
  },
  [`${COMMON_CONTACT_DELETE_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete contact.',
    btnText: 'Retry'
  },

  // ACCOUNTS
  [ACCOUNT_COLLECTION_GET]: {
    message: 'Failed to load account collection.',
    btnText: 'Refresh',
    isFatal: true
  },
  [ACCOUNT_GET]: {
    message: 'Failed to load account.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${ACCOUNT_ADD_PROMISE}.TRIGGER`]: {
    message: 'Failed to add account.',
    btnText: 'Retry'
  },
  [ACCOUNT_EDIT_PROMISE]: {
    message: 'Failed to edit account.',
    btnText: 'Retry'
  },

  // CURRENCIES
  [CURRENCIES_CALL]: {
    message: 'Failed to load currencies.',
    btnText: 'Refresh',
    isFatal: true
  },
  [CURRENCY_RATES_CALL]: {
    message: 'Failed to load currency rates.',
    btnText: 'Refresh',
    isFatal: true
  },
  [CURRENCY_RATE_CALL]: {
    message: 'Failed to load currency rate.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${CURRENCY_RATE_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to add currency.',
    btnText: 'Retry'
  },
  [`${CURRENCY_RATE_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to edit currency.',
    btnText: 'Retry'
  },
  [`${CURRENCY_RATE_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete currency.',
    btnText: 'Retry'
  },

  // MEDIA LIBRARY
  [MEDIA_FOLDERS_CALL]: {
    message: 'Failed to load media folders.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${MEDIA_FOLDER_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to add folder.',
    btnText: 'Retry'
  },
  [`${MEDIA_FOLDER_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to edit folder.',
    btnText: 'Retry'
  },
  [`${MEDIA_FOLDERS_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete folder(s).',
    btnText: 'Retry'
  },

  // ROLES PERMISSIONS
  [ROLES_PERMISSIONS_CALL]: {
    message: 'Failed to load media folders.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${ROLE_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to add role.',
    btnText: 'Retry'
  },
  [`${ROLE_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to edit role.',
    btnText: 'Retry'
  },
  [`${ROLES_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete role.',
    btnText: 'Retry'
  },

  // CUSTOMER TYPES
  [CUSTOMER_TYPES_CALL]: {
    message: 'Failed to load customer types.',
    btnText: 'Refresh',
    isFatal: true
  },
  [`${CUSTOMER_TYPE_ADD_PROMISE}.TRIGGER`]: {
    message: 'Failed to add customer role.',
    btnText: 'Retry'
  },
  [`${CUSTOMER_TYPE_EDIT_PROMISE}.TRIGGER`]: {
    message: 'Failed to edit customer role.',
    btnText: 'Retry'
  },
  [`${CUSTOMER_TYPE_DELETE_PROMISE}.TRIGGER`]: {
    message: 'Failed to delete customer role.',
    btnText: 'Retry'
  },
  [`${CUSTOMER_TYPE_SORT_PROMISE}.TRIGGER`]: {
    message: 'Failed to sort customer role.',
    btnText: 'Retry'
  },

  // Catalogue Search
  [GROUPS_BY_PARTNUMBER_GET]: {
    name: 'The part number you entered could not be found.',
    message: 'Try search by vehicle selection or registration',
    btnText: 'Refresh',
    closeOnFail: true
  },
  [`${GROUPS_BY_REGNUMBER_GET}`]: {
    name: 'The vehicle registration you entered could not be found.',
    message: 'Try search by vehicle selection or part number.',
    closeOnFail: true
  },
  [`${GROUPS_BY_VINNUMBER_GET}`]: {
    name: 'The VIN number you entered could not be found.',
    message: 'Try search by vehicle selection or part number',
    closeOnFail: true
  }
}

export default errorsList
