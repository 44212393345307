import { createPromiseAction } from '@adobe/redux-saga-promise'

export const ROLES_PERMISSIONS_CALL = 'ROLES_PERMISSIONS_CALL'
export const ROLE_ADD_CALL_PROMISE = 'ROLE_ADD_CALL_PROMISE'
export const ROLE_EDIT_CALL_PROMISE = 'ROLE_EDIT_CALL_PROMISE'
export const ROLES_DELETE_CALL_PROMISE = 'ROLES_DELETE_CALL_PROMISE'
export const ROLE_LOADING_SWITCH = 'ROLE_LOADING_SWITCH'

export const roleAddPromise = createPromiseAction(ROLE_ADD_CALL_PROMISE)
export const roleEditPromise = createPromiseAction(ROLE_EDIT_CALL_PROMISE)
export const roleDeletePromise = createPromiseAction(ROLES_DELETE_CALL_PROMISE)

export function rolesPermissionsCall(moduleName) {
  return { type: ROLES_PERMISSIONS_CALL, payload: { moduleName } }
}

export function roleLoadingSwitch(moduleName, status) {
  return { type: ROLE_LOADING_SWITCH, payload: { moduleName, status } }
}
