import React, { forwardRef } from 'react'
import { Box, Divider } from 'components/common'
import FTP from './FTP'
import API from './API'

const stockFeedSources = [
  {
    type: 'radio',
    name: 'type',
    label: 'FTP',
    value: 'ftp'
  },
  {
    type: 'radio',
    name: 'type',
    label: 'API',
    value: 'api'
  }
]

const StockFeedSection = forwardRef(({ title, data, FormField, watch, isEdit }, ref) => {
  const checkedType = watch('type')

  return (
    <Box title={title}>
      <div className="flex">
        {stockFeedSources.map(({ type, name, label, value }) =>
          FormField({
            key: label,
            type,
            name,
            label,
            className: `${!isEdit ? 'field-view-only' : ''}`,
            disabled: !isEdit,
            defaultValue: value
          })
        )}
      </div>
      <Divider className="mt-xs mb-sm" />
      {checkedType === 'ftp' ? (
        <FTP FormField={FormField} ftp={data.ftp} />
      ) : (
        <API isEdit={isEdit} apis={data.apis} FormField={FormField} apiWatcher={watch('api_id')} />
      )}
    </Box>
  )
})

export default StockFeedSection
StockFeedSection.displayName = 'StockFeedSection'
