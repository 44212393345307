import { call, put, select, fork, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction
} from '@adobe/redux-saga-promise'
// eslint-disable-next-line import/no-cycle
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import { appendObjectToFormData } from 'utils/helpers'
import {
  GROUPS_BY_REGNUMBER_GET,
  GROUPS_BY_VINNUMBER_GET,
  GROUPS_BY_PARTNUMBER_GET,
  VEHICLE_DATA_GET,
  PRODUCTS_BY_VEHICLE_GET,
  TOGGLE_SECTION,
  searchLoadingSwitch,
  FETCH_NON_AUTO_GROUPS_REQUEST,
  TOGGLE_GROUP,
  GET_STOCK_REQUEST
} from './actions'

const searchPrefix = '/web-catalogue/search'

// Loading wrapper
function* groupLoadingGeneratorWrapper(moduleName, gen) {
  yield put(searchLoadingSwitch(moduleName, true))
  yield gen()
  yield put(searchLoadingSwitch(moduleName, false))
}

function* nonAutoGroupsGetGenerator(action) {
  const {
    type,
    payload: { moduleName }
  } = action
  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData;
    try {
      const { data } = yield call(() => axiosSagaRequest('get', `${searchPrefix}/non-auto-groups`))
      fetchedData = data
    } catch (error) { 
      yield put(errorAdd(error, action))
    }
    if (fetchedData.warning) 
      yield put(errorAdd(fetchedData.message, action))
    else 
      yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: fetchedData } })
  })
}


function* toggleGroupGetGenerator(action) {
  const {
    type,
    payload: { moduleName, groupId }
  } = action
  if(moduleName === 'non_automative') {
    yield groupLoadingGeneratorWrapper(moduleName, function* () {
      let fetchedData;
      try {
        const { data } = yield call(axiosSagaRequest, 'get', `${searchPrefix}/by-non-auto-group/${groupId}`)
        fetchedData= data
      } catch (error) {
        yield put(errorAdd(error, action))
      }

      if(fetchedData.warning)
        yield put(errorAdd(fetchedData.message, action))
      else
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: fetchedData } })
    })
  }
}

function* groupsByPartNumberGetGenerator(action) {
  const {
    type,
    payload: { moduleName, partNumber }
  } = action
  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData;
    try {
      const { data } = yield call(axiosSagaRequest, 'get', `${searchPrefix}/by-part-number/${partNumber}`)
      fetchedData= data
    } catch (error) {
      yield put(errorAdd(error, action))
    }

    if(fetchedData.warning)
      yield put(errorAdd(fetchedData.message, action))
    else
      yield put({ type: `${type}_RESPONSE`, payload: { moduleName, partNumber, data: fetchedData } })
  })
}

function* groupsByRegnumberGetGenerator(action) {
  const {
    type,
    payload: { moduleName, regNumber }
  } = action
  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData
    try {
      const regNumberParam = regNumber.replace(/\s/g, '')
      const { data } = yield call(
        axiosSagaRequest,
        'get',
        `${searchPrefix}/by-reg-number/${regNumberParam}`
      )
      fetchedData = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }

    if (fetchedData.warning) yield put(errorAdd(fetchedData.message, action))
    else yield put({ type: `${type}_RESPONSE`, payload: { moduleName, regNumber, data: fetchedData} })
  })
}

function* groupsByVinNumberGetGenerator(action) {
  const {
    type,
    payload: { moduleName, vinNumber }
  } = action
  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData;
    try {
      const { data } = yield call(
        axiosSagaRequest,
        'get',
        `${searchPrefix}/by-vin-number/${vinNumber}`
      )
      fetchedData = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }

    if (fetchedData.warning) yield put(errorAdd(fetchedData.message, action))
    else yield put({ type: `${type}_RESPONSE`, payload: { moduleName, vinNumber, data: fetchedData } })
  })
}

function* vehicleDataGetGenerator(action) {
  const {
    type,
    payload: { moduleName, options }
  } = action

  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let d
    try {
      const { data } = yield call(axiosSagaRequest, 'post', `/web-catalogue/get-vehicles`, {
        data: options
      })
      d = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }

    // if(d.warning)
    // yield put(errorAdd(d.message, action))
    // else
    yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d } })
  })
}

function* productsByVehicleIdsGetGenerator(action) {
  const {
    type,
    payload: { moduleName, vehicleIds }
  } = action

  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData
    try {
      const { data } = yield call(axiosSagaRequest, 'post', `${searchPrefix}/by-vehicle`, {
        vehicleIds
      })
      fetchedData = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }
    yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: fetchedData } })
  })
}

function* productsGetGenerator(action) {
  const {
    type,
    payload: { moduleName, sectionId }
  } = action
  const getLookupId = (state) => state.searchData?.[moduleName].data.lookupId
  const lookup_id = yield select(getLookupId)
  yield groupLoadingGeneratorWrapper(moduleName, function* () {
    let fetchedData;
    try {
      const { data } = yield call(
        axiosSagaRequest,
        'get',
        `${searchPrefix}/by-section/${lookup_id}/${sectionId}`
      )
      fetchedData = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }
    const partNumberIds = fetchedData.products.map((product) => product.part_number_id)
    yield fork(getStock, { type: GET_STOCK_REQUEST, moduleName, partNumberIds, sectionId })
    yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: fetchedData, section_id: sectionId } })
  })
}

function* getStock(payload) {
  const {
    type, moduleName, partNumberIds, sectionId
  } = payload
  try {
    const stockResponse = yield call(() =>
      axiosSagaRequest('post', 'web-catalogue/get-stock', {
        part_number_ids: partNumberIds
      })
    )
    const stockData = stockResponse.data
    yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: stockData, section_id: sectionId } })
  } catch (error) {
    yield put(errorAdd(error, {type}))
  }
}

export function* searchWatcherSaga() {
  yield takeLatest(GROUPS_BY_REGNUMBER_GET, groupsByRegnumberGetGenerator)
  yield takeLatest(GROUPS_BY_VINNUMBER_GET, groupsByVinNumberGetGenerator)
  yield takeLatest(GROUPS_BY_PARTNUMBER_GET, groupsByPartNumberGetGenerator)
  yield takeLatest(VEHICLE_DATA_GET, vehicleDataGetGenerator)
  yield takeLatest(PRODUCTS_BY_VEHICLE_GET, productsByVehicleIdsGetGenerator)
  yield takeLatest(TOGGLE_SECTION, productsGetGenerator)
  yield takeLatest(GET_STOCK_REQUEST, getStock)
  yield takeLatest(FETCH_NON_AUTO_GROUPS_REQUEST, nonAutoGroupsGetGenerator)
  yield takeLatest(TOGGLE_GROUP, toggleGroupGetGenerator)
}
