/* eslint-disable no-param-reassign */
import produce from 'immer'

import { CAMPAIGNS_CALL, CAMPAIGNS_DELETE_CALL_PROMISE, CAMPAIGNS_LOADING_SWITCH } from './actions'

const initialState = {
  campaigns: {
    isFetched: false,
    isLoading: false,
    data: [],
    base_path: ''
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case CAMPAIGNS_CALL: {
      state.campaigns.isFetched = false
      state.campaigns.data = []
      state.campaigns.base_path = ''
      return state
    }

    case `${CAMPAIGNS_CALL}_RESPONSE`: {
      state.campaigns.isFetched = true
      state.campaigns.data = payload.promotions.data
      state.campaigns.base_path = payload.base_path
      return state
    }

    case `${CAMPAIGNS_DELETE_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload.type === 'clear') {
        state.campaigns.data = []
        return state
      }
      if (payload.type === 'selected') {
        const ids = payload.data.data.map((item) => item.id)

        state.campaigns.data = state.campaigns.data.filter((cp) => !ids.includes(cp.id))
        return state
      }

      state.campaigns.data = state.campaigns.data.filter((cp) => cp.id !== payload)
      return state
    }

    case CAMPAIGNS_LOADING_SWITCH: {
      const { status } = payload
      state.campaigns.isLoading = status !== undefined ? status : !state.campaigns.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
