import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { userTokenSelector } from 'store/user/selectors'
import { TranslationProvider } from 'components/common/translation'
import { SnackbarProvider } from 'components/common/snackbar'
import { DialogProvider } from 'components/common/dialog'

import { fetchLocalesRequest } from 'store/locales/actions'
import { localesSelector } from 'store/locales/selectors'
import { useSelector, useDispatch } from 'react-redux'
import Module from './modules'

import 'utils/prototypes'
import 'assets/scss/main.scss'

function App() {
  window['__react-beautiful-dnd-disable-dev-warnings'] = true

  const { t, i18n } = useTranslation()

  const { token } = useSelector(userTokenSelector, isEqual)
  const { locales } = useSelector(localesSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLocalesRequest())

    if (!localStorage.getItem('locale')) localStorage.setItem('locale', i18n.language)
  }, [])

  return (
    <>
      <TranslationProvider defaultLanguage="en">
        <SnackbarProvider>
          <DialogProvider>
            <Module isAuthorized={Boolean(token)} />
          </DialogProvider>
        </SnackbarProvider>
      </TranslationProvider>
    </>
  )
}

export default App
