import { createPromiseAction } from '@adobe/redux-saga-promise'

export const COMMON_CONTACTS_GET = 'COMMON_CONTACTS_GET'
export const COMMON_CONTACT_GET = 'COMMON_CONTACT_GET'
export const COMMON_CONTACT_ADD_PROMISE = 'COMMON_CONTACT_ADD_PROMISE'
export const COMMON_CONTACT_ACTION_INSTEP_PROMISE = 'COMMON_CONTACT_ACTION_INSTEP_PROMISE'
export const COMMON_CONTACT_EDIT_PROMISE = 'COMMON_CONTACT_EDIT_PROMISE'
export const COMMON_CONTACT_DELETE_PROMISE = 'COMMON_CONTACT_DELETE_PROMISE'
export const COMMON_CONTACT_LOADING_SWITCH = 'COMMON_CONTACT_LOADING_SWITCH'
export const COMMON_CONTACT_CLEAR_STEP_DATA = 'COMMON_CONTACT_CLEAR_STEP_DATA'

export const commonContactAddPromise = createPromiseAction(COMMON_CONTACT_ADD_PROMISE)
export const commonContactEditPromise = createPromiseAction(COMMON_CONTACT_EDIT_PROMISE)
export const commonContactDeletePromise = createPromiseAction(COMMON_CONTACT_DELETE_PROMISE)
export const commonContactActionInStepPromise = createPromiseAction(
  COMMON_CONTACT_ACTION_INSTEP_PROMISE
)

export function commonContactsGet(moduleName, accountId) {
  return { type: COMMON_CONTACTS_GET, payload: { moduleName, accountId } }
}

export function commonContactGet(moduleName, contactId) {
  return { type: COMMON_CONTACT_GET, payload: { moduleName, contactId } }
}

export function commonContactLoadingSwitch(moduleName, status) {
  return { type: COMMON_CONTACT_LOADING_SWITCH, payload: { moduleName, status } }
}

export function commonContactClearStepData(moduleName) {
  return { type: COMMON_CONTACT_CLEAR_STEP_DATA, payload: { moduleName } }
}
