import { createPromiseAction } from '@adobe/redux-saga-promise'

export const CHARGES_CALL = 'CHARGES_CALL'
export const CHARGES_EDIT_PROMISE = 'CHARGES_EDIT_PROMISE'

export const CHARGES_LOADING_SWITCH = 'CHARGES_LOADING_SWITCH'

export const chargesEditPromise = createPromiseAction(CHARGES_EDIT_PROMISE)

export function chargesCall() {
  return { type: CHARGES_CALL }
}

export function chargesLoadingSwitch(status) {
  return { type: CHARGES_LOADING_SWITCH, payload: { status } }
}
