/* eslint-disable no-alert */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'components/common'
import Actions from './Actions'
import Header from './Header'
import Title from './Title'
import Listing from './Listing'

export default function OrderDetails({ data, isOpen, close }) {
  const handlePrint = () => {
    window.print()
  }

  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      close={close}
      size="xl"
      alignment="center"
      title={t('Order Details')}
      disableBackdropClick
    >
      {data && (
        <div className="p-md">
          <Title orderNumber={data?.order.id} orderDate={data?.order.created_at} />
          <Header companyDetails={data?.companyDetails} order={data?.order} />
          <Listing orderDetails={data?.orderDetails} />
          <Actions priceVat={data?.order?.total_price_with_vat} onPrint={handlePrint} />
        </div>
      )}
    </Dialog>
  )
}
