import React from 'react'
import PropTypes from 'prop-types'

const Select = ({ handleChange, error, options, selectedId, defaultValue }) => {
  const selectedOption = options.find((option) => option.id === selectedId)

  const handleSelectChange = (e) => {
    handleChange(e.target.value)
  }

  return (
    <div className="selector">
      <select
        className={`selector__selector ${error ? 'selector__selector--error' : ''}`}
        onChange={handleSelectChange}
        value={selectedOption ? selectedOption.id : ''}
      >
        {defaultValue && <option value="">{defaultValue}</option>}
        {options.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
      {error && <span className="selector__error">{error}</span>}
    </div>
  )
}

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  selectedId: PropTypes.string,
  defaultValue: PropTypes.string
}

Select.defaultProps = {
  options: [],
  defaultValue: '',
  error: undefined,
  selectedId: ''
}

export default Select
