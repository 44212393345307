import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { promiseMiddleware } from '@adobe/redux-saga-promise'
import rootReducer from './root-reducer'
import rootSaga from './root-saga'

const sagaMiddleware = createSagaMiddleware()
const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const middleware = [promiseMiddleware, sagaMiddleware]

const store = createStore(rootReducer, composeSetup(applyMiddleware(...middleware)))

sagaMiddleware.run(rootSaga)

export default store
