import { takeLatest, put, call } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import localStorageService from 'utils/storage/localStorageService'
import {
  USER_LOGIN,
  USER_REGISTRATION,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  USER_SWITCH,
  userLoadingSwitch,
  userLoginSuccess,
  userLoginFailed,
  userRegisterSuccess,
  userRegisterFailed,
  userLogout,
  userLogoutSuccess,
  userLogoutFailed,
  userSwitchSuccess,
  userSwitchFailed
} from './actions'
import { errorAdd } from '../error/actions'

const prefix = ''

function* userLoginGenerator(action) {
  yield put(userLoadingSwitch(true))
  try {
    const {
      payload: { email, password }
    } = action
    // const userData = yield select(userDataSelector);

    const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/login`, {
      email,
      password
    })

    yield put(userLoginSuccess(data));
  } catch (error) {
    console.log(Object.values(error.response.data)[0]);
    yield put(userLoginFailed(Object.values(error.response.data)[0]));
  } finally {
    yield put(userLoadingSwitch(false))
  }
}

function* userRegisterGenerator(action) {
  yield put(userLoadingSwitch(true))
  try {
    const {
      payload: { userData }
    } = action

    const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/register`, userData)

    if (!data.token) {
      throw data?.errors
    }

    yield put(userRegisterSuccess(data.token))
  } catch (error) {
    yield put(userRegisterFailed(error))
  } finally {
    yield put(userLoadingSwitch(false))
  }
}

function* userLogoutGenerator(action) {
  yield put(userLoadingSwitch(true))
  try {
    yield call(axiosSagaRequest, 'post', `${prefix}/logout`, {
      token: localStorageService.getAccessToken(),
      email: localStorageService.getUser().email
    })

    yield put(userLogoutSuccess())
  } catch (error) {
    yield put(userLogoutFailed())
  } finally {
    yield put(userLoadingSwitch(false))
  }
}

function* userRefreshTokenGenerator(action) {
  const { type } = action
  try {
    const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/refresh`)
    yield put({ type: `${type}_SUCCESS`, payload: data })
  } catch (error) {
    // Clean token + redux state if request failed with any reason
    yield put(userLogoutSuccess())
  }
}

function* userSwitchGenerator(action) {
  yield put(userLoadingSwitch(true))
  const {
    payload: { userId }
  } = action

  try {
    const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/switchUser`, {
      userId
    })

    yield put(userSwitchSuccess(data))
  } catch (error) {
    yield put(userSwitchFailed())
  } finally {
    yield put(userLoadingSwitch(false))
  }
}

export default function* userWatcherSaga() {
  yield takeLatest(USER_LOGIN, userLoginGenerator)
  yield takeLatest(USER_REGISTRATION, userRegisterGenerator)
  yield takeLatest(USER_LOGOUT, userLogoutGenerator)
  yield takeLatest(USER_REFRESH_TOKEN, userRefreshTokenGenerator)
  yield takeLatest(USER_SWITCH, userSwitchGenerator)
}
