import { memo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components/common'

const NavigationCard = ({ iconClass, title_primary, title_secondary, className }) => (
  <div className={`list-card list-card--settings ${className}`}>
    <div className="list-card__avatar--icon">
      <Icon iconClass={iconClass} size="48px" />
    </div>
    <div className="list-card__title ml-lg">
      <span className="list-card__title__primary">{title_primary}</span>
      <span className="list-card__title__secondary mt-xs">{title_secondary}</span>
    </div>
  </div>
)

NavigationCard.propTypes = {
  iconClass: PropTypes.string,
  title_primary: PropTypes.string,
  title_secondary: PropTypes.string,
  className: PropTypes.string
}

NavigationCard.defaultProps = {
  iconClass: '',
  title_primary: '',
  title_secondary: '',
  className: ''
}

export default memo(NavigationCard)
