import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

const DateField = forwardRef(({ control, name, placeholder, disabled, ...otherProps }, ref) => {
  // TODO => add non control version for switch

    const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <DatePicker
            ref={ref}
            id={`field-${name}`}
            name={name}
            className="field__inner"
            // className
            // calendarClassName
            disabled={disabled}
            showTimeSelect
            dateFormat="dd.MM.yyyy - hh:mm"
            // dateFormatCalendar="LLLL yyyy"
            todayButton={t('Today')}
            placeholderText={placeholder}
            // openToDate instanceOf(Date)
            preventOpenOnFocus
            // popperModifiers={{
            //     preventOverflow: {
            //         enabled: true
            //     }
            // }}
            withPortal
            portalId="datepicker-portal"
            // popperClassName="some-custom-class"
            // popperPlacement="top-end"
            // popperModifiers={[
            //     {
            //         name: "offset",
            //         options: {
            //             offset: [50, 10],
            //         },
            //     },
            //     {
            //         name: "preventOverflow",
            //         options: {
            //             rootBoundary: "viewport",
            //             tether: false,
            //             altAxis: true,
            //         },
            //     },
            // ]}

            {...otherProps}
            selected={value || otherProps.defaultValue}
            onChange={onChange}

            // minDate={new Date()}
            // selected={
            //     dateReceived?.value ? new Date(dateReceived.value) : null
            // }
            // customInput={<StyledInput errors={errors} />}
            // dropdownMode="select"
            // isClearable
            // shouldCloseOnSelect
          />
        )
      }}
    />
  )
})

DateField.propTypes = {}

DateField.defaultProps = {}

export default DateField
DateField.displayName = 'DateField'
