export const settingsSelector = (store) => store.generalData.settings

export const initSettingsSelector = (store) => store.generalData.initSettings

export const initSettingsModulesSelector = (store) => {
  if (store.generalData.initSettings.isFetched && store.generalData.initSettings?.modules) {
    return store.generalData.initSettings.modules
  }
  return null
}

export const initSettingsModulePermissionsSelector = (store, moduleName) => {
  if (store.generalData.initSettings.isFetched && store.generalData.initSettings?.modules) {
    return store.generalData.initSettings.modules?.[moduleName].permissions
  }
  return null
}

export const initSettingsModulePermissionsSelectorHook = (store, moduleName, permissionName) => {
  if (store.generalData.initSettings.isFetched && store.generalData.initSettings?.modules) {
    return store.generalData.initSettings.modules?.[moduleName]
  }
  return null
}

export const initSettingsCompanyIdSelector = (store) => store.generalData.initSettings.companyId

export const initSettingsAdditionalSelector = (store) => store.generalData.initSettings.additional

export const rolesSelector = (store, type) => store.generalData.roles[type]
