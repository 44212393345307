import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchLocales() {
  yield requestWithActions(
    actions.FETCH_LOCALES_REQUEST,
    () => axiosSagaRequest('get', 'locales'),
    actions.fetchLocalesSuccess,
    actions.fetchLocalesFailure
  )
}

export default function* localesWatcherSaga() {
  yield takeLatest(actions.FETCH_LOCALES_REQUEST, fetchLocales)
}
