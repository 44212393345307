import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Icon } from 'components/common'

/**
 * Note block component
 * @param {Boolean} open - Note state
 * @param {String} type - Note Type ['info', 'success', 'warning', 'danger']
 */

const icons = {
  info: 'information',
  success: 'checkmark',
  warning: 'exclamation-cog',
  danger: 'Exclamation-Triangle'
}

const NoteBar = memo(({ type, className, children }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isClosing, setIsClosing] = useState(false)
  const icon = icons[type]

  const handleClose = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
      setIsOpen(false)
    }, 320)
  }, [])

  return isOpen ? (
    <div
      className={classNames(
        'note-bar',
        `note-bar--${type}`,
        { 'note-bar--is-closing': isClosing },
        { [className]: className }
      )}
    >
      <div className="note-bar__body">
        {icon && (
          <div className="note-bar__icon">
            <Icon iconClass={icon} size="32px" />
          </div>
        )}

        <div className="note-bar__content">{children && children}</div>

        <Button
          className="note-bar__close"
          title="Close Note"
          variant="outlined"
          isIcon
          onClick={handleClose}
        >
          <Icon iconClass="close" type="dark" size="14px" />
        </Button>
      </div>
    </div>
  ) : null
})

NoteBar.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
    PropTypes.node
  ])
}

NoteBar.defaultProps = {
  type: 'info',
  className: '',
  children: undefined
}

export default NoteBar
NoteBar.displayName = 'NoteBar'
