import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Button component
 * @param {String} tag - Component tag name
 * @param {String} type - Button type
 * @param {Function} onClick - onClick function
 * @param {String} variant - ['primary', 'secondary', 'tertiary', 'outlined', 'link']
 * @param {String} size - ['small', 'medium', 'large']
 * @param {String} style - Style object
 * @param {Boolean} disabled - Disabled state
 * @param {String} className - Additional classname
 * @param {JSX} isIcon - Button has only icon
 * @param {Boolean} isDestructive - destructive state
 * @param {JSX} children - Content
 */

const Button = ({
  tag,
  type,
  onClick,
  variant,
  size,
  style,
  disabled,
  className,
  isIcon,
  isDestructive,
  children,
  ...restProps
}) => {
  return React.createElement(
    tag,
    {
      type,
      className: classNames(
        'btn',
        `btn--${variant}`,
        { [`btn--${size}`]: size && !isIcon && variant !== 'link' },
        { [`btn--destructive`]: isDestructive },
        { btn__icon: isIcon },
        { [className]: className }
      ),
      style,
      onClick,
      disabled,
      ...restProps
    },
    children
  )
}

Button.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'outlined', 'link']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isDestructive: PropTypes.bool,
  isIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
    PropTypes.node
  ])
}

Button.defaultProps = {
  tag: 'button',
  type: 'button',
  onClick: undefined,
  variant: 'primary',
  size: 'small',
  style: {},
  disabled: false,
  className: undefined,
  isDestructive: false,
  isIcon: false,
  children: ''
}

export default Button
