/* eslint-disable no-undef */
/* eslint-disable radix */
import produce from 'immer'
import {
  VAT_RATES_CALL,
  VAT_AVAILABLE_CODES_CALL,
  VAT_RATE_ADD_PROMISE,
  VAT_RATE_EDIT_PROMISE,
  VAT_RATE_DELETE_PROMISE,
  VAT_RATE_LOADING_SWITCH
} from './actions'

const initialState = {
  vatRates: {
    isFetched: false,
    isLoading: false,
    vatRates: [],
    available_vat_codes: []
  }
}

const vatRatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case VAT_RATES_CALL: {
      return {
        ...state,
        vatRates: {
          ...state.vatRates,
          isFetched: false,
          isLoading: true,
          vatRates: []
        }
      }
    }
    case `${VAT_RATES_CALL}_RESPONSE`: {
      return {
        ...state,
        vatRates: {
          ...state.vatRates,
          isFetched: true,
          isLoading: false,
          vatRates: payload?.vat_rates || []
        }
      }
    }
    case VAT_AVAILABLE_CODES_CALL: {
      return {
        ...state,
        vatRates: {
          ...state.vatRates,
          isFetched: false,
          isLoading: true,
          available_vat_codes: []
        }
      }
    }
    case `${VAT_AVAILABLE_CODES_CALL}_RESPONSE`: {
      return {
        ...state,
        vatRates: {
          ...state.vatRates,
          isFetched: true,
          isLoading: false,
          available_vat_codes: payload?.available_vat_codes || []
        }
      }
    }
    case `${VAT_RATE_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      const { data } = payload
      if (data?.vat_rate) {
        const vatRates = [...state.vatRates.vatRates]
        const available_vat_codes = _.filter(state.vatRates.available_vat_codes, (item) => {
          return parseInt(item) !== parseInt(data.vat_rate.code)
        })
        vatRates.push(data.vat_rate)
        return {
          ...state,
          vatRates: {
            ...state.vatRates,
            isFetched: true,
            vatRates,
            available_vat_codes: available_vat_codes || []
          }
        }
      }
      return state
    }
    case `${VAT_RATE_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const { vatRateId, editData } = payload

      if (vatRateId) {
        const vatRates = [...state.vatRates.vatRates]
        const available_vat_codes = _.filter(state.vatRates.available_vat_codes, (item) => {
          return parseInt(item) !== parseInt(editData.code)
        })
        const oldVatRate = _.find(vatRates, { id: vatRateId })
        if (oldVatRate) {
          available_vat_codes.push(oldVatRate.code)
          oldVatRate.code = editData.code
          oldVatRate.title = editData.title
        }
        return {
          ...state,
          vatRates: {
            ...state.vatRates,
            isFetched: true,
            vatRates,
            available_vat_codes: _.sortBy(available_vat_codes) || []
          }
        }
      }
      return state
    }
    case `${VAT_RATE_DELETE_PROMISE}.TRIGGER_RESPONSE`: {
      const { vatRateId } = payload
      if (vatRateId) {
        const vatRates = [...state.vatRates.vatRates]
        const available_vat_codes = _.values(state.vatRates.available_vat_codes)
        const oldVatRate = _.find(vatRates, { id: vatRateId })
        if (oldVatRate) {
          available_vat_codes.push(oldVatRate.code)
          _.remove(vatRates, oldVatRate)
        }
        return {
          ...state,
          vatRates: {
            ...state.vatRates,
            isFetched: true,
            vatRates,
            available_vat_codes: _.sortBy(available_vat_codes) || []
          }
        }
      }
      return state
    }
    case VAT_RATE_LOADING_SWITCH: {
      const { status } = payload
      return {
        ...state,
        vatRates: {
          ...state.vatRates,
          isLoading: status !== undefined ? status : !state.vatRates.isLoading
        }
      }
    }
    default: {
      return state
    }
  }
}

export default vatRatesReducer
