/* eslint-disable func-names */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction
} from '@adobe/redux-saga-promise'
import { initSettingsCompanyIdSelector } from 'store/general/selectors'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import {
  FINANCIAL_DETAILS_CALL,
  financialDetailsEditPromise,
  financialDetailsLoadingSwitch
} from './actions'

const prefix = '/account/financial'

// Loading wrapper
function* financialDetailsLoadingGeneratorWrapper(moduleName, gen) {
  yield put(financialDetailsLoadingSwitch(moduleName, true))
  yield gen()
  yield put(financialDetailsLoadingSwitch(moduleName, false))
}

function* financialDetailsCallGenerator(action) {
  const {
    type,
    payload: { moduleName, id }
  } = action

  let d
  try {
    let accountId
    if (id) {
      accountId = id
    } else {
      accountId = yield select(initSettingsCompanyIdSelector)
    }

    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}`, {
      params: {
        account: accountId
      }
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d.finance } })
}

function* financialDetailsEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, id, finalData }
    } = action

    yield financialDetailsLoadingGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'patch', `${prefix}/set/${id}`, finalData)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, finalData } })
        yield call(resolvePromiseAction, action, finalData)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* financialDetailsWatcherSaga() {
  yield takeLatest(FINANCIAL_DETAILS_CALL, financialDetailsCallGenerator)
  yield takeLatest(financialDetailsEditPromise, financialDetailsEditPromiseGenerator)
}
