/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// TODO -> remove this component

const RadioButton = ({ checked, onChange, name, label, disabled }) => {
  const inputEl = useRef(null)

  const handleClick = () => {
    inputEl.current.click()
  }

  return (
    <div className={`radio ${disabled ? 'radio--disabled' : ''}`}>
      <input
        className="radio__input"
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange || null}
        key={`element${name}`}
        id={`element${name}`}
        disabled={disabled}
        ref={inputEl}
      />
      <span className="radio__label" onClick={handleClick}>
        {label}
      </span>
    </div>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

RadioButton.defaultProps = {
  disabled: false
}

export default RadioButton
