// components
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ConditionRoute from 'components/common/conditionRoute'

// utils
import PropTypes from 'prop-types'
import { usePermission } from 'components/common/permission'

// modules
import AuthModule from 'modules/auth'
import AdminModule from 'modules/admin'
import ClientModule from 'modules/client'

// configs
import { dashboardFullPath, clientFullPath } from './configs/fullPathStatics'

// controllers
import { LoginController } from './auth/controllers'
import { AdminController } from './admin/controllers'
import { CatalogueController } from './client/controllers'

const Main = ({ isAuthorized }) => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route index element={<Navigate to={LoginController.path} replace />} />

        <Route
          path="/*"
          element={
            <ConditionRoute
              condition={!isAuthorized}
              redirectLink={usePermission('dashboard-access') ? dashboardFullPath : clientFullPath}
            >
              <AuthModule />
            </ConditionRoute>
          }
        />

        <Route
          path={`${AdminController.path}/*`}
          element={
            <ConditionRoute condition={isAuthorized} redirectLink={`/${LoginController.path}`}>
              <AdminModule />
            </ConditionRoute>
          }
        />

        <Route
          path={`${CatalogueController.path}/*`}
          element={
            <ConditionRoute condition={isAuthorized} redirectLink={`/${LoginController.path}`}>
              <ClientModule />
            </ConditionRoute>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>
)

Main.propTypes = {
  isAuthorized: PropTypes.bool.isRequired
}

export default Main
