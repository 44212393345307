import { createPromiseAction } from '@adobe/redux-saga-promise'

export const COMMON_USERS_GET = 'COMMON_USERS_GET'
export const COMMON_USER_GET = 'COMMON_USER_GET'
export const COMMON_USER_ADD_PROMISE = 'COMMON_USER_ADD_PROMISE'
export const COMMON_USER_ACTION_INSTEP_PROMISE = 'COMMON_USER_ACTION_INSTEP_PROMISE'
export const COMMON_USER_EDIT_PROMISE = 'COMMON_USER_EDIT_PROMISE'
export const COMMON_USER_DELETE_PROMISE = 'COMMON_USER_DELETE_PROMISE'
export const COMMON_USER_STATUS_PROMISE = 'COMMON_USER_STATUS_PROMISE'
export const COMMON_USER_LOADING_SWITCH = 'COMMON_USER_LOADING_SWITCH'
export const COMMON_USER_CLEAR_STEP_DATA = 'COMMON_USER_CLEAR_STEP_DATA'

export const commonUserDeletePromise = createPromiseAction(COMMON_USER_DELETE_PROMISE)
export const commonUserAddPromise = createPromiseAction(COMMON_USER_ADD_PROMISE)
export const commonUserEditPromise = createPromiseAction(COMMON_USER_EDIT_PROMISE)
export const commonUserStatusPromise = createPromiseAction(COMMON_USER_STATUS_PROMISE)
export const commonUserActionInStepPromise = createPromiseAction(COMMON_USER_ACTION_INSTEP_PROMISE)

export const COMMON_SHORT_USERS_GET = 'COMMON_SHORT_USERS_GET'

export function commonUsersGet(moduleName, accountId) {
  return { type: COMMON_USERS_GET, payload: { moduleName, accountId } }
}

export function commonUserGet(moduleName, userId) {
  return { type: COMMON_USER_GET, payload: { moduleName, userId } }
}

export function commonUserLoadingSwitch(moduleName, status) {
  return { type: COMMON_USER_LOADING_SWITCH, payload: { moduleName, status } }
}

export function  commonUserClearStepData(moduleName) {
  return { type: COMMON_USER_CLEAR_STEP_DATA, payload: { moduleName } }
}

// Short type

export function commonShortUsersGet(moduleName, accountId) {
  return { type: COMMON_SHORT_USERS_GET, payload: { moduleName, accountId } }
}
