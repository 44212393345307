/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  ACCOUNT_LOADING_SWITCH,
  ACCOUNT_COLLECTION_GET,
  ACCOUNT_GET,
  ACCOUNT_ADD_PROMISE,
  ACCOUNT_EDIT_PROMISE
} from './actions'

const initialState = {
  company: {
    isFetched: false,
    data: [],
    single: {
      isFetched: false,
      isLoading: false,
      data: []
    }
  },
  supplier: {
    isFetched: false,
    data: [],
    single: {
      isFetched: false,
      isLoading: false,
      data: [],
      inStepData: []
    }
  },
  branch: {
    isFetched: false,
    data: [],
    single: {
      isFetched: false,
      isLoading: false,
      data: [],
      inStepData: []
    }
  },
  customer: {
    isFetched: false,
    data: [],
    single: {
      isFetched: false,
      isLoading: false,
      data: [],
      inStepData: []
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    // ACCOUNTS LIST
    case ACCOUNT_COLLECTION_GET: {
      const { moduleName } = payload
      if (moduleName) {
        state[moduleName].isFetched = false
        state[moduleName].data = []
        state[moduleName].pagination = {}
      }
      return state
    }

    case `${ACCOUNT_COLLECTION_GET}_RESPONSE`: {
      const { moduleName, data, pagination } = payload
      if (moduleName) {
        state[moduleName].isFetched = true
        state[moduleName].data = data || []
        state[moduleName].pagination = pagination || {}
      }
      return state
    }

    case ACCOUNT_GET: {
      const { moduleName } = payload
      if (moduleName) {
        state[moduleName].single.isFetched = false
        state[moduleName].single.data = {}
      }
      return state
    }

    case `${ACCOUNT_GET}_RESPONSE`: {
      const { moduleName, data } = payload
      if (moduleName) {
        state[moduleName].single.isFetched = true
        state[moduleName].single.data = data || {}
      }
      return state
    }

    case `${ACCOUNT_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      const { moduleName, data } = payload
      if (data.step) {
        if (
          state[moduleName].single.inStepData.length &&
          state[moduleName].single.inStepData.some(({ step }) => step === data.step)
        ) {
          state[moduleName].single.inStepData = state[moduleName].single.inStepData.map((step) => {
            if (step.step === data.step) {
              return data
            }
            return step
          })
        } else {
          if(data.step === 'final') {
            state[moduleName].single.inStepData = [];
          }else {
            state[moduleName].single.inStepData.push(data)
          }
        }
      } else {
        state[moduleName].data.push(data)
      }

      return state
    }

    case `${ACCOUNT_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const { moduleName, id, data } = payload

      if (moduleName === 'company') {
        const { finalData, logo } = data
        const { settings, ...d } = finalData
        state[moduleName].single.data = {
          ...d,
          image: logo.url,
          branches: state[moduleName].single.data.branches
        }
      } else {
        const { step, ...d } = data
        state[moduleName].single.data = { ...d }
      }

      return state
    }

    case ACCOUNT_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        state[mKey].single.isLoading = status !== undefined ? status : !state[mKey].single.isLoading
      }
      return state
    }

    default: {
      return state
    }
  }
})
