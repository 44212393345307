export const FETCH_LOCALES_REQUEST = 'FETCH_LOCALES_REQUEST'
export const FETCH_LOCALES_SUCCESS = 'FETCH_LOCALES_SUCCESS'
export const FETCH_LOCALES_FAILURE = 'FETCH_LOCALES_FAILURE'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchLocalesRequest = createAction(FETCH_LOCALES_REQUEST)
export const fetchLocalesSuccess = createAction(FETCH_LOCALES_SUCCESS)
export const fetchLocalesFailure = createAction(FETCH_LOCALES_FAILURE)
