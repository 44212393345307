import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

// Helper function for making API requests and handling success/failure actions
function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

// Fetch Landed Prices
function* fetchLandedPrices() {
  yield requestWithActions(
    actions.FETCH_LANDED_PRICES_REQUEST,
    () => axiosSagaRequest('get', 'landed-price'),
    actions.fetchLandedPricesSuccess,
    actions.fetchLandedPricesFailure
  )
}

// Create Landed Price
function* createLandedPrice(action) {
  const { data, file, prices } = action.payload
  const formData = new FormData()
  formData.append('supplier_id', data.supplier)
  formData.append('brand_id', data.brand)
  formData.append('currency_id', data.currency)
  formData.append('price_file', file)

  prices.forEach((price, index) => {
    formData.append(`prices[${index}][part_number]`, price.part_number)
    formData.append(`prices[${index}][price]`, price.price)
  })

  yield requestWithActions(
    actions.CREATE_LANDED_PRICE_REQUEST,
    () =>
      axiosSagaRequest('post', 'landed-price', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
    actions.createLandedPriceSuccess,
    actions.createLandedPriceFailure
  )
}

// Update Landed Price
function* updateLandedPrice(action) {
  yield requestWithActions(
    actions.UPDATE_LANDED_PRICE_REQUEST,
    () => axiosSagaRequest('put', `landed-price/${action.payload.id}`, action.payload),
    actions.updateLandedPriceSuccess,
    actions.updateLandedPriceFailure
  )
}

// Delete Landed Price
function* deleteLandedPriceSaga(action) {
  const priceId = action.payload
  yield requestWithActions(
    actions.DELETE_LANDED_PRICE_REQUEST,
    () => axiosSagaRequest('delete', `landed-price/${priceId}`),
    (data) => actions.deleteLandedPriceSuccess(priceId, data),
    actions.deleteLandedPriceFailure
  )
}

// Fetch Single Landed Price
function* fetchSingleLandedPrice(action) {
  const id = action.payload
  yield requestWithActions(
    actions.FETCH_SINGLE_LANDED_PRICE_REQUEST,
    () => axiosSagaRequest('get', `landed-price/${id}`),
    actions.fetchSingleLandedPriceSuccess,
    actions.fetchSingleLandedPriceFailure
  )
}

// Fetch Form Data
function* fetchFormData() {
  yield requestWithActions(
    actions.FETCH_FORM_DATA_REQUEST,
    () => axiosSagaRequest('get', 'landed-price/get-form-data'),
    actions.fetchFormDataSuccess,
    actions.fetchFormDataFailure
  )
}

// Fetch Price Export
function* fetchExportPrice(action) {
  const id = action.payload

  yield requestWithActions(
    actions.FETCH_EXPORT_REQUEST,
    () => axiosSagaRequest('get', `landed-price/export/${id}`),
    actions.fetchExportSuccess,
    actions.fetchExportFailure
  )
}

function* createSellingPrice(action) {
  yield requestWithActions(
    actions.CREATE_SELLING_PRICE_REQUEST,
    () =>
      axiosSagaRequest('post', `selling-price/${action.payload.landed_price_id}`, action.payload),
    actions.createSellingPriceSuccess,
    actions.createSellingPriceFailure
  )
}

// Root saga
export default function* landedPricesWatcherSaga() {
  yield takeLatest(actions.FETCH_LANDED_PRICES_REQUEST, fetchLandedPrices)
  yield takeLatest(actions.CREATE_LANDED_PRICE_REQUEST, createLandedPrice)
  yield takeLatest(actions.UPDATE_LANDED_PRICE_REQUEST, updateLandedPrice)
  yield takeLatest(actions.DELETE_LANDED_PRICE_REQUEST, deleteLandedPriceSaga)
  yield takeLatest(actions.FETCH_SINGLE_LANDED_PRICE_REQUEST, fetchSingleLandedPrice)
  yield takeLatest(actions.FETCH_FORM_DATA_REQUEST, fetchFormData)
  yield takeLatest(actions.FETCH_EXPORT_REQUEST, fetchExportPrice)
  yield takeLatest(actions.CREATE_SELLING_PRICE_REQUEST, createSellingPrice)
}
