import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, NavLink } from 'react-router-dom'
import TabsContext from './tabs-context'

const activeClassName = 'tab--active'

const TabTitle = ({
  title,
  to,
  index,
  className,
  disabled,
  beforeTabChange,
  onTabClick,
  tabColor
}) => {
  const navigate = useNavigate()

  const { activeTabIndex, setActiveTabIndex } = useContext(TabsContext)
  const handleClick = (type) => (e) => {
    e.preventDefault()
    onTabClick()

    function doMove() {
      setActiveTabIndex(index)
      if (type !== 'button') {
        navigate(type)
      }
    }

    if (beforeTabChange) {
      beforeTabChange(activeTabIndex, index, doMove)
    } else {
      setActiveTabIndex(index)
    }
  }

  return (
    <li className="tab">
      {to ? (
        <NavLink
          className={(navData) =>
            navData.isActive
              ? `tab__link ${className} ${activeClassName}`
              : `tab__link ${className}`
          }
          {...(beforeTabChange && { onClick: handleClick(to) })}
          to={to}
          disabled={disabled}
        >
          {title}
        </NavLink>
      ) : (
        <button
          className={`tab__button ${activeTabIndex === index ? activeClassName : ''} ${className}`}
          onClick={handleClick('button')}
          type="button"
          disabled={disabled}
          style={{ color: tabColor }}
        >
          {title}
        </button>
      )}
    </li>
  )
}

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  beforeTabChange: PropTypes.func,
  onTabClick: PropTypes.func
}

TabTitle.defaultProps = {
  to: undefined,
  index: undefined,
  className: '',
  disabled: false,
  beforeTabChange: undefined,
  onTabClick: () => {}
}

export default TabTitle
