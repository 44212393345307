import { createPromiseAction } from '@adobe/redux-saga-promise'

export const CUSTOMER_TYPES_CALL = 'CUSTOMER_TYPES_CALL'
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';


export const SECTIONS_BY_GROUP_GET = 'SECTIONS_BY_GROUP_GET';
export const BRANDS_BY_SECTION_GET = 'BRANDS_BY_SECTION_GET';

export const CUSTOMER_TYPE_ADD_PROMISE = 'CUSTOMER_TYPE_ADD_PROMISE'
export const CUSTOMER_TYPE_EDIT_PROMISE = 'CUSTOMER_TYPE_EDIT_PROMISE'
export const CUSTOMER_TYPE_DELETE_PROMISE = 'CUSTOMER_TYPE_DELETE_PROMISE'
export const CUSTOMER_TYPE_SORT_PROMISE = 'CUSTOMER_TYPE_SORT_PROMISE'
export const CUSTOMER_TYPE_RENAME_PROMISE = 'CUSTOMER_TYPE_RENAME_PROMISE'

export const CUSTOMER_TYPE_LOADING_SWITCH = 'CUSTOMER_TYPE_LOADING_SWITCH'

export const customerTypeAddPromise = createPromiseAction(CUSTOMER_TYPE_ADD_PROMISE)
export const customerTypeEditPromise = createPromiseAction(CUSTOMER_TYPE_EDIT_PROMISE)
export const customerTypeRenamePromise = createPromiseAction(CUSTOMER_TYPE_RENAME_PROMISE)
export const customerTypeDeletePromise = createPromiseAction(CUSTOMER_TYPE_DELETE_PROMISE)
export const customerTypeSortPromise = createPromiseAction(CUSTOMER_TYPE_SORT_PROMISE)

export function customerTypesCall() {
  return { type: CUSTOMER_TYPES_CALL }
}

export function customerTypeCall(customerTypeId) {
  return { type: CUSTOMER_TYPE_GET,  payload: { customerTypeId } }
}

export function customerTypeLoadingSwitch(status) {
  return { type: CUSTOMER_TYPE_LOADING_SWITCH, payload: { status } }
}

export function sectionsByGroupGet(groupId, customerTypeId) {
  return { type: SECTIONS_BY_GROUP_GET, payload: { groupId, customerTypeId } }
}

export function brandsBySectionGet(sectionId, customerTypeId, groupId) {
  return { type: BRANDS_BY_SECTION_GET, payload: { sectionId, customerTypeId, groupId } }
}
