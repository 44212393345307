import React, { useState } from 'react'
import { Button, Icon } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { selectBespokePrices } from 'store/selling-prices/selectors'
import { updateAdvancedPriceLevel } from 'store/selling-prices/actions'
import FileUpload from './FileUpload'

const buttonStyles = { backgroundColor: '#2569a1', borderRadius: 0, width: '80px' }

export default function Advanced({ formData }) {
  const dispatch = useDispatch()
  const bespokePrices = useSelector(selectBespokePrices)

  const [showUploadModal, setShowUploadModal] = useState(false)
  const [currentPricing, setCurrentPricing] = useState(null)

  const showUpload = (item) => {
    setCurrentPricing(item)
    setShowUploadModal(true)
  }

  const handleUpload = (e) => {
    dispatch(updateAdvancedPriceLevel(e))
  }
  const handleDelete = (item) => {
    dispatch({ type: 'DELETE_BESPOKE_PRICE', item })
  }

  return (
    <>
      {showUploadModal && (
        <FileUpload
          isOpen
          close={() => setShowUploadModal(false)}
          data={currentPricing}
          onUpload={handleUpload}
        />
      )}

      <div className="flex flex-align-center">
        {formData.map((item, index) => {
          const hasBespokePrices = bespokePrices.some(
            (product) => product.price_level_id === item.id
          )

          return (
            <div
              className="flex flex-align-center flex-column"
              style={{ marginRight: 56 }}
              key={item.id || index}
            >
              <div className="flex flex-align-start flex-row">
                <div className="flex flex-align-center flex-column">
                  <Button type="button" style={buttonStyles} onClick={() => showUpload(item)}>
                    Upload
                  </Button>
                  <span style={{ color: '#263355', textWrap: 'nowrap' }}>{item.name}</span>
                </div>
                {hasBespokePrices && (
                  <div className="ml-xs">
                    <Button
                      style={{ backgroundColor: '#eb3b3b' }}
                      type="button"
                      size="small"
                      onClick={() => handleDelete(item)}
                    >
                      <Icon
                        iconClass="trash-delete"
                        size="16px"
                        style={{ color: 'white', margin: '0px' }}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
