/* eslint-disable no-else-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { userDataSelector } from 'store/user/selectors'

export const usePermission = (permissionName) => {
  const { user } = useSelector(userDataSelector)
  if (Array.isArray(permissionName)) {
    // Check if any of the permissions in the array matches
    const hasPermission = useMemo(() => {
      const permissions = user?.roles[0]?.permissions
      if (permissions) {
        return permissionName.some((name) =>
          permissions.some((permission) => permission.name === name)
        )
      }
      return false
    }, [user, permissionName])

    return hasPermission
  } else if (typeof permissionName === 'string') {
    // Check if the permissionName is a string
    const hasPermission = useMemo(() => {
      const permissions = user?.roles[0]?.permissions
      if (permissions) {
        return permissions.some((permission) => permission.name === permissionName)
      }
      return false
    }, [user, permissionName])

    return hasPermission
  }

  // Default to false if the permissionName is neither a string nor an array
  return false
}
