import { createPromiseAction } from '@adobe/redux-saga-promise'

export const PRICE_LEVELS_CALL = 'PRICE_LEVELS_CALL'
export const PRICE_LEVELS_ACCOUNT_DATA_CALL = 'PRICE_LEVELS_ACCOUNT_DATA_CALL'
export const PRICE_LEVELS_ACCOUNT_EDIT_PROMISE = 'PRICE_LEVELS_ACCOUNT_EDIT_PROMISE'

export const PRICE_LEVELS_LOADING_SWITCH = 'PRICE_LEVELS_LOADING_SWITCH'

export function priceLevelsCall(moduleName, params) {
  return { type: PRICE_LEVELS_CALL, payload: { moduleName, params } }
}

export function priceLevelsAccountDataCall(moduleName, id) {
  return { type: PRICE_LEVELS_ACCOUNT_DATA_CALL, payload: { moduleName, id } }
}

export const priceLevelsAccountEditPromise = createPromiseAction(PRICE_LEVELS_ACCOUNT_EDIT_PROMISE)

export function priceLevelsLoadingSwitch(moduleName, status) {
  return { type: PRICE_LEVELS_LOADING_SWITCH, payload: { moduleName, status } }
}

// export const clearState = createAction(CLEAR_STATE)
