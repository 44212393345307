import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { generateImageUrl } from 'utils/helpers'
import { useSelector } from 'react-redux'
import { badgeColorsByStatus } from 'constant'
import { initSettingsAdditionalSelector } from 'store/general/selectors'
import { CardDetails } from 'components/complex/index'
import { usePermission } from 'components/common/permission'
import { Icon, Dropdown, Badge } from 'components/common'

const UserListCard = memo(
  ({ firstCardId, onAction, data, className, isStep, moduleName = null }) => {
    const {
      id,
      image,
      email,
      role,
      index,
      status_id,
      user_detail: { full_name, job_title, mobile, telephone }
    } = data

    const { status } = useSelector(initSettingsAdditionalSelector, isEqual)
    const userActions = useMemo(
      () =>
        isStep
          ? [
              {
                actionStatusId: 'edit',
                action: onAction('edit'),
                icon: 'edit-outlined',
                text: 'Edit'
              },
              {
                actionStatusId: 5,
                action: onAction('delete'),
                icon: 'trash-delete',
                text: 'Delete'
              }
            ]
          : [
              {
                actionStatusId: 'edit',
                action: onAction('edit'),
                icon: 'edit-outlined',
                text: 'Edit'
              },
              {
                actionStatusId: 1,
                action: onAction('activate'),
                icon: 'activate',
                text: 'Activate'
              },
              {
                actionStatusId: 2,
                action: onAction('deactivate'),
                icon: 'deactivate',
                text: 'Deactivate'
              },
              {
                actionStatusId: 5,
                action: onAction('delete'),
                icon: 'trash-delete',
                text: 'Delete'
              },
              {
                actionStatusId: 'restore',
                action: onAction('restore'),
                icon: 'trash-delete',
                text: 'Restore'
              }
            ],
      [onAction]
    )

    let managePermission = 'company_account_user-manage'
    if (moduleName === 'branch') {
      managePermission = ['branch-manage', 'own_branch-manage']
    }

    return (
      <div className={`list-card list-card--${className}`}>
        <div className="list-card__header">
          {image ? (
            <img
              className="list-card__avatar list-card__avatar--img"
              src={generateImageUrl(image)}
              alt="img"
            />
          ) : (
            <div className="list-card__avatar list-card__avatar--text">{full_name.charAt(0)}</div>
          )}
          <div className="list-card__title">
            <span className="list-card__title__primary">{full_name}</span>
            {role && (
              <span className="list-card__title__secondary">
                <span className="list-card__title__secondary__label">Role: </span>
                <span className="list-card__title__secondary__value">{role[0]?.name || ''}</span>
              </span>
            )}
          </div>
          {usePermission(managePermission) && (
            <Dropdown
              label={
                <button type="button">
                  <Icon iconClass="dots" type="lighter" size="18px" />
                </button>
              }
              menuAlignment="right"
              className="list-card__dropdown"
            >
              {id === firstCardId && !isStep
                ? userActions
                    .filter(({ actionStatusId }) => actionStatusId === 'edit')
                    .map(({ action, icon, text }) => (
                      <Dropdown.Item
                        key={text}
                        className="list-card__dropdown-item"
                        onClick={() => action(id)}
                      >
                        <Icon iconClass={icon} type="dark" size="16px" />
                        {text}
                      </Dropdown.Item>
                    ))
                : // check if user is deleted
                status_id === 5
                ? userActions
                    .filter(({ actionStatusId }) => actionStatusId === 'restore')
                    .map(({ action, icon, text }) => (
                      <Dropdown.Item
                        key={text}
                        className="list-card__dropdown-item"
                        onClick={() => action(id)}
                      >
                        <Icon iconClass={icon} type="dark" size="16px" />
                        {text}
                      </Dropdown.Item>
                    ))
                : userActions
                    .filter(
                      ({ actionStatusId }) =>
                        actionStatusId !== status_id && actionStatusId !== 'restore'
                    )
                    .map(({ action, icon, text }) => (
                      <Dropdown.Item
                        key={text}
                        className={`list-card__dropdown-item ${
                          icon === 'trash-delete' ? 'red' : ''
                        }`}
                        // when id is undefined there is only Delete action left in inStep actions,
                        onClick={() => action(id || index)}
                      >
                        <Icon iconClass={icon} type="dark" size="16px" />
                        {text}
                      </Dropdown.Item>
                    ))}
            </Dropdown>
          )}
        </div>
        <div className="list-card__body">
          <CardDetails
            data={{
              title: job_title || '-',
              email: email || '-',
              tel: telephone || '-',
              mobile: mobile || '-'
            }}
          />
          <Badge
            title={
              isStep
                ? // 4 is pending's status_id
                  status.find((s) => +s.id === 4).name
                : status.find((s) => +s.id === +status_id).name
            }
            type={badgeColorsByStatus[isStep ? 4 : status_id]}
          />
        </div>
      </div>
    )
  }
)

UserListCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

UserListCard.defaultProps = {
  className: ''
}

export default UserListCard
UserListCard.displayName = 'UserListCard'
