/* eslint-disable no-bitwise */
/* eslint-disable no-shadow */
import React from 'react'

export default function LetteredAvatar({ name, width = 40, height = 40, margin }) {
  function getInitials(name) {
    if (name.includes(' ')) {
        return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    } 
    
    return `${name[0]}${name[1] || ''}`;
  }

  function generateBackground(name) {
    let hash = 0
    let i

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  const initials = getInitials(name)
  const color = generateBackground(name)
  const customStyle = {
    display: 'flex',
    height,
    width,
    borderRadius: '100px',
    color: 'white',
    background: color,
    margin
  }

  return (
    <div style={customStyle}>
      <span style={{ margin: 'auto' }}> {initials} </span>
    </div>
  )
}
