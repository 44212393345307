/* eslint-disable no-plusplus */
import moment from 'moment'
import _ from 'lodash'
import { backendDateFormat, BASE_URL, MMM_D_YYYY_HH_MM } from 'constant'

export function bytesToSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function getNameFirstLetters(firstName, lastName) {
  const fL = firstName ? firstName.charAt(0) : ''
  const lL = lastName ? lastName.charAt(0) : ''

  return `${fL}${lL}`
}

export function formatDate(d, inFormat = backendDateFormat, outFormat = MMM_D_YYYY_HH_MM) {
  return moment.utc(d, inFormat).format(outFormat)
}

export function copyToClipboard(copyMe) {
  try {
    const elem = document.createElement('textarea')
    elem.setAttribute(
      'style',
      `
                position: absolute;
                z-index: -99999999;
                top: -10000px;
                left: -10000px;
                opacity: 0;
            `
    )
    document.body.appendChild(elem)
    elem.value = copyMe
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)
  } catch (err) {
    console.error('Failed to copy!', err)
  }
}

export function nFormatter(num, digits = 1) {
  const si = [
    {
      value: 1,
      symbol: ''
    },
    {
      value: 1e3,
      symbol: 'K'
    },
    {
      value: 1e6,
      symbol: 'M'
    },
    {
      value: 1e9,
      symbol: 'G'
    },
    {
      value: 1e12,
      symbol: 'T'
    },
    {
      value: 1e15,
      symbol: 'P'
    },
    {
      value: 1e18,
      symbol: 'E'
    }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function toCurrency(value, symbol = '$', decimals = 2) {
  if (decimals === 0) {
    return `${symbol}${Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }
  return `${symbol}${parseFloat(value)
    .toFixed(decimals)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

export function isNumeric(str) {
  if (typeof str !== 'string') return false
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str))
}

export function stringIsFloatNumber(str) {
  return !Number.isNaN(str) && str.toString().indexOf('.') !== -1
}

export function isBlankString(str) {
  return !str || /^\s*$/.test(str)
}

export function filterArrayOfObjectsByKey(
  arrayOfObjects,
  key,
  searchStr = '',
  condition = 'contains'
) {
  const searchVal = String(searchStr).toLowerCase()
  let search
  switch (condition) {
    case 'equals': {
      search = (txt) => {
        if (searchVal === '0') {
          return txt !== searchVal
        }

        return txt === searchVal
      }
      break
    }
    default: {
      search = (txt) => txt.includes(searchVal)
    }
  }

  return arrayOfObjects.filter((item) => {
    const itemKeyValue = _.get(item, key)
    return isBlankString(searchVal)
      ? true
      : itemKeyValue && search(String(itemKeyValue).toLowerCase())
  })
}

export function generatePassword(length = 8) {
  const letters = 'abcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'
  const symbols = '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/'
  let val = ''

  function genSection(chars, len) {
    for (let i = 0, n = chars.length; i < len; ++i) {
      val += chars.charAt(Math.floor(Math.random() * n))
    }
  }

  const sectionLength = parseInt(length / 4, 10)
  genSection(letters, sectionLength)
  genSection(letters.toUpperCase(), sectionLength)
  genSection(numbers, sectionLength)
  genSection(symbols, length % 4 === 0 ? sectionLength : sectionLength + 1)

  return val.shuffle()
}

export function appendObjectToFormData(obj) {
  const formData = new FormData()
  function appendObject(innerObject, parentKey) {
    Object.entries(innerObject).forEach(([key, value]) => {
      if (typeof value === 'object') {
        appendObject(value, key)
      } else if (value) {
        formData.append(parentKey ? `${parentKey}[${key}]` : key, value)
      }
    })
  }

  if (typeof obj === 'object') {
    appendObject(obj)
  }

  return formData
}

export const generateImageUrl = (path, folderPath, imagePath) =>
  `${BASE_URL}${path || ''}${folderPath || ''}${imagePath || ''}`
