import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchNotifications() {
  yield requestWithActions(
    actions.FETCH_NOTIFICATIONS_REQUEST,
    () => axiosSagaRequest('get', 'notifications'),
    actions.fetchNotificationsSuccess,
    actions.fetchNotificationsFailure
  )
}

function* fetchNotification(action) {
  yield requestWithActions(
    actions.FETCH_NOTIFICATION_REQUEST,
    () => axiosSagaRequest('get', `notifications/${action.payload}`),
    actions.fetchNotificationSuccess,
    actions.fetchNotificationFailure
  )
}

export default function* notificationsWatcherSaga() {
  yield takeLatest(actions.FETCH_NOTIFICATIONS_REQUEST, fetchNotifications)
  yield takeLatest(actions.FETCH_NOTIFICATION_REQUEST, fetchNotification)
}
