import React from 'react'
import { GridList } from 'components/common'
import BankAccountCard from './bank-account-card'

const FinancialDetailsSection = ({ bankAccounts, isLoading, moduleName, accountId, isNew }) => {
  return (
    <GridList gap="xs" padding="p-0" columns={{ md: 1, lg: 2 }}>
      {bankAccounts.map((bankAccount) => (
        <BankAccountCard
          key={bankAccount.id}
          bankAccountData={bankAccount}
          isLoading={isLoading}
          moduleName={moduleName}
          accountId={accountId}
          isNew={isNew}
        />
      ))}
    </GridList>
  )
}

export default FinancialDetailsSection
