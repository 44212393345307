import React, { memo } from 'react'
import { Typography } from 'components/common'

const Block = memo(({ title, rightSection, className, children }) => (
    <div className={`block__container ${className} mb-xs`} key={`title-${title}`}>
        <div className={`block__header`}>
            <div>
                {title && <Typography variant="h3">{title}</Typography>}
            </div>
            {
                rightSection && rightSection
            }
        </div>
        {children && <div className="block__content">{children}</div>}
    </div>
))

export default Block
Block.displayName = 'Block'
