export const FETCH_LANDED_PRICES_REQUEST = 'FETCH_LANDED_PRICES_REQUEST'
export const FETCH_LANDED_PRICES_SUCCESS = 'FETCH_LANDED_PRICES_SUCCESS'
export const FETCH_LANDED_PRICES_FAILURE = 'FETCH_LANDED_PRICES_FAILURE'

export const CREATE_LANDED_PRICE_REQUEST = 'CREATE_LANDED_PRICE_REQUEST'
export const CREATE_LANDED_PRICE_SUCCESS = 'CREATE_LANDED_PRICE_SUCCESS'
export const CREATE_LANDED_PRICE_FAILURE = 'CREATE_LANDED_PRICE_FAILURE'

export const UPDATE_LANDED_PRICE_REQUEST = 'UPDATE_LANDED_PRICE_REQUEST'
export const UPDATE_LANDED_PRICE_SUCCESS = 'UPDATE_LANDED_PRICE_SUCCESS'
export const UPDATE_LANDED_PRICE_FAILURE = 'UPDATE_LANDED_PRICE_FAILURE'

export const DELETE_LANDED_PRICE_REQUEST = 'DELETE_LANDED_PRICE_REQUEST'
export const DELETE_LANDED_PRICE_SUCCESS = 'DELETE_LANDED_PRICE_SUCCESS'
export const DELETE_LANDED_PRICE_FAILURE = 'DELETE_LANDED_PRICE_FAILURE'

export const FETCH_SINGLE_LANDED_PRICE_REQUEST = 'FETCH_SINGLE_LANDED_PRICE_REQUEST'
export const FETCH_SINGLE_LANDED_PRICE_SUCCESS = 'FETCH_SINGLE_LANDED_PRICE_SUCCESS'
export const FETCH_SINGLE_LANDED_PRICE_FAILURE = 'FETCH_SINGLE_LANDED_PRICE_FAILURE'

export const UPDATE_PREFIX_SUFFIX = 'UPDATE_PREFIX_SUFFIX'
export const FILTER_SECTION = 'FILTER_SECTION'

export const UPDATE_COSTS = 'UPDATE_COSTS'

export const FETCH_FORM_DATA_REQUEST = 'FETCH_FORM_DATA_REQUEST'
export const FETCH_FORM_DATA_SUCCESS = 'FETCH_FORM_DATA_SUCCESS'
export const FETCH_FORM_DATA_FAILURE = 'FETCH_FORM_DATA_FAILURE'

export const FETCH_EXPORT_REQUEST = 'FETCH_EXPORT_REQUEST'
export const FETCH_EXPORT_SUCCESS = 'FETCH_EXPORT_SUCCESS'
export const FETCH_EXPORT_FAILURE = 'FETCH_EXPORT_FAILURE'

export const CREATE_SELLING_PRICE_REQUEST = 'CREATE_SELLING_PRICE_REQUEST'
export const CREATE_SELLING_PRICE_SUCCESS = 'CREATE_SELLING_PRICE_SUCCESS'
export const CREATE_SELLING_PRICE_FAILURE = 'CREATE_SELLING_PRICE_FAILURE'

export const CLEAR_STATE = 'CLEAR_STATE'

// Action creator factory function
const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchLandedPricesRequest = createAction(FETCH_LANDED_PRICES_REQUEST)
export const fetchLandedPricesSuccess = createAction(FETCH_LANDED_PRICES_SUCCESS)
export const fetchLandedPricesFailure = createAction(FETCH_LANDED_PRICES_FAILURE)

export const createLandedPriceRequest = createAction(CREATE_LANDED_PRICE_REQUEST)
export const createLandedPriceSuccess = createAction(CREATE_LANDED_PRICE_SUCCESS)
export const createLandedPriceFailure = createAction(CREATE_LANDED_PRICE_FAILURE)

export const updateLandedPriceRequest = createAction(UPDATE_LANDED_PRICE_REQUEST)
export const updateLandedPriceSuccess = createAction(UPDATE_LANDED_PRICE_SUCCESS)
export const updateLandedPriceFailure = createAction(UPDATE_LANDED_PRICE_FAILURE)

export const deleteLandedPriceRequest = createAction(DELETE_LANDED_PRICE_REQUEST)
export const deleteLandedPriceSuccess = createAction(DELETE_LANDED_PRICE_SUCCESS)
export const deleteLandedPriceFailure = createAction(DELETE_LANDED_PRICE_FAILURE)

export const fetchSingleLandedPriceRequest = createAction(FETCH_SINGLE_LANDED_PRICE_REQUEST)
export const fetchSingleLandedPriceSuccess = createAction(FETCH_SINGLE_LANDED_PRICE_SUCCESS)
export const fetchSingleLandedPriceFailure = createAction(FETCH_SINGLE_LANDED_PRICE_FAILURE)

export const fetchFormDataRequest = createAction(FETCH_FORM_DATA_REQUEST)
export const fetchFormDataSuccess = createAction(FETCH_FORM_DATA_SUCCESS)
export const fetchFormDataFailure = createAction(FETCH_FORM_DATA_FAILURE)

export const fetchExportRequest = createAction(FETCH_EXPORT_REQUEST)
export const fetchExportSuccess = createAction(FETCH_EXPORT_SUCCESS)
export const fetchExportFailure = createAction(FETCH_EXPORT_FAILURE)

export const createSellingPriceRequest = createAction(CREATE_SELLING_PRICE_REQUEST)
export const createSellingPriceSuccess = createAction(CREATE_SELLING_PRICE_SUCCESS)
export const createSellingPriceFailure = createAction(CREATE_SELLING_PRICE_FAILURE)

export const clearState = createAction(CLEAR_STATE)

export const updatePrefixSuffix = (prefix, suffix) => ({
  type: UPDATE_PREFIX_SUFFIX,
  payload: { prefix, suffix }
})

export const filterSection = (section_id) => ({
  type: FILTER_SECTION,
  payload: { section_id }
})

export const updateCosts = (data) => ({
  type: UPDATE_COSTS,
  payload: { data }
})
