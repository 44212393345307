/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Dialog } from 'components/common'
import './QuantityChanged.css'
import { useTranslation } from 'react-i18next'

const QuantityChanged = ({ isOpen, close, damagedItems, removedItems }) => {

    const { t } = useTranslation()
  return (
    <Dialog open={isOpen} close={close} size="md" alignment="center" title="Information">
      <div className="dialog-body">
        {damagedItems?.length ? (
          <>
            <p className="mb-lg">
              {t('The quantities of the following items have been updated because the stock was greater. Please review the details below')}:
            </p>
            <ul>
              {damagedItems.map((item, index) => (
                <li key={index}>
                  <h4 className="mb-sm">{`${item.part_number} Part Number's quantity is now ${item.available_quantity}`}</h4>
                  <strong>{t('Part Number')}:</strong> {item.part_number}
                  <br />
                  <strong>{t('Requested Quantity')}:</strong> {item.requested_quantity}
                  <br />
                  <strong>{t('Available Quantity')}:</strong> {item.available_quantity}
                </li>
              ))}
            </ul>
          </>
        ) : null}

        {removedItems?.length ? (
          <>
            <p className="mb-lg">
              {t('The following items have been removed from your basket due to insufficient stock')}
            </p>

            <ul>
              {removedItems.map((item, index) => (
                <li key={index}>
                  <strong>{t('Part Number')}:</strong> {item.part_number}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </Dialog>
  )
}

export default QuantityChanged
