import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useForm as useReactHookForm, useFieldArray } from 'react-hook-form'
import { Field } from 'components/common'
import { joiResolver } from '@hookform/resolvers/joi'

const FormField = ({
  control,
  dirtyFields,
  errors,
  isLoading,
  register,
  // -> field specific <-
  name,
  type,
  withLabel = true,
  label,
  placeholder,
  required,
  uncontrolled,
  arrayItem,
  isMulti,
  ...otherProps
}) => {
  let cleanLabel = ''
  if (typeof label === 'string') {
    cleanLabel = label
  } else {
    cleanLabel = name.normalizeString()
  }

  // const errorMessage = errors?.[name]?.message;
  // const errorType = errors?.[name]?.type;
  // const error = useMemo(() => {
  //     const err = errorMessage || validationMessages[errorType];
  //
  //     if (typeof err === 'function') {
  //         const errorValue = validation[errorType];
  //         return err(errorValue);
  //     } else if (typeof err === 'string') {
  //         return err;
  //     } else {
  //         return null;
  //     }
  // }, [errorMessage, errorType]);

  return (
    <Field
      isMulti={isMulti}
      {...(!arrayItem && !uncontrolled && { control })}
      name={name}
      type={type}
      label={cleanLabel}
      withLabel={withLabel}
      placeholder={placeholder ?? (type === 'select' ? 'Select' : `Enter ${cleanLabel}`)}
      dirty={get(dirtyFields, name)}
      error={get(errors, name)?.message}
      disabled={isLoading}
      required={required}
      {...register(name)}
      {...otherProps}
    />
  )
}

const useForm = ({ validationSchema, defaultValues, isLoading }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    resetField,
    getValues,
    formState: { isDirty, isValid, errors, dirtyFields, touchedFields }
  } = useReactHookForm({
    mode: 'all',
    reValidateMode: 'onChange', // onBlur
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: joiResolver(validationSchema),
    defaultValues
  })
  return {
    control,
    register,
    FormField: (props) => {
      return (
        <FormField
          control={control}
          dirtyFields={dirtyFields}
          errors={errors}
          isLoading={isLoading}
          register={register}
          {...props}
        />
      )
    },
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { isDirty, isValid, errors }
  }
}

useForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  isLoading: PropTypes.bool
}

useForm.defaultProps = {
  defaultValues: {},
  isLoading: false
}

export default useForm
