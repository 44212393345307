/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  MEDIA_FOLDERS_CALL,
  MEDIA_FOLDER_ADD_CALL_PROMISE,
  MEDIA_FOLDER_EDIT_CALL_PROMISE,
  MEDIA_FOLDERS_DELETE_CALL_PROMISE,
  MEDIA_FOLDER_LOADING_SWITCH
} from './actions'

const initialState = {
  mediaFolders: {
    isFetched: false,
    isLoading: false,
    data: []
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case MEDIA_FOLDERS_CALL: {
      state.mediaFolders.isFetched = false
      state.mediaFolders.data = []
      state.mediaFolders.base_path = ''
      return state
    }

    case `${MEDIA_FOLDERS_CALL}_RESPONSE`: {
      state.mediaFolders.isFetched = true
      state.mediaFolders.data = payload.library
      state.mediaFolders.base_path = payload.base_path
      return state
    }

    case `${MEDIA_FOLDER_ADD_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { media }
      } = payload
      state.mediaFolders.data.push({
        thumbnails: [],
        ...media
      })
      return state
    }

    case `${MEDIA_FOLDER_EDIT_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { media }
      } = payload
      state.mediaFolders.data = state.mediaFolders.data.map((mF) =>
        mF.id === media.id
          ? {
              thumbnails: mF.thumbnails,
              ...media
            }
          : mF
      )
      return state
    }

    case `${MEDIA_FOLDERS_DELETE_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const { folderId } = payload
      state.mediaFolders.data = state.mediaFolders.data.filter((mF) => mF.id !== folderId)
      return state
    }

    case MEDIA_FOLDER_LOADING_SWITCH: {
      const { status } = payload
      state.mediaFolders.isLoading = status !== undefined ? status : !state.mediaFolders.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
