import React, { createContext, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const DialogContext = createContext([[], () => {}])

export const DialogProvider = ({ children }) => {
  const [visibleDialogs, setVisibleDialogs] = useState([])

  const addDialog = useCallback((dialogId) => {
    // setDialogList(prevList => [...new Set([...prevList, dialogId])]);
    setVisibleDialogs((prevList) => [...prevList, dialogId])
  }, [])

  const removeDialog = useCallback((dialogId) => {
    setVisibleDialogs((prevList) => [...prevList.filter((id) => id !== dialogId)])
  }, [])

  useEffect(() => {
    if (visibleDialogs.length > 0) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [visibleDialogs.length])

  return (
    <DialogContext.Provider
      value={{
        addDialog,
        removeDialog
      }}>
      {children}
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

DialogProvider.defaultProps = {
  children: undefined
}
