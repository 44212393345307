/* eslint-disable no-restricted-globals */
/* eslint-disable no-lonely-if */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPricingSections } from 'store/selling-prices/selectors'
import { Input } from 'components/common'
import { updatePricingSection } from 'store/selling-prices/actions'

export default function Basic({ formData }) {
  const dispatch = useDispatch()
  const { pricingSections, sectionId } = useSelector(selectPricingSections)

  const handleUpdate = (e, id) => {
    const inputValue = parseFloat(e.target.value)

    // Find the section with the given sectionId
    const sectionToUpdate = pricingSections.find((section) => section.section_id === sectionId)

    // Check if the section exists
    if (sectionToUpdate) {
      // Find the index of the selling price with the provided price_level_id
      const sellingPriceIndex = sectionToUpdate.selling_prices.findIndex(
        (price) => price.price_level_id === id
      )

      if (!isNaN(inputValue)) {
        // If inputValue is not NaN, update the existing selling price value or add a new one
        if (sellingPriceIndex !== -1) {
          // Update the existing selling price value
          sectionToUpdate.selling_prices[sellingPriceIndex].value = inputValue
        } else {
          // If the price_level_id doesn't exist, add a new selling price object
          sectionToUpdate.selling_prices.push({
            price_level_id: id,
            value: inputValue
          })
        }
      } else {
        // If inputValue is NaN, remove the selling price with the specified id
        if (sellingPriceIndex !== -1) {
          sectionToUpdate.selling_prices.splice(sellingPriceIndex, 1)
        }

        // Check if selling_prices is empty and remove the section if it is
        if (sectionToUpdate.selling_prices.length === 0) {
          const sectionIndex = pricingSections.indexOf(sectionToUpdate)
          if (sectionIndex !== -1) {
            pricingSections.splice(sectionIndex, 1)
          }
        }
      }
    } else {
      // If the section doesn't exist, create a new section with the specified sectionId
      // and add the initial selling price
      const newSection = {
        section_id: sectionId,
        selling_prices: [
          {
            price_level_id: id,
            value: inputValue
          }
        ]
      }

      pricingSections.push(newSection)
    }
    dispatch(updatePricingSection(pricingSections))
  }

  const getValueForPriceLevel = (item) => {
    // Find the section with the given sectionId
    const section = pricingSections.find((section) => section.section_id === sectionId)

    if (section) {
      // Find the selling price for the provided price_level_id
      const sellingPrice = section.selling_prices.find((price) => price.price_level_id === item.id)

      return sellingPrice ? sellingPrice.value : ''
    }

    return ''
  }

  const getPlaceholderForPriceLevel = (form) => {
    // Find the section with the given sectionId
    const section = pricingSections.find((section) => section.section_id === null)

    if (section) {
      // Find the selling price for the provided price_level_id
      const sellingPrice = section.selling_prices.find((price) => price.price_level_id === form.id)

      return sellingPrice !== undefined ? `${sellingPrice.value} %` : '%'
    }
    return '%'
  }

  return (
    <div className="flex flex-align-center">
      {formData.map((item, index) => {
        return (
          <div style={{ marginRight: 56 }} key={item.id || index}>
            <div className="flex flex-align-center flex-column" style={{ width: 100 }}>
              <Input
                type="number"
                placeholder={getPlaceholderForPriceLevel(item)}
                value={getValueForPriceLevel(item)}
                onChange={(e) => handleUpdate(e, item.id)}
              />
              <span style={{ color: '#263355', textWrap: 'nowrap' }}>{item.name}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
