/* eslint-disable func-names */
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import {
  PRICE_LEVELS_CALL,
  PRICE_LEVELS_ACCOUNT_DATA_CALL,
  priceLevelsAccountEditPromise,
  priceLevelsLoadingSwitch
} from './actions'
import { errorAdd } from '../error/actions'

const prefix = '/account/pricing'

// Loading wrapper
function* priceLevelsLoadingGeneratorWrapper(moduleName, gen) {
  yield put(priceLevelsLoadingSwitch(moduleName, true))
  yield gen()
  yield put(priceLevelsLoadingSwitch(moduleName, false))
}

function* priceLevelsCallGenerator(action) {
  const {
    type,
    payload: { moduleName, params }
  } = action

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}`, {
      params
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d } })
}

function* priceLevelsAccountDataCallGenerator(action) {
  const { type, payload } = action

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/${payload.id}`)
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName: payload.moduleName, data: d } })
}

function* pricingLevelAccountEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, id, finalData }
    } = action

    yield priceLevelsLoadingGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/${id}`, finalData)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: finalData } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* priceLevelsWatcherSaga() {
  yield takeLatest(PRICE_LEVELS_CALL, priceLevelsCallGenerator)
  yield takeLatest(PRICE_LEVELS_ACCOUNT_DATA_CALL, priceLevelsAccountDataCallGenerator)
  yield takeEvery(priceLevelsAccountEditPromise, pricingLevelAccountEditPromiseGenerator)
}
