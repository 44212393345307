/* eslint-disable require-yield */
import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchOrders(action) {
  try {
    const queryParams = {
      start_date: action.payload?.start_date,
      end_date: action.payload?.end_date,
      page: action.payload?.page,
      search: action.payload?.search,
      customer_orders: action.payload?.customer_orders
    }

    const response = yield call(() =>
      axiosSagaRequest('get', 'web-catalogue/orders', { params: queryParams })
    )

    yield put(actions.fetchOrdersSuccess(response.data))
  } catch (error) {
    yield put(actions.fetchOrdersFailure(error.message))
    yield put(errorAdd(error, actions.FETCH_ORDERS_REQUEST))
  }
}

function* fetchOrder(action) {
  yield requestWithActions(
    actions.FETCH_ORDER_REQUEST,
    () => axiosSagaRequest('get', `web-catalogue/orders/${action.payload}`),
    actions.fetchOrderSuccess,
    actions.fetchOrderFailure
  )
}

function* updateOrder(action) {
  yield requestWithActions(
    actions.UPDATE_ORDER_REQUEST,
    () =>
      axiosSagaRequest('put', `web-catalogue/orders/${action.payload.id}`, {
        is_completed: action.payload.value
      }),
    actions.updateOrderSuccess,
    actions.updateOrderFailure
  )
}

export default function* ordersWatcherSaga() {
  yield takeLatest(actions.FETCH_ORDERS_REQUEST, fetchOrders)
  yield takeLatest(actions.FETCH_ORDER_REQUEST, fetchOrder)
  yield takeLatest(actions.UPDATE_ORDER_REQUEST, updateOrder)
}
