import React from 'react'
import { Button } from '..'

const ButtonSwitcher = ({ switcherOptions, checked, setChecked, className }) => {
  return (
    <div className={`btn-switcher ${className}`}>
      {switcherOptions.map((option) => {
        const { id, label } = option
        return (
          <Button
            key={id}
            variant={checked.id === id ? 'primary' : 'tertiary'}
            onClick={() => setChecked(option)}
          >
            {label}
          </Button>
        )
      })}
    </div>
  )
}

export default ButtonSwitcher
