import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useImage } from 'hooks'
import { Loader, Typography } from 'components/common'
import { useTranslation } from 'react-i18next'

/**
 * Image component (with loading and error states)
 * @param {String} src - Image url
 * @param {String} alt - Alternative text
 */

const Image = memo(({ src, alt, style, ...props }) => {
  const { t } = useTranslation()
  const { isLoaded: isLoadedImage, isError: isErrorImage } = useImage(src)

  return isErrorImage ? (
    <Typography variant="body2">{t('Failed to load Image')}</Typography>
  ) : !isLoadedImage ? (
    <Loader linear />
  ) : (
    <img src={src} alt={alt} {...props} style={style || { width: '100%', height: '100%' }} />
  )
})

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
}

Image.defaultProps = {
  alt: ''
}

export default Image
Image.displayName = 'Image'
