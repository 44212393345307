/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
import _ from 'lodash'
import * as actionTypes from './actions'

const initialState = {
  landedPrices: null,
  singleLandedPrice: null,
  productPrices: null,
  costs: null,
  currentCosts: null,
  sectionId: null,
  exportFile: null,
  allProductPrices: null,
  formData: null,
  sections: null,
  catalogueCurrency: null,
  sectionIdToBackgroundColor: {},
  loading: false,
  error: null
}

const landedPricesReducer = (state = initialState, action) => {
  const generateRandomColor = (opacity = 0.6) => {
    const letters = '0123456789ABCDEF'
    let color = 'rgba('

    // Adjust the minimum value for each channel to bias towards lighter colors
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 3; i++) {
      const channel = Math.floor(Math.random() * 128) + 128 // Minimum value is 128
      color += `${channel},`
    }

    color += `${opacity})`
    return color
  }

  // Calculations
  const calculateProfitByMargin = (price, margin) => {
    let modifiedMargin = margin
    modifiedMargin /= 100
    const profit = price * modifiedMargin
    return profit
  }

  const calculateProfitByMarkup = (price, markup) => {
    let modifiedMarkup = markup
    modifiedMarkup /= 100
    const cost = price / (1 + modifiedMarkup)
    const profit = price - cost
    return profit
  }

  const calculateTax = (price, costs) => {
    // eslint-disable-next-line no-param-reassign
    let margin = 0
    let markup = 0

    costs.forEach((cost) => {
      if (cost.type === 'margin') {
        margin += cost.value
      } else {
        markup += cost.value
      }
    })

    const marginTax = calculateProfitByMargin(price, margin) || 0
    const markupTax = calculateProfitByMarkup(price, markup) || 0
    return price + marginTax + markupTax
  }

  const calculatePercentageDifference = (x, y) => {
    const highest = parseFloat(x)
    const lowest = parseFloat(y)

    return ((highest - lowest) / Math.abs(lowest)) * 100
  }

  switch (action.type) {
    case actionTypes.FETCH_LANDED_PRICES_REQUEST:
    case actionTypes.CREATE_LANDED_PRICE_REQUEST:
    case actionTypes.UPDATE_LANDED_PRICE_REQUEST:
    case actionTypes.DELETE_LANDED_PRICE_REQUEST:
    case actionTypes.FETCH_SINGLE_LANDED_PRICE_REQUEST:
    case actionTypes.FETCH_FORM_DATA_REQUEST:
    case actionTypes.FETCH_EXPORT_REQUEST:
    case actionTypes.CREATE_SELLING_PRICE_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_LANDED_PRICES_SUCCESS:
      return {
        ...state,
        landedPrices: action.payload.landed_prices,
        loading: false,
        error: null
      }

    case actionTypes.CREATE_SELLING_PRICE_SUCCESS:
      const landedPricesUpdated = [...state.landedPrices]

      // eslint-disable-next-line array-callback-return
      landedPricesUpdated.map((price) => {
        if (price.id === action.payload.landed_price.id) {
          price.status = true
        }
      })

      return { ...state, landedPrices: landedPricesUpdated }

    case actionTypes.CREATE_LANDED_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_LANDED_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }

    case actionTypes.DELETE_LANDED_PRICE_SUCCESS:
      return {
        ...state,
        landedPrices: state.landedPrices.filter((price) => price.id !== action.payload),
        loading: false,
        error: null
      }

    case actionTypes.FETCH_SINGLE_LANDED_PRICE_SUCCESS:
      const sections = action.payload.sections.map((item) => ({
        id: item.id,
        names: JSON.parse(item.name)
      }))

      sections.forEach((section) => {
        state.sectionIdToBackgroundColor[section.id] =
          state.sectionIdToBackgroundColor[section.id] || generateRandomColor()
      })

      const exchangeRateOriginal = parseFloat(
        action.payload.landed_price.currency.currency_rate.rate
      )
      const exchangeRateCompany = parseFloat(action.payload.catalogue_currency.rate)
      const currentCostsData = _.cloneDeep(action.payload.landed_price.costs)
      const updatedProductPrices = action.payload.landed_price.product_prices.map((price) => {
        let costsBySection = currentCostsData.filter(
          (cost) => cost.section_id === price.product_section_id
        )
        if (costsBySection.length === 0) {
          costsBySection = currentCostsData.filter((cost) => !cost.section_id)
        }
        return {
          ...price,
          convertedPrice: price.price * (exchangeRateOriginal / exchangeRateCompany),
          landed_price: costsBySection.length
            ? parseFloat(
                calculateTax(
                  price.price * (exchangeRateOriginal / exchangeRateCompany),
                  costsBySection
                ).toFixed(2)
              )
            : parseFloat(price.price * (exchangeRateOriginal / exchangeRateCompany)),
          added_costs: costsBySection.length
            ? parseFloat(
                calculatePercentageDifference(
                  calculateTax(
                    price.price * (exchangeRateOriginal / exchangeRateCompany),
                    costsBySection
                  ),
                  price.price * (exchangeRateOriginal / exchangeRateCompany)
                ).toFixed(2)
              )
            : 0
        }
      })

      const priceCosts = _.cloneDeep(action.payload.landed_price.costs)
      if (priceCosts.length) {
        sections.forEach((section) => {
          const sectionCostExists = priceCosts.find((cost) => cost.section_id === section.id)
          if (sectionCostExists) {
            updatedProductPrices.forEach((product) => {
              if (product.product_section_id === section.id) {
                product.background_color =
                  state.sectionIdToBackgroundColor[product.product_section_id]
              }
            })
            section.background_color = state.sectionIdToBackgroundColor[section.id]
          }
        })
      }
      return {
        ...state,
        singleLandedPrice: action.payload.landed_price,
        allProductPrices: updatedProductPrices,
        productPrices: updatedProductPrices,
        costs: action.payload.landed_price.costs,
        currentCosts: action.payload.landed_price.costs.filter(
          (item) => item.section_id === state.sectionId
        ),
        sections,
        catalogueCurrency: action.payload.catalogue_currency,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_PREFIX_SUFFIX:
      // eslint-disable-next-line no-case-declarations
      const { prefix, suffix } = action.payload
      // eslint-disable-next-line no-case-declarations
      const updatedPrefixSuffixProductPrices = state.allProductPrices.map((price) => {
        return {
          ...price,
          prefix,
          suffix
        }
      })

      return {
        ...state,
        singleLandedPrice: {
          ...state.singleLandedPrice,
          prefix,
          suffix
        },
        productPrices: updatedPrefixSuffixProductPrices
      }

    case actionTypes.FILTER_SECTION:
      const { section_id } = action.payload

      const productPrices = section_id
        ? state.allProductPrices.filter((item) => item.product_section_id === section_id)
        : state.allProductPrices

      let filteredCosts
      if (section_id) {
        filteredCosts = state.costs.filter((item) => item.section_id === section_id)
      } else {
        filteredCosts = state.costs.filter((item) => !item.section_id)
      }

      return {
        ...state,
        sectionId: section_id,
        productPrices,
        currentCosts: filteredCosts
      }

    case actionTypes.FETCH_EXPORT_SUCCESS:
      return {
        ...state,
        exportFile: action.payload
      }

    case actionTypes.FETCH_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.payload,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_COSTS:
      let updatedAllProductPrices
      let updatedProductPricesData
      let updatedSections

      const { data } = action.payload

      let newCosts = state.costs.filter(
        (item) => item.section_id !== state.sectionId || (!state.sectionId && item.section_id)
      )

      const currentCosts = data.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.section_id = state.sectionId
        return item
      })
      if (currentCosts) {
        newCosts = [...newCosts, ...currentCosts]
      }
      // All Sections
      if (state.sectionId === null) {
        updatedAllProductPrices = state.allProductPrices.map((price) => {
          let costsBySection = newCosts.filter((cost) => !cost.section_id)
          if (!newCosts.find((item) => item.section_id === price.product_section_id)) {
            return {
              ...price,
              landed_price: calculateTax(price.convertedPrice, costsBySection).toFixed(2),
              added_costs: parseFloat(
                calculatePercentageDifference(
                  calculateTax(price.convertedPrice, costsBySection),
                  price.convertedPrice
                ).toFixed(2)
              )
            }
          }
          return {
            ...price
          }
        })

        updatedSections = state.sections
        updatedProductPricesData = updatedAllProductPrices
      }
      // Is Specific Section
      else {
        updatedAllProductPrices = state.allProductPrices.map((price) => {
          let landed_price = price.landed_price // Keep the existing value if sectionId doesn't match
          let added_costs = price.added_costs // Keep the existing value if sectionId doesn't match

          if (state.sectionId === price.product_section_id) {
            let costsBySection = currentCosts
            if (!price.background_color) {
              // eslint-disable-next-line no-param-reassign
              price.background_color = state.sectionIdToBackgroundColor[price.product_section_id]
            }
            if (currentCosts.length === 0) {
              costsBySection = newCosts.filter((cost) => !cost.section_id)
              price.background_color = ''
              delete state.sectionIdToBackgroundColor[price.product_section_id]
            }

            const exchangeRateCompany = parseFloat(state.catalogueCurrency.rate)
            const exchangeRateOriginal = parseFloat(
              state.singleLandedPrice.currency.currency_rate.rate
            )
            landed_price = calculateTax(price.convertedPrice, costsBySection).toFixed(2)
            added_costs = parseFloat(
              calculatePercentageDifference(
                calculateTax(price.convertedPrice, costsBySection),
                price.convertedPrice
              ).toFixed(2)
            )
          }

          return {
            ...price,
            landed_price,
            added_costs
          }
        })

        updatedSections = state.sections.map((section) => {
          if (state.sectionId === section.id) {
            return {
              ...section,
              background_color: state.sectionIdToBackgroundColor[section.id]
            }
          }
          return section
        })

        updatedProductPricesData = updatedAllProductPrices.filter(
          (item) => item.product_section_id === state.sectionId
        )
      }

      const landedPrices = [...state.landedPrices]

      // eslint-disable-next-line array-callback-return
      landedPrices.map((price) => {
        if (price.id === action.payload.data[0]?.landed_price_id) {
          price.refined = true
        }
      })

      return {
        ...state,
        landedPrices,
        sections: updatedSections,
        productPrices: updatedProductPricesData,
        allProductPrices: updatedAllProductPrices,
        costs: newCosts,
        currentCosts
      }

    case actionTypes.FETCH_LANDED_PRICES_FAILURE:
    case actionTypes.CREATE_LANDED_PRICE_FAILURE:
    case actionTypes.UPDATE_LANDED_PRICE_FAILURE:
    case actionTypes.DELETE_LANDED_PRICE_FAILURE:
    case actionTypes.FETCH_SINGLE_LANDED_PRICE_FAILURE:
    case actionTypes.FETCH_FORM_DATA_FAILURE:
    case actionTypes.FETCH_EXPORT_FAILURE:
    case actionTypes.CREATE_SELLING_PRICE_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default landedPricesReducer
