/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import {
  PRICING_LEVELS_CALL,
  PricingLevelAddPromise,
  PricingLevelEditPromise,
  PricingLevelDeletePromise,
  PricingLevelSortPromise,
  pricingLevelLoadingSwitch
} from './actions'
import { errorAdd } from '../../error/actions'

const prefix = '/price_levels'

// Loading wrapper
function* pricingLevelGeneratorWrapper(gen) {
  yield put(pricingLevelLoadingSwitch(true))
  yield gen()
  yield put(pricingLevelLoadingSwitch(false))
}

function* PricingLevelAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const { type, payload } = action

    yield pricingLevelGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, payload)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
       } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}
function* PricingLevelDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const { type, payload } = action

    yield pricingLevelGeneratorWrapper(function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/${payload}`)
        yield put({ type: `${type}_RESPONSE`, payload: { payload } })
        yield call(resolvePromiseAction, action, payload)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}
function* PricingLevelSortPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const { type } = action
    yield pricingLevelGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/sort`, action.payload)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}
function* PricingLevelEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { id, name }
    } = action

    yield pricingLevelGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/${id}`, { name })
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* pricingLevelsWatcherSaga() {
  yield takeLatest(PRICING_LEVELS_CALL, axiosGetGenerator(prefix))
  yield takeEvery(PricingLevelAddPromise, PricingLevelAddPromiseGenerator)
  yield takeEvery(PricingLevelEditPromise, PricingLevelEditPromiseGenerator)
  yield takeEvery(PricingLevelDeletePromise, PricingLevelDeletePromiseGenerator)
  yield takeEvery(PricingLevelSortPromise, PricingLevelSortPromiseGenerator)
}
