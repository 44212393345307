import ReactPaginate from 'react-paginate'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'


const Pagination = ({ className, pagesCount, setActivePage }) => {
    const { t } = useTranslation();
  
    return (
      <ReactPaginate
        breakLabel="..."
        nextLabel={t('Next')}
        previousLabel={t('Previous')}
        onPageChange={(args) => setActivePage(args.selected)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        pageCount={pagesCount}
        renderOnZeroPageCount={null}
        pageClassName="pagination__item"
        pageLinkClassName="pagination__item__link"
        previousClassName="pagination__item pagination__item--previous"
        previousLinkClassName="pagination__item__link"
        nextClassName="pagination__item pagination__item--next"
        nextLinkClassName="pagination__item__link"
        breakClassName="pagination__item pagination__item--break"
        breakLinkClassName="pagination__item__link"
        activeClassName="pagination__item--active"
        containerClassName={classNames('pagination', className)}
      />
    )
  }
  
  export default Pagination
