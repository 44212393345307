/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Dropdown list section component
 * @param {Array.<DropdownSection, DropdownItem, DropdownDivider>} children - List of allowed items
 */

const DropdownSection = memo(({ children }) => (
  <li className="dropdown__section">
    <ul>{children}</ul>
  </li>
))

DropdownSection.propTypes = {
  children: PropTypes.node.isRequired
}

export default DropdownSection
