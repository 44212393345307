import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Icon component
 * @param {String} iconClass - Icon class name
 * @param {String} type - Icon type
 * @param {String} size - Icon size
 * @param {Boolean} reverse - Render icon reversed
 * @param {String} className - Custom classname for icon
 * @param {Object} style - Custom styles for icon
 */

const Icon = memo(({ iconClass, type, size, reverse, className, style, ...otherProps }) => (
  <span
    className={classNames(
      'icon',
      `icon--${type}`,
      `icon-${iconClass}`,
      { 'icon--reverse': reverse },
      { [className]: className }
    )}
    style={{ fontSize: size, width: size, height: size, ...style }}
    {...otherProps}
  />
))

Icon.propTypes = {
  iconClass: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  reverse: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({})
}

Icon.defaultProps = {
  iconClass: '',
  type: 'dark',
  size: '16px',
  reverse: false,
  className: undefined,
  style: null
}

Icon.displayName = 'Icon'

export default Icon
