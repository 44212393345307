import React from 'react'
import { TimeAgo, LetteredAvatar } from 'components/common'

const NotificationItem = ({ avatar, name, title, subject, isRead, time }) => {
  return (
    <>
      {avatar ? (
        <img src={avatar} className="notification-item__avatar" />
      ) : (
        <LetteredAvatar name={name} width={50} height={50} margin="auto" />
      )}
      <div
        className="notification-item__description flex flex-column ml-xs"
        style={{ width: '86%' }}
      >
        <span className="notification-item__title">
          <span className="fw-medium">{name} </span>
          <span style={{ color: '#808080' }}>{title} </span>
          <span style={{ color: '#2c9e84' }} className="fw-medium">
            {subject}
          </span>
        </span>
        <span className="notification-item__time" style={{ color: isRead ? '#808080' : '#2569A1' }}>
          <TimeAgo time={time} />
        </span>
      </div>
    </>
  )
}

export default NotificationItem
