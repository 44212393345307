import React from 'react'
import SearchByRegNumberImage from 'assets/images/reg_number_search.png'
import { useTranslation } from 'react-i18next'
import Icon from '../icon'

const Input = ({
  name,
  className,
  handleShowHideClick,
  icon,
  onChange,
  innerRef,
  handleBlur,
  style,
  handleKeyDown,
  error,
  label,
  ...otherProps
}) => {
  const { t } = useTranslation()

  return (
    <div className={`input ${className ?? ''}`}>
      <div className="input__flag">
        <img src={SearchByRegNumberImage} alt={t('Search By Reg Number')} />
      </div>
      {label && <label htmlFor={name}>{t(label)}</label>}
      <div className="input__input">
        {icon && <Icon iconClass={icon} type="lighter" size="16px" onClick={handleShowHideClick} />}
        <input
          name={name}
          ref={innerRef}
          className={error && 'input__error'}
          onChange={onChange}
          style={style}
          onBlur={handleBlur}
          {...(handleKeyDown && { onKeyDown: handleKeyDown })}
          {...otherProps}
          autoComplete={className === 'input--reg' ? 'off' : Math.random()}
        />
      </div>
      {error && <span className="input__error">{error}</span>}
    </div>
  )
}

export default Input
