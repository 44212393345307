/* eslint-disable no-param-reassign */
import produce from 'immer'
import localStorage from 'store'
import localStorageService from 'utils/storage/localStorageService'

import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILED,
  USER_REFRESH_TOKEN,
  USER_LOADING_SWITCH,
  USER_SWITCH_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
  RESET_SWITCH_USER,
  CHANGE_TOKEN
} from './actions'

const initialState = {
  isLoading: false,
  error: '',
  tokenIsFetched: false,
  tokenExpiresIn: null,
  token: localStorageService.getAccessToken() || null,
  user: localStorageService.getUser('user') || null
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN: {
      state.error = ''
      return state
    }

    case USER_LOGIN_SUCCESS: {
      if (payload) {
        const { token, user, expires_in } = payload
        state.tokenIsFetched = true
        state.tokenExpiresIn = expires_in
        state.token = token
        state.user = user

        localStorageService.setToken({
          _token: token,
          user
        })

        localStorageService.setOriginalToken({
          _token: token,
          user
        })
      }
      return state
    }

    case USER_LOGIN_FAILED: {
      state.error = payload?.error || 'Unknown error.'
      return state
    }

    case USER_REGISTRATION: {
      state.error = ''
      return state
    }

    case USER_REGISTRATION_SUCCESS: {
      if (payload) {
        const { token } = payload
        state.token = token

        localStorage.set('token', token)
        return state
      }
      return state
    }

    case USER_REGISTRATION_FAILED: {
      if (payload?.errors) {
        state.error = payload.errors
        return state
      }
      return state
    }

    case `${USER_REFRESH_TOKEN}_SUCCESS`: {
      if (payload) {
        const { token, user, expires_in } = payload
        state.tokenIsFetched = true
        state.tokenExpiresIn = expires_in
        state.token = token
        state.user = user

        localStorageService.setToken({
          _token: token,
          user
        })

        localStorageService.setOriginalToken({
          _token: token,
          user
        })
      }
      return state
    }

    case USER_SWITCH_SUCCESS: {
      if (payload) {
        const { token, user } = payload
        state.token = token
        state.user = user
        // state.previousToken = state.token
        // state.previousUser = state.user

        // localStorageService.setPreviousToken()
        localStorageService.setToken({
          _token: token,
          user
        })

        return state
      }
      return state
    }

    case RESET_SWITCH_USER: {
      state.previousToken = null
      state.previousUser = null
      state.token = state.previousToken
      state.user = state.previousUser

      localStorageService.resetPreviousToken()
      return state
    }

    case CHANGE_TOKEN: {
      if (payload) {
        state.token = payload
        return state
      }
      return state
    }

    case USER_LOGOUT_SUCCESS: {
      state.token = null
      state.user = null

      localStorageService.clearToken()
      return state
    }

    case USER_LOGOUT_FAILED: {
      // TODO -> handle error case on logout
      state.token = null
      state.user = null

      localStorageService.clearToken()
      return state
    }

    case USER_LOADING_SWITCH: {
      state.isLoading = payload !== undefined ? payload : !state.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
