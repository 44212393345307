/* eslint-disable no-param-reassign */
import produce from 'immer'

import {
  ROLES_PERMISSIONS_CALL,
  ROLE_ADD_CALL_PROMISE,
  ROLE_EDIT_CALL_PROMISE,
  ROLES_DELETE_CALL_PROMISE,
  ROLE_LOADING_SWITCH
} from './actions'

const initialState = {
  company: {
    isFetched: false,
    isLoading: false,
    data: {
      module: [],
      permissions: [],
      roles: []
    }
  },
  branch: {
    isFetched: false,
    isLoading: false,
    data: {
      module: [],
      permissions: [],
      roles: []
    }
  },
  customer: {
    isFetched: false,
    isLoading: false,
    data: {
      module: [],
      permissions: [],
      roles: []
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case ROLES_PERMISSIONS_CALL: {
      const { moduleName } = payload
      state[moduleName].isFetched = false
      state[moduleName].data.module = []
      state[moduleName].data.permissions = []
      state[moduleName].data.roles = []
      return state
    }

    case `${ROLES_PERMISSIONS_CALL}_RESPONSE`: {
      const { moduleName, data } = payload
      state[moduleName].isFetched = true
      state[moduleName].data.module = data?.data?.module
      state[moduleName].data.permissions = data?.data?.permissions
      state[moduleName].data.roles = data?.data?.roles
      return state
    }

    case `${ROLE_ADD_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        moduleName,
        data: { role }
      } = payload

      const {
        // created_at,
        // updated_at,
        // guard_name,
        // id,
        // name,
        // type,
        permissions,
        users,
        ...rest
      } = role

      state[moduleName].data.roles.push({
        default: 0,
        permissions_count: role?.permissions?.length || 0,
        users_count: role?.users?.length || 0,
        ...rest
      })
      return state
    }

    case `${ROLE_EDIT_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        moduleName,
        data: { role }
      } = payload

      const { permissions, users, ...rest } = role

      state[moduleName].data.roles = state[moduleName].data.roles.map((r) =>
        r.id === role.id
          ? {
              permissions_count: role?.permissions?.length || 0,
              users_count: role?.users?.length || 0,
              ...rest
            }
          : r
      )

      return state
    }

    case `${ROLES_DELETE_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const { moduleName, roleId } = payload
      state[moduleName].data.roles = state[moduleName].data.roles.filter((r) => r.id !== roleId)
      return state
    }

    case ROLE_LOADING_SWITCH: {
      const { moduleName, status } = payload
      state[moduleName].isLoading = status !== undefined ? status : !state[moduleName].isLoading
      return state
    }

    default: {
      return state
    }
  }
})
