import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Icon } from 'components/common'

const Breadcrumb = ({ items, className }) => {
  if (items && items.length > 2) {
    return (
      <div className={`breadcrumbs ${className}`}>
        <ul className="breadcrumbs__list">
          {items.map((item) => (
            <li key={item.title} className="breadcrumbs__item">
              <NavLink to={item.link} className="breadcrumbs__link">
                {item.title}
              </NavLink>
              {item !== items[items.length - 1] && (
                <Icon iconClass="arrow" type="dark" size="8px" className="ml-xs" />
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string
}

Breadcrumb.defaultProps = {
  items: [],
  className: ''
}

export default memo(Breadcrumb)
