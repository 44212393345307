/* eslint-disable react/display-name */
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { usePrevious } from 'hooks'
import { Loader, ErrorWrapper } from 'components/common'

const withReduxDataFetch =
  (fetchArray, showError, customErrorScreen, errorScreenContainerClass) =>
  (Component) =>
  (componentProps) => {
    const dispatch = useDispatch()

    const prevFetchArrayKeys = usePrevious(fetchArray.map((item) => item.key))

    const fetchStatuses = fetchArray.map((i) => {
      const { isFetched } = useSelector(i.selector, isEqual)
      return isFetched
    })
    const isAllFetched = useMemo(() => fetchStatuses.every((i) => i === true), [fetchStatuses])
    const fetchActions = useMemo(() => fetchArray.map((i) => i.call()), [fetchArray])
    const listenToErrors = useMemo(() => fetchActions.map((i) => i.type), [fetchActions])
    // Dispatch call actions on mount
    useEffect(() => {
      const fetchArrayKeys = fetchArray.map((item) => item.key)
      if (!isEqual(fetchArrayKeys, prevFetchArrayKeys)) {
        fetchStatuses.forEach((status, index) => {
          if (fetchArray[index].forceLoad || !status) {
            dispatch(fetchActions[index])
          }
        })
      }
    }, [fetchArray])

    return !isAllFetched ? (
      <Loader />
    ) : (
      <ErrorWrapper
        listenToErrors={listenToErrors}
        {...(customErrorScreen && { customErrorScreen })}
        {...(errorScreenContainerClass && { errorScreenContainerClass })}
        {...(showError !== undefined && { showError })}
      >
        <Component {...componentProps} />
      </ErrorWrapper>
    )
  }

export default withReduxDataFetch
