/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import _ from 'lodash'
import * as actionTypes from './actions'

const initialState = {
  sellingPrices: null,
  sellingPrice: null,

  productPrices: null,
  allProductPrices: null, // For storing only all product prices

  advancedProductPrices: null,
  advancedAllProductPrices: null,

  priceLevels: null,
  formData: null,

  pricingSections: [
    {
      section_id: null,
      selling_prices: []
    }
  ],

  bespokePrices: [
    {
      price_level_id: null,
      products: []
    }
  ],

  mainTab: null,
  sectionId: null,

  costs: null,
  catalogueCurrency: null,

  sectionIdToBackgroundColor: {},

  sections: null,

  shouldSave: false,

  loading: false,
  error: null
}

const sellingPricesReducer = (state = initialState, action) => {
  const generateRandomColor = (opacity = 0.6) => {
    const letters = '0123456789ABCDEF'
    let color = 'rgba('

    // Adjust the minimum value for each channel to bias towards lighter colors
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 3; i++) {
      const channel = Math.floor(Math.random() * 128) + 128 // Minimum value is 128
      color += `${channel},`
    }

    color += `${opacity})`
    return color
  }

  const calculateProfitByMargin = (price, margin) => {
    let modifiedMargin = margin
    modifiedMargin /= 100
    const profit = price * modifiedMargin
    return profit
  }

  const calculateProfitByMarkup = (price, markup) => {
    let modifiedMarkup = markup
    modifiedMarkup /= 100
    const cost = price / (1 + modifiedMarkup)
    const profit = price - cost
    return profit
  }

  const calculateTax = (price, costs) => {
    let margin = 0
    let markup = 0

    costs.forEach((cost) => {
      if (cost.type === 'margin') {
        margin += cost.value
      } else {
        markup += cost.value
      }
    })

    const marginTax = calculateProfitByMargin(price, margin) || 0
    const markupTax = calculateProfitByMarkup(price, markup) || 0

    return price + marginTax + markupTax
  }

  const calculatePercentageDifference = (x, y) => {
    const highest = parseFloat(x)
    const lowest = parseFloat(y)

    return ((highest - lowest) / Math.abs(lowest)) * 100
  }

  switch (action.type) {
    case actionTypes.FETCH_SELLING_PRICES_REQUEST:
    case actionTypes.FETCH_SELLING_PRICE_REQUEST:
    case actionTypes.DELETE_SELLING_PRICE_REQUEST:
    case actionTypes.FETCH_SELLING_FORM_DATA_REQUEST:
    case actionTypes.CREATE_SELLING_PRICE_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_SELLING_PRICES_SUCCESS:
      return {
        ...state,
        sellingPrices: action.payload.selling_prices,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_SELLING_PRICE_SUCCESS:
      const sections = action.payload.sections.map((item) => ({
        id: item.id,
        names: JSON.parse(item.name)
      }))

      sections.forEach((section) => {
        state.sectionIdToBackgroundColor[section.id] =
          state.sectionIdToBackgroundColor[section.id] || generateRandomColor()
      })

      const exchangeRateOriginal = parseFloat(
        action.payload.landed_price.currency.currency_rate.rate
      )
      const exchangeRateCompany = parseFloat(action.payload.catalogue_currency.rate)

      const originalPrefix = action.payload.landed_price.prefix
      const originalSuffix = action.payload.landed_price.suffix
      const currentCostsData = _.cloneDeep(action.payload.landed_price.costs)
      const updatedProductPrices = action.payload.landed_price.product_prices.map((price) => {
        let costsBySection = currentCostsData.filter(
          (cost) => cost.section_id === price.product_section_id
        )
        if (costsBySection.length === 0) {
          costsBySection = currentCostsData.filter((cost) => !cost.section_id)
        }
        return {
          ...price,
          convertedPrice: price.price * (exchangeRateOriginal / exchangeRateCompany),
          landed_price: costsBySection.length
            ? parseFloat(
                calculateTax(
                  price.price * (exchangeRateOriginal / exchangeRateCompany),
                  costsBySection
                ).toFixed(2)
              )
            : parseFloat(price.price * (exchangeRateOriginal / exchangeRateCompany)),
          added_costs: costsBySection.length
            ? parseFloat(
                calculatePercentageDifference(
                  calculateTax(
                    price.price * (exchangeRateOriginal / exchangeRateCompany),
                    costsBySection
                  ),
                  price.price * (exchangeRateOriginal / exchangeRateCompany)
                ).toFixed(2)
              )
            : 0
        }
      })

      const landed_price = action.payload.landed_price

      const convertedSellingPrices = {}

      landed_price.selling_prices.forEach((element) => {
        const { section_id, price_level_id, value } = element

        if (!convertedSellingPrices[section_id]) {
          convertedSellingPrices[section_id] = {
            section_id,
            selling_prices: [
              {
                price_level_id,
                value
              }
            ]
          }
        } else {
          convertedSellingPrices[section_id].selling_prices.push({
            price_level_id,
            value
          })
        }
      })

      // Convert the object into an array, if needed
      const resultArraySellingPrices = Object.values(convertedSellingPrices)

      const advancedPrices = updatedProductPrices

      const convertedBespokePrices = []

      advancedPrices.forEach((price) => {
        price.bespoke_prices.forEach((bespoke) => {
          const { price_level_id, price, product_price_id } = bespoke

          if (!convertedBespokePrices[price_level_id]) {
            convertedBespokePrices[price_level_id] = {
              price_level_id,
              products: [
                {
                  product_price_id,
                  price
                }
              ]
            }
          } else {
            convertedBespokePrices[price_level_id].products.push({
              product_price_id,
              price
            })
          }
        })
      })

      const resultArrayBespokePrices = Object.values(convertedBespokePrices)

      const sellingPrices = action.payload.landed_price.selling_prices

      if (sellingPrices.length) {
        sections.forEach((section) => {
          const sectionCostExists = sellingPrices.find((cost) => cost.section_id === section.id)
          if (sectionCostExists) {
            updatedProductPrices.forEach((product) => {
              if (product.product_section_id === section.id) {
                product.background_color =
                  state.sectionIdToBackgroundColor[product.product_section_id]
              }
            })
            section.background_color = state.sectionIdToBackgroundColor[section.id]
            section.background_color_advanced = state.sectionIdToBackgroundColor[section.id]
          }
        })
      }

      return {
        ...state,
        sellingPrice: action.payload.landed_price,
        productPrices: updatedProductPrices,
        allProductPrices: updatedProductPrices,
        advancedProductPrices: _.cloneDeep(updatedProductPrices),
        advancedAllProductPrices: _.cloneDeep(updatedProductPrices),
        catalogueCurrency: action.payload.catalogue_currency,
        sections,
        pricingSections: resultArraySellingPrices,
        bespokePrices: resultArrayBespokePrices,
        costs: action.payload.landed_price.costs,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_ADVANCED_PRICE_LEVEL:
      const { data, fileData } = action.payload
      const priceLevelId = data.id
      const updatedBespokePricesArray = [...state.bespokePrices]

      const bespokePrice = updatedBespokePricesArray.find(
        (bespoke) => bespoke.price_level_id === priceLevelId
      )

      if (bespokePrice) {
        for (const item of fileData) {
          if (!item.part_number && !item.price) {
            continue;
          }
          const partNumber = item.part_number.toString()
          const product = state.advancedAllProductPrices.find(
            (item) => item.part_number === partNumber || item.modified_part_number === partNumber
          )
          const productPriceId = product?.id
          const price = item.price

          const existingProduct = bespokePrice?.products.find(
            (p) => p.product_price_id === productPriceId
          )
          if (existingProduct) {
            existingProduct.price = price
          } else {
            // eslint-disable-next-line no-lonely-if
            if (productPriceId)
              bespokePrice.products.push({
                product_price_id: productPriceId,
                price
              })
          }
        }
      } else {
        const newProductsArray = []

        for (const item of fileData) {
          if (!item.part_number && !item.price) {
            continue;
          }
          const partNumber = item.part_number.toString()
          const product = state.advancedAllProductPrices.find(
            (item) => item.part_number === partNumber || item.modified_part_number === partNumber
          )
          const productPriceId = product?.id
          const price = item.price

          const existingProduct = bespokePrice?.products.find(
            (p) => p.product_price_id === productPriceId
          )
          if (!existingProduct) {
            if (productPriceId)
              newProductsArray.push({
                product_price_id: productPriceId,
                price
              })
          }
        }
        updatedBespokePricesArray.push({
          price_level_id: priceLevelId,
          products: newProductsArray
        })
      }

      let updatedAllProductPricesAdvanced = state.allProductPrices
      let updatedProductPricesDataAdvanced = state.productPrices
      let updatedSectionsAdvanced = state.sections

      if (state.sectionId !== null) {
        updatedAllProductPricesAdvanced = state.advancedAllProductPrices.map((price) => {
          if (state.sectionId === price.product_section_id) {
            if (!price.background_color) {
              // eslint-disable-next-line no-param-reassign
              price.background_color = state.sectionIdToBackgroundColor[price.product_section_id]
            }
          }

          return {
            ...price
          }
        })

        updatedSectionsAdvanced = state.sections.map((section) => {
          if (state.sectionId === section.id) {
            if (!section.background_color_advanced) {
              // eslint-disable-next-line no-param-reassign
              section.background_color_advanced = state.sectionIdToBackgroundColor[section.id]
            }
          }

          return {
            ...section
          }
        })

        updatedProductPricesDataAdvanced = updatedAllProductPricesAdvanced.filter(
          (item) => item.product_section_id === state.sectionId
        )
      }

      return {
        ...state,
        bespokePrices: updatedBespokePricesArray,
        advancedProductPrices: updatedProductPricesDataAdvanced,
        sections: updatedSectionsAdvanced,
        advancedAllProductPrices: updatedAllProductPricesAdvanced,
        shouldSave: true
      }

    case actionTypes.UPDATE_PRICING_SECTIONS:
      let updatedAllProductPrices = state.allProductPrices
      let updatedProductPricesData = state.productPrices
      let updatedSections = state.sections

      if (state.sectionId !== null) {
        updatedAllProductPrices = state.allProductPrices.map((price) => {
          if (state.sectionId === price.product_section_id) {
            if (!price.background_color) {
              // eslint-disable-next-line no-param-reassign
              price.background_color = state.sectionIdToBackgroundColor[price.product_section_id]
            }
          }

          return {
            ...price
          }
        })

        updatedSections = state.sections.map((section) => {
          if (state.sectionId === section.id) {
            if (!section.background_color) {
              // eslint-disable-next-line no-param-reassign
              section.background_color = state.sectionIdToBackgroundColor[section.id]
            }
          }

          return {
            ...section
          }
        })

        updatedProductPricesData = updatedAllProductPrices.filter(
          (item) => item.product_section_id === state.sectionId
        )
      }

      return {
        ...state,
        pricingSections: action.payload,
        productPrices: updatedProductPricesData,
        sections: updatedSections,
        allProductPrices: updatedAllProductPrices
      }

    case actionTypes.DELETE_BESPOKE_PRICE:
      const itemDeleteId = action.item.id

      const updatedBespokePrices = state.bespokePrices.filter(
        (price) => price.price_level_id !== itemDeleteId
      )

      return {
        ...state,
        bespokePrices: updatedBespokePrices,
        shouldSave: true
      }

    case actionTypes.CLEAR_SHOULD_SAVE:
      return {
        ...state,
        shouldSave: false
      }

    case actionTypes.UPDATE_SECTION:
      // eslint-disable-next-line no-case-declarations
      const { section_id } = action.payload

      // eslint-disable-next-line no-case-declarations
      const productPrices = section_id
        ? state.allProductPrices.filter((item) => item.product_section_id === section_id)
        : state.allProductPrices

      const advancedProductPrices = section_id
        ? state.advancedAllProductPrices.filter((item) => item.product_section_id === section_id)
        : state.advancedAllProductPrices

      return {
        ...state,
        sectionId: section_id,
        productPrices,
        advancedProductPrices
      }

    case actionTypes.FETCH_SELLING_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.payload.price_levels,
        sectionId: null,
        loading: false,
        error: null
      }

    case actionTypes.DELETE_SELLING_PRICE_SUCCESS:
      return {
        ...state,
        sellingPrices: state.sellingPrices.filter((price) => price.id !== action.payload),
        loading: false,
        error: null
      }

    case actionTypes.CREATE_SELLING_PRICE_SUCCESS:
      return { ...state }

    case actionTypes.FETCH_SELLING_PRICES_FAILURE:
    case actionTypes.FETCH_SELLING_PRICE_FAILURE:
    case actionTypes.DELETE_SELLING_PRICE_FAILURE:
    case actionTypes.FETCH_SELLING_FORM_DATA_FAILURE:
    case actionTypes.CREATE_SELLING_PRICE_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default sellingPricesReducer
