export const FETCH_SELLING_PRICES_REQUEST = 'FETCH_SELLING_PRICES_REQUEST'
export const FETCH_SELLING_PRICES_SUCCESS = 'FETCH_SELLING_PRICES_SUCCESS'
export const FETCH_SELLING_PRICES_FAILURE = 'FETCH_SELLING_PRICES_FAILURE'

export const FETCH_SELLING_PRICE_REQUEST = 'FETCH_SELLING_PRICE_REQUEST'
export const FETCH_SELLING_PRICE_SUCCESS = 'FETCH_SELLING_PRICE_SUCCESS'
export const FETCH_SELLING_PRICE_FAILURE = 'FETCH_SELLING_PRICE_FAILURE'

export const DELETE_SELLING_PRICE_REQUEST = 'DELETE_SELLING_PRICE_REQUEST'
export const DELETE_SELLING_PRICE_SUCCESS = 'DELETE_SELLING_PRICE_SUCCESS'
export const DELETE_SELLING_PRICE_FAILURE = 'DELETE_SELLING_PRICE_FAILURE'

export const FETCH_SELLING_FORM_DATA_REQUEST = 'FETCH_SELLING_FORM_DATA_REQUEST'
export const FETCH_SELLING_FORM_DATA_SUCCESS = 'FETCH_SELLING_FORM_DATA_SUCCESS'
export const FETCH_SELLING_FORM_DATA_FAILURE = 'FETCH_SELLING_FORM_DATA_FAILURE'

export const UPDATE_SECTION = 'UPDATE_SECTION'
export const UPDATE_PRICING_SECTIONS = 'UPDATE_PRICING_SECTIONS'
export const UPDATE_ADVANCED_PRICE_LEVEL = 'UPDATE_ADVANCED_PRICE_LEVEL'

export const CREATE_SELLING_PRICE_REQUEST = 'CREATE_SELLING_PRICE_REQUEST'
export const CREATE_SELLING_PRICE_SUCCESS = 'CREATE_SELLING_PRICE_SUCCESS'
export const CREATE_SELLING_PRICE_FAILURE = 'CREATE_SELLING_PRICE_FAILURE'

export const DELETE_BESPOKE_PRICE = 'DELETE_BESPOKE_PRICE'

export const CLEAR_SHOULD_SAVE = 'CLEAR_SHOULD_SAVE'

export const CLEAR_STATE = 'CLEAR_STATE'

// Action creator factory function
const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchSellingPricesRequest = createAction(FETCH_SELLING_PRICES_REQUEST)
export const fetchSellingPricesSuccess = createAction(FETCH_SELLING_PRICES_SUCCESS)
export const fetchSellingPricesFailure = createAction(FETCH_SELLING_PRICES_FAILURE)

export const fetchSellingPriceRequest = createAction(FETCH_SELLING_PRICE_REQUEST)
export const fetchSellingPriceSuccess = createAction(FETCH_SELLING_PRICE_SUCCESS)
export const fetchSellingPriceFailure = createAction(FETCH_SELLING_PRICE_FAILURE)

export const fetchSellingFormDataRequest = createAction(FETCH_SELLING_FORM_DATA_REQUEST)
export const fetchSellingFormDataSuccess = createAction(FETCH_SELLING_FORM_DATA_SUCCESS)
export const fetchSellingFormDataFailure = createAction(FETCH_SELLING_FORM_DATA_FAILURE)

export const deleteSellingPriceRequest = createAction(DELETE_SELLING_PRICE_REQUEST)
export const deleteSellingPriceSuccess = createAction(DELETE_SELLING_PRICE_SUCCESS)
export const deleteSellingPriceFailure = createAction(DELETE_SELLING_PRICE_FAILURE)

export const createSellingPriceRequest = createAction(CREATE_SELLING_PRICE_REQUEST)
export const createSellingPriceSuccess = createAction(CREATE_SELLING_PRICE_SUCCESS)
export const createSellingPriceFailure = createAction(CREATE_SELLING_PRICE_FAILURE)

export const clearState = createAction(CLEAR_STATE)

export const updateSection = (section_id) => ({
  type: UPDATE_SECTION,
  payload: { section_id }
})

export const deleteBespokePrice = (item) => ({
  type: DELETE_BESPOKE_PRICE,
  payload: item
})

export const updatePricingSection = (data) => ({
  type: UPDATE_PRICING_SECTIONS,
  payload: data
})

export const updateAdvancedPriceLevel = (data) => ({
  type: UPDATE_ADVANCED_PRICE_LEVEL,
  payload: { ...data }
})

export const clearShouldSave = createAction(CLEAR_SHOULD_SAVE)
