/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import {
  ROLES_PERMISSIONS_CALL,
  roleAddPromise,
  roleEditPromise,
  roleDeletePromise,
  roleLoadingSwitch
} from './actions'
import { errorAdd } from '../../error/actions'

const prefix = '/role-permission'

function* roleCallGenerator(action) {
  const {
    payload: { moduleName }
  } = action
  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/role`, {
      params: {
        type: moduleName
      }
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  const { type } = action
  yield put({
    type: `${type}_RESPONSE`,
    payload: {
      moduleName,
      data: d
    }
  })
}

// Loading wrapper
function* roleGeneratorWrapper(moduleName, gen) {
  yield put(roleLoadingSwitch(moduleName, true))
  yield gen()
  yield put(roleLoadingSwitch(moduleName, false))
}

function* roleAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, roleData }
    } = action

    yield roleGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/role`, roleData)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* roleEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, roleId, roleData }
    } = action

    yield roleGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/role/${roleId}`, roleData)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* roleDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, roleId }
    } = action
    yield roleGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/role/${roleId}`)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, roleId } })
        yield call(resolvePromiseAction, action, roleId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* rolesPermissionsWatcherSaga() {
  yield takeLatest(ROLES_PERMISSIONS_CALL, roleCallGenerator)
  yield takeEvery(roleAddPromise, roleAddPromiseGenerator)
  yield takeEvery(roleEditPromise, roleEditPromiseGenerator)
  yield takeEvery(roleDeletePromise, roleDeletePromiseGenerator)
}
