export const FETCH_CARTS_REQUEST = 'FETCH_CARTS_REQUEST'
export const FETCH_CARTS_SUCCESS = 'FETCH_CARTS_SUCCESS'
export const FETCH_CARTS_FAILURE = 'FETCH_CARTS_FAILURE'

export const UPDATE_QUANTITY_REQUEST = 'UPDATE_QUANTITY_REQUEST'
export const UPDATE_QUANTITY_SUCCESS = 'UPDATE_QUANTITY_SUCCESS'
export const UPDATE_QUANTITY_FAILURE = 'UPDATE_QUANTITY_FAILURE'

export const DELETE_FROM_CART_REQUEST = 'DELETE_FROM_CART_REQUEST'
export const DELETE_FROM_CART_SUCCESS = 'DELETE_FROM_CART_SUCCESS'
export const DELETE_FROM_CART_FAILURE = 'DELETE_FROM_CART_FAILURE'

export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST'
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS'
export const GET_STOCK_FAILURE = 'GET_STOCK_FAILURE'

export const GET_DATA_BY_SECTION_REQUEST = 'GET_DATA_BY_SECTION_REQUEST'
export const GET_DATA_BY_SECTION_SUCCESS = 'GET_DATA_BY_SECTION_SUCCESS'
export const GET_DATA_BY_SECTION_FAILURE = 'GET_DATA_BY_SECTION_FAILURE'

export const SEARCH_BY_PART_NUMBER_REQUEST = 'SEARCH_BY_PART_NUMBER_REQUEST'
export const SEARCH_BY_PART_NUMBER_SUCCESS = 'SEARCH_BY_PART_NUMBER_SUCCESS'
export const SEARCH_BY_PART_NUMBER_FAILURE = 'SEARCH_BY_PART_NUMBER_FAILURE'

export const CHECKOUT_ORDER_REQUEST = 'CHECKOUT_ORDER_REQUEST'
export const CHECKOUT_ORDER_SUCCESS = 'CHECKOUT_ORDER_SUCCESS'
export const CHECKOUT_ORDER_FAILURE = 'CHECKOUT_ORDER_FAILURE'

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

export const CLEAR_STATE = 'CLEAR_STATE'

export const CLEAR_CART = 'CLEAR_CART'

export const INIT_CATALOG_FAILURE = 'INIT_CATALOG_FAILURE'
export const INIT_CATALOG_REQUEST = 'INIT_CATALOG_REQUEST'

export const CLEAR_DAMAGED_ITEMS = 'CLEAR_DAMAGED_ITEMS'
export const CLEAR_REMOVED_ITEMS = 'CLEAR_REMOVED_ITEMS'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchCartsRequest = createAction(FETCH_CARTS_REQUEST)
export const fetchCartsSuccess = createAction(FETCH_CARTS_SUCCESS)
export const fetchCartsFailure = createAction(FETCH_CARTS_FAILURE)

export const updateQuantityRequest = createAction(UPDATE_QUANTITY_REQUEST)
export const updateQuantitySuccess = createAction(UPDATE_QUANTITY_SUCCESS)
export const updateQuantityFailure = createAction(UPDATE_QUANTITY_FAILURE)

export const deleteFromCartRequest = createAction(DELETE_FROM_CART_REQUEST)
export const deleteFromCartSuccess = createAction(DELETE_FROM_CART_SUCCESS)
export const deleteFromCartFailure = createAction(DELETE_FROM_CART_FAILURE)

export const getStockRequest = createAction(GET_STOCK_REQUEST)
export const getStockSuccess = createAction(GET_STOCK_SUCCESS)
export const getStockFailure = createAction(GET_STOCK_FAILURE)

export const getDataBySectionRequest = createAction(GET_DATA_BY_SECTION_REQUEST)
export const getDataBySectionSuccess = createAction(GET_DATA_BY_SECTION_SUCCESS)
export const getDataBySectionFailure = createAction(GET_DATA_BY_SECTION_FAILURE)

export const searchByPartNumberRequest = createAction(SEARCH_BY_PART_NUMBER_REQUEST)
export const searchByPartNumberSuccess = createAction(SEARCH_BY_PART_NUMBER_SUCCESS)
export const searchByPartNumberFailure = createAction(SEARCH_BY_PART_NUMBER_FAILURE)

export const checkoutOrderRequest = createAction(CHECKOUT_ORDER_REQUEST)
export const checkoutOrderSuccess = createAction(CHECKOUT_ORDER_SUCCESS)
export const checkoutOrderFailure = createAction(CHECKOUT_ORDER_FAILURE)

export const clearState = createAction(CLEAR_STATE)

export const clearCart = createAction(CLEAR_CART)

export const clearDamagedItems = createAction(CLEAR_DAMAGED_ITEMS)
export const clearRemovedItems = createAction(CLEAR_REMOVED_ITEMS)

export const removeFromCart = createAction(REMOVE_FROM_CART)
