import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction, data = {}) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction({...response.data, requestData: data}))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchInitCatalog() {
  yield requestWithActions(
    actions.FETCH_INIT_CATALOG_REQUEST,
    () => axiosSagaRequest('get', 'web-catalogue/init-catalog'),
    actions.fetchInitCatalogSuccess,
    actions.fetchInitCatalogFailure
  )
}

function* addToCartPromise(action) {
  const {
    type,
    payload: { branch_id, product_price_id, quantity, section_lookup_id }
  } = action
  yield requestWithActions(
    actions.ADD_TO_CART_PROMISE,
    () => axiosSagaRequest('put', `/web-catalogue/cart/add-to-cart`, {
      section_lookup_id,
      product_price_id,
      branch_id,
      quantity
    }),
    actions.addToCartSuccess,
    actions.addToCartFailure,
    action.payload
  )
}

function* callRequestPromise(action) {
  const {
    payload: { part_number }
  } = action
  yield requestWithActions(
    actions.CALL_REQUEST,
    () => axiosSagaRequest('post', `/web-catalogue/request-call`, {part_number}),
    actions.callRequestSuccess,
    actions.callRequestFailure
  )
}

export default function* catalogWatcherSaga() {
  yield takeLatest(actions.FETCH_INIT_CATALOG_REQUEST, fetchInitCatalog)
  yield takeLatest(actions.ADD_TO_CART_PROMISE, addToCartPromise)
  yield takeLatest(actions.CALL_REQUEST, callRequestPromise)
}