/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/display-name */
import React, { memo, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import DropdownContext from './dropdown-context'

/**
 * Dropdown item component (use to / onClick)
 * @param {String} label - Item label
 * @param {String} to - Item url
 * @param {Function} onClick - Item onClick function
 * @param {String} style - Item style object
 * @param {String} className - Item className
 * @param {Element} children - Item inner content
 */

const DropdownItem = memo(({ label, to, onClick, style, className, children }) => {
  const history = useNavigate()
  const handleToggle = useContext(DropdownContext)

  const isClickable = useMemo(() => to || onClick, [to, onClick])

  const handleClick = () => {
    if (to) {
      history(to)
    } else if (onClick) {
      onClick()
    }
    handleToggle()
  }

  return (
    <li
      className={`dropdown__item ${isClickable ? 'dropdown__item--clickable' : ''} ${className}`}
      style={style}
      onClick={handleClick}
    >
      {children || label}
    </li>
  )
})

DropdownItem.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.node
}

DropdownItem.defaultProps = {
  label: '',
  to: undefined,
  onClick: undefined,
  style: {},
  className: '',
  children: undefined
}

export default DropdownItem
