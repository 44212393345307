import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { clientRouteNames } from 'constant'
import { NotificationPreview } from 'components/complex'
import { Icon, LanguageSwitcher, Badge } from 'components/common'
import { userDataSelector } from 'store/user/selectors'
import { initCatalogSelector } from 'store/catalog/selectors'

import NotificationsDropdown from './notifications'

const HeaderIcons = () => {
  const [action, setAction] = useState(null)

  const { basket } = useSelector(initCatalogSelector)
  const { user } = useSelector(userDataSelector)

  return (
    <div className="header__icons">
      <LanguageSwitcher />
      <NotificationsDropdown setAction={setAction} />
      {Boolean(action) && (
        <NotificationPreview
          isOpen
          close={() => setAction(null)}
          action={action}
          id={action.id}
          isLoading={false}
        />
      )}

      <div className="flex mr-l">
        {user.account?.type !== 'company' && (
          <NavLink to={clientRouteNames.CART} className="icon-box cart-icon-box">
            <Icon iconClass="Basket" type="dark" size="20px" />
            {basket?.length > 0 && <Badge title={basket?.length} type="danger" />}
          </NavLink>
        )}
      </div>
    </div>
  )
}

export default HeaderIcons
