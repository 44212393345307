import Joi from 'joi'
import { emailSchema } from 'constant/validation/schemas'
import joiDefaultErrorMessages from 'constant/validation/messages'

export default Joi.object({
  full_name: Joi.string().required().messages(joiDefaultErrorMessages),

  email: emailSchema.required(),

  department: Joi.string().allow('').messages(joiDefaultErrorMessages),

  job_title: Joi.string().allow('').messages(joiDefaultErrorMessages),

  telephone: Joi.string()
    .allow('')
    .pattern(new RegExp('^[+]?[0-9]{1,4}[0-9]{6,}$')) // Example: Allowing optional '+' and at least 7-digit number
    .messages(joiDefaultErrorMessages),

  mobile: Joi.string()
    .allow('')
    .pattern(new RegExp('^[+]?[0-9]{1,4}[0-9]{6,}$')) // Example: Allowing optional '+' and at least 7-digit number
    .messages(joiDefaultErrorMessages),
})
