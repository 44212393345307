/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar, Dialog, Tabs, Table } from 'components/common'
import {
  updateSection,
  fetchSellingFormDataRequest,
  clearShouldSave
} from 'store/selling-prices/actions'
import { fetchLandedPricesRequest, createSellingPriceSuccess } from 'store/landed-prices/actions'
import { useTranslation } from 'react-i18next'
import { axiosRequest } from 'clients/api'
import { useLocation } from 'react-router-dom'

import {
  selectSections,
  selectProductPrices,
  selectSellingPrice,
  selectPricingSections,
  selectCatalogueCurrency,
  selectFormData,
  selectPriceLevels,
  selectBespokePrices,
  selectAdvancedProductPrices,
  selectShouldSave
} from 'store/selling-prices/selectors'
import { useSearch, usePagination } from 'hooks'
import Advanced from './sections/Advanced'
import Basic from './sections/Basic'

const PER_PAGE_PAGINATION = 5

const SellingPriceConfigure = ({ isOpen, close, title = 'This is the default modal title' }) => {
  const dispatch = useDispatch()
  const productPrices = useSelector(selectProductPrices)
  const advancedProductPrices = useSelector(selectAdvancedProductPrices)
  const sections = useSelector(selectSections)
  const { pricingSections, sectionId } = useSelector(selectPricingSections)
  const bespokePrices = useSelector(selectBespokePrices)
  const priceData = useSelector(selectSellingPrice)
  const catalogueCurrency = useSelector(selectCatalogueCurrency)
  const formData = useSelector(selectFormData)
  const priceLevels = useSelector(selectPriceLevels)
  const addSnackbar = useSnackbar()
  const { t, i18n } = useTranslation()
  const search = useSearch()
  const [mainTab, setMainTab] = useState(0)
  const [currentTabId, setCurrentTabId] = useState(null)
  const [tabsArray, setTabsArray] = useState(null)
  const [displayedItems, setDisplayedItems] = useState([])
  const { pathname } = useLocation()

  const shouldSave = useSelector(selectShouldSave)
  const [showWarningDialog, setShowWarningDialog] = useState(false)

  useEffect(() => {
    dispatch(fetchSellingFormDataRequest())
  }, [dispatch])

  useEffect(() => {
    const tabsUpdated = [
      { id: null, title: 'All Sections', to: 'all' },
      ...sections.map((item) => {
        const name = item.names.find((translation) => translation[i18n.language])
        const backgroundColor =
          mainTab === 0 ? item.background_color : item.background_color_advanced

        return {
          id: item.id,
          bgColor: backgroundColor,
          title: name ? name[i18n.language] : 'Translation not available'
        }
      })
    ]

    setTabsArray(tabsUpdated)
  }, [sections, i18n.language, mainTab])

  const convertUnicode = (input) => {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) => String.fromCharCode(parseInt(b, 16)))
  }

  const columns = [
    {
      Header: 'Part Number',
      accessor: 'part_number',
      Cell: ({ value }) =>
        `${priceData.prefix ? priceData.prefix : ''}${value}${
          priceData.suffix ? priceData.suffix : ''
        }`
    },
    {
      Header: `Net Price (${priceData?.currency.name})`,
      accessor: 'price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(priceData.currency.symbol)}`
    },
    {
      Header: `Net Price (${catalogueCurrency?.name})`,
      accessor: 'convertedPrice',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    },
    {
      Header: 'Add Costs %',
      accessor: 'added_costs',
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} %`
    },
    {
      Header: 'Landed Price',
      accessor: 'landed_price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    },
    ...(formData
      ? formData.map((item) => ({
          Header: item.name,
          accessor: (row) => {
            const priceLevelId = item.id

            const section = pricingSections.find(
              (section) => section.section_id === row.product_section_id
            )

            if (section) {
              const sellingPrice = section?.selling_prices.find(
                (price) => price.price_level_id === priceLevelId
              )

              if (sellingPrice) {
                const calculatedPrice = parseFloat(
                  parseFloat(row.landed_price) +
                    (parseFloat(row.landed_price) * sellingPrice.value) / 100
                ).toFixed(2)

                return `${convertUnicode(catalogueCurrency.symbol)} ${calculatedPrice} (${
                  sellingPrice.value
                }%)`
              } else {
                // Find the section with the given sectionId
                const section = pricingSections.find((section) => section.section_id === null)

                if (section) {
                  // Find the selling price for the provided price_level_id
                  const sellingPrice = section.selling_prices.find(
                    (price) => price.price_level_id === priceLevelId
                  )
                  if (sellingPrice) {
                    const calculatedPrice = parseFloat(
                      parseFloat(row.landed_price) +
                        (parseFloat(row.landed_price) * sellingPrice.value) / 100
                    ).toFixed(2)
                    return `${convertUnicode(catalogueCurrency.symbol)} ${calculatedPrice} (${
                      sellingPrice.value
                    }%)`
                  }
                }
              }
            } else {
              // Find the section with the given sectionId
              const section = pricingSections.find((section) => section.section_id === null)

              if (section) {
                // Find the selling price for the provided price_level_id
                const sellingPrice = section.selling_prices.find(
                  (price) => price.price_level_id === priceLevelId
                )
                if (sellingPrice) {
                  const calculatedPrice = parseFloat(
                    parseFloat(row.landed_price) +
                      (parseFloat(row.landed_price) * sellingPrice.value) / 100
                  ).toFixed(2)
                  return `${convertUnicode(catalogueCurrency.symbol)} ${calculatedPrice} (${
                    sellingPrice.value
                  }%)`
                }
              }
            }

            return '-'
          }
        }))
      : [])
  ]

  const advancedColumns = [
    {
      Header: 'Part Number',
      accessor: 'part_number',
      Cell: ({ value }) =>
        `${priceData.prefix ? priceData.prefix : ''}${value}${
          priceData.suffix ? priceData.suffix : ''
        }`
    },
    {
      Header: `Net Price (${priceData?.currency.name})`,
      accessor: 'price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(priceData.currency.symbol)}`
    },
    {
      Header: `Net Price (${catalogueCurrency?.name})`,
      accessor: 'convertedPrice',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    },
    {
      Header: 'Add Costs %',
      accessor: 'added_costs',
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} %`
    },
    {
      Header: 'Landed Price',
      accessor: 'landed_price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    },
    ...(formData
      ? formData.map((item) => ({
          Header: item.name,
          accessor: (row) => {
            const priceLevelId = item.id

            const product = bespokePrices.find((product) => product.price_level_id === priceLevelId)
            if (product) {
              const productPrice = product?.products.find(
                (product) => product.product_price_id === row.id
              )

              if (productPrice) {
                const { price } = productPrice

                return `${price.toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
              }
            }

            return `-`
          }
        }))
      : [])
  ]

  const {
    filteredList: filteredProductPrices,
    SearchBar,
    searchState: { modified_part_number }
  } = search(mainTab === 0 ? productPrices : advancedProductPrices, [
    {
      type: 'text',
      keyName: 'modified_part_number',
      condition: 'contains',
      placeholder: 'Search...'
    }
  ])

  const { activePage, pagesCount, setActivePage } = usePagination({
    totalCount: filteredProductPrices.length,
    perPage: PER_PAGE_PAGINATION
  })

  const paginateProducts = ({ prodList }) => {
    const startIndex = activePage * PER_PAGE_PAGINATION
    const endIndex = startIndex + PER_PAGE_PAGINATION
    return prodList.slice(startIndex, endIndex)
  }

  useEffect(() => {
    if (activePage >= 0) {
      const nextProductsList = paginateProducts({ prodList: filteredProductPrices })
      setDisplayedItems(nextProductsList)
    }
  }, [activePage, filteredProductPrices])

  const handleTabChange = (tabId) => {
    setCurrentTabId(tabId)
    dispatch(updateSection(tabId))
    setActivePage(0)
  }

  const handleCreateBespokePrice = (payload) => {
    axiosRequest('post', `selling-price/${payload.landed_price_id}`, payload)
      .then((response) => {
        if (pathname.includes('landed')) {
          dispatch(fetchLandedPricesRequest())
        }

        dispatch(clearShouldSave())

        addSnackbar({
          variant: 'success',
          message: 'Saved Successfully'
        })
        close()
      })
      .catch((error) => {
        addSnackbar({
          variant: 'danger',
          message: 'Something went wrong'
        })
        close()
      })
  }

  const handleCancel = () => {
    if (shouldSave) {
      setShowWarningDialog(true)
    } else {
      close()
    }
  }

  const handleSave = () => {
    const payload = {
      landed_price_id: priceData.id,
      sections: [...pricingSections],
      bespoke_prices: [...bespokePrices]
    }
    handleCreateBespokePrice(payload)
  }

  const mainTabs = [
    { title: 'Basic', id: 0 },
    { title: 'Advanced', id: 1 }
  ]

  return (
    <>
      <Dialog
        open={isOpen}
        close={() => handleCancel()}
        size="xl"
        alignment="center"
        hasPagination
        pagesCount={pagesCount}
        setActivePage={setActivePage}
        title={title}
        disableBackdropClick
        actions={[
          {
            text: t('cancel'),
            variant: 'tertiary',
            onClick: handleCancel
          },
          {
            text: 'Save And Activate',
            variant: 'primary',
            onClick: handleSave
          }
        ]}
      >
        <div className="mx-md mt-md">
          <Tabs defaultTabIndex={0} type={2} onTabClick={(tabId) => setMainTab(tabId)}>
            {mainTabs.map((tab, index) => (
              <Tabs.Tab key={tab.id || index} title={tab.title} id={tab.id} />
            ))}
          </Tabs>
        </div>
        <div className="price-edit-modal-container">
          {tabsArray && (
            <Tabs defaultTabIndex={0} type={2} onTabClick={handleTabChange}>
              {tabsArray.map((tab, index) => (
                <Tabs.Tab
                  key={tab.id || index}
                  title={tab.title}
                  id={tab.id}
                  tabColor={tab.bgColor}
                />
              ))}
            </Tabs>
          )}
          <div className="pricing-table-wrapper">
            <div className="flex flex-align-center">
              {SearchBar}
              <div className="flex flex-align-center w-full px-md">
                {mainTab === 0
                  ? formData && <Basic formData={formData} />
                  : formData && <Advanced formData={formData} />}
              </div>
            </div>
            <Table
              data={displayedItems}
              columns={mainTab === 0 ? columns : advancedColumns}
              className="mt-sm"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showWarningDialog}
        close={() => setShowWarningDialog(false)}
        size="xs"
        alignment="center"
        title="Close without saving"
        description="You have unsaved changes. Are you sure you want to leave?"
        disableBackdropClick
        actions={[
          {
            text: t('No'),
            variant: 'tertiary',
            onClick: () => setShowWarningDialog(false)
          },
          {
            text: t('Yes'),
            isDestructive: true,
            onClick: () => {
              close()
              setShowWarningDialog(false)
            }
          }
        ]}
      />
    </>
  )
}

export default SellingPriceConfigure
