import { call, put, select, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import { initSettingsCompanyIdSelector } from 'store/general/selectors'
import * as actions from './actions'

// Helper function for making API requests and handling success/failure actions
function* requestWithActions(actionType, apiRequest, successAction, failureAction, moduleName) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction({ data: response.data, moduleName }))
  } catch (error) {
    yield put(failureAction({ error: error.message, moduleName }))
    yield put(errorAdd(error, actionType))
  }
}

// Fetch API/FTP - Branches
function* fetchStockFeedData(action) {
  yield requestWithActions(
    actions.FETCH_STOCK_FEED_DATA_REQUEST,
    () => axiosSagaRequest('get', 'account/stock_feed/get_form_data'),
    actions.fetchStockFeedDataSuccess,
    actions.fetchStockFeedDataFailure,
    action.payload.moduleName || undefined
  )
}

function* fetchStockFeedForAccount(action) {
  const id = action.payload?.id
  let accountId
  if (id) {
    accountId = id
  } else {
    accountId = yield select(initSettingsCompanyIdSelector)
  }
  yield requestWithActions(
    actions.FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST,
    () => axiosSagaRequest('get', `account/stock_feed/${accountId}`),
    actions.fetchStockFeedForAccountSuccess,
    actions.fetchStockFeedForAccountFailure,
    action.payload.moduleName || undefined
  )
}

function* StockFeedEditPromiseGenerator(action) {
  const {
    payload: { id, finalData, moduleName }
  } = action

  let accountId
  if (id) {
    accountId = id
  } else {
    accountId = yield select(initSettingsCompanyIdSelector)
  }

  yield requestWithActions(
    actions.STOCK_FEED_EDIT_ACCOUNT_REQUEST,
    () => axiosSagaRequest('put', `/account/stock_feed/${accountId}`, finalData),
    actions.stockEditAccountSuccess,
    actions.stockEditAccountFailure,
    moduleName || undefined
  )
}
// Root saga
export default function* stockFeedWatcherSaga() {
  yield takeLatest(actions.FETCH_STOCK_FEED_DATA_REQUEST, fetchStockFeedData)
  yield takeLatest(actions.FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST, fetchStockFeedForAccount)
  yield takeLatest(actions.STOCK_FEED_EDIT_ACCOUNT_REQUEST, StockFeedEditPromiseGenerator)
}
