import Joi from 'joi'
import { emailSchema, passwordSchema, editPasswordSchema} from 'constant/validation/schemas'
import joiDefaultErrorMessages from 'constant/validation/messages'

export default Joi.object({
  full_name: Joi.string().max(100).required().messages(joiDefaultErrorMessages),
  email: emailSchema.required(),  
  password: Joi.string().when('isNew', {
    is: true,
    then: passwordSchema,
    otherwise: Joi.when('accountId', {
      is: true,
      then: editPasswordSchema,
      otherwise: passwordSchema
    })
  }),
  password_confirmation: Joi.when('password', {
    is: Joi.exist(),
    then: Joi.string()
      .required()
      .valid(Joi.ref('password'))
      .messages(joiDefaultErrorMessages),
    otherwise: Joi.forbidden()
  }),
  job_title: Joi.string().allow('').max(100).messages(joiDefaultErrorMessages),
  role: Joi.string().required().messages(joiDefaultErrorMessages),
  
  telephone: Joi.string()
    .allow('')
    .max(30)
    .pattern(new RegExp('^[+]?[0-9]{1,4}[0-9]{6,}$')) // Example: Allowing optional '+' and at least 7-digit number
    .messages({
      ...joiDefaultErrorMessages,
      'string.pattern.base': 'Must be a valid phone number.'
    }),

  mobile: Joi.string()
    .allow('')
    .max(30)
    .pattern(new RegExp('^[+]?[0-9]{1,4}[0-9]{6,}$')) // Example: Allowing optional '+' and at least 7-digit number
    .messages({
      ...joiDefaultErrorMessages,
      'string.pattern.base': 'Must be a valid phone number.'
    }),

  add_to_contact_book: Joi.boolean(),
  isNew: Joi.allow(''),
  accountId: Joi.allow('')
})
