export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE'

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE'

export const CLEAR_STATE = 'CLEAR_STATE'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchOrdersRequest = createAction(FETCH_ORDERS_REQUEST)
export const fetchOrdersSuccess = createAction(FETCH_ORDERS_SUCCESS)
export const fetchOrdersFailure = createAction(FETCH_ORDERS_FAILURE)

export const fetchOrderRequest = createAction(FETCH_ORDER_REQUEST)
export const fetchOrderSuccess = createAction(FETCH_ORDER_SUCCESS)
export const fetchOrderFailure = createAction(FETCH_ORDER_FAILURE)

export const updateOrderRequest = createAction(UPDATE_ORDER_REQUEST)
export const updateOrderSuccess = createAction(UPDATE_ORDER_SUCCESS)
export const updateOrderFailure = createAction(UPDATE_ORDER_FAILURE)

export const clearState = createAction(CLEAR_STATE)
