import React, { createContext, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { v4 as uuidv4 } from 'uuid'
import Snackbar from './snackbar-component'

export const visibleDuration = 5000

export const SnackbarContext = createContext([[], () => {}])

export const SnackbarProvider = ({ children }) => {
  const [snackbarList, setSnackbarList] = useState([])

  const removeSnackbar = useCallback((snackId) => {
    setSnackbarList((prevList) => [...prevList.filter((snack) => snack.id !== snackId)])
  }, [])

  const addSnackbar = ({
    message,
    variant,
    duration = visibleDuration,
    withoutIcon,
    withoutClose
  }) => {
    const newSnackbar = {
      id: uuidv4(),
      variant,
      message,
      duration,
      withoutIcon,
      withoutClose
    }
    setSnackbarList((prevList) => [...prevList, newSnackbar])
  }

  const SnackbarList = useMemo(
    () =>
      ReactDOM.createPortal(
        <div className="snackbar-container">
          {snackbarList.map(({ id, variant, message, duration, withoutIcon, withoutClose }) => (
            <Snackbar
              key={id}
              variant={variant}
              message={message}
              duration={duration}
              withoutIcon={withoutIcon}
              withoutClose={withoutClose}
              removeSnack={() => removeSnackbar(id)}
            />
          ))}
        </div>,
        document.body
      ),
    [snackbarList]
  )

  return (
    <>
      <SnackbarContext.Provider value={{ addSnackbar }}>{children}</SnackbarContext.Provider>
      {SnackbarList}
    </>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

SnackbarProvider.defaultProps = {
  children: undefined
}
