export const authRouteNames = {
  LOGIN: '/login',
  REGISTER: '/register'
}

export const clientRouteNames = {
  AUTOMOTIVE: '/catalogue/automotive',
  NONAUTOMOTIVE: '/catalogue/non-automotive',
  CART: '/catalogue/cart'
}

export const adminRouteNames = {
  DASHBOARD: '/admin/dashboard',
  NOTIFICATIONS: '/admin/notifications',
  ACCOUNT: '/admin/account',
  PRICING: '/admin/pricing',
  MARKETING: '/admin/marketing-tools',
  SETTINGS: '/admin/settings'
}

export const adminDashboardRouteNames = {
  INFO: '/info',
  USER: '/user',
  CHARGES: '/company-charges',
  STOCK: '/stock',
  FINANCIAL: '/financial',
  CONTACT: '/contact'
}

export const adminAccountRouteNames = {
  SUPPLIERS: '/suppliers',
  BRANCHES: '/branches',
  CUSTOMERS: '/customers'
}

export const adminPricingRouteNames = {
  PRICING: '/prices',
  CURRENCY: '/currency'
}

export const adminPricingPricesRouteNames = {
  LANDED: '/landed',
  SELLING: '/selling'
}

export const adminSystemSettingsRouteNames = {
  PRICING_LEVELS: '/pricing-levels',
  ROLES_PERMISSIONS: '/roles-permissions',
  CATALOGUE_PREFERENCES: '/catalogue-preferences',
  CATALOGUE_SLIDER: '/catalogue-slider',
  PRODUCT_MANAGEMENT: '/product-management',
  CUSTOMER_TYPES: '/customer-types',
  CATALOGUE_MANAGEMENT: '/catalogue-management'
}

export const adminRolesAndPermissionsSettingsRouteNames = {
  COMPANY_LEVEL: '/company-level',
  BRANCH_LEVEL: '/branch-level',
  CUSTOMER_LEVEL: '/customer-level'
}

export const adminCataloguePreferencesSettingsRouteNames = {
  GENERAL: '/general-preferences',
  VEHICLEMANUFACTURERS: '/vehicle-manufacturers'
}
