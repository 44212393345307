import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Header({ companyDetails, order }) {


const { t } = useTranslation()
  return (
    <div className="flex w-full flex-align-center flex-justify-space-between mb-xl">
      <div style={{ textAlign: 'left' }}>
        <p className="fw-bold">{t('From')}</p>
        <p>{companyDetails?.name}</p>
        <p>{companyDetails?.account_detail?.address_1}</p>
        <p>{companyDetails?.account_detail?.town}</p>
        <p>{companyDetails?.account_detail?.country?.name}</p>
        <p>{companyDetails?.account_detail?.postal_code}</p>
      </div>

      <div style={{ textAlign: 'right' }}>
        <p className="fw-bold">{t('Invoice to')}</p>
        <p>{order?.user?.user_detail?.full_name}</p>
        <p>{order?.account?.account_detail?.address_1}</p>
        <p>{order?.account?.account_detail?.town}</p>
        <p>{order?.account?.account_detail?.country?.name}</p>
        <p>{order?.account?.account_detail?.postal_code}</p>
      </div>
    </div>
  )
}
