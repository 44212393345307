/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import {
  VAT_RATES_CALL,
  VAT_AVAILABLE_CODES_CALL,
  vatRateAddPromise,
  vatRateEditPromise,
  vatRateDeletePromise,
  vatRateLoadingSwitch
} from './actions'
import { errorAdd } from '../../error/actions'

const prefix = '/vat_rate'

// Loading wrapper
function* vatRateGeneratorWrapper(gen) {
  yield put(vatRateLoadingSwitch(true))
  yield gen()
  yield put(vatRateLoadingSwitch(false))
}

function* vatRateAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { vatRateData }
    } = action

    yield vatRateGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, vatRateData)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* vatRateEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { vatRateId, editData }
    } = action

    yield vatRateGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'put', `${prefix}/${vatRateId}`, editData)
        yield put({ type: `${type}_RESPONSE`, payload: { vatRateId, editData } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* vatRateDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { vatRateId }
    } = action
    yield vatRateGeneratorWrapper(function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/${vatRateId}`)
        yield put({ type: `${type}_RESPONSE`, payload: { vatRateId } })
        yield call(resolvePromiseAction, action, vatRateId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* vatRatesWatcherSaga() {
  yield takeLatest(VAT_RATES_CALL, axiosGetGenerator(`${prefix}`))
  yield takeLatest(VAT_AVAILABLE_CODES_CALL, axiosGetGenerator(`${prefix}/get-form-data`))
  yield takeEvery(vatRateAddPromise, vatRateAddPromiseGenerator)
  yield takeEvery(vatRateEditPromise, vatRateEditPromiseGenerator)
  yield takeEvery(vatRateDeletePromise, vatRateDeletePromiseGenerator)
}
