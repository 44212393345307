import React, { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

export const TranslationContext = createContext([[], () => {}])

export const TranslationProvider = ({ defaultLanguage, children }) => {
  const [language, setLanguage] = useState(defaultLanguage)

  const changeLanguage = useCallback((lng) => {
    setLanguage(lng)
  }, [])

  return (
    <TranslationContext.Provider
      value={{
        defaultLanguage,
        language,
        changeLanguage
      }}>
      {children}
    </TranslationContext.Provider>
  )
}

TranslationProvider.propTypes = {
  defaultLanguage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ])
}

TranslationProvider.defaultProps = {
  defaultLanguage: 'en',
  children: undefined
}
