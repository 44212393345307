import React, { memo, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useForm } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { financialDetailsEditPromise } from 'store/financial-details/actions'
import { currencyRatesSelector } from 'store/currency/selectors'
import { initSettingsAdditionalSelector } from 'store/general/selectors'
import { Box, Divider, Typography, RequiredStar, useSnackbar, Loader } from 'components/common'
import { usePermission } from 'components/common/permission'
import { EditCancelSave } from 'components/complex'
import { accountAddPromise } from 'store/account/actions'
import { useTranslation } from 'react-i18next'
import validationSchema from './validation-schema'

const BankAccountCard = memo(({ bankAccountData, isLoading, moduleName, accountId, isNew }) => {
  const dispatch = useDispatch()
  const addSnackbar = useSnackbar()

  const { id, title, data } = bankAccountData

  const [isEdit, setIsEdit] = useState(false)
  const { t } = useTranslation()

  const currencies = useSelector(currencyRatesSelector, isEqual).data.map(
    (currencyRate) => currencyRate.currency
  )
  const { country: countries } = useSelector(initSettingsAdditionalSelector, isEqual)
  let managePermission = 'company_account_financial-manage'

  if (moduleName === 'branch') {
    managePermission = ['branch-manage', 'own_branch-manage']
  }

  const bankAccountFields = useMemo(
    () => [
      {
        name: 'name',
        type: 'text',
        label: t('Bank Account Name'),
        placeholder: t('Enter bank account name'),
        required: true
      },
      {
        name: 'number',
        type: 'text',
        label: t('Account Number'),
        placeholder: t('Enter account number'),
        required: true
      },
      {
        name: 'sort_code',
        type: 'text',
        label: t('Sort Code'),
        placeholder: t('Enter sort code'),
        required: true
      },
      {
        name: 'iban',
        type: 'text',
        label: t('IBAN'),
        placeholder: t('Enter iban')
      },
      {
        name: 'bic',
        type: 'text',
        label: t('BIC / Swift Code'),
        placeholder: t('Enter bic / swift code')
      },
      {
        name: 'country_id',
        type: 'select',
        label: t('Country'),
        placeholder: t('Select'),
        required: true,
        isSearchable: true,
        options:
          countries.map(({ id: countryId, name }) => ({
            value: countryId,
            label: name
          })) || []
      },
      {
        name: 'currency_id',
        type: 'select',
        label: t('Currency'),
        placeholder: t('Select'),
        required: true,
        isSearchable: true,
        options:
          currencies.map(({ id: currencyId, name, country }) => ({
            value: currencyId,
            label: `${name} - ${country}`
          })) || []
      }
    ],
    []
  )

  const defaultValues = useMemo(() => {
    const { name, number, sort_code, iban, bic, country_id, currency_id } = data
    return {
      name: name || '',
      number: number || '',
      sort_code: sort_code || '',
      iban: iban || '',
      bic: bic || '',
      country_id: country_id || null,
      currency_id: currency_id || null
    }
  })

  const { FormField, handleSubmit, reset, getValues } = useForm({
    validationSchema,
    defaultValues,
    isLoading
  })

  const submitForm = useCallback(async (d) => {
    const { name, number, sort_code, iban, bic, country_id, currency_id } = d
    const finalData = {
      name,
      number,
      sort_code,
      iban: iban || '',
      bic: bic || '',
      country_id,
      currency_id,
      type: id === 1 ? 'primary' : 'secondary'
    }

    try {
      isNew
        ? await dispatch(
            accountAddPromise({
              moduleName,
              accountData: { ...finalData, step: 'financial_detail' }
            })
          )
        : await dispatch(financialDetailsEditPromise({ moduleName, id: accountId, finalData }))

      setIsEdit(false)
      addSnackbar({
        variant: 'success',
        message: t('Bank account updated.')
      })
    } catch (error) {
      // console.error(error)
    }
  }, [])

  const handleClear = useCallback(() => {
    reset({
      name: '',
      number: '',
      sort_code: '',
      iban: '',
      bic: '',
      country_id: null,
      currency_id: null
    })
  })

  return (
    <>
      {isLoading && <Loader fixed />}
      <div className="card-list__container py-xs px-md">
        <Box
          title={title}
          rightSection={
            usePermission(managePermission) && (
              <EditCancelSave
                isEditing={isEdit}
                onEdit={() => setIsEdit(true)}
                onCancel={() => {
                  setIsEdit(false)
                  reset(defaultValues)
                }}
                onSave={handleSubmit(submitForm)}
                onClear={handleClear}
              />
            )
          }
        >
          <Divider className="mt-xs mb-sm" />
          <form className="bank__card">
            {bankAccountFields.map((fieldProps) => {
              const { name, label, required } = fieldProps
              return (
                <div
                  key={name}
                  className={`bank__card__item ${isEdit ? 'mb-sm' : 'flex-align-center'}`}
                >
                  <Typography>
                    {label}:{isEdit && required && <RequiredStar />}
                  </Typography>
                  {FormField({
                    ...fieldProps,
                    disabled: !isEdit,
                    className: `${!isEdit ? 'field-view-only' : ''}`
                  })}
                </div>
              )
            })}
          </form>
        </Box>
      </div>
    </>
  )
})

BankAccountCard.propTypes = {
  bankAccountData: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.string,
      sort_code: PropTypes.string,
      iban: PropTypes.string,
      bic: PropTypes.string,
      country_id: PropTypes.number,
      currency_id: PropTypes.number
    })
  })
}

BankAccountCard.defaultProps = {
  bankAccountData: {
    title: '',
    data: {
      name: '',
      number: '',
      sort_code: '',
      iban: '',
      bic: '',
      country_id: null,
      currency_id: null
    }
  }
}

export default BankAccountCard
BankAccountCard.displayName = 'BankAccountCard'
