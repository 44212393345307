/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import { CAMPAIGNS_CALL, campaignsDeletePromise, campaignsLoadingSwitch } from './actions'
import { errorAdd } from '../error/actions'

const prefix = '/promotion'

function* campaignsGeneratorWrapper(gen) {
  yield put(campaignsLoadingSwitch(true))
  yield gen()
  yield put(campaignsLoadingSwitch(false))
}

function* campaignsDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const { type, payload } = action

    yield campaignsGeneratorWrapper(function* () {
      if (payload.type === 'clear') {
        try {
          yield call(axiosSagaRequest, 'delete', `/campaign/${payload.type}`)
          yield put({ type: `${type}_RESPONSE`, payload: { type: payload.type } })
          yield call(resolvePromiseAction, action, { type: payload.type })
        } catch (error) {
          yield put(errorAdd(error, action))
          yield call(rejectPromiseAction, action, error)
        }
      } else if (payload.type === 'selected') {
        try {
          const { data } = yield call(axiosSagaRequest, 'post', '/campaign/batch_delete', {
            ids: [...payload.id]
          })
          yield put({ type: `${type}_RESPONSE`, payload: { data, type: payload.type } })
          yield call(resolvePromiseAction, action, { data, type: payload.type })
        } catch (error) {
          yield put(errorAdd(error, action))
          yield call(rejectPromiseAction, action, error)
        }
      } else {
        try {
          yield call(axiosSagaRequest, 'delete', `${prefix}/${payload.id}`)
          yield put({ type: `${type}_RESPONSE`, payload: payload.id })
          yield call(resolvePromiseAction, action, payload.id)
        } catch (error) {
          yield put(errorAdd(error, action))
          yield call(rejectPromiseAction, action, error)
        }
      }
    })
  })
}

export default function* campaignsWatcherSaga() {
  yield takeLatest(CAMPAIGNS_CALL, axiosGetGenerator(prefix))
  yield takeEvery(campaignsDeletePromise, campaignsDeletePromiseGenerator)
}
