import React, { memo, useCallback, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../icon'
import { useTranslation } from 'react-i18next'


/**
 * Snackbar hook
 * @param {String} message - Snackbar text or html content
 * @param {String} variant - Snackbar Variant ['default', 'info', 'success', 'warning', 'danger']
 * @param {Number} duration - Duration how long snackbar is visible
 * @param {Function} removeSnack - Snackbar remove function
 * @param {Boolean} withoutIcon - Snackbar icon state
 * @param {Boolean} withoutClose - Snackbar close button state
 */

const icons = {
  info: 'information',
  success: 'checkmark',
  warning: 'information',
  danger: 'Exclamation-Triangle'
}

const Snackbar = memo(({ message, variant, duration, removeSnack, withoutIcon, withoutClose }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()

  const icon = icons[variant]

  const handleClose = useCallback(() => {
    setIsVisible(false)
    setTimeout(() => {
      removeSnack && removeSnack()
    }, 600)
  }, [removeSnack])

  useLayoutEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 300)

    if (duration !== 0) {
      setTimeout(() => {
        handleClose()
      }, duration)
    }
  }, [])

  return (
    <div
      className={classNames('snackbar', `snackbar--${variant}`, {
        'snackbar--visible': isVisible
      })}
    >
      <div className="snackbar__body">
        {!withoutIcon && icon && (
          <div className="snackbar__body__icon">
            <Icon iconClass={icon} type="light" size="20px" />
          </div>
        )}
        {message && <span className="snackbar__body__content">{message}</span>}
      </div>
      {!withoutClose && removeSnack && (
        <button
          className="snackbar__close"
          type="button"
          title={t('Close Snackbar')}
          onClick={handleClose}
        >
          <Icon iconClass="close" type="light" size="9px" />
        </button>
      )}
    </div>
  )
})

Snackbar.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(['neutral', 'info', 'success', 'warning', 'danger']),
  duration: PropTypes.number,
  removeSnack: PropTypes.func.isRequired,
  withoutIcon: PropTypes.bool,
  withoutClose: PropTypes.bool
}

Snackbar.defaultProps = {
  message: '',
  variant: 'neutral',
  duration: 3000,
  withoutIcon: false,
  withoutClose: false
}

export default Snackbar
Snackbar.displayName = 'Snackbar'
