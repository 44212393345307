import { useRef, useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import axiosInstance from 'clients/api'

const useAxios = (method, url, data = {}, options = {}) => {
  const axiosSourceRef = useRef(null)
  const [state, setState] = useState({
    data: undefined,
    isLoading: false,
    isError: null
  })

  const request = useCallback(() => {
    const httpMethod = method.toLowerCase()

    const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0
    const settings = hasData ? options : data

    axiosSourceRef.current = axios.CancelToken.source()
    settings.cancelToken = axiosSourceRef.current.token

    return hasData
      ? axiosInstance[httpMethod](url, data, settings)
      : axiosInstance[httpMethod](url, settings)
  }, [method, url, data, options])

  const execute = useCallback(() => {
    setState({
      data: undefined,
      isLoading: true,
      isError: null
    })

    if (axiosSourceRef.current) {
      axiosSourceRef.current.cancel()
    }

    axiosSourceRef.current = axios.CancelToken.source()

    request()
      .then(({ data: responseData }) => {
        setState({
          data: responseData,
          isLoading: false
        })
      })
      .catch((error) => {
        setState({
          isLoading: false,
          isError: true
        })
      })
  }, [request])

  const cancelRequest = useCallback(() => {
    axiosSourceRef.current.cancel()
  }, [])

  useEffect(
    () => () => {
      if (method.toLowerCase() === 'get' && axiosSourceRef.current) {
        axiosSourceRef.current.cancel()
      }
    },
    []
  )

  return [state, execute, cancelRequest]
}

export default useAxios
