import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Loader component
 * @param {Boolean} fixed - will have fixed position, relative otherwise
 * @param {Boolean} linear - Linear look at the bottom, circle otherwise
 */

const Loader = memo(({ fixed, linear, absolute }) => (
  <>
    <div className="loader__backdrop" />

    {linear ? (
      <div className={classNames('loader-linear', { 'loader-linear--absolute': absolute })}>
        <div className="loader-linear-inner" />
      </div>
    ) : (
      <div className={classNames('loader', { 'loader--fixed': fixed })}>
        <div className="loader__icon" />
      </div>
    )}
  </>
))

Loader.propTypes = {
  fixed: PropTypes.bool,
  linear: PropTypes.bool,
  absolute: PropTypes.bool
}

Loader.defaultProps = {
  fixed: false,
  linear: false,
  absolute: false
}

export default Loader
Loader.displayName = 'Loader'
