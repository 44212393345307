/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-duplicates */
import React, { useCallback, useState } from 'react'
import { generateImageUrl } from 'utils/helpers'
import { isEqual } from 'lodash'
import _ from 'lodash'
import { axiosRequest } from 'clients/api'
import emptyImage from 'assets/images/empty.png'
import { useTranslation } from 'react-i18next'
import {
  Box,
  GridList,
  Loader,
  Dialog,
  Image,
  // useTranslation,
  Icon,
  Typography,
  useSnackbar,
  Field
} from 'components/common'

const PriceLevelException = ({
  isOpen,
  close,
  moduleName,
  accountId,
  groups,
  priceLevelExceptions,
  setPriceLevelExceptions
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const addSnackbar = useSnackbar()

  const [sections, setSections] = useState(null)
  const [brands, setBrands] = useState(null)
  const [priceLevels, setPriceLevels] = useState(null)

  const [selectedGroupID, setSelectedGroupID] = useState(null)
  const [selectedSectionID, setSelectedSectionID] = useState(null)
  const [selectedBrandID, setSelectedBrandID] = useState(null)

  const { t, i18n } = useTranslation()

  const handleToggleGroup = useCallback((group_id) => {
    setIsLoading(true)

    setSelectedGroupID(group_id)
    setSelectedSectionID(null)
    setSelectedBrandID(null)

    setSections(null)
    setBrands(null)
    setPriceLevels(null)

    axiosRequest('get', '/account/pricing', {
      params: { group_id }
    })
      .then(({ data }) => {
        setSections(data.sections)
      })
      .catch((error) => {
        addSnackbar({
          variant: 'danger',
          message: t('Something goes wrong.')
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleToggleSection = useCallback((group_id, section_id) => {
    setIsLoading(true)

    setSelectedSectionID(section_id)
    setSelectedBrandID(null)

    setBrands(null)
    setPriceLevels(null)

    axiosRequest('get', '/account/pricing', {
      params: { group_id, section_id }
    })
      .then(({ data }) => {
        setBrands(data.brands)
      })
      .catch((error) => {
        addSnackbar({
          variant: 'danger',
          message: t('Something goes wrong.')
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleToggleBrand = useCallback((group_id, section_id, brand_id) => {
    setIsLoading(true)

    setSelectedBrandID(brand_id)

    setPriceLevels(null)

    axiosRequest('get', '/account/pricing', {
      params: { group_id, section_id, brand_id }
    })
      .then(({ data }) => {
        setPriceLevels(data.price_levels)
      })
      .catch((error) => {
        addSnackbar({
          variant: 'danger',
          message: t('Something goes wrong.')
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const getGroupSectionName = (data) => {
    return JSON.parse(data).find((name) => name[i18n.language])[i18n.language]
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="xl"
      alignment="center"
      title={t('Price Level Exceptions')}
      disableBackdropClick
      className="pricing-levels-dialog"
      actions={[
        {
          text: t('Cancel'),
          variant: 'tertiary',
          onClick: () => {
            close()
          }
        },
        {
          text: t('Save Exceptions'),
          onClick: close
        }
      ]}
    >
      {isLoading && <Loader linear />}
      <div className="price-level-account container pt-lg pb-lg px-lg">
        <GridList gap="xs" padding="p-0" columns={{ md: 4 }}>
          <Box title="Groups" height={358}>
            <div className="card-list__container card-list__container--checkbox-list card-list__container--checkbox-list-wrapper px-xs">
              {groups?.map((group, index) => (
                <button
                  type="button"
                  className="checkbox-option pl-sm pr-xs my-xs cursor-pointer"
                  key={`group-${group.id}-${index}`}
                  onClick={() => handleToggleGroup(group.id)}
                >
                  <Image src={group.image ?? emptyImage} style={{ width: 30 }} />
                  <Typography variant="subtitle1" aling="left" className="">
                    {getGroupSectionName(group.name)}
                  </Typography>
                  {selectedGroupID && parseInt(selectedGroupID) === parseInt(group.id) ? (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() => handleToggleGroup(group.id)}
                      className="cursor-pointer isopened"
                    />
                  ) : (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() => handleToggleGroup(group.id)}
                      className="cursor-pointer"
                    />
                  )}
                </button>
              ))}
            </div>
          </Box>
          <Box title="Sections">
            <div className="card-list__container card-list__container--checkbox-list card-list__container--checkbox-list-wrapper px-xs">
              {sections?.map((section, index) => (
                <button
                  type="button"
                  className="checkbox-option pl-sm pr-xs my-xs cursor-pointer"
                  key={`section-${section.id}-${index}`}
                  onClick={() => handleToggleSection(selectedGroupID, section.id)}
                >
                  <Image src={section.image ?? emptyImage} style={{ width: 30 }} />
                  <Typography variant="subtitle1" aling="left" className="w-full px-md">
                    {getGroupSectionName(section.name)}
                  </Typography>
                  {selectedSectionID && parseInt(selectedSectionID) === parseInt(section.id) ? (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() => handleToggleSection(selectedGroupID, section.id)}
                      className="cursor-pointer isopened"
                    />
                  ) : (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() => handleToggleSection(selectedGroupID, section.id)}
                      className="cursor-pointer"
                    />
                  )}
                </button>
              ))}
            </div>
          </Box>
          <Box title="Brands">
            <div className="card-list__container card-list__container--checkbox-list card-list__container--checkbox-list-wrapper px-xs">
              {brands?.map((brand, index) => (
                <button
                  type="button"
                  className="checkbox-option pl-sm pr-xs my-xs cursor-pointer"
                  key={`brand-${brand.id}-${index}`}
                  onClick={() => handleToggleBrand(selectedGroupID, selectedSectionID, brand.id)}
                >
                  <Image src={brand.image ?? emptyImage} style={{ width: 30 }} />
                  <Typography variant="subtitle1" aling="left" className="w-full px-md">
                    {brand.name}
                  </Typography>
                  {selectedBrandID && parseInt(selectedBrandID) === parseInt(brand.id) ? (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() =>
                        handleToggleBrand(selectedGroupID, selectedSectionID, brand.id)
                      }
                      className="cursor-pointer isopened"
                    />
                  ) : (
                    <Icon
                      iconClass="arrow-right"
                      type="dark"
                      size="12px"
                      onClick={() =>
                        handleToggleBrand(selectedGroupID, selectedSectionID, brand.id)
                      }
                      className="cursor-pointer"
                    />
                  )}
                </button>
              ))}
            </div>
          </Box>
          <Box title="Price Levels">
            <div className="card-list__container card-list__container--checkbox-list card-list__container--checkbox-list-wrapper px-xs">
              {priceLevels?.map((priceLevel, index) => (
                <button
                  type="button"
                  className="checkbox-option pl-sm pr-xs my-xs"
                  key={`price-level-${index}-${priceLevel.id}`}
                >
                  <Typography variant="subtitle1" aling="left" className="w-full px-md">
                    {priceLevel.name}
                  </Typography>
                  <Field
                    checked={priceLevelExceptions.some(
                      (pl) =>
                        pl.brand_id === selectedBrandID &&
                        pl.section_id === selectedSectionID &&
                        pl.price_level_id === priceLevel.id
                    )}
                    name={`price_level_exceptions.${selectedSectionID}.${selectedBrandID}.${priceLevel.id}`}
                    type="checkbox"
                    label=""
                    onChange={(e) => {
                      const { checked } = e.target

                      setPriceLevelExceptions((prev) => {
                        if (checked) {
                          // Find and remove the previously checked item, if any
                          const filteredPrev = prev.filter(
                            (pl) =>
                              !(
                                pl.brand_id === selectedBrandID &&
                                pl.section_id === selectedSectionID
                              )
                          )

                          return [
                            ...filteredPrev,
                            {
                              brand_id: selectedBrandID,
                              section_id: selectedSectionID,
                              price_level_id: priceLevel.id,
                              brand: brands.find((br) => br.id === selectedBrandID),
                              price_level: priceLevels.find((pl) => pl.id === priceLevel.id),
                              section: sections.find((sc) => sc.id === selectedSectionID)
                            }
                          ]
                        }

                        return prev.filter(
                          (pl) =>
                            !(
                              pl.brand_id === selectedBrandID &&
                              pl.section_id === selectedSectionID &&
                              pl.price_level_id === priceLevel.id
                            )
                        )
                      })
                    }}
                  />
                </button>
              ))}
            </div>
          </Box>
        </GridList>
      </div>
    </Dialog>
  )
}

export default PriceLevelException
