import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useDidUpdate, useSearch } from 'hooks'
import { appendObjectToFormData } from 'utils/helpers'
import {
  Divider,
  Button,
  EmptyContent,
  GridList,
  Box,
  useSnackbar,
  Loader
} from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  commonUserAddPromise,
  commonUserDeletePromise,
  commonUserActionInStepPromise,
  commonUserStatusPromise,
  commonUserEditPromise
} from 'store/common/users/actions'
import { commonUsersSelector, commonUserSelector } from 'store/common/users/selectors'
import { usePermission } from 'components/common/permission'
import { initSettingsAdditionalSelector } from 'store/general/selectors'
import { ConfirmationDialog } from 'components/complex'
import UsersListCard from './user-list-card'
import UserAddEdit from './user-add-edit'

const UsersSection = ({ title, moduleName, accountId, isStep }) => {
  const dispatch = useDispatch()
  const addSnackbar = useSnackbar()
  const search = useSearch()
  const { t } = useTranslation()

  const { data, inStepData } = useSelector(
    (store) => commonUsersSelector(store, moduleName),
    isEqual
  )
  const { isLoading } = useSelector((store) => commonUserSelector(store, moduleName), isEqual)
  const [users, setUsers] = useState((isStep ? inStepData : data) || [])

  const [action, setAction] = useState(null)

  const { status } = useSelector(initSettingsAdditionalSelector, isEqual)

  const { filteredList: filteredUsersList, SearchBar } = search(
    users,
    isStep
      ? [
          {
            type: 'text',
            keyName: 'user_detail.full_name',
            condition: 'contains',
            placeholder: t('Search...')
          }
        ]
      : [
          {
            type: 'text',
            keyName: 'user_detail.full_name',
            condition: 'contains',
            placeholder: t('Search...')
          },
          {
            type: 'select',
            keyName: 'status_id',
            condition: 'equals',
            placeholder: t('All Statuses'),
            options: [
              {
                value: 0,
                label: t('All Statuses')
              },
              ...status
                .filter((s) => s.id !== 4 && s.id !== 3)
                .map((s) => ({
                  value: s.id,
                  label: s.name
                }))
            ]
          }
        ]
  )

  const findUserById = useCallback(
    (userId) => {
      if (userId && userId !== 'new') {
        return users.find((c) => c.id === userId)
      }
      return null
    },
    [users]
  )

  // basic create (no step)
  const createUser = useCallback(
    async (userData) => {
      try {
        await dispatch(
          commonUserAddPromise({
            moduleName,
            userData
          })
        )

        setAction('')
        addSnackbar({
          variant: 'success',
          message: 'User created.'
        })
      } catch (error) {
        console.error(error)
      }
    },
    [moduleName]
  )

  // basic edit (no step)
  const editUser = useCallback(
    async (userData, userId) => {
      try {
        await dispatch(
          commonUserEditPromise({
            moduleName,
            userId,
            userData
          })
        )

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: 'User edited.'
        })
      } catch (error) {
        console.error(error)
      }
    },
    [moduleName]
  )

  // basic delete (no step)
  const deleteUser = useCallback(
    async (userId) => {
      try {
        await dispatch(
          commonUserDeletePromise({
            moduleName,
            userId
          })
        )

        addSnackbar({
          variant: 'success',
          message: 'User deleted.'
        })
      } catch (error) {
        console.error(error)
      }
    },
    [moduleName]
  )

  const findUserByIndex = useCallback(
    (IndexAndMethod) => {
      if (IndexAndMethod && IndexAndMethod !== 'new') {
        return { ...users[IndexAndMethod.index], ...IndexAndMethod }
      }
      return null
    },
    [users]
  )

  // create/edit/delete when step
  const userActionOnStep = useCallback(
    async (user, indexAndMethod) => {
      try {
        await dispatch(
          commonUserActionInStepPromise({
            moduleName,
            formData: user,
            indexAndMethod
          })
        )

        setAction(null)
        addSnackbar({
          variant: 'success',
          message: 'Changes saved.'
        })
      } catch (error) {
        // console.error(error)
      }
    },
    [moduleName]
  )

  const changeUserStatus = useCallback(
    async (userId, statusData) => {
      try {
        await dispatch(
          commonUserStatusPromise({
            moduleName,
            userId,
            statusData
          })
        )

        addSnackbar({
          variant: 'success',
          message: 'Status changed.'
        })
      } catch (error) {
        console.error(error)
      }
    },
    [moduleName]
  )

  const handleUserAction = useCallback(
    (actionType) => async (indexOrId) => {
      switch (actionType) {
        case 'edit': {
          isStep ? setAction({ index: indexOrId, method: 'update' }) : setAction(indexOrId)
          break
        }
        case 'activate': {
          setAction({ actionType, id: indexOrId })
          // await changeUserStatus(indexOrId, { id: 1, name: 'Active' })
          break
        }
        case 'deactivate': {
          setAction({ actionType, id: indexOrId })
          // await changeUserStatus(indexOrId, { id: 2, name: 'Inactive' })
          break
        }
        case 'delete': {
          setAction({ actionType, id: indexOrId })
          // isStep
          //   ? await userActionOnStep(
          //       appendObjectToFormData({ index: String(indexOrId), method: 'delete' }),
          //       { index: indexOrId, method: 'delete' }
          //     )
          //   : await deleteUser(indexOrId)
          break
        }
        case 'restore': {
          setAction({ actionType, id: indexOrId })
          // await changeUserStatus(indexOrId, { name: 'restore' })
          break
        }
        default:
          break
      }
    },
    []
  )

  // Update contact list from store
  useDidUpdate(() => {
    setUsers(isStep ? inStepData : data)
  }, [isStep ? inStepData : data])

  const actionTypeStatuses = {
    activate: (indexOrId) => changeUserStatus(indexOrId, { id: 1, name: 'Active' }),
    deactivate: (indexOrId) => changeUserStatus(indexOrId, { id: 2, name: 'Inactive' }),
    restore: (indexOrId) => changeUserStatus(indexOrId, { name: 'restore' }),
    delete: (indexOrId) => {
      isStep
        ? userActionOnStep(appendObjectToFormData({ index: String(indexOrId), method: 'delete' }), {
            index: indexOrId,
            method: 'delete'
          })
        : deleteUser(indexOrId)
    }
  }
  const canCompanyAccountUserManage = usePermission('company_account_user-manage')

  return (
    <>
      {!action && isLoading && <Loader fixed />}
      {SearchBar}
      <Divider className="mb-sm" />
      <Box
        title={title}
        rightSection={
          canCompanyAccountUserManage && (
            <Button variant="link" onClick={() => setAction('new')}>
              + Add New User
            </Button>
          )
        }
      >
        <div className="card-list__container">
          {users.length === 0 ? (
            <EmptyContent
              title="This list of users is empty"
              description='Click "+ Add New User" at the top right to add user to this list'
            />
          ) : filteredUsersList.length === 0 ? (
            <EmptyContent title="No users found" description="Try something else." />
          ) : (
            <GridList columns={{ md: 2, lg: 3, xl: 4 }} padding="px-0 py-xs" gap="sm">
              {filteredUsersList.map((user, index) => (
                <UsersListCard
                  key={user.id || index}
                  data={user.id ? user : { ...user, index }}
                  firstCardId={users[0].id}
                  onAction={handleUserAction}
                  isStep={isStep}
                  moduleName={moduleName}
                />
              ))}
            </GridList>
          )}
        </div>
      </Box>
      {Boolean(action) &&
        ((Boolean(['activate', 'deactivate', 'restore', 'delete'].includes(action.actionType)) && (
          <ConfirmationDialog
            isOpen={Boolean(action.id)}
            close={() => setAction(null)}
            type={action.actionType}
            typeAdditional="User"
            onConfirm={actionTypeStatuses[action.actionType]}
            action={action}
          />
        )) || (
          <UserAddEdit
            moduleName={moduleName}
            accountId={accountId}
            isOpen={Boolean(action)}
            close={() => setAction(null)}
            userData={isStep ? findUserByIndex(action) : findUserById(action)}
            createAction={isStep ? userActionOnStep : createUser}
            editAction={isStep ? userActionOnStep : editUser}
            isLoading={isLoading}
            isStep={isStep}
          />
        ))}
    </>
  )
}

UsersSection.propTypes = {
  title: PropTypes.string,
  moduleName: PropTypes.string.isRequired,
  isStep: PropTypes.bool
}

UsersSection.defaultProps = {
  title: 'Users',
  isStep: undefined
}

export default UsersSection
