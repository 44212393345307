// hooks
import { useMemo } from 'react'
import { useTable, useFlexLayout, useRowSelect } from 'react-table'

// utils
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

// components
import { Button, Icon, Pagination } from 'components/common'
import IndeterminateCheckbox from './IndeterminateCheckbox'

const Table = ({
  columns,
  data,
  defaultColumn,
  className,
  hasSelection,
  hasActions,
  hideEdit,
  hideDelete,
  showFooter,
  handleDelete,
  handleEdit,
  handleRowSelect,
  handleMasterRowSelect,
  hasPagination,
  pagesCount,
  setActivePage
}) => {
  const { t } = useTranslation()

  const actionColumnWidth = useMemo(() => {
    const actionColumnDefaultWidth = 120
    const hiddenEditActionColumnWidth = 35
    const hiddenDeleteActionColumnWidth = 90

    if (hideEdit) {
      return hiddenEditActionColumnWidth
    }
    if (hideDelete) {
      return hiddenDeleteActionColumnWidth
    }
    return actionColumnDefaultWidth
  }, [hideEdit, hideDelete])

  const handleEditClick = (row) => (typeof handleEdit === 'function' ? handleEdit(row) : null)

  const handleDeleteClick = (row) => (typeof handleDelete === 'function' ? handleDelete(row) : null)

  const handleMasterRowCheck = async (allRows) => {
    if (typeof handleMasterRowCheck === 'function') {
      const isAllRowSelected = await allRows.every((row) => row.isSelected)
      handleMasterRowSelect(!isAllRowSelected)
    }
  }

  const handleRowCheck = (row) => {
    if (typeof handleMasterRowCheck === 'function') {
      handleRowSelect(row.original, !row.isSelected)
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn
      },
      useFlexLayout,
      useRowSelect,
      (hooks) => {
        hooks.allColumns.push((allColumns) => {
          if (hasSelection) {
            allColumns.push({
              id: 'selection',
              minWidth: 20,
              width: 20,
              maxWidth: 20,
              Header: ({ getToggleAllRowsSelectedProps, rows: allRows }) => (
                <IndeterminateCheckbox
                  {...getToggleAllRowsSelectedProps()}
                  className="table__cell__intermediate"
                  onClick={() => handleMasterRowCheck(allRows)}
                />
              ),
              Cell: ({ row }) => (
                <IndeterminateCheckbox
                  // eslint-disable-next-line react/destructuring-assignment
                  {...row.getToggleRowSelectedProps()}
                  className="table__cell__intermediate"
                  onClick={() => handleRowCheck(row)}
                />
              )
            })
          }
          if (hasActions) {
            allColumns.push({
              id: 'actions',
              minWidth: actionColumnWidth,
              width: actionColumnWidth,
              maxWidth: actionColumnWidth,
              Header: () => <span />,
              Cell: ({ row }) => (
                <div className="flex flex-justify-center">
                  {!hideEdit && (
                    <Button
                      className={classNames('px-xxl', { 'mr-md': !hideDelete })}
                      onClick={() => handleEditClick(row)}
                    >
                      {t('Edit')}
                    </Button>
                  )}
                  {!hideDelete && (
                    <Button
                      color="danger"
                      isIcon
                      className="px-xs"
                      onClick={() => handleDeleteClick(row)}
                    >
                      <Icon iconClass="trash-delete" type="light" size="16px" />
                    </Button>
                  )}
                </div>
              )
            })
          }
          return allColumns
        })
      }
    )

  const showTableContent = () => {
    if (data.length) {
      return (
        <div {...getTableProps()} className={classNames('table', className)}>
          <div className="table__head">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} key={uuidv4()} className="table__row">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} key={uuidv4()} className="table__cell">
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="table__body">
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <div {...row.getRowProps()} key={uuidv4()} className="table__row">
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        key={uuidv4()}
                        className="table__cell"
                        style={{
                          backgroundColor: cell.row.original.background_color || 'white',
                          boxSizing: 'border-box',
                          flex: `${cell.column.width} 0 auto`,
                          width: cell.column.width
                        }}
                      >
                        <span className="flex flex-align-center h-full">{cell.render('Cell')}</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          {!!showFooter && (
            <div className="table__footer">
              {footerGroups.map((footerGroup) => (
                <div
                  key={uuidv4()}
                  {...footerGroup.getFooterGroupProps()}
                  className="table__footer__row"
                >
                  {footerGroup.headers.map((column) => (
                    <div key={uuidv4()} {...column.getFooterProps()} className="table__cell">
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
    return <span>Loading</span>
  }

  return (
    <>
      {showTableContent()}
      {hasPagination && pagesCount > 1 && (
        <Pagination
          pagesCount={pagesCount}
          setActivePage={setActivePage}
          className="mt-md ml-auto"
        />
      )}
    </>
  )
}

export default Table
