import { Routes, Route, Navigate } from 'react-router-dom'
import {
  DashboardController,
  AccountController,
  PricingController,
  MarketingToolsController,
  SettingsController,
  NotificationsController
} from './controllers'
import Wrapper from './wrapper'

const AdminModule = () => {
  return (
    <Routes>
      <Route element={<Wrapper />}>
        <Route index element={<Navigate to={DashboardController.path} />} />
        <Route path={`${DashboardController.path}/*`} element={DashboardController.Component} />
        <Route path={`${AccountController.path}/*`} element={AccountController.Component} />
        <Route path={`${PricingController.path}/*`} element={PricingController.Component} />
        <Route
          path={`${MarketingToolsController.path}/*`}
          element={MarketingToolsController.Component}
        />
        <Route path={`${SettingsController.path}/*`} element={SettingsController.Component} />
        <Route
          path={`${NotificationsController.path}/*`}
          element={NotificationsController.Component}
        />
      </Route>
    </Routes>
  )
}

export default AdminModule
