import React from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { initSettingsModulePermissionsSelector } from 'store/general/selectors'
import { Button, EmptyContent } from 'components/common'
import { useTranslation } from 'react-i18next'
import { userDataSelector } from 'store/user/selectors'

export default function PermissionWrapper({
  module,
  permissionName,
  component: Component,
  children
}) {
  const { user } = useSelector(userDataSelector)

  const permissions = user?.roles[0]?.permissions

  const history = useNavigate()
  const { t } = useTranslation()

  let isAllowed = permissions.some((permission) => permission.name === permissionName)
  if (permissionName === 'branch-view') {
    isAllowed =
      permissions.some((permission) => permission.name === permissionName) ||
      permissions.some((permission) => permission.name === 'own_branch-view')
        ? true
        : false
  }
  if (permissionName === 'branch-manage') {
    isAllowed =
      permissions.some((permission) => permission.name === permissionName) ||
      permissions.some((permission) => permission.name === 'own_branch-manage')
        ? true
        : false
  }

  const handleBackButton = () => {
    history(-1)
  }

  return (
    <>
      {isAllowed ? (
        Component ? (
          <Component />
        ) : (
          children
        )
      ) : (
        <EmptyContent
          title={t('Access Denied')}
          description={t('You do not have permission to access this page')}
        >
          <div className="flex flex-align-center flex-justify-space-between mb-md mt-md">
            <Button variant="primary" size="medium" onClick={handleBackButton}>
              {t('Go Back')}
            </Button>
          </div>
        </EmptyContent>
      )}
    </>
  )
}

PermissionWrapper.propTypes = {
  module: PropTypes.string.isRequired,
  permissionName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  component: PropTypes.element
}

PermissionWrapper.defaultProps = {
  permissionName: 'View',
  children: <></>,
  component: undefined
}
