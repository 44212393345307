/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  COMMON_CONTACTS_GET,
  COMMON_CONTACT_GET,
  COMMON_CONTACT_ADD_PROMISE,
  COMMON_CONTACT_ACTION_INSTEP_PROMISE,
  COMMON_CONTACT_EDIT_PROMISE,
  COMMON_CONTACT_DELETE_PROMISE,
  COMMON_CONTACT_LOADING_SWITCH,
  COMMON_CONTACT_CLEAR_STEP_DATA
} from './actions'

const initialState = {
  contacts: {
    company: {
      isFetched: false,
      data: []
    },
    supplier: {
      isFetched: false,
      data: [],
      inStepData: []
    }
  },
  contact: {
    company: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
    supplier: {
      isFetched: false,
      isLoading: false,
      data: undefined
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case COMMON_CONTACTS_GET: {
      if (payload?.moduleName) {
        const mKey = payload.moduleName
        state.contacts[mKey].isFetched = false
        state.contacts[mKey].data = []
      }
      return state
    }

    case `${COMMON_CONTACTS_GET}_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        state.contacts[mKey].isFetched = true
        state.contacts[mKey].data = data || []
      }
      return state
    }

    case COMMON_CONTACT_GET: {
      if (payload?.moduleName) {
        const mKey = payload.moduleName
        state.contact[mKey].isFetched = false
        state.contact[mKey].data = []
      }
      return state
    }

    case `${COMMON_CONTACT_GET}_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        state.contact[mKey].isFetched = true
        state.contact[mKey].data = data
      }
      return state
    }

    case `${COMMON_CONTACT_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload

        state.contacts[mKey].data.push({ ...data.contact })
      }
      return state
    }

    case `${COMMON_CONTACT_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload
        const newContact = { ...data.contact }

        state.contacts[mKey].data = state.contacts[mKey].data.map((contact) =>
          +contact.id === +data.contact.id ? newContact : contact
        )
      }
      return state
    }

    case `${COMMON_CONTACT_DELETE_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, contactId } = payload
        state.contacts[mKey].data = state.contacts[mKey].data.filter(
          (contact) => contact.id !== contactId
        )
      }
      return state
    }

    case `${COMMON_CONTACT_ACTION_INSTEP_PROMISE}.TRIGGER_RESPONSE`: {
      if (payload?.moduleName) {
        const { moduleName: mKey, data } = payload

        if (data.method) {
          const { index, method } = data
          if (method === 'update') {
            state.contacts[mKey].inStepData[index] = data.contact
          }
          if (method === 'delete') {
            state.contacts[mKey].inStepData.splice(index, 1)
          }
        } else {
          state.contacts[mKey].inStepData.push({ ...data.contact })
        }
      }
      return state
    }

    case COMMON_CONTACT_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        state.contact[mKey].isLoading =
          status !== undefined ? status : !state.contact[mKey].isLoading
      }
      return state
    }

    case COMMON_CONTACT_CLEAR_STEP_DATA: {
      if (payload?.moduleName) {
        const moduleName = payload?.moduleName;
        state.contacts[moduleName].inStepData = [];
      }
      return state
    }

    default: {
      return state
    }
  }
})
