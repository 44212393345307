import moment from 'moment'
import PropTypes from 'prop-types'

moment.relativeTimeThreshold('s', 55)
moment.relativeTimeThreshold('ss', 56)
moment.relativeTimeThreshold('m', 55)
moment.relativeTimeThreshold('h', 23)
moment.relativeTimeThreshold('d', 6)
moment.relativeTimeThreshold('w', 4)
moment.relativeTimeThreshold('M', 11)

const TimeAgo = ({ time }) => <>{moment(time, 'DD.MM.YYYY - hh:mm').fromNow()}</>

TimeAgo.propTypes = {
  time: PropTypes.string.isRequired
}

export default TimeAgo
