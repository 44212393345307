/* eslint-disable react/display-name */
import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isNumeric, stringIsFloatNumber } from 'utils/helpers'

/**
 * Badge component
 * @param {String} title - Inner text for badge
 * @param {String} type - Color of badge available options [success, warning, danger, neutral, purple, info, teal, yellow, magenta, black].
 * @param {String} cursor - Cursor style
 */

const Badge = memo(({ title, type, cursor }) => {
  const parsedTitle = useCallback((t) => {
    if (isNumeric(t)) {
      if (stringIsFloatNumber(t)) {
        const num = parseFloat(t)
        return num > 99 ? '99+' : num.toFixed(2)
      }

      const num = parseInt(t, 10)
      return num > 99 ? '99+' : num
    }
    return t
  }, [])

  return (
    <div className={`badge badge--${type}`} style={{ cursor }}>
      {parsedTitle(title)}
    </div>
  )
})

Badge.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  cursor: PropTypes.string
}

Badge.defaultProps = {
  title: 'inactive',
  cursor: 'default',
  type: 'neutral'
}

export default Badge
