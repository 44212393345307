/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/** String **/

// eslint-disable-next-line no-extend-native
String.prototype.normalizeString = function () {
  return this.replace(/_/g, ' ')
}

// eslint-disable-next-line no-extend-native
String.prototype.variablizeString = function () {
  return this.toLowerCase().replace(/ /g, '_')
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

// eslint-disable-next-line no-extend-native
String.prototype.shuffle = function () {
  const a = this.split('')
  const n = a.length

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const tmp = a[i]
    a[i] = a[j]
    a[j] = tmp
  }
  return a.join('')
}
