// eslint-disable-next-line max-classes-per-file
import { Suspense, lazy } from 'react'
import { Loader } from 'components/common'

/**
 * Load scenes while it needed(lazy loading)
 */
const SettingsMainPage = lazy(() => import('./pages/main'))
const PricingLevelsPage = lazy(() => import('./pages/pricing-levels'))
const RolesPermissionsPage = lazy(() => import('./pages/roles-permissions'))
const ProductManagementPage = lazy(() => import('./pages/product-management'))
const CatalogueManagementPage = lazy(() => import('./pages/catalogue-management'))
const CatalogueImageSliderPage = lazy(() => import('./pages/catalogue-image-slider'))
const VatRatePage = lazy(() => import('./pages/vat-rates'))

/**
 * Initialize auth regarded routes
 */
class SettingsMainController {
  static Component = (
    <Suspense fallback={<Loader linear />}>
      <SettingsMainPage />
    </Suspense>
  )
}

class PricingLevelsController {
  static path = 'pricing-levels'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <PricingLevelsPage />
    </Suspense>
  )
}

class VatRatesController {
  static path = 'vat-rates'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <VatRatePage />
    </Suspense>
  )
}

class RolesPermissionsController {
  static path = 'roles-permissions'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <RolesPermissionsPage />
    </Suspense>
  )
}

class ProductManagementController {
  static path = 'product-management'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <ProductManagementPage />
    </Suspense>
  )
}

class CatalogueManagementController {
  static path = 'catalogue-management'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CatalogueManagementPage />
    </Suspense>
  )
}

class CatalogueImageSliderController {
  static path = 'catalogue-image-slider'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CatalogueImageSliderPage />
    </Suspense>
  )
}

export {
  SettingsMainController,
  PricingLevelsController,
  RolesPermissionsController,
  ProductManagementController,
  CatalogueManagementController,
  CatalogueImageSliderController,
  VatRatesController
}
