/* eslint-disable no-param-reassign */
import produce from 'immer'
import { ERROR_ADD, ERROR_REMOVE, ERROR_TOGGLE } from './actions'

const initialState = {
  errorList: {}
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case ERROR_ADD:
      if (payload?.error && payload?.action) {
        state.errorList[payload.action] = {
          error: payload.error,
          action: payload.action,
          isVisible: true
        }
      }
      return state

    case ERROR_REMOVE:
      if (payload) {
        delete state.errorList[payload]
      }
      return state

    case ERROR_TOGGLE:
      if (payload) {
        state.errorList[payload].isVisible = !state.errorList[payload].isVisible
      }
      return state

    default:
      return state
  }
})
