/* eslint-disable func-names */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import {
  CURRENCIES_CALL,
  ALL_CURRENCIES_CALL,
  CURRENCY_RATES_CALL,
  currencyRateAddPromise,
  currencyRateEditPromise,
  currencyRateDeletePromise,
  currencyRateLoadingSwitch
} from './actions'
import { errorAdd } from '../error/actions'

const prefix = '/currency_rates'

// Loading wrapper
function* currencyRateGeneratorWrapper(gen) {
  yield put(currencyRateLoadingSwitch(true))
  yield gen()
  yield put(currencyRateLoadingSwitch(false))
}

function* currencyRateAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { currencyRateData }
    } = action

    yield currencyRateGeneratorWrapper(function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, currencyRateData)
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* currencyRateEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { currencyRateId, currencyRateData }
    } = action

    yield currencyRateGeneratorWrapper(function* () {
      try {
        const { data } = yield call(
          axiosSagaRequest,
          'patch',
          `${prefix}/${currencyRateId}`,
          currencyRateData
        )
        yield put({ type: `${type}_RESPONSE`, payload: { data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* currencyRateDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { currencyRateId }
    } = action

    yield currencyRateGeneratorWrapper(function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/${currencyRateId}`)
        yield put({ type: `${type}_RESPONSE`, payload: { currencyRateId } })
        yield call(resolvePromiseAction, action, currencyRateId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* currencyWatcherSaga() {
  yield takeLatest(CURRENCIES_CALL, axiosGetGenerator('/currencies'))
  yield takeLatest(ALL_CURRENCIES_CALL, axiosGetGenerator('/currencies?all=true'))
  yield takeLatest(CURRENCY_RATES_CALL, axiosGetGenerator(prefix))
  yield takeEvery(currencyRateAddPromise, currencyRateAddPromiseGenerator)
  yield takeEvery(currencyRateEditPromise, currencyRateEditPromiseGenerator)
  yield takeEvery(currencyRateDeletePromise, currencyRateDeletePromiseGenerator)
}
