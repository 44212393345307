import { Routes, Route, Navigate } from 'react-router-dom'
import {
  CatalogueAutomativeController,
  CatalogueNonAutomativeController,
  CartController,
  OrdersController,
  CustomerOrdersController
} from './controllers'
import Wrapper from './wrapper'

const ClientModule = () => {
  return (
    <Routes>
      <Route element={<Wrapper />}>
        <Route index element={<Navigate to={CatalogueAutomativeController.path} />} />
        <Route
          path={CatalogueAutomativeController.path}
          element={CatalogueAutomativeController.Component}
        />
        <Route
          path={CatalogueNonAutomativeController.path}
          element={CatalogueNonAutomativeController.Component}
        />
        <Route path={CartController.path} element={CartController.Component} />
        <Route path={OrdersController.path} element={OrdersController.Component} />
        <Route path={CustomerOrdersController.path} element={CustomerOrdersController.Component} />
      </Route>
    </Routes>
  )
}

export default ClientModule
