/* eslint-disable no-lonely-if */
import { errorsList } from 'constant'

export default class NetworkError {
  constructor(err) {
    const customMessageObject = errorsList[err.action.type] || ''
    this.message = ''
    this.name = customMessageObject.name ?? 'Failed'
    this.buttonName = customMessageObject.btnText || 'Retry'
    this.closeOnFail = customMessageObject.closeOnFail || false
    this.isFatal = customMessageObject.isFatal || false

    if (err.error && err.error.response) {
      if (err.error.response.data) {
        const { status, message, errors } = err.error.response.data

        if (errors) {
          if (Array.isArray(errors)) {
            this.message += errors.map((e) => `\u2022 ${e.message}`).join('\n')
          } else if (typeof errors === 'object') {
            this.message += Object.values(errors)
              .map((e) => `\u2022 ${e[0]}`)
              .join('\n')
          } else if (typeof errors === 'string') {
            this.message += errors
          }
        } else if (message) {
          this.message += message
        } else {
          this.message += `There is a server issue. Please contact support. ${
            status ? `(Code ${status})` : ''
          }`
        }
      } else {
        if (err.error.response.statusText) {
          this.message += `\n ${err.error.response.statusText}`
        }
        if (err.error.response.status) {
          this.message += `\n (Code ${err.error.response.status})`
        }
      }
    } else {
      // If no response
      if (customMessageObject.message) {
        this.message = `\n ${customMessageObject.message}`
      } else {
        this.message = `\n Unknown network error ${this.message}`
      }
    }
  }
}
