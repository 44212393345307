// eslint-disable-next-line max-classes-per-file
import { Suspense, lazy } from 'react'
import { Loader } from 'components/common'

/**
 * Load scenes while it needed(lazy loading)
 */
const DashboardScene = lazy(() => import('./pages/dashboard'))
const AccountScene = lazy(() => import('./pages/account'))
const PricingScene = lazy(() => import('./pages/pricing'))
const MarketingToolsScene = lazy(() => import('./pages/marketing-tools'))
const SettingsScene = lazy(() => import('./pages/settings'))
const NotificationsScene = lazy(() => import('./pages/notifications'))

/**
 * Initialize auth regarded routes
 */
class AdminController {
  static path = 'admin'
}

class DashboardController {
  static path = 'dashboard'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <DashboardScene />
    </Suspense>
  )
}

class AccountController {
  static path = 'account'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <AccountScene />
    </Suspense>
  )
}

class PricingController {
  static path = 'pricing'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <PricingScene />
    </Suspense>
  )
}

class MarketingToolsController {
  static path = 'marketing-tools'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <MarketingToolsScene />
    </Suspense>
  )
}

class SettingsController {
  static path = 'settings'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <SettingsScene />
    </Suspense>
  )
}
class NotificationsController {
  static path = 'notifications'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <NotificationsScene />
    </Suspense>
  )
}

export {
  AdminController,
  DashboardController,
  AccountController,
  PricingController,
  MarketingToolsController,
  SettingsController,
  NotificationsController
}
