/* eslint-disable import/prefer-default-export */

export const companyPermissionsDefaultValues = {
  'dashboard-access': false,

  'company_account-view': false,
  'company_account-manage': false,
  'company_account_contact-view': false,
  'company_account_contact-manage': false,
  'company_account_user-view': false,
  'company_account_user-manage': false,
  'company_account_charges-view': false,
  'company_account_charges-manage': false,
  'company_account_stock-view': false,
  'company_account_stock-manage': false,
  'company_account_financial-view': false,
  'company_account_financial-manage': false,

  'branch-view': false,
  'branch-manage': false,

  'customer-view': false,
  'customer-manage': false,

  'supplier-view': false,
  'supplier-manage': false,

  'marketing_tools-view': false,
  'marketing_tools-manage': false,

  'pricing-view': false,
  'pricing-manage': false,

  'currency-view': false,
  'currency-manage': false,

  'system_settings-view': false,
  'system_settings-manage': false,

  'account-mirror': false
}

export const branchPermissionsDefaultValues = {
  'dashboard-access': false,
  'company_account-view': false,
  'company_account_contact-view': false,
  'company_account_stock-view': false,
  'own_branch-view': false,
  'own_branch-manage': false,
  'customer-view': false,
  'customer-manage': false,
  'account-mirror': false
}

// export const customerPermissionsDefaultValues = {
//   'account-mirror': false
// }

export const permissionExists = (permissionName, permissionArray) =>
  permissionArray?.some((permission) => permission.name === permissionName)
