/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { Divider } from 'components/common'

const API = ({ isEdit, apis, FormField, columns = [] }) => {
  const APIFields = [
    {
      name: 'api_id',
      type: 'select',
      label: 'API Type',
      placeholder: 'Select API Type',
      required: true,
      options: apis.map(({ id, name }) => ({
        value: id,
        label: name
      }))
    }
  ]
  return (
    <>
      {APIFields.map((apiField) => (
        <div key={apiField.name} className={`stockfeed__item ${isEdit && 'mb-sm'}`}>
          {FormField({
            key: apiField.name,
            placeholder: `${!isEdit ? '...' : apiField.placeholder}`,
            disabled: !isEdit,
            ...apiField
          })}
        </div>
      ))}
      <Divider className="mt-xs mb-sm" />
      {columns &&
        columns.map((column) => (
          <div key={column.name} className={`stockfeed__item ${isEdit && 'mb-sm'}`}>
            {FormField({
              key: column.name,
              placeholder: `${!isEdit ? '...' : column.placeholder}`,
              className: `${!isEdit ? 'field-view-only' : ''}`,
              disabled: !isEdit,
              required: true,
              ...column
            })}
          </div>
        ))}
    </>
  )
}

export default (props) => {
  const { isEdit, apis, FormField, apiWatcher } = props

  const currentApi = apis.find((api) => api.id === apiWatcher)
  const columns = currentApi?.columns.map((api) => ({
    name: `api_credentials.${api.id}`,
    type: 'text',
    label: api.column,
    placeholder: '...'
  }))

  if (isEdit) {
    return <API isEdit={isEdit} apis={apis} FormField={FormField} columns={columns} />
  }

  return <API sEdit={isEdit} apis={apis} FormField={FormField} columns={columns} />
}
