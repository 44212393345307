import React from 'react'
import { Button } from 'components/common'
import { useTranslation } from 'react-i18next'

export default function Actions({ onPrint, priceVat }) {


const { t } = useTranslation()
  return (
    <div className="flex flex-align-center w-full flex-justify-space-between mt-xl">
      <Button style={{ backgroundColor: '#2569a1', borderRadius: 0 }} onClick={onPrint}>
        Print
      </Button>
      <div style={{ textAlign: 'right' }}>
        <p className="fw-bold">{t('Total')}: {priceVat}</p>
        <p>{t('All prices are excluding VAT')}</p>
      </div>
    </div>
  )
}
