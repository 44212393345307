/* eslint-disable no-underscore-dangle */
import localStorage from 'store'

const localStorageService = () => {
  const setToken = ({ _token, user }) => {
    localStorage.set('_token', _token)
    localStorage.set('user', user)
  }

  const setPreviousToken = () => {
    localStorage.set('previousToken', localStorage.get('_token'))
    localStorage.set('previousUser', localStorage.get('user'))
  }

  const setOriginalToken = ({ _token, user }) => {
    localStorage.set('originalToken', _token)
    localStorage.set('originalUser', user)
  }

  const getOriginalToken = () => {
    return {
      originalToken: localStorage.get('originalToken'),
      originalUser: localStorage.get('originalUser')
    }
  }

  const getPreviousToken = () => {
    return {
      previousToken: localStorage.get('previousToken'),
      previousUser: localStorage.get('previousUser')
    }
  }

  const getAccessToken = () => {
    return localStorage.get('_token')
  }

  const getUser = () => {
    return localStorage.get('user')
  }

  const getRefreshToken = () => {
    return localStorage.get('refresh_token')
  }

  const getLocale = () => {
    return localStorage.get('locale')
  }

  const clearToken = () => {
    localStorage.remove('_token')
    localStorage.remove('user')

    localStorage.remove('originalToken')
    localStorage.remove('originalUser')
    // localStorage.remove('refresh_token');
  }

  const resetPreviousToken = () => {
    const _token = localStorage.get('previousToken')
    const user = localStorage.get('previousUser')

    setToken({
      _token,
      user
    })

    localStorage.remove('previousToken')
    localStorage.remove('previousUser')
  }

  return {
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    getUser,
    setPreviousToken,
    resetPreviousToken,
    setOriginalToken,
    getOriginalToken,
    getLocale,
    getPreviousToken
  }
}

export default { ...localStorageService() }
