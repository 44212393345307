/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Text component
 * @param {String} tag - Text tag name
 * @param {String} variant - Text variant
 * @param {String} align - Text alignment
 * @param {Boolean} gutterBottom - Bottom margin
 * @param {Boolean} noWrap - Prevent text wrap
 * @param {String} className - Component classname
 * @param {String} children - Text
 */

const Typography = memo(
  ({ tag, variant, align, gutterBottom, noWrap, className, children, ...restProps }) => {
    return React.createElement(
      tag,
      {
        className: classNames(
          'typography',
          `typography--${variant}`,
          { [`typography__align--${align}`]: align },
          { 'typography--gutter-bottom': gutterBottom },
          { 'typography--no-wrap': noWrap },
          { [className]: className }
        ),
        ...restProps
      },
      children
    )
  }
)

Typography.propTypes = {
  tag: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'required'
  ]),
  align: PropTypes.oneOf(['center', 'right', 'left']),
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
}

Typography.defaultProps = {
  tag: 'span',
  variant: 'body1',
  align: undefined,
  gutterBottom: false,
  noWrap: false,
  className: undefined,
  children: undefined
}

export default Typography
