import React, { memo, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { fetchNotificationRequest } from 'store/notifications/actions'
import { notificationSelector } from 'store/notifications/selectors'
import { generateImageUrl } from 'utils/helpers'

import { useTranslation } from 'react-i18next'

import { Dialog, Image } from 'components/common'
import './index.css'

const NotificationPreview = memo(({ isOpen, close, id }) => {
  const dispatch = useDispatch()
  const notification = useSelector(notificationSelector)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchNotificationRequest(id))
    return () => {}
  }, [])

  return (
    <Dialog
      open={isOpen}
      close={close}
      title={notification?.title}
      actions={[
        {
          text: t('Close'),
          variant: 'tertiary'
        }
      ]}
      className="preview-media-dialog"
    >
      {notification && (
        <div className="modal-body">
          <p>
            <strong>{t('Name')}:</strong> {notification.promotion.title}
          </p>
          <p>
            <strong>{t('Date sent')}: </strong>
            {moment(notification.promotion.send_at).format('DD/MM/YYYY HH:MM')}
          </p>
          {notification.promotion?.media_library.length && (
            <div className="media-library-list">
              <ul>
                {notification.promotion?.media_library.map((mediaItem) => (
                  <li key={mediaItem.id}>
                    <Image
                      src={generateImageUrl(
                        `${mediaItem?.public_path}${mediaItem?.parent?.path}/${mediaItem?.path}`
                      )}
                      alt={mediaItem.title}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </Dialog>
  )
})

export default NotificationPreview
NotificationPreview.displayName = 'NotificationPreview'
