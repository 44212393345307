import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * This hook gives loading, loaded, error states for image
 * @param {String} src - Image source
 */

export const useImage = (src) => {
  const [isLoaded, setIsLoaded] = useState(!src)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const image = new Image()

    const handleError = () => {
      setIsError(true)
    }

    const handleLoad = () => {
      setIsLoaded(true)
      setIsError(false)
    }

    if (src) {
      setIsLoaded(false)
      setIsError(false)

      image.src = src
      image.onerror = handleError
      image.onload = handleLoad
    }

    return () => {
      image.removeEventListener('error', handleError)
      image.removeEventListener('load', handleLoad)
    }
  }, [src])

  return {
    isLoaded,
    isError
  }
}

useImage.propTypes = {
  src: PropTypes.string.isRequired
}

export default useImage
