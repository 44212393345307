export const selectSellingPrices = (state) => state.sellingPricesData.sellingPrices
export const selectSellingPrice = (state) => state.sellingPricesData.sellingPrice

export const selectCatalogueCurrency = (state) => state.sellingPricesData.catalogueCurrency

export const selectSections = (state) => state.sellingPricesData.sections
export const selectProductPrices = (state) => state.sellingPricesData.productPrices

export const selectFormData = (state) => state.sellingPricesData.formData

export const selectPriceLevels = (state) => state.sellingPricesData.priceLevels

export const selectAdvancedProductPrices = (state) => state.sellingPricesData.advancedProductPrices

export const selectPricingSections = (state) => ({
  pricingSections: state.sellingPricesData.pricingSections,
  sectionId: state.sellingPricesData.sectionId
})

export const selectBespokePrices = (state) => state.sellingPricesData.bespokePrices

export const selectShouldSave = (state) => state.sellingPricesData.shouldSave
