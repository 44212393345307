import { useState } from 'react'

const usePagination = ({ totalCount, perPage }) => {
  const [activePage, setActivePage] = useState(0)
  const pagesCount = Math.ceil(totalCount / perPage)

  const nextPage = () => {
    if (activePage < pagesCount) {
      setActivePage((curr) => curr + 1)
    }
  }

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage((curr) => curr - 1)
    }
  }

  const resetPageSize = () => {
    setActivePage(1)
  }

  return {
    pagesCount,
    activePage,
    nextPage,
    prevPage,
    setActivePage,
    resetPageSize
  }
}

export default usePagination
