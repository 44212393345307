/* eslint-disable no-case-declarations */
import localStorageService from 'utils/storage/localStorageService'
import * as actionTypes from './actions'

const initialState = {
  branches: [],
  customers: [],

  showSidebar: false,

  selectedBranch: null,
  selectedCustomer: null,

  mirroredTokenData: {
    tokenIsFetched: false,
    tokenExpiresIn: null,
    token: null,
    user: null
  },

  isRedirected: false,

  loading: false,
  error: null
}

const accountMirrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_MIRRORS_REQUEST:
    case actionTypes.FETCH_ACCOUNT_MIRROR_REQUEST:
    case actionTypes.CREATE_ACCOUNT_MIRROR_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_ACCOUNT_MIRRORS_SUCCESS:
      const { branches } = action.payload

      return {
        ...state,
        branches,
        customers: [],
        loading: false,
        error: null
      }

    case actionTypes.SELECT_BRANCH:
      return { ...state, selectedBranch: action.payload }

    case actionTypes.SELECT_CUSTOMER:
      return { ...state, selectedCustomer: action.payload }

    case actionTypes.CLEAR_BRANCHES:
      return { ...state, branches: [] }

    case actionTypes.FETCH_ACCOUNT_MIRROR_SUCCESS:
      const { customers } = action.payload

      return {
        ...state,
        customers,
        mirroredTokenData: {
          tokenIsFetched: false,
          tokenExpiresIn: null,
          token: null,
          user: null
        },
        loading: false,
        error: null
      }

    case actionTypes.CREATE_ACCOUNT_MIRROR_SUCCESS:
      const { token, user, expires_in } = action.payload

      const updatedMirroredTokenData = {
        tokenIsFetched: true,
        tokenExpiresIn: expires_in,
        token,
        user
      }

      return {
        ...state,
        mirroredTokenData: updatedMirroredTokenData,
        loading: false,
        error: null
      }

    case actionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
        loading: false,
        error: null
      }

    case actionTypes.SET_ORIGINAL_USER_TOKEN:
      const updatedOriginalUserToken = {
        token: action.payload.token,
        user: action.payload.user
      }

      return {
        ...state,
        originalTokenData: updatedOriginalUserToken,
        loading: false,
        error: null
      }

    case actionTypes.EXIT_MIRRORING:
      const originalToken = localStorageService.getOriginalToken()

      localStorageService.setToken({
        _token: originalToken.originalToken,
        user: originalToken.originalUser
      })

      localStorageService.resetPreviousToken()
      return {
        ...initialState,
        loading: false,
        error: null
      }

    case actionTypes.REDIRECT:
      localStorageService.setPreviousToken({
        _token: state.mirroredTokenData.token,
        user: state.mirroredTokenData.user
      })

      localStorageService.setToken({
        _token: state.mirroredTokenData.token,
        user: state.mirroredTokenData.user
      })

      return {
        ...state,
        isRedirected: false,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_ACCOUNT_MIRRORS_FAILURE:
    case actionTypes.FETCH_ACCOUNT_MIRROR_FAILURE:
    case actionTypes.CREATE_ACCOUNT_MIRROR_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_MIRRORING_STATE:
      return { ...initialState, originalTokenData: state.originalTokenData }

    default:
      return state
  }
}

export default accountMirrorReducer
