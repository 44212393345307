import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { clientRouteNames } from 'constant'
import { Tabs, UserDropdown } from 'components/common'
import _ from 'lodash'
import Logo from 'assets/images/logo.png'
import HeaderIcons from './header-icons'

const Header = () => {
  return (
    <header className="header">
      <NavLink to="/automotive">
        <img src={Logo} alt="Logo" />
      </NavLink>
      <Tabs type={2}>
        <Tabs.Tab title="Automotive" to={clientRouteNames.AUTOMOTIVE} />
        <Tabs.Tab title="Non-Automotive" to={clientRouteNames.NONAUTOMOTIVE} />
      </Tabs>
      <div className="header__right">
        <HeaderIcons />
        <UserDropdown />
      </div>
    </header>
  )
}

export default memo(Header)
