/* eslint-disable func-names */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  implementPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import { axiosGetGenerator } from 'utils/sagas'
import { CHARGES_CALL, chargesEditPromise, chargesLoadingSwitch } from './actions'

const prefix = '/account/charges'

// Loading wrapper
function* chargesLoadingGeneratorWrapper(moduleName, gen) {
  yield put(chargesLoadingSwitch(moduleName, true))
  yield gen()
  yield put(chargesLoadingSwitch(moduleName, false))
}

function* chargesEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { finalData }
    } = action

    yield chargesLoadingGeneratorWrapper(finalData, function* () {
      try {
        yield call(axiosSagaRequest, 'post', `${prefix}/set`, finalData)
        yield put({ type: `${type}_RESPONSE`, payload: { finalData } })
        yield call(resolvePromiseAction, action, finalData)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

export default function* chargesWatcherSaga() {
  yield takeLatest(CHARGES_CALL, axiosGetGenerator(prefix))
  yield takeLatest(chargesEditPromise, chargesEditPromiseGenerator)
}
