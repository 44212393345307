/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useMemo } from 'react'
import { Field, Icon } from 'components/common'
import { useStateComplex } from 'hooks'
import { filterArrayOfObjectsByKey } from 'utils/helpers'

const useSearch = () => (list, searchFields) => {
  const [searchState, setSearchState] = useStateComplex(
    searchFields.reduce((acc, searchField) => {
      // setting default value of select to All Statuses
      const keyNameValue = searchField.type === 'select' ? 0 : ''

      return {
        ...acc,
        [searchField.keyName]: keyNameValue
      }
    }, {})
  )

  const updateSearch = useCallback((key, value) => {
    setSearchState({
      [key]: value
    })
  }, [])

  const filteredList = useMemo(() => {
    let fList = [...list]

    searchFields.forEach(({ keyName, condition }) => {
      fList = filterArrayOfObjectsByKey(fList, keyName, searchState[keyName], condition)
    })

    return fList
  }, [list, searchState])

  const SearchBar = useMemo(
    () => (
      <div className="search-header">
        {searchFields.map(({ type, keyName, placeholder, options }) => {
          return (
            <Field
              key={keyName}
              name={keyName}
              type={type}
              // setting defaultvalue to All Statuses
              defaultValue={type === 'select' ? options.find((opt) => opt.value === 0) : ''}
              placeholder={placeholder}
              fullWidth={false}
              {...(type === 'text' && {
                className: 'search-header__text',
                startAdornment: <Icon iconClass="search" />,
                onChange: (e) => updateSearch(keyName, e.target.value)
              })}
              {...(type === 'select' && {
                className: 'search-header__select',
                options,
                onChange: (e) => updateSearch(keyName, e.value)
              })}
            />
          )
        })}
      </div>
    ),
    []
  )

  return {
    filteredList,
    SearchBar,
    searchState
  }
}

export default useSearch
