import React from 'react'
import { Button, Icon } from 'components/common'

export default function MirrorView({ onExitView, branchName, customerName }) {
  return (
    <div className="account-mirror-view-content-wrapper">
      <div className="account-mirror-container container">
        <div className="account-mirror-container-body">
          <div className="account-mirror-title-and-icon">
            <Icon iconClass="info-circle" style={{ color: 'white' }} size="24px" />

            <span className="account-mirror-title">
              {`Viewing catalogue as: ${branchName} - ${customerName}`}
            </span>
          </div>
          <div className="account-mirror-view-content-buttons">
            <Button
              style={{ backgroundColor: 'white', borderRadius: 4, color: 'black' }}
              onClick={onExitView}
            >
              Exit View
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
