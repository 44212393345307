/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  FINANCIAL_DETAILS_CALL,
  FINANCIAL_DETAILS_EDIT_PROMISE,
  FINANCIAL_DETAILS_LOADING_SWITCH
} from './actions'

const initialState = {
  company: {
    isFetched: false,
    isLoading: false,
    data: [{ type: 'primary' }, { type: 'secondary' }]
  },
  supplier: {
    isFetched: false,
    isLoading: false,
    data: [{ type: 'primary' }, { type: 'secondary' }],
    inStepData: [{ type: 'primary' }, { type: 'secondary' }]
  },
  branch: {
    isFetched: false,
    isLoading: false,
    data: [{ type: 'primary' }, { type: 'secondary' }],
    inStepData: [{ type: 'primary' }, { type: 'secondary' }]
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case FINANCIAL_DETAILS_CALL: {
      const { moduleName } = payload
      if (moduleName) {
        state[moduleName].isFetched = false
        state[moduleName].data = []
      }
      return state
    }

    case `${FINANCIAL_DETAILS_CALL}_RESPONSE`: {
      const { moduleName, data } = payload
      if (moduleName) {
        state[moduleName].isFetched = true
        state[moduleName].data = data
      }
      return state
    }

    case `${FINANCIAL_DETAILS_EDIT_PROMISE}.TRIGGER_RESPONSE`: {
      const { moduleName, finalData } = payload

      if (finalData.step) {
        const { step, ...d } = finalData

        state[moduleName].inStepData = state[moduleName].inStepData.map((item) => {
          if (item.type === d.type) {
            return d
          }

          return item
        })
      }

      if (!finalData.step) {
        state[moduleName].data = state[moduleName].data.map((item) => {
          if (item.type === finalData.type) {
            return finalData
          }

          return item
        })
      }

      return state
    }

    case FINANCIAL_DETAILS_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        state[mKey].isLoading = status !== undefined ? status : !state[mKey].isLoading
      }
      return state
    }

    default: {
      return state
    }
  }
})
