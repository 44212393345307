import axios, { CancelToken } from 'axios'
import { CANCEL } from 'redux-saga'
import { BASE_URL } from 'constant'
import localStorageService from 'utils/storage/localStorageService'
import { userLogoutSuccess } from 'store/user/actions'
import { reduxStoreReset } from 'store/general/actions'
// eslint-disable-next-line import/no-cycle
import store from '../../store'
// import { setError } from 'store/error/action';

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 25000,
  headers: {
    Authorization: localStorageService.getAccessToken() || '',
    Locale: localStorageService.getLocale() || 'en'
  },
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  (request) => {
    const token = localStorageService.getAccessToken()
    const locale = localStorageService.getLocale() || 'en'

    request.headers.Locale = locale

    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error) && error?.response) {
      if (
        error.response.status === 401 ||
        error.response.statusText.toLowerCase().includes('unauth')
      ) {
        store.dispatch(userLogoutSuccess())
        // store.dispatch(reduxStoreReset())
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance

export function axiosRequest(method, url, data = {}, options = {}) {
  const httpMethod = method.toLowerCase()

  const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0
  const settings = hasData ? options : data

  const source = CancelToken.source()
  settings.cancelToken = source.token

  const request = hasData
    ? axiosInstance[httpMethod](url, data, settings)
    : axiosInstance[httpMethod](url, settings)

  return request
}

export function axiosSagaRequest(method, url, data = {}, options = {}) {
  const httpMethod = method.toLowerCase()

  const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0
  const settings = hasData ? options : data

  const source = CancelToken.source()
  settings.cancelToken = source.token

  const request = hasData
    ? axiosInstance[httpMethod](url, data, settings)
    : axiosInstance[httpMethod](url, settings)

  request[CANCEL] = () => source.cancel()

  return request
}
