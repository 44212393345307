import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { UserDropdown } from 'components/common'

import Logo from 'assets/images/logo.png'
import HeaderIcons from './header-icons'

const Header = memo(() => {
  return (
    <header className="header">
      <NavLink to="/admin">
        <img src={Logo} alt="Logo" />
      </NavLink>
      <div className="header__right">
        <HeaderIcons />
        <UserDropdown />
      </div>
    </header>
  )
})

export default Header
Header.displayName = 'Header'
