import React, { useLayoutEffect, useState } from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { errorSelectorByIds } from 'store/error/selector'
import { errorRemove } from 'store/error/actions'
import { NetworkError } from 'utils/models'
import { Button, EmptyContent } from 'components/common'
import { useTranslation } from 'react-i18next'


export default function ErrorWrapper({
  listenToErrors,
  customErrorScreen,
  errorScreenContainerClass,
  children,
  showError
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const error = useSelector((store) => errorSelectorByIds(store, listenToErrors), isEqual)
  const [popupData, setPopupData] = useState({})

  useLayoutEffect(() => {
    if (error) {
      const processedError = new NetworkError(error)
      setPopupData(processedError)
    }
  }, [error])

  const handleButtonAction = () => {
    dispatch(errorRemove(error.action.type))

    if (error.action) {
      if (typeof error.action === 'function') {
        dispatch(error.action())
      } else {
        dispatch(error.action)
      }
    }
  }

  return (
    <>
      {error
        ? showError &&
          (customErrorScreen ? (
            <div className={errorScreenContainerClass || 'error-wrapper'}>
              {customErrorScreen}
              <div>
                {error.action && (
                  <Button variant="primary" size="medium" onClick={handleButtonAction}>
                    {popupData.buttonName}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <EmptyContent
              title={popupData?.message ? t('Attention') : t('Connection problem')}
              description={
                popupData?.message ||
                t('Slow or no internet connection. Refresh the page or check your connection.')
              }
            >
              <div className="error-wrapper__content">
                {error.action && (
                  <Button variant="primary" size="medium" onClick={handleButtonAction}>
                    {popupData.buttonName}
                  </Button>
                )}
              </div>
            </EmptyContent>
          ))
        : children}
    </>
  )
}

ErrorWrapper.propTypes = {
  listenToErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ]),
  customErrorScreen: PropTypes.node,
  errorScreenContainerClass: PropTypes.string,
  showError: PropTypes.bool
}

ErrorWrapper.defaultProps = {
  customErrorScreen: undefined,
  errorScreenContainerClass: '',
  children: <></>,
  showError: true
}
