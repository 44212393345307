/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-case-declarations */
import * as actionTypes from './actions'

const initialState = {
  orders: null,
  order: null,

  loading: false,
  error: null
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_REQUEST:
    case actionTypes.FETCH_ORDER_REQUEST:
    case actionTypes.UPDATE_ORDER_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_ORDERS_SUCCESS:
      const { orders } = action.payload

      return {
        ...state,
        orders,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_ORDER_SUCCESS:
      const updatedOrder = action.payload.order

      const orderIndex = state.orders.data.findIndex((order) => order.id === updatedOrder.id)

      if (orderIndex !== -1) {
        const updatedOrdersData = state.orders.data.map((order, index) => {
          if (index === orderIndex) {
            return {
              ...order,
              is_completed: updatedOrder.is_completed
            }
          } else {
            return order
          }
        })

        return {
          ...state,
          orders: {
            ...state.orders,
            data: updatedOrdersData
          },
          loading: false,
          error: null
        }
      }

      return {
        ...state,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_ORDERS_FAILURE:
    case actionTypes.FETCH_ORDER_FAILURE:
    case actionTypes.UPDATE_ORDER_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default ordersReducer
