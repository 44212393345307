export const FETCH_ACCOUNT_MIRRORS_REQUEST = 'FETCH_ACCOUNT_MIRRORS_REQUEST'
export const FETCH_ACCOUNT_MIRRORS_SUCCESS = 'FETCH_ACCOUNT_MIRRORS_SUCCESS'
export const FETCH_ACCOUNT_MIRRORS_FAILURE = 'FETCH_ACCOUNT_MIRRORS_FAILURE'

export const FETCH_ACCOUNT_MIRROR_REQUEST = 'FETCH_ACCOUNT_MIRROR_REQUET'
export const FETCH_ACCOUNT_MIRROR_SUCCESS = ' FETCH_ACCOUNT_MIRROR_SUCCESS'
export const FETCH_ACCOUNT_MIRROR_FAILURE = 'FETCH_ACCOUNT_MIRROR_FAIURE'

export const CREATE_ACCOUNT_MIRROR_REQUEST = 'CREATE_ACCOUNT_MIRROR_REQUEST'
export const CREATE_ACCOUNT_MIRROR_SUCCESS = 'CREATE_ACCOUNT_MIRROR_SUCCESS'
export const CREATE_ACCOUNT_MIRROR_FAILURE = 'CREATE_ACCOUNT_MIRROR_FAILURE'

export const CLEAR_MIRRORING_STATE = 'CLEAR_MIRRORING_STATE'
export const CLEAR_BRANCHES = 'CLEAR_BRANCHES'

export const REDIRECT = 'REDIRECT'
export const EXIT_MIRRORING = 'EXIT_MIRRORING'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const SELECT_BRANCH = 'SELECT_BRANCH'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'

export const SET_ORIGINAL_USER_TOKEN = 'SET_ORIGINAL_USER_TOKEN'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchAccountMirrorsRequest = createAction(FETCH_ACCOUNT_MIRRORS_REQUEST)
export const fetchAccountMirrorsSuccess = createAction(FETCH_ACCOUNT_MIRRORS_SUCCESS)
export const fetchAccountMirrorsFailure = createAction(FETCH_ACCOUNT_MIRRORS_FAILURE)

export const fetchAccountMirrorRequest = createAction(FETCH_ACCOUNT_MIRROR_REQUEST)
export const fetchAccountMirrorSuccess = createAction(FETCH_ACCOUNT_MIRROR_SUCCESS)
export const fetchAccountMirrorFailure = createAction(FETCH_ACCOUNT_MIRROR_FAILURE)

export const createAccountMirrorRequest = createAction(CREATE_ACCOUNT_MIRROR_REQUEST)
export const createAccountMirrorSuccess = createAction(CREATE_ACCOUNT_MIRROR_SUCCESS)
export const createAccountMirrorFailure = createAction(CREATE_ACCOUNT_MIRROR_FAILURE)

export const clearMirroringState = createAction(CLEAR_MIRRORING_STATE)
export const clearBranches = createAction(CLEAR_BRANCHES)
export const redirect = createAction(REDIRECT)
export const exitMirroring = createAction(EXIT_MIRRORING)

export const toggleSidebar = createAction(TOGGLE_SIDEBAR)

export const selectBranch = createAction(SELECT_BRANCH)
export const selectCustomer = createAction(SELECT_CUSTOMER)
export const setOriginalUserToken = createAction(SET_ORIGINAL_USER_TOKEN)
