import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { axiosGetGenerator } from 'utils/sagas'
import { SETTINGS_CALL, INIT_SETTINGS_CALL, ROLES_CALL } from './actions'
import { errorAdd } from '../error/actions'

function* rolesCallGenerator(action) {
  const { type, payload } = action
  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', '/role-permission/role', {
      params: {
        filter: payload.filter,
        type: payload.type
      }
    })
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({
    type: `${type}_RESPONSE`,
    payload: { data: d, type: payload.type }
  })
}

export default function* userWatcherSaga() {
  yield takeLatest(SETTINGS_CALL, axiosGetGenerator('/settings'))
  yield takeLatest(INIT_SETTINGS_CALL, axiosGetGenerator('/init-settings'))
  yield takeLatest(ROLES_CALL, rolesCallGenerator)
}
