import { combineReducers } from 'redux'
import errorReducer from './error/reducers'
import userReducer from './user/reducers'
import generalReducer from './general/reducers'
import accountReducer from './account/reducers'
import searchReducer from './catalogue/reducers'
import commonContactsReducer from './common/contacts/reducers'
import commonUsersReducer from './common/users/reducers'
import customerTypesReducer from './settings/customer-types/reducers'
import pricingLevelsReducer from './settings/pricing-levels/reducers'
import currencyReducer from './currency/reducers'
import mediaLibraryReducer from './media-library/reducers'
import rolesPermissionsReducer from './settings/roles-permissions/reducers'
import campaignsReducer from './campaigns/reducers'
import chargesReducer from './charges/reducers'
import financialDetailsReducer from './financial-details/reducers'
import priceLevelsReducer from './price-levels/reducers'
import stockFeedReducer from './stock-feed/reducers'
import landedPricesReducer from './landed-prices/reducers'
import sellingPricesReducer from './selling-prices/reducers'
import notificationsReducer from './notifications/reducers'
import vatRatesReducer from './settings/vat-rates/reducers'
import ordersReducer from './orders/reducers'
import cartsReducer from './cart/reducers'
import catalogReducer from './catalog/reducers'
import accountMirrorReducer from './account-mirror/reducers'
import localesReducer from './locales/reducers'

import { REDUX_STORE_RESET } from './general/actions'

const allReducers = combineReducers({
  errorData: errorReducer,
  generalData: generalReducer,
  userData: userReducer,
  commonContactsData: commonContactsReducer,
  commonUsersData: commonUsersReducer,
  accountData: accountReducer,
  searchData: searchReducer,
  customerTypesData: customerTypesReducer,
  pricingLevelsData: pricingLevelsReducer,
  currencyData: currencyReducer,
  mediaLibraryData: mediaLibraryReducer,
  rolesPermissionsData: rolesPermissionsReducer,
  campaignsData: campaignsReducer,
  chargesData: chargesReducer,
  financialDetailsData: financialDetailsReducer,
  priceLevelsData: priceLevelsReducer,
  landedPricesData: landedPricesReducer,
  sellingPricesData: sellingPricesReducer,
  stockFeedData: stockFeedReducer,
  notificationsData: notificationsReducer,
  vatRatesData: vatRatesReducer,
  ordersData: ordersReducer,
  cartsData: cartsReducer,
  catalogData: catalogReducer,
  accountMirrorData: accountMirrorReducer,
  localesData: localesReducer
})

const rootReducer = (state, action) => {
  // if (action.type === REDUX_STORE_RESET) {
  //     localStorageService.clearToken();
  //     return allReducers(undefined, action);
  // }
  //
  // return allReducers(state, action);

  if (action.type === REDUX_STORE_RESET) {
    const { userData } = state

    // eslint-disable-next-line no-param-reassign
    state = {
      userData: {
        ...userData,
        // necessary to remove token & user from state
        token: null,
        user: null
      }
    }
  }

  return allReducers(state, action)
}

export default rootReducer
