import { createPromiseAction } from '@adobe/redux-saga-promise'

export const ACCOUNT_COLLECTION_GET = 'ACCOUNT_COLLECTION_GET'
export const ACCOUNT_GET = 'ACCOUNT_GET'
export const ACCOUNT_ADD_PROMISE = 'ACCOUNT_ADD_PROMISE'
export const ACCOUNT_EDIT_PROMISE = 'ACCOUNT_EDIT_PROMISE'
export const ACCOUNT_STATUS_PROMISE = 'ACCOUNT_STATUS_PROMISE'
export const ACCOUNT_DELETE_PROMISE = 'ACCOUNT_DELETE_PROMISE'
export const ACCOUNT_LOADING_SWITCH = 'ACCOUNT_LOADING_SWITCH'

export const accountAddPromise = createPromiseAction(ACCOUNT_ADD_PROMISE)
export const accountEditPromise = createPromiseAction(ACCOUNT_EDIT_PROMISE)
export const accountStatusPromise = createPromiseAction(ACCOUNT_STATUS_PROMISE)
export const accountDeletePromise = createPromiseAction(ACCOUNT_DELETE_PROMISE)

export function accountCollectionGet(moduleName, page, status_id) {
  return { type: ACCOUNT_COLLECTION_GET, payload: { moduleName, page, status_id } }
}

export function accountGet(moduleName, id, body) {
  return { type: ACCOUNT_GET, payload: { moduleName, id, body } }
}

export function accountLoadingSwitch(moduleName, status) {
  return { type: ACCOUNT_LOADING_SWITCH, payload: { moduleName, status } }
}
