import React from 'react'
import { Typography } from 'components/common'
import { useTranslation } from 'react-i18next'

const FTP = ({ ftp, FormField }) => {
    const { t } = useTranslation()
  const FTPFields = [
    {
      name: 'communication_ftp.name',
      type: 'text',
      label: t('Hostname'),
      placeholder: t('Enter hostname')
    },
    {
      name: 'communication_ftp.host',
      type: 'text',
      label: t('Host'),
      placeholder: t('Enter host')
    },
    {
      name: 'communication_ftp.username',
      type: 'text',
      label: t('Username'),
      placeholder: t('Enter username')
    },
    {
      name: 'communication_ftp.password',
      type: 'text',
      label: t('Password'),
      placeholder: t('Enter password')
    },
    {
      name: 'communication_ftp.port',
      type: 'text',
      label: t('Port'),
      placeholder: t('Enter port')
    }
  ]

  return (
    <>
      {!ftp.communication_ftp ? (
        <Typography>{ftp.message}</Typography>
      ) : (
        FTPFields.map(({ name, type, label, placeholder }) => (
          <div key={name} className="stockfeed__item mb-sm">
            <Typography>{label}:</Typography>
            {FormField({
              key: name,
              name,
              type,
              label: '',
              placeholder: '...',
              disabled: true,
              className: 'field-view-only'
            })}
          </div>
        ))
      )}
    </>
  )
}

export default FTP
