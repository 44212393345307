/* eslint-disable react/default-props-match-prop-types */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'components/common'

/**
 * Box component
 * @param {Node} title - Box title block sting or any node
 * @param {Node} subtitle - Box Subtitle block sting or any node
 * @param {Node} leftSection - Left section node
 * @param {Node} rightSection - Right section node
 * @param {Node} children - Any node
 */

const Box = memo(({ title, subtitle, leftSection, rightSection, className, children }) => (
  <>
    <div className={`box__header ${className}`}>
      <div>
        {title && <Typography variant="h3">{title}</Typography>}
        {leftSection && leftSection}
      </div>
      <div>{rightSection && rightSection}</div>
    </div>
    {subtitle && <span className="block__subtitle">{subtitle}</span>}
    {children && <div className="block__content">{children}</div>}
  </>
))

Box.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  leftSection: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rightSection: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
}

Box.defaultProps = {
  title: undefined,
  subtitle: undefined,
  leftSection: undefined,
  rightSection: undefined,
  children: undefined,
  className: ''
}

export default Box
Box.displayName = 'Box'
