import { Outlet, useOutletContext } from 'react-router-dom'

// constants
import { CLIENT } from 'constant'

// assets
import DefaultBg from 'assets/images/default.jpg'
import LoginLogo from 'assets/images/nordic-logo.png'

const client = CLIENT
const colors = {
  default: '#F9A700',
  newNordic: 'black',
  gt: 'black',
  stoneacre: '#348e44',
  eurobrake: '#009dd8',
  viking: 'black',
  titan: 'black',
  nk: '#FC3000',
  quickco: '#55BB44'
}

const AuthWrapper = () => (
  <main className="main main--auth">
    <div className="auth-container" style={{ backgroundImage: `url(${DefaultBg})` }}>
      <Outlet context={{ client, colors, logo: LoginLogo }} />
    </div>
  </main>
)

export const useAuthConfigs = () => {
  return useOutletContext()
}

export default AuthWrapper
