import Joi from 'joi'
import joiDefaultErrorMessages from 'constant/validation/messages'

export default Joi.object({
  media_send_field_date: Joi.when('is_fixed', {
    is: 1,
    then: Joi.date().empty(''),
    otherwise: Joi.optional()
  }),
  media_send_field_title: Joi.string().required().messages(joiDefaultErrorMessages)
})
