import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CartFooter({ additionalNote, onChangeNote, vatRatePrice }) {
  const handleUpdateNote = (e) => {
    onChangeNote(e.target.value)
  }

  const { t } = useTranslation()

  return (
    <div className="cart-details-wrapper">
      <div className="cart-textarea-wrapper">
        <textarea
          placeholder="Additional Notes"
          value={additionalNote}
          onChange={handleUpdateNote}
        />
      </div>
      <div className="cart-total">
        <p>{`${t('Total')}: ${vatRatePrice}`}</p>
        <p>{t('All prices are including VAT')}</p>
      </div>
    </div>
  )
}
