import { createPromiseAction } from '@adobe/redux-saga-promise'

// Form Data Actions
export const FETCH_STOCK_FEED_DATA_REQUEST = 'FETCH_STOCK_FEED_DATA_REQUEST'
export const FETCH_STOCK_FEED_DATA_SUCCESS = 'FETCH_STOCK_FEED_DATA_SUCCESS'
export const FETCH_STOCK_FEED_DATA_FAILURE = 'FETCH_STOCK_FEED_DATA_FAILURE'


export const FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST = 'FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST'
export const FETCH_STOCK_FEED_FOR_ACCOUNT_SUCCESS = 'FETCH_STOCK_FEED_FOR_ACCOUNT_SUCCESS'
export const FETCH_STOCK_FEED_FOR_ACCOUNT_FAILURE = 'FETCH_STOCK_FEED_FOR_ACCOUNT_FAILURE'
export const STOCK_FEED_EDIT_ACCOUNT_REQUEST = 'STOCK_FEED_EDIT_ACCOUNT_REQUEST'
export const STOCK_FEED_EDIT_ACCOUNT_SUCCESS = 'STOCK_FEED_EDIT_ACCOUNT_SUCCESS'
export const STOCK_FEED_EDIT_ACCOUNT_FAILURE = 'STOCK_FEED_EDIT_ACCOUNT_FAILURE'

export const CLEAR_STATE = 'CLEAR_STATE'

// Action creator factory function
const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchStockFeedDataRequest = createAction(FETCH_STOCK_FEED_DATA_REQUEST)
export const fetchStockFeedDataSuccess = createAction(FETCH_STOCK_FEED_DATA_SUCCESS)
export const fetchStockFeedDataFailure = createAction(FETCH_STOCK_FEED_DATA_FAILURE)

export const fetchStockFeedForAccountRequest = createAction(FETCH_STOCK_FEED_FOR_ACCOUNT_REQUEST)
export const fetchStockFeedForAccountSuccess = createAction(FETCH_STOCK_FEED_FOR_ACCOUNT_SUCCESS)
export const fetchStockFeedForAccountFailure = createAction(FETCH_STOCK_FEED_FOR_ACCOUNT_FAILURE)

export const stockEditAccountRequest = createAction(STOCK_FEED_EDIT_ACCOUNT_REQUEST)
export const stockEditAccountSuccess = createAction(STOCK_FEED_EDIT_ACCOUNT_SUCCESS)
export const stockEditAccountFailure = createAction(STOCK_FEED_EDIT_ACCOUNT_FAILURE)

export const clearState = createAction(CLEAR_STATE)