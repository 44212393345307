import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'components/common'
import { useTranslation } from 'react-i18next'

const EditCancelSave = memo(({ isEditing, onEdit, onCancel, onSave, onClear }) => {
    const { t } = useTranslation();
  
    return (
      <>
        {isEditing ? (
          <>
            {onClear && (
              <Button variant="tertiary" onClick={onClear} className="mr-xs">
                <span>{t('Clear')}</span>
              </Button>
            )}
            <Button variant="tertiary" onClick={onCancel}>
              <span>{t('Cancel')}</span>
            </Button>
            <Button variant="primary" onClick={onSave} className="ml-xs">
              <span>{t('Save')}</span>
            </Button>
          </>
        ) : (
          <Button variant="tertiary" onClick={onEdit}>
            <Icon iconClass="edit-filled" type="dark" size="12px" />
            <span>{t('Edit')}</span>
          </Button>
        )}
      </>
    );
  })
  
  EditCancelSave.propTypes = {
    data: PropTypes.shape({})
  }
  
  EditCancelSave.defaultProps = {
    data: null
  }
  
  export default EditCancelSave
  EditCancelSave.displayName = 'EditCancelSave'