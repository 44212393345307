import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

const paddingForGap = {
  0: 'p-0',
  xxs: 'p-xs',
  xs: 'p-md',
  sm: 'p-lg',
  md: 'p-xl',
  lg: 'p-xxxl'
}

const colSizes = {
  1: 12,
  2: 6,
  3: 4,
  4: 3,
  6: 2,
  12: 1
}

/**
 * Grid component
 * @param {String} gap - Gap between items [0, 'xxs', 'xs', 'sm', 'md']
 * @param {String} padding - Padding classes for container
 * @param {Number|Object} columns - Columns in grid [1, 2, 3, 4, 6, 12] | { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }
 * @param {JSX} children - Content
 */

// xs (extra-small): 0px or larger.
// sm (small): 600px or larger.
// md (medium): 960px or larger.
// lg (large): 1280px or larger.
// xl (extra-large): 1920px or larger.

// const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

const GridList = memo(({ gap, padding, columns, children }) => {
  const colClasses = useMemo(() => {
    if (typeof columns === 'object' && columns !== null) {
      // sizes.map(size => {
      //     if (columns[size]) {
      //         const colValue = columns[size];
      //         return `col-${size}-${colSizes[colValue]}`;
      //     }
      //     return `col-${colKey}-${colSizes[colValue]}`;
      // });

      return Object.entries(columns)
        .map(([colKey, colValue]) => `col-${colKey}-${colSizes[colValue]}`)
        .join(' ')
    }
    return `col-${colSizes[columns]}`
  }, [columns])

  return (
    <div className={`container-fluid ${padding || paddingForGap[gap]}`}>
      <div className={`row -m-${gap}`}>
        {children &&
          React.Children.map(children, (child) => child && (
            <div key={child.key} className={`col ${colClasses} p-${gap}`}>
              {child}
            </div>
          ))}
      </div>
    </div>
  )
})

GridList.propTypes = {
  // gap: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  gap: PropTypes.oneOfType([
    PropTypes.oneOf([0, 'xxs', 'xs', 'sm', 'md', 'lg']),
    PropTypes.shape({})
  ]),
  padding: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element)
  ])
}

GridList.defaultProps = {
  gap: 'xxs',
  padding: undefined,
  columns: 3,
  children: undefined
}

export default GridList
GridList.displayName = 'GridList'
