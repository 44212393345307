import React, { memo, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDidUpdate, useEventListener } from 'hooks'
import classNames from 'classnames'
import DropdownContext from './dropdown-context'
import DropdownSection from './dropdown-section'
import DropdownItem from './dropdown-item'
import DropdownDivider from './dropdown-divider'

/**
 * Dropdown component
 * @param {String, Element} label - Dropdown toggle button
 * @param {String} className - Dropdown className
 * @param {Array.<DropdownSection, DropdownItem, DropdownDivider>} children - List of allowed items
 */

const Dropdown = memo(({ label, menuAlignment, className, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  // const labelIsString = useMemo(() => typeof label === 'string', [label]);

  const dropdownRef = useRef(null)
  const listRef = useRef(null)

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState)
  }, [])

  const calculateDropdownBoundaries = () => {
    const buffer = 10

    const dropdownRect = dropdownRef.current.getBoundingClientRect()
    const listRect = listRef.current.getBoundingClientRect()

    const maxHeight = window.innerHeight - listRect.top - buffer
    const maxRight = dropdownRect.left + listRect.width + buffer

    const inlineStyles = {
      maxHeight: `${maxHeight}px`,
      marginLeft:
        window.innerWidth < maxRight
          ? `-${Math.floor(listRect.width - dropdownRect.width)}px`
          : 'auto'
    }

    Object.assign(listRef.current.style, inlineStyles)
  }

  const handleResize = () => {
    if (isOpen) {
      calculateDropdownBoundaries()
    }
  }

  const handleOutsideClick = (e) => {
    if (isOpen && !dropdownRef.current.contains(e.target)) {
      handleToggle()
    }
  }

  useEventListener('mousedown', handleOutsideClick)
  useEventListener('resize', handleResize, window)

  useDidUpdate(() => {
    handleResize()
  }, [isOpen])

  return (
    <DropdownContext.Provider value={handleToggle}>
      <div
        className={classNames(
          'dropdown',
          { 'dropdown--open': isOpen },
          { 'dropdown--close': !isOpen },
          className
        )}
        ref={dropdownRef}
      >
        {typeof label === 'string' ? (
          <button
            className="dropdown__toggle"
            type="button"
            aria-label={label}
            onClick={handleToggle}
          >
            {label}
          </button>
        ) : (
          React.cloneElement(label, {
            onClick: handleToggle,
            className: `${label.props?.className ?? ''} ${isOpen ? 'isOpen' : 'isClose'}`
          })
        )}

        {isOpen && (
          <ul className={`dropdown__list ${menuAlignment}`} ref={listRef}>
            {children}
          </ul>
        )}
      </div>
    </DropdownContext.Provider>
  )
})

Dropdown.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
    PropTypes.node.isRequired
  ]).isRequired,
  menuAlignment: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Dropdown.defaultProps = {
  menuAlignment: 'left',
  className: ''
}

Dropdown.Section = DropdownSection
Dropdown.Item = DropdownItem
Dropdown.Divider = DropdownDivider

export default Dropdown
Dropdown.displayName = 'Dropdown'
