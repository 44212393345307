import PermissionWrapper from '../wrapper'

const withPermission = (module, permissionName) => (Component) => {
  return () =>
    PermissionWrapper({
      component: Component,
      module,
      permissionName
    })
}

export default withPermission
