/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import PageHeader from './page-header'
import PageSubHeader from './page-sub-header'
// import PerfectScrollbar from 'react-perfect-scrollbar';

/**
 * Page component
 * @param {String} tabTitle - Browser tab title
 * @param {String} pageType - Page type ['full', 'top', 'mid', 'bottom']
 * @param {PageHeader} pageHeader - Page header component
 * @param {PageSubHeader} pageSubHeader - Page sub header component
 * @param {Object} classNames - Page and content classNames { page: '', content: ''}
 * @param {Node} children - Any node
 */

const Page = forwardRef(
  ({ tabTitle, pageType, pageHeader, pageSubHeader, classNames, children }, ref) => {
    const title = tabTitle || pageSubHeader?.props?.title
    return (
      <>
        {title && (
          <Helmet>
            <title>Availcat {title ? ` - ${title}` : ''}</title>
          </Helmet>
        )}
        {pageHeader && pageHeader}
        <div ref={ref} className={`page ${classNames.page ?? ''}`}>
          {pageSubHeader && pageSubHeader}
          {children && (
            // <PerfectScrollbar
            //     options={{
            //         suppressScrollX: true,
            //         useBothWheelAxes: false
            //     }}
            // >
            <div className={`page__content page__content--${pageType} ${classNames.content ?? ''}`}>
              {children}
            </div>
            // </PerfectScrollbar>
          )}
        </div>
      </>
    )
  }
)

Page.SubHeader = PageSubHeader
Page.Header = PageHeader

Page.propTypes = {
  tabTitle: PropTypes.string,
  pageType: PropTypes.oneOf(['full', 'top', 'mid', 'bottom']),
  pageHeader: PropTypes.element,
  pageSubHeader: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.node
  ]),
  classNames: PropTypes.shape({
    page: PropTypes.string,
    content: PropTypes.string
  })
}

Page.defaultProps = {
  tabTitle: undefined,
  pageType: 'full',
  pageHeader: undefined,
  pageSubHeader: undefined,
  children: undefined,
  classNames: {
    page: '',
    content: ''
  }
}

export default Page
