// eslint-disable-next-line max-classes-per-file
import { Suspense, lazy } from 'react'
import { Loader } from 'components/common'

/**
 * Load scenes while it needed(lazy loading)
 */
const MainPage = lazy(() => import('./pages/main'))
const ProductPage = lazy(() => import('./pages/product'))
const BulkChangesPage = lazy(() => import('./pages/bulk-changes'))
const KitBuilderPage = lazy(() => import('./pages/kit-builder'))

/**
 * Initialize auth regarded routes
 */
class MainController {
  static Component = (
    <Suspense fallback={<Loader linear />}>
      <MainPage />
    </Suspense>
  )
}

class ProductController {
  static path = 'product'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <ProductPage />
    </Suspense>
  )
}

class BulkChangesController {
  static path = 'bulk-changes'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <BulkChangesPage />
    </Suspense>
  )
}

class KitBuilderController {
  static path = 'kit-builder'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <KitBuilderPage />
    </Suspense>
  )
}

export { MainController, ProductController, BulkChangesController, KitBuilderController }
