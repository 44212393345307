import { DashboardController, AdminController, SettingsController } from '../admin/controllers'
import { CatalogueController, CatalogueAutomativeController } from '../client/controllers'
import {
  PricingLevelsController,
  RolesPermissionsController,
  CatalogueManagementController,
  ProductManagementController,
  VatRatesController
} from '../admin/pages/settings/controllers'
import {
  ProductController,
  KitBuilderController
} from '../admin/pages/settings/pages/product-management/controllers'

// dashboard
const dashboardFullPath = `/${AdminController.path}/${DashboardController.path}`

// client
const clientFullPath = `/${CatalogueController.path}/${CatalogueAutomativeController.path}`

// settings
const settingsFullPath = `/${AdminController.path}/${SettingsController.path}`
const settingsPricingLevelsPath = `${settingsFullPath}/${PricingLevelsController.path}`
const settingsVatRatesPath = `${settingsFullPath}/${VatRatesController.path}`
const settingsRolesPermissionsPath = `${settingsFullPath}/${RolesPermissionsController.path}`
const settingsCatalogueManagementPath = `${settingsFullPath}/${CatalogueManagementController.path}`

const settingsProductManagementPath = `${settingsFullPath}/${ProductManagementController.path}`
const settingsProductManagementAddEditMainPath = `${settingsFullPath}/${ProductManagementController.path}/${ProductController.path}`
const settingsProductManagementKitBuilderPath = `${settingsFullPath}/${ProductManagementController.path}/${KitBuilderController.path}`

export {
  dashboardFullPath,
  clientFullPath,
  settingsFullPath,
  settingsPricingLevelsPath,
  settingsVatRatesPath,
  settingsRolesPermissionsPath,
  settingsCatalogueManagementPath,
  settingsProductManagementPath,
  settingsProductManagementAddEditMainPath,
  settingsProductManagementKitBuilderPath
}
