import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchSellingPrices() {
  yield requestWithActions(
    actions.FETCH_SELLING_PRICES_REQUEST,
    () => axiosSagaRequest('get', 'selling-price'),
    actions.fetchSellingPricesSuccess,
    actions.fetchSellingPricesFailure
  )
}

function* fetchSellingPrice(action) {
  const priceId = action.payload

  yield requestWithActions(
    actions.FETCH_SELLING_PRICE_REQUEST,
    () => axiosSagaRequest('get', `selling-price/${priceId}`),
    actions.fetchSellingPriceSuccess,
    actions.fetchSellingPriceFailure
  )
}

function* fetchSellingFormData() {
  yield requestWithActions(
    actions.FETCH_SELLING_FORM_DATA_REQUEST,
    () => axiosSagaRequest('get', 'selling-price/get-form-data'),
    actions.fetchSellingFormDataSuccess,
    actions.fetchSellingFormDataFailure
  )
}

function* deleteSellingPrice(action) {
  const priceId = action.payload
  yield requestWithActions(
    actions.DELETE_SELLING_PRICE_REQUEST,
    () => axiosSagaRequest('delete', `selling-price/${priceId}`),
    (data) => actions.deleteSellingPriceSuccess(priceId, data),
    actions.deleteSellingPriceFailure
  )
}

function* createSellingPrice(action) {
  yield requestWithActions(
    actions.CREATE_SELLING_PRICE_REQUEST,
    () =>
      axiosSagaRequest('post', `selling-price/${action.payload.landed_price_id}`, action.payload),
    actions.createSellingPriceSuccess,
    actions.createSellingPriceFailure
  )
}

export default function* sellingPricesWatcherSaga() {
  yield takeLatest(actions.FETCH_SELLING_PRICES_REQUEST, fetchSellingPrices)
  yield takeLatest(actions.FETCH_SELLING_PRICE_REQUEST, fetchSellingPrice)
  yield takeLatest(actions.DELETE_SELLING_PRICE_REQUEST, deleteSellingPrice)
  yield takeLatest(actions.FETCH_SELLING_FORM_DATA_REQUEST, fetchSellingFormData)
  yield takeLatest(actions.CREATE_SELLING_PRICE_REQUEST, createSellingPrice)
}
