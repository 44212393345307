import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components/common'
import './index.css'
import { useTranslation } from 'react-i18next'


export default function CartOrdersEmpty({ title }) {
  const history = useNavigate()

  const handleBack = () => {
    history('/catalogue')
  }

  const { t } = useTranslation()
  return (
    <div className="cart-orders-empty-wrapper">
      <h1 className="mb-xl">{title}</h1>
      <Button style={{ backgroundColor: '#263355', borderRadius: 3 }} onClick={handleBack}>
        {t('Back To Catalogue')}
      </Button>
    </div>
  )
}
