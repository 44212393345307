import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { NotificationPreview } from 'components/complex'
import { Icon, LanguageSwitcher } from 'components/common'
import NotificationsDropdown from './notifications'

const HeaderIcons = () => {
  const [action, setAction] = useState(null)

  return (
    <div className="header__icons">
      <LanguageSwitcher />
      {/* <NavLink to="/chat" className="icon-box chat-icon-box">
        <Icon iconClass="chat" type="lighter" size="17px" />
      </NavLink> */}
      <NotificationsDropdown setAction={setAction} />
      {Boolean(action) && (
        <NotificationPreview
          isOpen
          close={() => setAction(null)}
          action={action}
          id={action.id}
          isLoading={false}
        />
      )}
    </div>
  )
}

export default HeaderIcons
