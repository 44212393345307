import React from 'react'

const Tab = ({ children }) => <>{children}</>

// Tab.propTypes = {
//
// };
//
// Tab.defaultProps = {
//
// };

export default Tab
