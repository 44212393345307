export const FETCH_INIT_CATALOG_REQUEST = 'FETCH_INIT_CATALOG_REQUEST'
export const FETCH_INIT_CATALOG_SUCCESS = 'FETCH_INIT_CATALOG_SUCCESS'
export const FETCH_INIT_CATALOG_FAILURE = 'FETCH_INIT_CATALOG_FAILURE'

export const ADD_TO_CART_PROMISE = 'ADD_TO_CART_PROMISE'
export const ADD_TO_CART_PROMISE_SUCCESS = 'ADD_TO_CART_PROMISE_SUCCESS'
export const ADD_TO_CART_PROMISE_FAILURE = 'ADD_TO_CART_PROMISE_FAILURE'

export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET'
export const REMOVE_FROM_BASKET_BY_IDS = 'REMOVE_FROM_BASKET_BY_IDS'
export const CLEAR_BASKET = 'CLEAR_BASKET'

export const CALL_REQUEST = 'CALL_REQUEST'
export const CALL_REQUEST_SUCCESS = 'CALL_REQUEST_SUCCESS'
export const CALL_REQUEST_FAILURE = 'CALL_REQUEST_FAILURE'

export const CLEAR_STATE = 'CLEAR_STATE'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchInitCatalogRequest = createAction(FETCH_INIT_CATALOG_REQUEST)
export const fetchInitCatalogSuccess = createAction(FETCH_INIT_CATALOG_SUCCESS)
export const fetchInitCatalogFailure = createAction(FETCH_INIT_CATALOG_FAILURE)

export const addToCart = createAction(ADD_TO_CART_PROMISE)
export const addToCartSuccess = createAction(ADD_TO_CART_PROMISE_SUCCESS)
export const addToCartFailure = createAction(ADD_TO_CART_PROMISE_FAILURE)

export const callRequest = createAction(CALL_REQUEST)
export const callRequestSuccess = createAction(CALL_REQUEST_SUCCESS)
export const callRequestFailure = createAction(CALL_REQUEST_FAILURE)

export const removeFromBasket = createAction(REMOVE_FROM_BASKET)
export const removeFromBasketByIds = createAction(REMOVE_FROM_BASKET_BY_IDS)
export const clearBasket = createAction(CLEAR_BASKET)

export const clearState = createAction(CLEAR_STATE)
