import { createPromiseAction } from '@adobe/redux-saga-promise'

export const CAMPAIGNS_CALL = 'CAMPAIGNS_CALL'
export const CAMPAIGNS_DELETE_CALL_PROMISE = 'CAMPAIGNS_DELETE_CALL_PROMISE'

export const CAMPAIGNS_LOADING_SWITCH = 'CAMPAIGNS_LOADING_SWITCH'

export const campaignsDeletePromise = createPromiseAction(CAMPAIGNS_DELETE_CALL_PROMISE)

export function campaignsCall() {
  return { type: CAMPAIGNS_CALL }
}

export function campaignsLoadingSwitch(status) {
  return { type: CAMPAIGNS_LOADING_SWITCH, payload: { status } }
}
