/* eslint-disable import/no-extraneous-dependencies */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationDE from './locales/de/translation.json'
import translationDK from './locales/dk/translation.json'
import translationFR from './locales/fr/translation.json'
import translationRU from './locales/ru/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  dk: {
    translation: translationDK
  },
  fr: {
    translation: translationFR
  },
  ru: {
    translation: translationRU
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  interpolation: {
    escapeValue: false
  }
})

export default i18n
