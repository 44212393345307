import { useCallback, useContext } from 'react'
import { TranslationContext } from './translation-context'

/**
 * This hook translates word key to current selected language
 */

const useTranslation = () => {
  const { language, defaultLanguage, changeLanguage } = useContext(TranslationContext)
  // TODO -> DEMO LANG DATA
  const data = {
    en: {
      CANCEL: 'cancel',
      CREATE_USER: 'create user',
      'company-admin': 'Company Admin',
      'company-user': 'Company User',
      EDIT_USER: 'edit user',
      ADD_CURRENCY: 'add currency',
      SET: 'set'
    }
    // ru: {
    //     CANCEL: 'отмена',
    //     CREATE_USER: 'создать пользователя'
    // }
  }

  const translate = useCallback(
    (wordKey, lng = language) => {
      if (typeof wordKey === 'string') {
        if (data[lng] && data[lng][wordKey]) {
          return data[lng][wordKey]
        }

        if (data[defaultLanguage] && data[defaultLanguage][wordKey]) {
          return data[defaultLanguage][wordKey]
        }

        return wordKey
      }

      return '-'
    },
    [data, language, defaultLanguage]
  )

  return {
    t: translate,
    language,
    defaultLanguage,
    changeLanguage
  }
}

export default useTranslation
