import { all } from 'redux-saga/effects'
import generalWatcherSaga from './general/sagas'
import userWatcherSaga from './user/sagas'
import accountWatcherSaga from './account/sagas'
import { searchWatcherSaga } from './catalogue/sagas'
import commonContactsWatcherSaga from './common/contacts/sagas'
import commonUsersWatcherSaga from './common/users/sagas'
import customerTypesWatcherSaga from './settings/customer-types/sagas'
import pricingLevelsWatcherSaga from './settings/pricing-levels/sagas'
import currencyWatcherSaga from './currency/sagas'
import mediaLibraryWatcherSaga from './media-library/sagas'
import rolesPermissionsWatcherSaga from './settings/roles-permissions/sagas'
import campaignsWatcherSaga from './campaigns/sagas'
import chargesWatcherSaga from './charges/sagas'
import financialDetailsWatcherSaga from './financial-details/sagas'
import priceLevelsWatcherSaga from './price-levels/sagas'
import stockFeedWatcherSaga from './stock-feed/sagas'
import landedPricesWatcherSaga from './landed-prices/sagas'
import sellingPricesWatcherSaga from './selling-prices/sagas'
import notificationsWatcherSaga from './notifications/sagas'
import vatRatesWatcherSaga from './settings/vat-rates/sagas'
import ordersWatcherSaga from './orders/sagas'
import cartsWatcherSaga from './cart/sagas'
import catalogWatcherSaga from './catalog/sagas'
import accountMirrorWatcherSaga from './account-mirror/sagas'
import localesWatcherSaga from './locales/sagas'

export default function* rootSaga() {
  yield all([
    generalWatcherSaga(),
    userWatcherSaga(),
    commonContactsWatcherSaga(),
    commonUsersWatcherSaga(),
    accountWatcherSaga(),
    searchWatcherSaga(),
    customerTypesWatcherSaga(),
    pricingLevelsWatcherSaga(),
    currencyWatcherSaga(),
    mediaLibraryWatcherSaga(),
    rolesPermissionsWatcherSaga(),
    campaignsWatcherSaga(),
    chargesWatcherSaga(),
    financialDetailsWatcherSaga(),
    priceLevelsWatcherSaga(),
    stockFeedWatcherSaga(),
    landedPricesWatcherSaga(),
    sellingPricesWatcherSaga(),
    notificationsWatcherSaga(),
    vatRatesWatcherSaga(),
    ordersWatcherSaga(),
    cartsWatcherSaga(),
    catalogWatcherSaga(),
    accountMirrorWatcherSaga(),
    localesWatcherSaga()
  ])
}
