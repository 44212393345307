/* eslint-disable func-names */
import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  implementPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction
} from '@adobe/redux-saga-promise'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import {
  COMMON_USERS_GET,
  COMMON_USER_GET,
  commonUserAddPromise,
  commonUserActionInStepPromise,
  commonUserEditPromise,
  commonUserStatusPromise,
  commonUserDeletePromise,
  commonUserLoadingSwitch,
  COMMON_SHORT_USERS_GET
} from './actions'

const prefix = '/account/user'

// Loading wrapper
function* commonUserGeneratorWrapper(moduleName, gen) {
  yield put(commonUserLoadingSwitch(moduleName, true))
  yield gen()
  yield put(commonUserLoadingSwitch(moduleName, false))
}

const commonUsersCallGenerator = (dataType) =>
  function* gen(action) {
    const {
      type,
      payload: { moduleName, accountId }
    } = action

    let d
    try {
      const { data } = yield call(axiosSagaRequest, 'get', prefix, {
        params: {
          account_id: accountId,
          data_type: dataType,
          filter: 'all'
        }
      })
      d = data
    } catch (error) {
      yield put(errorAdd(error, action))
    }
    yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d } })
  }

function* commonUserGetGenerator(action) {
  const {
    type,
    payload: { moduleName, userId }
  } = action
  // const moduleId = modules[moduleName].id;

  let d
  try {
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/${userId}`)
    d = data
  } catch (error) {
    yield put(errorAdd(error, action))
  }
  yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data: d } })
}

function* commonUserAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, userData }
    } = action

    yield commonUserGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', prefix, userData)

        yield put({
          type: `${type}_RESPONSE`,
          payload: {
            moduleName,
            // todo we dont use this promise in steps anymore, so method need to be deleted - check
            data: userData.method
              ? { ...data, index: userData.index, method: userData.method }
              : data
          }
        })

        if (moduleName === 'company' && data.contact && data.contact.length > 0) {
          yield put({
            type: `COMMON_CONTACT_ADD_PROMISE.TRIGGER_RESPONSE`,
            payload: { moduleName, data }
          })
        }

        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* commonUserEditPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, userId, userData }
    } = action

    yield commonUserGeneratorWrapper(moduleName, function* () {
      try {
        const { data } = yield call(axiosSagaRequest, 'post', `${prefix}/${userId}`, userData, {
          params: {
            _method: 'PATCH'
          }
        })
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, data } })
        yield call(resolvePromiseAction, action, data)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* commonUserStatusPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, userId, statusData }
    } = action
    yield commonUserGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'get', `${prefix}/${userId}/${statusData.name.toLowerCase()}`)
        yield put({
          type: `${type}_RESPONSE`,
          payload: { moduleName, userId, statusData }
        })
        yield call(resolvePromiseAction, action, userId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* commonUserDeletePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* () {
    const {
      type,
      payload: { moduleName, userId }
    } = action

    yield commonUserGeneratorWrapper(moduleName, function* () {
      try {
        yield call(axiosSagaRequest, 'delete', `${prefix}/${userId}`)
        yield put({ type: `${type}_RESPONSE`, payload: { moduleName, userId } })
        yield call(resolvePromiseAction, action, userId)
      } catch (error) {
        yield put(errorAdd(error, action))
        yield call(rejectPromiseAction, action, error)
      }
    })
  })
}

function* commonUserActionInStepPromiseGenerator(action) {
  const {
    type,
    payload: { moduleName, formData, indexAndMethod }
  } = action

  yield commonUserGeneratorWrapper(moduleName, function* () {
    try {
      const { data } = yield call(axiosSagaRequest, 'post', `/account/${moduleName}`, formData)
      yield put({
        type: `${type}_RESPONSE`,
        payload: {
          moduleName,
          data: { ...data, ...indexAndMethod }
        }
      })
      yield call(resolvePromiseAction, action, data)
    } catch (error) {
      yield put(errorAdd(error, action))
      yield call(rejectPromiseAction, action, error)
    }
  })
}

export default function* commonUsersWatcherSaga() {
  yield takeLatest(COMMON_USERS_GET, commonUsersCallGenerator('full'))
  yield takeEvery(COMMON_USER_GET, commonUserGetGenerator)
  yield takeEvery(commonUserAddPromise, commonUserAddPromiseGenerator)
  yield takeEvery(commonUserActionInStepPromise, commonUserActionInStepPromiseGenerator)
  yield takeEvery(commonUserEditPromise, commonUserEditPromiseGenerator)
  yield takeEvery(commonUserStatusPromise, commonUserStatusPromiseGenerator)
  yield takeEvery(commonUserDeletePromise, commonUserDeletePromiseGenerator)
  yield takeEvery(COMMON_SHORT_USERS_GET, commonUsersCallGenerator('short'))
}
