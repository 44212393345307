import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { localesSelector } from 'store/locales/selectors'
import { useSelector } from 'react-redux'

const getFlagEmoji = (locale) => {
  switch (locale) {
    case 'en':
      return '🇬🇧'
    case 'de':
      return '🇩🇪'
    case 'dk':
      return '🇩🇰'
    case 'fr':
      return '🇫🇷'
    case 'ru':
      return '🇷🇺'
    default:
      return ''
  }
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 'auto',
    marginRight: '32px',
    cursor: 'pointer',
    boxShadow: 'none',
    border: 'none'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
}
const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation()
  const { locales } = useSelector(localesSelector)

  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    const storedLocale = localStorage.getItem('locale')
    const defaultLocale = storedLocale || i18n.language || 'en'
    setSelectedValue(defaultLocale)
  }, [i18n.language])

  const options = locales.map((locale) => ({
    value: locale,
    label: `${getFlagEmoji(locale)} ${t(`languages.${locale}`)}`
  }))

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption.value)
    localStorage.setItem('locale', selectedOption.value)
    i18n.changeLanguage(selectedOption.value)
  }

  return (
    <div>
      <Select
        options={options}
        styles={customStyles}
        isSearchable={false}
        value={options.find((option) => option.value === selectedValue)}
        onChange={handleChange}
      />
    </div>
  )
}

export default LanguageSwitcher
