import Joi from 'joi'
import joiDefaultErrorMessages from 'constant/validation/messages'

export default Joi.object({
  name: Joi.string().required().messages(joiDefaultErrorMessages),

  number: Joi.string().required().messages(joiDefaultErrorMessages),

  sort_code: Joi.string().required().messages(joiDefaultErrorMessages),

  iban: Joi.string().allow('').messages(joiDefaultErrorMessages),

  bic: Joi.string().allow('').messages(joiDefaultErrorMessages),

  country_id: Joi.number().required().messages(joiDefaultErrorMessages),

  currency_id: Joi.number().required().messages(joiDefaultErrorMessages)
})
