/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Pusher from 'pusher-js'
import { Link } from 'react-router-dom'
import { adminRouteNames } from 'constant'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsSelector } from 'store/notifications/selectors'
import { fetchNotificationsRequest } from 'store/notifications/actions'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  Dropdown,
  Box,
  Badge,
  EmptyContent,
  NotificationItem,
  useSnackbar
} from 'components/common'
import { userDataSelector, userTokenSelector } from 'store/user/selectors'

const NotificationsDropdown = ({ setAction }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(userDataSelector)
  const { token } = useSelector(userTokenSelector)

  const { t } = useTranslation()

  const addSnackbar = useSnackbar()

  const { marketing_notifications, unseenNotifications } = useSelector(notificationsSelector)
  let notifications

  if (marketing_notifications) {
    notifications = marketing_notifications.slice(0, 5)
  }

  useEffect(() => {
    dispatch(fetchNotificationsRequest())
    if (process.env.REACT_APP_PUSHER_ENV && process.env.REACT_APP_PUSHER_CLUSTER_ENV) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER_ENV,
        authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      })

      const channelName = `private-App.Models.User.${user.id}`
      const channel = pusher.subscribe(channelName)

      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
        dispatch(fetchNotificationsRequest())
        addSnackbar({
          variant: 'info',
          message: `${t('new notification')} ${data.title}`
        })
      })

      return () => {
        pusher.unsubscribe(channelName)
      }
    }
    return ''
  }, [])

  return (
    <Dropdown
      label={
        <button type="button" className="icon-box bell-neutral-icon-box">
          <Icon iconClass="bell-neutral" type="lighter" size="18px" />
          {unseenNotifications > 0 && (
            <Badge title={`${unseenNotifications}`} type="danger" cursor="pointer" />
          )}
        </button>
      }
      menuAlignment="left"
      className="list-card__dropdown notifications-dropdown p-relative ml-auto"
    >
      <Box
        title="Notifications"
        rightSection={
          <Dropdown.Item className="notification-item p-0">
            <Link
              to={`${adminRouteNames.NOTIFICATIONS}/all`}
              className="notification-item__link fw-medium"
            >
              {t('see all')}
            </Link>
          </Dropdown.Item>
        }
        className="px-md"
      >
        <Dropdown.Divider height={1} className="mb-md" />
        {!notifications.length ? (
          <EmptyContent description="You don't have any notifications received yet" />
        ) : (
          <>
            {notifications.map((item) => (
              <Dropdown.Item
                className="notification-item flex flex-align-start"
                key={item.id}
                onClick={() => setAction(item)}
              >
                <NotificationItem
                  avatar={item.promotion.user.image}
                  name={item.promotion.user.user_detail.full_name}
                  title="sent you a very interesting media file:"
                  subject={item.title}
                  isRead={item.seen_at}
                  time={moment(item.send_at).format('DD.MM.YYYY - HH:mm')}
                />
              </Dropdown.Item>
            ))}
          </>
        )}
      </Box>
    </Dropdown>
  )
}

export default NotificationsDropdown
