/* eslint-disable no-case-declarations */
import * as actionTypes from './actions'

const initialState = {
  notifications: {
    marketing_notifications: [],
    order_notifications: [],
    unseenNotifications: null
  },
  notification: null,

  loading: false,
  error: null
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_REQUEST:
    case actionTypes.FETCH_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      const { marketing_notifications, unseenNotifications } = action.payload

      return {
        ...state,
        notifications: {
          marketing_notifications: marketing_notifications.data,
          unseenNotifications
        },
        loading: false,
        error: null
      }

    case actionTypes.FETCH_NOTIFICATION_SUCCESS:
      const { notification } = action.payload
      return {
        ...state,
        notification,
        loading: false,
        error: null
      }

    case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
    case actionTypes.FETCH_NOTIFICATION_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default notificationsReducer
