// eslint-disable-next-line max-classes-per-file
import { Suspense, lazy } from 'react'
import { Loader } from 'components/common'

/**
 * Load scenes while it needed(lazy loading)
 */
const SignInScene = lazy(() => import('./scenes/signIn'))
const SignUpScene = lazy(() => import('./scenes/signUp'))
const ForgottenPasswordScene = lazy(() => import('./scenes/forgottenPassword'))

/**
 * Initialize auth regarded routes
 */
class LoginController {
  static path = 'login'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <SignInScene />
    </Suspense>
  )
}

class SignUpController {
  static path = 'sign-up'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <SignUpScene />
    </Suspense>
  )
}

class ForgottenPasswordController {
  static path = 'forgotten-password'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <ForgottenPasswordScene />
    </Suspense>
  )
}

export { LoginController, SignUpController, ForgottenPasswordController }
