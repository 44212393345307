import produce from 'immer'
import _ from 'lodash'
import {
  GROUPS_BY_REGNUMBER_GET,
  GROUPS_BY_VINNUMBER_GET,
  VEHICLE_DATA_GET,
  PRODUCTS_BY_VEHICLE_GET,
  TOGGLE_GROUP,
  TOGGLE_SECTION,
  CLEAR_SECTION,
  FETCH_NON_AUTO_GROUPS_REQUEST,
  SEARCH_LOADING_SWITCH,
  GROUPS_BY_PARTNUMBER_GET,
  CLEAR_DATA_FROM_STORE,
  SEARCH_FILTER_TOGGLE,
  SEARCH_FILTER_BRAND_TOGGLE,
  AXLES_FILTER_TOGGLE,
  GET_STOCK_REQUEST,
  RESET_STATE
} from './actions'

const initialState = {
  catalogue: {
    isLoading: false,
    isFetched: false,
    warning: false,
    vehicle: {},
    vehicleFullName: '',
    groups: {},
    sections: [],
    selectedGroups: [],
    selectedSections: [],
    products: [],
    vehicleSearchData: [],
    vehicleSearchDataOptions: [],
    lookup_id: null,
    sectionLookupIds: [],

    data: {
      groups: {},
      sections: [],
      selectedGroups: [],
      selectedSections: [],
      selectedBrands: [],
      selectedAxles: {},
      regNumber: '',
      partNumber: '',
      vinNumber: '',
      vehicle: {},
      lookupId: null,
      vehicleSearchData: [],
      vehicleSearchIsActive: false,
      vehicleSearchDataOptions: [],
      vehiclesCount: 0,
      productCount: 0,
      products: {},
      sectionLookupIds: {},
      brands: [],
      showExtraFilter: false
    }
  },
  non_automative: {
    isLoading: false,
    isFetched: false,
    data: {
      groups: {},
      sections: [],
      selectedGroups: [],
      selectedSections: [],
      selectedAxles: {},
      regNumber: '',
      lookupId: null,
      productCount: 0,
      products: {},
      sectionLookupIds: {},
      brands: [],
      showExtraFilter: false
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  const getSections = (selected, moduleName) => {
    const groups = _.cloneDeep(state[moduleName].data.groups) || []
    const selectedSections = _.cloneDeep(state[moduleName].data.selectedSections) || []
    const products = _.cloneDeep(state[moduleName].data.products) || []
    const selectedSectionsNew = []
    const newProducts = []
    let productCount = 0
    let sections = []
    // if (groups && groups.length === 1) {
    //   sections = [...sections, ...Object.values(groups[0].sections)];
    //   return sections || []
    // }
    if (selected) {
      if (groups.length > 0 && selected.length > 0) {
        groups.forEach((group) => {
          if (selected.includes(group.group_id)) {
            Object.values(group.sections).forEach((section) => {
              if (selectedSections.includes(section.section_id)) {
                selectedSectionsNew.push(section.section_id)
                if (products[section.section_id]) {
                  newProducts[section.section_id] = products[section.section_id]
                  productCount += products[section.section_id].length
                }
              }
            })
            sections = [...sections, ...Object.values(group.sections)]
          }
        })
      }
    }
    return {
      sections: sections || [],
      productCount: productCount || 0,
      selectedSections: selectedSectionsNew || [],
      products: newProducts || []
    }
  }

  const getVehicleName = (vehicle) => {
    let vehicleTitle = ''
    const vh = vehicle
    if (vh) {
      if (vh.EndYr || vh.EndYr === '0') vh.EndYr = ''

      const nameSelectAttr = [
        'Make',
        'Model',
        'ModelInfo',
        'ModelInfo',
        'BodyStyle',
        'Engine',
        'Fuel',
        'StartYr',
        'EndYr',
        'Chassis',
        'HP'
      ]

      _.map(vh, (val, key) => {
        if (_.includes(nameSelectAttr, key) && val !== null) {
          let addon = val
          if (key === 'Engine' && !val.isNaN) addon = `${val}`
          if (key === 'HP' && !val.isNan) addon = `${val} hp`
          if (key === 'StartYr') addon = `${val} -`
          vehicleTitle += `${addon} `
        }
      })
    }

    return vehicleTitle
  }

  switch (type) {
    case FETCH_NON_AUTO_GROUPS_REQUEST: {
      const { moduleName } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            data: {
              groups: {},
              sections: {},
              selectedGroups: [],
              selectedSections: [],
              regNumber: '',
              lookupId: null
            }
          }
        }
      }
      return state
    }
    case `${FETCH_NON_AUTO_GROUPS_REQUEST}_RESPONSE`: {
      const { moduleName, data } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              groups: Object.values(data.groups) || {}
            }
          }
        }
      }
      return state
    }
    case TOGGLE_GROUP: {
      const { moduleName, groupId, enableMultiSelectGroups } = payload
      if (moduleName && state[moduleName]) {
        let selectedGroups = _.cloneDeep(state[moduleName].data.selectedGroups) || []
        if (selectedGroups.includes(groupId)) {
          const index = selectedGroups.indexOf(groupId)
          selectedGroups.splice(index, 1)
        } else if (enableMultiSelectGroups) {
          selectedGroups.push(groupId)
        } else {
          selectedGroups = [groupId]
        }

        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isLoading: moduleName === 'non_automative',
            data: {
              ...state[moduleName].data,
              selectedGroups,
              ...getSections(selectedGroups, moduleName)
            }
          }
        }
      }
      return state
    }
    case SEARCH_FILTER_TOGGLE: {
      const { moduleName } = payload
      if (moduleName && state[moduleName]) {
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            data: {
              ...state[moduleName].data,
              showExtraFilter: !state[moduleName].data.showExtraFilter
            }
          }
        }
      }
      return state
    }

    case SEARCH_FILTER_BRAND_TOGGLE: {
      const { moduleName, brandId } = payload
      if (moduleName && state[moduleName]) {
        const selectedBrands = _.cloneDeep(state[moduleName].data.selectedBrands) ?? []
        if (selectedBrands.includes(brandId)) {
          const index = selectedBrands.indexOf(brandId)
          if (index > -1) {
            selectedBrands.splice(index, 1)
          }
        } else {
          selectedBrands.push(brandId)
        }
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            data: {
              ...state[moduleName].data,
              selectedBrands
            }
          }
        }
      }
      return state
    }

    case AXLES_FILTER_TOGGLE: {
      const { moduleName, axles, sectionId } = payload
      if (moduleName && state[moduleName]) {
        const selectedAxles = _.cloneDeep(state[moduleName].data.selectedAxles) ?? []
        selectedAxles[sectionId] = {
          ...selectedAxles[sectionId],
          ...axles[sectionId]
        }
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            data: {
              ...state[moduleName].data,
              selectedAxles
            }
          }
        }
      }
      return state
    }

    case `${TOGGLE_GROUP}_RESPONSE`: {
      const { moduleName, data } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isLoading: false,
            data: {
              ...state[moduleName].data,
              lookupId: data.lookup_id ?? null
            }
          }
        }
      }
      return state
    }

    case GROUPS_BY_PARTNUMBER_GET: {
      const { moduleName, partNumber } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            warning: false,
            data: {
              ...state[moduleName].data,
              groups: {},
              sections: [],
              selectedGroups: [],
              selectedSections: [],
              vehicle: {},
              vehicleFullName: '',
              partNumber,
              lookupId: null
            }
          }
        }
      }
      return state
    }

    case `${GROUPS_BY_PARTNUMBER_GET}_RESPONSE`: {
      const {
        moduleName,
        partNumber,
        data: { lookup_id, groups }
      } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              partNumber,
              regNumber: '',
              vinNumber: '',
              groups: Object.values(groups) || {},
              lookupId: lookup_id ?? null
            }
          }
        }
      }
      return state
    }

    case RESET_STATE: {
      return initialState
    }

    case CLEAR_DATA_FROM_STORE: {
      const { moduleName, field } = payload
      return {
        ...state,
        [moduleName]: {
          ...state[moduleName],
          data: {
            ...state[moduleName].data,
            [field]: ''
          }
        }
      }
    }

    case GROUPS_BY_REGNUMBER_GET: {
      const { moduleName, regNumber } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            data: {
              ...state[moduleName].data,
              groups: {},
              sections: [],
              selectedGroups: [],
              selectedSections: [],
              partNumber: '',
              regNumber,
              vinNumber: '',
              vehicle: {},
              lookupId: null
            }
          }
        }
      }
      return state
    }

    case `${GROUPS_BY_REGNUMBER_GET}_RESPONSE`: {
      const {
        moduleName,
        regNumber,
        data: { lookup_id, groups, vehicle }
      } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              partNumber: '',
              regNumber,
              vinNumber: '',
              groups: Object.values(groups) || {},
              vehicle: vehicle || {},
              vehicleFullName: getVehicleName(vehicle) || {},
              lookupId: lookup_id ?? null
            }
          }
        }
      }
      return state
    }

    case GROUPS_BY_VINNUMBER_GET: {
      const { moduleName, vinNumber } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            data: {
              ...state[moduleName].data,
              groups: {},
              sections: [],
              selectedGroups: [],
              selectedSections: [],
              partNumber: '',
              regNumber: '',
              vinNumber,
              vehicle: {},
              lookupId: null
            }
          }
        }
      }
      return state
    }

    case `${GROUPS_BY_VINNUMBER_GET}_RESPONSE`: {
      const {
        moduleName,
        vinNumber,
        data: { lookup_id, groups, vehicle }
      } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              partNumber: '',
              regNumber: '',
              vinNumber,
              groups: Object.values(groups) || {},
              vehicle: vehicle || {},
              vehicleFullName: getVehicleName(vehicle) || {},
              lookupId: lookup_id ?? null
            }
          }
        }
      }
      return state
    }

    case VEHICLE_DATA_GET: {
      const { moduleName, options } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            data: {
              ...state[moduleName].data,
              partNumber: '',
              regNumber: '',
              vinNumber: '',
              vehicleSearchData: [],
              vehiclesCount: 0,
              vehicleSearchIsActive: true,
              vehicleSearchDataOptions: options
            }
          }
        }
      }
      return state
    }

    case `${VEHICLE_DATA_GET}_RESPONSE`: {
      const { moduleName, data } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              partNumber: '',
              regNumber: '',
              vinNumber: '',
              vehicleSearchIsActive: false,
              vehicleSearchData: data,
              vehiclesCount: data?.vehiclesCount || 0
            }
          }
        }
      }
      return state
    }

    case PRODUCTS_BY_VEHICLE_GET: {
      const { moduleName } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: false,
            isLoading: true,
            data: {
              ...state[moduleName].data,
              groups: {},
              sections: [],
              selectedGroups: [],
              selectedSections: [],
              partNumber: '',
              regNumber: '',
              vinNumber: '',
              vehicle: {},
              lookupId: null
            }
          }
        }
      }
      return state
    }

    case `${PRODUCTS_BY_VEHICLE_GET}_RESPONSE`: {
      const {
        moduleName,
        data: { lookup_id, groups, vehicle }
      } = payload
      if (moduleName) {
        return {
          ...state,
          [moduleName]: {
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              partNumber: '',
              regNumber: '',
              vinNumber: '',
              groups: Object.values(groups) || {},
              vehicle: vehicle || {},
              vehicleFullName: getVehicleName(vehicle) || {},
              lookupId: lookup_id ?? null
            }
          }
        }
      }
      return state
    }

    case CLEAR_SECTION: {
      const { moduleName, sectionId } = payload
      if (moduleName && state[moduleName]) {
        let productCount = 0
        const selectedSections = _.cloneDeep(state[moduleName].data.selectedSections) || []
        const products = _.cloneDeep(state[moduleName].data.products) || []
        if (selectedSections.includes(sectionId)) {
          const index = selectedSections.indexOf(sectionId)
          selectedSections.splice(index, 1)
          if (products[sectionId]) {
            productCount = state[moduleName].data.productCount - products[sectionId].length
            delete products[sectionId]
          }
        }

        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            data: {
              ...state[moduleName].data,
              productCount: productCount || 0,
              products: products || [],
              selectedSections: selectedSections || []
            }
          }
        }
      }
      return state
    }

    case TOGGLE_SECTION: {
      const { moduleName, sectionId } = payload
      if (moduleName && state[moduleName]) {
        const selectedSections = _.cloneDeep(state[moduleName].data.selectedSections) || []
        selectedSections.push(sectionId)
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: false,
            isLoading: true,
            data: {
              ...state[moduleName].data,
              selectedSections: selectedSections || []
            }
          }
        }
      }
      return state
    }

    case `${TOGGLE_SECTION}_RESPONSE`: {
      const { moduleName, data, section_id } = payload
      if (moduleName && state[moduleName]) {
        const selectedSections = _.cloneDeep(state[moduleName].data.selectedSections) || []
        const products = { ...state[moduleName].data.products, [section_id]: data.products || [] }
        let uniqueBrands = []
        const brandsOld = _.cloneDeep(state[moduleName].data.brands) || []
        selectedSections.forEach((sectionId) => {
          if (products[sectionId]) {
            uniqueBrands = _.uniqBy(products[sectionId], 'brand_id').map((item) => ({
              brand_id: item.brand_id,
              brand: item.brand,
              brand_image: item.brand_image ?? null
            }))
            uniqueBrands = _.uniqBy([...uniqueBrands, ...brandsOld], 'brand_id')
          }
        })

        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            isFetched: true,
            isLoading: false,
            data: {
              ...state[moduleName].data,
              productCount: state[moduleName].data.productCount + data?.products.length,
              products: {
                ...state[moduleName].data.products,
                [section_id]: data?.products || []
              },
              sectionLookupIds: {
                ...state[moduleName].data.sectionLookupIds,
                [section_id]: data?.section_lookup_id
              },
              brands: uniqueBrands,
              selectedBrands: _.intersection(
                state[moduleName].data.selectedBrands,
                uniqueBrands.map((brand) => brand.brand_id)
              )
            }
          }
        }
      }
      return state
    }

    case `${GET_STOCK_REQUEST}_RESPONSE`: {
      const {
        moduleName,
        data: { stock },
        section_id
      } = payload
      if (moduleName && state[moduleName]) {
        const products = _.cloneDeep(state[moduleName].data.products[section_id]) || []
        const updatedProducts = products.map((product) => {
          const partNumber = product.part_number
          if (stock[partNumber]) {
            const { combined_stock, suppliers_stock, branch_stock } = stock[partNumber]
            return {
              ...product,
              combined_stock,
              suppliers_stock,
              branch_stock
            }
          }
          return {
            ...product,
            combined_stock: 0,
            suppliers_stock: [],
            branch_stock: []
          }
        })
        return {
          ...state,
          [moduleName]: {
            ...state[moduleName],
            data: {
              ...state[moduleName].data,
              products: {
                ...state[moduleName].data.products,
                [section_id]: updatedProducts || []
              }
            }
          }
        }
      }
      return state
    }

    case SEARCH_LOADING_SWITCH: {
      if (payload?.moduleName) {
        const { moduleName: mKey, status } = payload
        // eslint-disable-next-line no-param-reassign
        state[mKey].isLoading = status !== undefined ? status : !state[mKey].isLoading
      }
      return state
    }

    default: {
      return state
    }
  }
})
