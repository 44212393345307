/* eslint-disable no-param-reassign */
import produce from 'immer'

import { INIT_SETTINGS_CALL, SETTINGS_CALL, ROLES_CALL, SETTINGS_EDIT } from './actions'

const initialState = {
  settings: {
    isFetched: false,
    data: undefined
  },
  initSettings: {
    isFetched: false,
    companyId: undefined,
    modules: undefined,
    additional: undefined
  },
  roles: {
    company: {
      isFetched: false,
      data: undefined
    },
    branch: {
      isFetched: false,
      data: undefined
    },
    customer: {
      isFetched: false,
      data: undefined
    }
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    // case REDUX_STORE_RESET: {
    //     state.token = null;
    //     state.user = null;
    //     localStorageService.clearToken();
    //     return state;
    // }

    case SETTINGS_CALL: {
      state.settings.isFetched = false
      state.settings.data = undefined
      return state
    }

    case `${SETTINGS_CALL}_RESPONSE`: {
      state.settings.isFetched = true
      state.settings.data = payload.data
      return state
    }

    case `${SETTINGS_EDIT}.TRIGGER_RESPONSE`: {
      state.settings.data = state.settings.data.map((settingsItem) => {
        if (Object.keys(payload.data).includes(settingsItem.key)) {
          return { ...settingsItem, value: payload.data?.[settingsItem.key], label: '' }
        }
        return settingsItem
      })
      return state
    }

    case INIT_SETTINGS_CALL: {
      state.initSettings.isFetched = false
      state.initSettings.companyId = undefined
      state.initSettings.modules = undefined
      state.initSettings.additional = undefined
      return state
    }

    case `${INIT_SETTINGS_CALL}_RESPONSE`: {
      const { company, modules, additional } = payload

      state.initSettings.isFetched = true
      state.initSettings.companyId = company.id
      state.initSettings.modules = modules
      state.initSettings.additional = additional
      return state
    }

    // case `${INIT_SETTINGS_CALL}_FAILED`: {
    //     return state;
    // }

    case ROLES_CALL: {
      const t = payload?.type
      if (t) {
        state.roles[t].isFetched = false
        state.roles[t].data = undefined
      }
      return state
    }

    case `${ROLES_CALL}_RESPONSE`: {
      const t = payload?.type
      if (t) {
        state.roles[t].isFetched = true
        state.roles[t].data = payload.data.data.roles
      }
      return state
    }

    default: {
      return state
    }
  }
})
