/* eslint-disable no-param-reassign */
import produce from 'immer'
import localCurrencies from 'constant/currencies'

import {
  CURRENCIES_CALL,
  ALL_CURRENCIES_CALL,
  CURRENCY_RATES_CALL,
  CURRENCY_RATE_CALL,
  CURRENCY_RATE_ADD_CALL_PROMISE,
  CURRENCY_RATE_EDIT_CALL_PROMISE,
  CURRENCY_RATE_DELETE_CALL_PROMISE,
  CURRENCY_RATE_LOADING_SWITCH
} from './actions'

const initialState = {
  currencies: {
    isFetched: false,
    data: []
  },

  allCurrencies: {
    isFetched: false,
    data: []
  },

  currencyRates: {
    isFetched: false,
    data: []
  },
  currencyRate: {
    isFetched: false,
    isLoading: false,
    data: undefined
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case CURRENCIES_CALL: {
      state.currencies.isFetched = false
      state.currencies.data = []
      return state
    }

    case `${CURRENCIES_CALL}_RESPONSE`: {
      state.currencies.isFetched = true
      state.currencies.data = payload.data?.map((currency) => {
        const localCurrencyCountryISO = localCurrencies.find(
          (c) => c.currency.code === currency.name
        )?.isoAlpha2
        return {
          ...currency,
          // eslint-disable-next-line prefer-template
          flag: '/icons/flags/' + localCurrencyCountryISO?.toLowerCase() + '.png'
        }
      })
      return state
    }

    case ALL_CURRENCIES_CALL: {
      state.allCurrencies.isFetched = false
      state.allCurrencies.data = []
      return state
    }

    case `${ALL_CURRENCIES_CALL}_RESPONSE`: {
      state.allCurrencies.isFetched = true
      state.allCurrencies.data = payload.data?.map((currency) => {
        const localCurrencyCountryISO = localCurrencies.find(
          (c) => c.currency.code === currency.name
        )?.isoAlpha2
        return {
          ...currency,
          // eslint-disable-next-line prefer-template
          flag: '/icons/flags/' + localCurrencyCountryISO?.toLowerCase() + '.png'
        }
      })
      return state
    }

    case CURRENCY_RATES_CALL: {
      state.currencyRates.isFetched = false
      state.currencyRates.data = []
      return state
    }

    case `${CURRENCY_RATES_CALL}_RESPONSE`: {
      state.currencyRates.isFetched = true
      state.currencyRates.data = payload.data
      return state
    }

    case CURRENCY_RATE_CALL: {
      state.currencyRate.isFetched = false
      state.currencyRate.data = []
      return state
    }

    case `${CURRENCY_RATE_CALL}_RESPONSE`: {
      const { data } = payload
      state.currencyRate.isFetched = true
      state.currencyRate.data = data
      return state
    }

    case `${CURRENCY_RATE_ADD_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { currency_rate }
      } = payload
      state.currencyRates.data.push(currency_rate)
      return state
    }

    case `${CURRENCY_RATE_EDIT_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const {
        data: { currency_rate }
      } = payload
      const { id } = currency_rate
      state.currencyRates.data = state.currencyRates.data.map((cR) =>
        cR.id === id
          ? {
              ...cR,
              ...currency_rate
            }
          : cR
      )
      return state
    }

    case `${CURRENCY_RATE_DELETE_CALL_PROMISE}.TRIGGER_RESPONSE`: {
      const { currencyRateId } = payload
      state.currencyRates.data = state.currencyRates.data.filter((cR) => cR.id !== currencyRateId)
      return state
    }

    case CURRENCY_RATE_LOADING_SWITCH: {
      const { status } = payload
      state.currencyRate.isLoading = status !== undefined ? status : !state.currencyRate.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
