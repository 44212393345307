import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ConditionRoute = ({ children, redirectLink, condition }) => {
  if (condition) {
    return children
  }
  return <Navigate to={redirectLink} />
}

ConditionRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  condition: PropTypes.bool.isRequired,
  redirectLink: PropTypes.string
}

ConditionRoute.defaultProps = {
  redirectLink: '/not-found'
}

export default ConditionRoute
