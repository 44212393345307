import { call, put, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* fetchAccountMirrors(action) {
  const { token } = action.payload

  yield requestWithActions(
    actions.FETCH_ACCOUNT_MIRRORS_REQUEST,
    () => axiosSagaRequest('get', 'web-catalogue/account-mirror', {}, { token }),
    actions.fetchAccountMirrorsSuccess,
    actions.fetchAccountMirrorsFailure
  )
}

function* fetchAccountMirror(action) {
  const { id, token } = action.payload
  yield requestWithActions(
    actions.FETCH_ACCOUNT_MIRROR_REQUEST,
    () => axiosSagaRequest('get', `web-catalogue/account-mirror/${id}`, {}, { token }),
    actions.fetchAccountMirrorSuccess,
    actions.fetchAccountMirrorFailure
  )
}

function* createAccountMirror(action) {
  const { id, token } = action.payload
  yield requestWithActions(
    actions.CREATE_ACCOUNT_MIRROR_REQUEST,
    () => axiosSagaRequest('post', `web-catalogue/account-mirror/mirror/${id}`, {}, { token }),
    actions.createAccountMirrorSuccess,
    actions.createAccountMirrorFailure
  )
}

export default function* accountMirrorWatcherSaga() {
  yield takeLatest(actions.FETCH_ACCOUNT_MIRRORS_REQUEST, fetchAccountMirrors)
  yield takeLatest(actions.FETCH_ACCOUNT_MIRROR_REQUEST, fetchAccountMirror)
  yield takeLatest(actions.CREATE_ACCOUNT_MIRROR_REQUEST, createAccountMirror)
}
