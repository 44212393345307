export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'

export const FETCH_NOTIFICATION_REQUEST = 'FETCH_NOTIFICATION_REQUEST'
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS'
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE'

export const CLEAR_STATE = 'CLEAR_STATE'

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const fetchNotificationsRequest = createAction(FETCH_NOTIFICATIONS_REQUEST)
export const fetchNotificationsSuccess = createAction(FETCH_NOTIFICATIONS_SUCCESS)
export const fetchNotificationsFailure = createAction(FETCH_NOTIFICATIONS_FAILURE)

export const fetchNotificationRequest = createAction(FETCH_NOTIFICATION_REQUEST)
export const fetchNotificationSuccess = createAction(FETCH_NOTIFICATION_SUCCESS)
export const fetchNotificationFailure = createAction(FETCH_NOTIFICATION_FAILURE)

export const clearState = createAction(CLEAR_STATE)
