export const ERROR_ADD = 'ERROR_ADD'
export const ERROR_REMOVE = 'ERROR_REMOVE'
export const ERROR_TOGGLE = 'ERROR_TOGGLE'

export function errorAdd(error, action) {
  return { type: ERROR_ADD, payload: { error, action } }
}

export function errorRemove(errorId) {
  return { type: ERROR_REMOVE, payload: errorId }
}

export function errorToggle(errorId) {
  return { type: ERROR_TOGGLE, payload: errorId }
}
