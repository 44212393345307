import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CardDetails } from 'components/complex/index'
import { usePermission } from 'components/common/permission'
import { Icon, Dropdown, Badge, Button } from 'components/common'
import { useTranslation } from 'react-i18next'

const badgeColorsByStatus = {
  Active: 'success',
  Inactive: 'neutral'
}

const PricingListCard = memo(({ onAction, data, className, isSelling = false }) => {
  const { t } = useTranslation()

  const { id, brand, price_file, updated_at, account, refined, priority } = data

  const canManagePricing = usePermission('pricing-manage')

  const pricingActions = useMemo(() => {
    const actions = [
      {
        actionStatusId: 'edit',
        action: onAction('edit'),
        visible: true,
        icon: 'edit-outlined',
        text: t('Edit')
      },
      {
        actionStatusId: 'create',
        action: onAction('createSellingPrices'),
        visible: true,
        icon: 'folder-arrow-outlined',
        text: t('Create selling prices')
      },
      {
        actionStatusId: 'primary',
        action: onAction('makePrimary'),
        visible: !data.priority && !isSelling,
        icon: 'plus',
        text: t('Make Primary')
      },
      {
        actionStatusId: 'history',
        action: onAction('history'),
        visible: true,
        icon: 'calendar',
        text: t('History')
      },
      {
        actionStatusId: 'export',
        action: onAction('export'),
        visible: !isSelling,
        icon: 'download',
        text: t('Export')
      },
      {
        actionStatusId: 5,
        action: onAction('delete'),
        visible: true,
        icon: 'trash-delete',
        text: t('Delete')
      }
    ]

    if (canManagePricing) {
      if (data.status) {
        return actions.filter((item) => item.actionStatusId !== 'create')
      }
      if (isSelling) {
        return actions.filter((item) => ['edit', 'history', 5].includes(item.actionStatusId))
      }
    }

    if (!canManagePricing) {
      return actions.filter((item) => item.actionStatusId === 'history')
    }

    return actions
  }, [isSelling, onAction])

  return (
    <div className={`list-card list-card--${className}`}>
      <div className="list-card__header">
        {brand.image ? (
          <img className="list-card__avatar list-card__avatar--img" src={brand.image} alt="img" />
        ) : (
          <div className="list-card__avatar list-card__avatar--text">{brand.name.charAt(0)}</div>
        )}
        <div className="list-card__title">
          <span className="list-card__title__primary flex">
            {brand.name}
            {priority && <p style={{ marginLeft: '4px', color: '#2ca125' }}>{t('Primary')}</p>}
          </span>
        </div>

        <Dropdown
          label={
            <Button isIcon variant="outlined">
              <Icon iconClass="dots" type="lighter" size="18px" />
            </Button>
          }
          menuAlignment="right"
          className="list-card__dropdown"
        >
          {pricingActions.map(
            ({ action, icon, visible, text, actionStatusId }) =>
              visible && (
                <Dropdown.Item
                  key={text}
                  className={`list-card__dropdown-item ${actionStatusId === 5 ? 'trash-red' : ''}`}
                  onClick={() => action(id)}
                >
                  <Icon iconClass={icon} type="dark" size="16px" />
                  <span>{text}</span>
                </Dropdown.Item>
              )
          )}
        </Dropdown>
      </div>
      <div className="list-card__body">
        <CardDetails
          data={{
            dataModified: moment(updated_at).format('DD.MM.YYYY') || '-',
            fileName: price_file?.name || '-',
            supplier: account?.name || '-'
          }}
          refined={refined}
        />
        <Badge
          title={data.status ? t('Active') : t('Inactive')}
          type={badgeColorsByStatus[data?.status ? t('Active') : t('Inactive')]}
        />
      </div>
    </div>
  )
})

PricingListCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

PricingListCard.defaultProps = {
  className: ''
}

export default PricingListCard
PricingListCard.displayName = 'PricingListCard'
