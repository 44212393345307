/* eslint-disable no-param-reassign */
import produce from 'immer'
import { arrayMove } from 'utils/helpers'
import {
  CUSTOMER_TYPES_CALL,
  CUSTOMER_TYPE_GET,
  SECTIONS_BY_GROUP_GET,
  BRANDS_BY_SECTION_GET,
  CUSTOMER_TYPE_ADD_PROMISE,
  CUSTOMER_TYPE_RENAME_PROMISE,
  CUSTOMER_TYPE_DELETE_PROMISE,
  CUSTOMER_TYPE_SORT_PROMISE,
  CUSTOMER_TYPE_LOADING_SWITCH
} from './actions'

const initialState = {
  customerTypes: {
    isFetched: false,
    isLoading: false,
    data: []
  }
}

export default produce((state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMER_TYPES_CALL: {
      state.customerTypes.isFetched = false
      state.customerTypes.data = []
      return state
    }

    case `${CUSTOMER_TYPES_CALL}_RESPONSE`: {
      state.customerTypes.isFetched = true
      state.customerTypes.data = payload.data || []
      return state
    }

    case CUSTOMER_TYPE_GET: {
      const { customerTypeId } = payload
      state.customerTypes.preferences = []
      return state
    }

    case `${CUSTOMER_TYPE_GET}_RESPONSE`: {
      const { data, customerTypeId } = payload || {}
      const form_data = data?.form_data || {}
      if (customerTypeId) {
        const foundCustomerType = state.customerTypes.data.find(
          (item) => item.id === customerTypeId
        )
        if (foundCustomerType) {
          const updatedState = {
            ...state,
            customerTypes: {
              ...state.customerTypes,
              data: state.customerTypes.data.map((item) => {
                if (item.id === customerTypeId) {
                  return {
                    ...item,
                    preferences: { ...form_data }
                  }
                }
                return item
              })
            }
          }
          return updatedState
        }
      }

      return state
    }

    // case `${CUSTOMER_TYPE_GET}_RESPONSE`: {
    //   const { data, customerTypeId } = payload || {}
    //   const preferences = data?.preferences || {}
    //   if (customerTypeId) {
    //     const index = state.customerTypes.data.findIndex(item => item.id === customerTypeId);
    //     if (index !== -1) {
    //       state.customerTypes.data[index].preferences = {...preferences};
    //     }
    //   }
    //   return state;
    // }

    case SECTIONS_BY_GROUP_GET: {
      // const { customerTypeId } = payload
      // state.customerTypes.data = []
      return state
    }

    case `${SECTIONS_BY_GROUP_GET}_RESPONSE`: {
      const { data, groupId, customerTypeId } = payload || {}
      const sections = data || {}

      if (!customerTypeId || !state.customerTypes || !state.customerTypes.data) {
        return state
      }

      const customerTypes = [...state.customerTypes.data]
      const customerTypeIndex = customerTypes.findIndex((item) => item.id === customerTypeId)

      if (customerTypeIndex === -1 || !customerTypes[customerTypeIndex].preferences.groups) {
        return state
      }

      const groupIndex = customerTypes[customerTypeIndex].preferences.groups.findIndex(
        (item) => item.id === groupId
      )

      if (groupIndex === -1) {
        return state
      }

      state.customerTypes.data[customerTypeIndex].preferences.groups[groupIndex].sections = [
        ...sections
      ]
      return state
    }

    case BRANDS_BY_SECTION_GET: {
      // const { customerTypeId } = payload
      // state.customerTypes.data = []
      return state
    }

    case `${BRANDS_BY_SECTION_GET}_RESPONSE`: {
      const { data, sectionId, customerTypeId, groupId } = payload || {}
      const brands = data || {}

      if (!customerTypeId || !state.customerTypes || !state.customerTypes.data) {
        return state
      }

      const customerTypes = [...state.customerTypes.data]
      const customerTypeIndex = customerTypes.findIndex((item) => item.id === customerTypeId)

      if (customerTypeIndex === -1 || !customerTypes[customerTypeIndex].preferences.groups) {
        return state
      }

      const groupIndex = customerTypes[customerTypeIndex].preferences.groups.findIndex(
        (item) => item.id === groupId
      )

      if (groupIndex === -1) {
        return state
      }

      const sectionIndex = customerTypes[customerTypeIndex].preferences.groups[
        groupIndex
      ].sections.findIndex((item) => item.id === sectionId)
      if (sectionIndex === -1) {
        return state
      }

      state.customerTypes.data[customerTypeIndex].preferences.groups[groupIndex].sections[
        sectionIndex
      ].brands = [...brands]
      return state
    }

    case `${CUSTOMER_TYPE_ADD_PROMISE}.TRIGGER_RESPONSE`: {
      const { data } = payload;
      if (data?.customerType) {
        data.customerType.isNew = true
        const newState = {
          ...state,
          customerTypes: {
            ...state.customerTypes,
            data: [...state.customerTypes.data, data.customerType]
          }
        };
        return newState;
      }
      return state;
    }

    case `${CUSTOMER_TYPE_RENAME_PROMISE}.TRIGGER_RESPONSE`: {
      const { id, name } = payload;

      if (id) {
        const updatedState = {
          ...state,
          customerTypes: {
            ...state.customerTypes,
            data: state.customerTypes.data.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  name
                };
              }
              return item;
            })
          }
        };
        return updatedState;
      }

      return state;
    }

    case `${CUSTOMER_TYPE_DELETE_PROMISE}.TRIGGER_RESPONSE`: {
      const { customerTypeId } = payload
      if (customerTypeId) {
        state.customerTypes.data = state.customerTypes.data.filter((cT) => cT.id !== customerTypeId)
      }
      return state
    }

    case `${CUSTOMER_TYPE_SORT_PROMISE}.TRIGGER_RESPONSE`: {
      const { customerTypeIds } = payload
      state.customerTypes.data = state.customerTypes.data.sort(
        (a, b) => customerTypeIds.indexOf(a.id) - customerTypeIds.indexOf(b.id)
      )
      return state
    }

    case CUSTOMER_TYPE_LOADING_SWITCH: {
      const { status } = payload
      state.customerTypes.isLoading = status !== undefined ? status : !state.customerTypes.isLoading
      return state
    }

    default: {
      return state
    }
  }
})
