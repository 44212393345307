// eslint-disable-next-line max-classes-per-file
import { Suspense, lazy } from 'react'
import { Loader } from 'components/common'

/**
 * Load scenes while it needed(lazy loading)
 */
const CatalogueAutomotiveScene = lazy(() => import('./scenes/catalogue/automative'))
const CatalogueNonAutomativeScene = lazy(() => import('./scenes/catalogue/nonautomative'))
const CartScene = lazy(() => import('./scenes/cart'))
const OrdersScene = lazy(() => import('./scenes/orders'))
const CustomerOrdersScene = lazy(() => import('./scenes/customerOrders/index'))

/**
 * Initialize catalogue regarded routes
 */
class CatalogueController {
  static path = 'catalogue'
}

class CatalogueAutomativeController {
  static path = 'automotive'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CatalogueAutomotiveScene />
    </Suspense>
  )
}

class CatalogueNonAutomativeController {
  static path = 'non-automotive'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CatalogueNonAutomativeScene />
    </Suspense>
  )
}

class CartController {
  static path = 'cart'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CartScene />
    </Suspense>
  )
}

class OrdersController {
  static path = 'orders'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <OrdersScene />
    </Suspense>
  )
}

class CustomerOrdersController {
  static path = 'customer-orders'

  static Component = (
    <Suspense fallback={<Loader linear />}>
      <CustomerOrdersScene />
    </Suspense>
  )
}

export {
  CatalogueController,
  CatalogueAutomativeController,
  CatalogueNonAutomativeController,
  CartController,
  OrdersController,
  CustomerOrdersController
}
