import { createPromiseAction } from '@adobe/redux-saga-promise'

export const FINANCIAL_DETAILS_CALL = 'FINANCIAL_DETAILS_CALL'
export const FINANCIAL_DETAILS_EDIT_PROMISE = 'FINANCIAL_DETAILS_EDIT_PROMISE'

export const FINANCIAL_DETAILS_LOADING_SWITCH = 'FINANCIAL_DETAILS_LOADING_SWITCH'

export const financialDetailsEditPromise = createPromiseAction(FINANCIAL_DETAILS_EDIT_PROMISE)

export function financialDetailsCall(moduleName, id) {
  return { type: FINANCIAL_DETAILS_CALL, payload: { moduleName, id } }
}

export function financialDetailsLoadingSwitch(moduleName, status) {
  return { type: FINANCIAL_DETAILS_LOADING_SWITCH, payload: { moduleName, status } }
}
