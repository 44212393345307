/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

/**
 * Dropdown divider component
 * @param {Number, String} height - Divider height
 * @param {Number, String} width - Divider width
 */

const DropdownDivider = memo(({ height, width }) => (
  <hr
    className="dropdown__divider"
    style={{
      height,
      width
    }}
    width={width}
  />
))

DropdownDivider.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DropdownDivider.defaultProps = {
  height: 1,
  width: '100%'
}

export default DropdownDivider
