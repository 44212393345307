/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { userDataSelector } from 'store/user/selectors'
import { toggleSidebar, exitMirroring } from 'store/account-mirror/actions'
import { accountMirrorDataSelector } from 'store/account-mirror/selectors'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserHeader, Icon, Dropdown, Badge } from 'components/common'
import LinkIcon from 'assets/images/link_icon.png'
import userAvatarDefault from 'assets/images/user_avatar.png'
import { userLogout, userSwitchSuccess } from 'store/user/actions'
import { resetState } from 'store/catalogue/actions'
import { usePermission } from 'components/common/permission'
import localStorageService from 'utils/storage/localStorageService'
import { generateImageUrl } from 'utils/helpers'

export default function UserDropdown() {
  const history = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { user } = useSelector(userDataSelector)
  const [isRedirected, setIsRedirected] = useState()

  const originalUserData = localStorageService.getOriginalToken()
  const previousUserData = localStorageService.getPreviousToken()

  const canAccessDashboard = usePermission('dashboard-access')
  const canAccountMirror = usePermission('account-mirror')

  const userFullName = user.user_detail?.full_name
  const userAvatar = user.image ? generateImageUrl(user.base_url, `/${user.image}`) : userAvatarDefault
  const userEmail = user?.email
  const userRole = user?.roles[0]?.name

  const [route, setRoute] = useState({
    name: location.pathname.includes('admin') ? t('Catalogue') : t('Dashboard'),
    to: location.pathname.includes('admin') ? '/catalogue' : '/admin',
    isAdmin: location.pathname.includes('admin'),
    showMyCompany: !location.pathname.includes('admin'),
    showMyProfile: !location.pathname.includes('admin'),
    showPreferences: !location.pathname.includes('admin'),
    showOrders: !location.pathname.includes('admin'),
    showCustomerOrders: !location.pathname.includes('admin') && user?.account.type === 'branch',
    showAccountMirroring: !location.pathname.includes('admin')
  })

  useEffect(() => {
    setIsRedirected(Boolean(previousUserData.previousToken))
  }, [previousUserData])

  const handleLogout = () => {
    dispatch(userLogout())
    dispatch(resetState())
  }

  const handleRoute = (to) => {
    history(to)
  }

  const handleViewCatalogue = () => {
    dispatch(toggleSidebar())
  }

  const handleExitView = () => {
    dispatch(exitMirroring())

    window.location.reload()
    history('/catalogue')

    dispatch(
      userSwitchSuccess({
        token: originalUserData.originalToken,
        user: originalUserData.originalUser
      })
    )
  }

  return (
    <>
      <UserHeader image={userAvatar} name={userFullName} userRole={t(userRole)} type="dropdown">
        <Dropdown.Item>
          <span>{userFullName}</span>
          <span>{userEmail}</span>
        </Dropdown.Item>
        <Dropdown.Divider />

        {!isRedirected ? (
          <>
            {canAccessDashboard && (
              <>
                <Dropdown.Item onClick={() => handleRoute(route.to)}>
                  <div className="mr-xs">
                    <Icon
                      iconClass={route.isAdmin ? 'book' : 'dashboard'}
                      type="dark"
                      width="16px"
                      height="12.22px"
                    />
                  </div>
                  {route.name}
                  <img src={LinkIcon} alt="Link Icon" />
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}

            {route.showOrders && (
              <Dropdown.Item onClick={() => handleRoute('/catalogue/orders')}>
                {t('Orders')}
                {/* {ordersCount > 0 && (
                  <div className="ml-xs">
                    <Badge title={ordersCount} type="danger" />
                  </div>
                )} */}
              </Dropdown.Item>
            )}
            {route.showCustomerOrders && (
              <Dropdown.Item onClick={() => handleRoute('/catalogue/customer-orders')}>
                {t('Customer Orders')}
                {/* {ordersCount > 0 && (
                  <div className="ml-xs">
                    <Badge title={ordersCount} type="danger" />
                  </div>
                )} */}
              </Dropdown.Item>
            )}
            {/* {route.showMyCompany && <Dropdown.Item>{t('My Company')}</Dropdown.Item>}
            {route.showMyProfile && <Dropdown.Item>{t('My Profile')}</Dropdown.Item>}
            {route.showPreferences && (
              <>
                <Dropdown.Item>{t('Preferences')}</Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item>
              <div className="mr-xs">
                <Icon iconClass="help" type="dark" width="16px" height="16px" />
              </div>
              {t('Help')}
            </Dropdown.Item> */}
            {canAccountMirror && route.showAccountMirroring && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleViewCatalogue}>
                  <div className="mr-xs">
                    <Icon iconClass="eye" type="dark" width="16px" height="16px" />
                  </div>
                  {t('View Catalogues As')}:
                </Dropdown.Item>
              </>
            )}
            <>
              {!route.isAdmin && <Dropdown.Divider />}
              <Dropdown.Item onClick={handleLogout}>
                <div className="mr-xs">
                  <Icon iconClass="logout" type="danger" width="16px" height="14px" />
                </div>
                {t('logout')}
              </Dropdown.Item>
            </>
          </>
        ) : (
          <Dropdown.Item onClick={handleExitView}>
            <div className="mr-xs">
              <Icon iconClass="logout" type="danger" width="16px" height="14px" />
            </div>
            {t('Exit View')}
          </Dropdown.Item>
        )}
      </UserHeader>
    </>
  )
}
