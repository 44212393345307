import { useRefreshToken } from 'hooks'
import { ErrorWindow } from 'components/common'
import { withReduxDataFetch } from 'components/HOC'
import { Header, Sidebar } from 'components/admin'
import { initSettingsCall, settingsCall } from 'store/general/actions'
import { initSettingsSelector, settingsSelector } from 'store/general/selectors'
import { currencyRatesCall } from 'store/currency/actions'
import { currencyRatesSelector } from 'store/currency/selectors'
import { refreshToken } from 'store/user/actions'
import { userTokenSelector } from 'store/user/selectors'
import { Outlet } from 'react-router-dom'
import { withPermission } from 'components/common/permission'

const AdminLayout = () => {
  useRefreshToken()

  return (
    <>
      <Header />
      <Sidebar />
      <main className="main main--admin">
        <Outlet />
      </main>
      <ErrorWindow />
    </>
  )
}

// Load user initial settings before accessing admin page
const AdminLayoutLayer2 = withReduxDataFetch([
  {
    call: initSettingsCall,
    selector: initSettingsSelector
  },
  {
    call: settingsCall,
    selector: settingsSelector
  },
  {
    call: currencyRatesCall,
    selector: currencyRatesSelector
  }
])(AdminLayout)

// Refresh token before accessing admin page
const AdminLayoutLayer1 = withReduxDataFetch([
  {
    call: refreshToken,
    selector: userTokenSelector
  }
])(AdminLayoutLayer2)

export default withPermission('dashboard-access', 'dashboard-access')(AdminLayoutLayer1)
